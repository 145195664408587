/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

import {
  Grid,
  makeStyles,
  Button as ButtonMaterial,
  Typography,
  CircularProgress,
  Modal,
  Box,
  Stepper,
  Step,
  StepButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  IconButton,
  AppBar,
  DialogContentText
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions'

import { GroupCard } from '../index';
import { showCharge, deleteCharge, updateChargeOwnerData, getChargeOwnerData, showTmpCharge, getChargeVoltage } from 'js/actions/chargeActions';
import { isAllowed } from 'helpers/auth';
import Swal from 'sweetalert2'
import styles from './styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import { showCards } from 'js/actions/cardAction';
import { getGeneralConsume } from 'js/actions/chargeDashboardActions';
import { withStyles } from '@material-ui/styles';
const useStyles = makeStyles(styles);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    borderTop: '1px solid rgb(128 128 128 / 50%)'
  },
}))(MuiDialogActions)

export default function GridGroup(props) {
  const classes = useStyles();
  const intl = useIntl()
  const { groupId, className, charge, typeEnergy } = props;
  const dispatch = useDispatch();
  const rootClassName = classNames(classes.root, className);
  let charges = useSelector(state => state.charges.charges);
  const user = useSelector(state => state.user.user);
  const chargeOwner = useSelector(state => state.charges.chargeOwner);
  const owner = useSelector(state=>state.chargeDashboard.generalConsumes.charge_info);
  const [activeStep, setActiveStep] = React.useState(0);
  const groups = useSelector(state => state.groups.groups);
  const [tabIndex, setTabIndex] = React.useState(0);
  const storedGroupName = localStorage.getItem(`groupName_${groupId}`);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const chargeInfo = useSelector(state => state.charges.charge);
  const [tmpCharge, setTmpCharge] = useState({});
  const corporative = useSelector(state => state.user.corporative);
  const [chargeVolts, setChargeVolts]=React.useState([]);
  const [newOwnerName, setNewOwnerName] = useState('');
  const [ownerName, setOwnerName] = useState(chargeOwner && chargeOwner !== null ? chargeOwner : '')
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  let chargeState = useSelector(state=>state.charges.loading);
  let isLoading = chargeState

  useEffect(()=>{
    if(selectedCharge)
      dispatch(getGeneralConsume(selectedCharge.id, new Date().getFullYear(), new Date().getMonth()+1))
  },[selectedCharge, ])

  
  useEffect(()=> {
    if(corporative) {
      dispatch(getChargeVoltage(corporative.id, funcVoltage));
    }
  }, [corporative])

  const funcVoltage = info => {
    info ? setChargeVolts(info) : setChargeVolts([])
  }

  useEffect(()=>{
    dispatch(showTmpCharge(selectedCharge ? selectedCharge.id : null))
  },[selectedCharge])

  useEffect(()=>{
    setOwnerName(chargeOwner && chargeOwner !== null ? chargeOwner : '')
  },[chargeOwner])


  useEffect(()=>{
    setTmpCharge({...tmpCharge,charge});
  },[charge]);


  useEffect(()=>{
    if(groups[0]){
      localStorage.setItem(`groupName_${groupId}`, groups[0].name);
    }
  }, [groups, groupId])

  const groupName = groups[0] ? groups[0].name : storedGroupName;


  const getSteps = () => {
    return [intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.measurement.title'}), intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.setting.title'}), /* 'Rango de Medición' */]
  }
  const steps = getSteps();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const comunicaciones = () => {

    const chargeType = () => {
      switch(selectedCharge.charge.charge_type) {
        case '1':
          return 'MONOFASICA';
        case '2':
          return 'BIFASICA';
        case '3':
          return 'TRIFASICA';
        default:
          return selectedCharge.charge.charge_type;
      }
    }
    

    return (
      <Box style={{width:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography
          variant="h5"
          style={{marginBottom: '10px', fontWeight: 400}}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.Params'})} {selectedCharge.type_unit}
        </Typography>
        <Typography
          variant="h5"
          style={{marginBottom: '10px', fontWeight: 400}}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElemLbl'})}: {selectedCharge.charge.element_type}
        </Typography>
        <Typography
          variant="h5"
          style={{marginBottom: '10px', fontWeight: 400}}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.measureTypeLbl'})}: {chargeType()}
        </Typography>
        <Typography
          variant="h5"
          style={{marginBottom: '10px', fontWeight: 400}}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.inputsUsed'})}: {selectedCharge.charge.num_inputs}
        </Typography>
        <Typography
          variant="h5"
          style={{marginBottom: '10px', fontWeight: 400}}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.groupLbl'})}: {groupName}
        </Typography>
      </Box>
    )
  }

  const rangoMedicion = () => {
    return (
      <Box style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
        <TextField
          defaultValue={selectedCharge.charge.demanda_min ? selectedCharge.charge.demanda_min : '0'}
          id="outlined-read-only-input"
          InputProps={{
            readOnly: true,
          }}
          label="Demanda Mínima"
          style={{marginBottom: '1rem'}}
          variant="outlined"
        />
        <TextField
          defaultValue={selectedCharge.charge.demanda_max ? selectedCharge.charge.demanda_max : '0'}
          id="outlined-read-only-input"
          InputProps={{
            readOnly: true,
          }}
          label="Demanda Máxima"
          style={{marginBottom: '1rem'}}
          variant="outlined"
        />
        <TextField
          defaultValue={selectedCharge.charge.consumo_min ? selectedCharge.charge.consumo_min : '0'}
          id="outlined-read-only-input"
          InputProps={{
            readOnly: true,
          }}
          label="Consumo Mínimo"
          style={{marginBottom: '1rem'}}
          variant="outlined"
        />
        <TextField
          defaultValue={selectedCharge.charge.consumo_max ? selectedCharge.charge.consumo_max : '0'}
          id="outlined-read-only-input"
          InputProps={{
            readOnly: true,
          }}
          label="Consumo Máximo"
          variant="outlined"
        />
      </Box>
    )
  }

  const medicion = () => {
    const phases = () => {
      return (
        chargeInfo ? chargeInfo.inputs.map((item)=>(
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            fontWeight={400}
          >
            <Typography
              variant="h5"
              style={{fontWeight: 400}}
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.modBusLbl'})}: {item.card_modbus}
            </Typography>
            <Typography
              variant="h5"
              style={{fontWeight: 400}}
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.inputsLbl'})}: {item.input}
            </Typography>
            <Typography
              variant="h5"
              style={{fontWeight: 400}}
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.relationLbl'})}: {item.relation}
            </Typography>
            <Typography
              variant="h5"
              style={{fontWeight: 400}}
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.adjustLbl'})}: {item.ajust}
            </Typography>
            <Typography
              variant="h5"
              style={{fontWeight: 400}}
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.offsetLbl'})}: {item.offset}
            </Typography>
          </Box>
        )) : null
      )
    }
    return (
      <Box style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
        <Box style={{ marginBottom: '10px' }}>
          <Typography
            variant="h4"
            style={{ marginBottom: '10px', textAlign: 'center' }}
          >{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.phaseConftitle'})}</Typography>
          {phases()}
        </Box>
        <Typography
          variant="h5"
          style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 400 }}
        >
          <Typography
            variant="h4"
            style={{ marginBottom: '10px' }}
          >{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.predefVolt'})}</Typography>
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.voltageLbl'})}: {chargeInfo.voltage ? chargeInfo.voltage.constant_value : null}
        </Typography>
        <Typography
          variant="h5"
          style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 400 }}
        >
          <Typography
            variant="h4"
            style={{ marginBottom: '10px' }}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.nivelLbl'})}
          </Typography>
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.nivelLbl'})}: {chargeInfo && chargeInfo.is_father === 1 ? 'Master' : 'Slave'}
        </Typography>
        <Typography
          variant="h5"
          style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 400 }}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.dynamicFP'})}: {chargeInfo.voltage && chargeInfo.voltage.is_constant ? 'No' : 'Si'}
        </Typography>
        <Typography
          variant="h5"
          style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 400 }}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.dynamicVoltage'})}: {chargeInfo.fp && chargeInfo.fp.is_constant ? 'No' : 'Si'}
        </Typography>
        <Typography
          variant="h5"
          style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 400 }}
        >
          <Typography
            variant="h4"
            style={{ marginBottom: '10px'}}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.fpLbl'})}
          </Typography>
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.prede'})}: {chargeInfo.fp ? chargeInfo.fp.constant_value : null}
        </Typography>
      </Box>
    )
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return comunicaciones();
      case 1:
        return medicion();
      // case 2:
      //   return rangoMedicion();
      default:
        return null;
    }
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  //const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id

  useEffect(() => {
    dispatch(showCharge(groupId))
  }, []);

  useEffect(() => {   
    props.handleCountStatusChanged()
  }, [props.handleCountStatusChanged]);

  const deleteChargeData = charge => {
    Swal.fire({
      title: `${intl.formatMessage({id:'app.chargesCards.deleteChargeBtn.title'})} `,
      text: `${intl.formatMessage({id:'app.chargesCards.deleteChargeBtn.text'})} ${charge.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'app.chargesCards.deleteChargeBtn.confirmation'})
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({id: 'app.chargesCards.deleteChargeBtn.titleConfirmation'}),
          text: intl.formatMessage({id: 'app.chargesCards.deleteChargeBtn.textConfirmation'}),
          icon: 'success'
        });
        dispatch(deleteCharge(charge.id));
      }
    });
  };

  const handleOpenReceiptDialog = (charge) => {
    dispatch(getChargeOwnerData(charge ? charge.id : null))
    setSelectedCharge(charge);
    setOpenReceiptDialog(true);
  };

  const handleCloseReceiptDialog = () => {
    setOpenReceiptDialog(false);
  };

  const handleSaveReceiptData = () => {
    dispatch(updateChargeOwnerData(selectedCharge.id, newOwnerName))
      .then(() => {
        setOpenReceiptDialog(false);
        setNewOwnerName('');
      })
      .then(
        Swal.fire({
          icon: 'success',
          title: 'Carga actualizada correctamente'
        })
      )
      .catch(function(error) {
        const errorMessage = error.response.data.message
  
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
        })
      });
  }
  

  const [showEditButton, setShowEditButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showInvoiceDataButton, setShowInvoiceDataButton] = useState(false);  
  const [showReceiptDataButton, setShowReceiptDataButton] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const getReceiptData = charge => {
    if(showReceiptDataButton === false) {
      return <div />;
    }else{
      return (
        <ButtonMaterial
          color="secondary" 
          onClick={() => handleOpenReceiptDialog(charge)}
          variant="outlined"
        >
          {intl.formatMessage({id: 'app.chargesCards.receiptData.tooltip'})}
        </ButtonMaterial>
      )
    }
  }

  const getEditButton = charge => {
    if (showEditButton == false) {
      return <div />;
    } else {
      return (
        <Link
          to={{
            pathname: `/updateCharge/${charge.id}`,
            state: { charge: charge }
          }}
        >
          <ButtonMaterial
            color="secondary"
            variant="outlined"
          >
            {intl.formatMessage({id: 'app.chargesCards.editChargeBtn'})}
          </ButtonMaterial>
        </Link>
      );
    }
  };

  const getDeleteButton = charge => {
    if (showDeleteButton == false) {
      return <div />;
    } else {
      return (
        <ButtonMaterial
          onClick={() => deleteChargeData(charge)}
          style={{border: '1px solid #D64539', color: '#D64539'}}
          variant="outlined"
        >
          {intl.formatMessage({id: 'app.chargesCards.deleteChargeBtn'})}
        </ButtonMaterial>
      );
    }
  };


  const getInvoiceDataButton = (charge) => {
    if (showInvoiceDataButton == false) {
      return <div />;
    } else {
      return(
        <Link
          to={{
            pathname: `/invoiceDataCharge/${charge.id}`,
            state: {charge: charge  , groupId : groupId }
          }}
        >
          <ButtonMaterial
            color="secondary" 
            variant="outlined"  
          >
            {intl.formatMessage({id: 'app.chargesCards.invDataBtn'})}</ButtonMaterial>
        </Link> );
    }
  };

  const receiptDialog = (
    <Dialog
      open={openReceiptDialog}
      onClose={handleCloseReceiptDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle>
        {intl.formatMessage({ id: 'app.chargesCards.receiptData.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!ownerName ? `${intl.formatMessage({ id: 'app.chargesCards.receiptData.label' })} ${selectedCharge ? selectedCharge.name : ''}` : `${intl.formatMessage({ id: 'app.chargesCards.receiptData.labelName' })} ${ownerName}`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre de Propietario"
          type="text"
          variant="outlined"
          fullWidth
          onChange={(e) => setNewOwnerName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <ButtonMaterial
          onClick={handleSaveReceiptData}
          color="secondary"
          variant="outlined"
          disabled={!newOwnerName}
        >
          Guardar
        </ButtonMaterial>
        <ButtonMaterial
          onClick={handleCloseReceiptDialog}
          style={{backgroundColor: '#D64539', color: 'white'}}
          variant="contained"
        >
          Cancelar
        </ButtonMaterial>
      </DialogActions>
    </Dialog>
  );


  const displayCharge = (charge) => {
    if (charge.visible == 1 ) {
      return(
        <Grid
          item
          lg={4}
          md={6}
          xs={12}
        >
          <GroupCard
          // deleteButton={showDeleteButton}
          //editButton={showEditButton}
            charge={charge}
            groupId={groupId}
            item
            setSelectedCharge={setSelectedCharge}
            setShowDetails={setShowDetails}
            typeEnergy={typeEnergy}
            setOpenReceiptDialog={setOpenReceiptDialog}
          // key={group.id}
          />
          <div>
            {charge.type_unit === 'VOLTAJE' ? '' : getInvoiceDataButton(charge)}
            {charge.type_unit === 'VOLTAJE' ? '' : getReceiptData(charge)}
            {getEditButton(charge) }
            {getDeleteButton(charge)}
          </div>
        </Grid>);
    } else {
      return null;
    }
  };

  return (
    <div>
      {isLoading ? 
        (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><CircularProgress /></div>)
        :
        (
          <>
          {charges.length > 0 ? (
            <Grid
              container
              spacing={2}
            >
              {charges 
                .sort((a, b) => {

                  if( a.charge_status==b.charge_status ){
                    return a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
                  }else {

                    if( a.charge_status==1 ){
                      return -1;
                    }else{
                      return  1 ;
                    }                
                  }              
                }
                )
                .map(charge => (
                  displayCharge(charge)
                ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Typography
                  style={{ textAlign: 'center' }}
                  variant="h2"
                >
                  {intl.formatMessage({id: 'app.chargesCards.noChargeMsg'})}{' '}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
        )}
      <Container
        styles={{ marginRight: '-20px', marginBottom: '-20px', zIndex: 900 }}
      >

        {/* <Button
          icon="fas fa-file-invoice"
          onClick={()=> setShowReceiptDataButton(!showReceiptDataButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.chargesCards.receiptData.title'})}
        />

        <Button
          icon="fas fa-file-invoice-dollar"
          onClick={() => setShowInvoiceDataButton(!showInvoiceDataButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.chargesCards.invDataBtn.toolTip'})}
        />


        {isAllowed(user.permissions, ['charge_delete']) && (
          <Button
            icon="fa fa-trash"
            onClick={() => setShowDeleteButton(!showDeleteButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.chargesCards.deleteChargeBtn.toolTip'})}
          />
        )}
        {isAllowed(user.permissions, ['charge_update']) && (
          <Button
            icon="fa fa-pencil-alt"
            onClick={() => setShowEditButton(!showEditButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.chargesCards.editChargeBtn.toolTip'})}
          />
        )} */}

        {isAllowed(user.permissions, ['charge_create']) && (
          <LinkFloat
            href={`/newCharge/${groupId}`}
            icon="fa fa-plus"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.chargesCards.addChargeBtn.toolTip'})}
          />
        )}

        {/* <Button
          icon="fa fa-cog"
          rotate
          styles={{
            backgroundColor: '#C69100',
            color: '#fff',
            width: '80px',
            height: '80px',
            fontSize: '1.5em'
          }}
        /> */}
      </Container>
      <Dialog
        onClose={() => {setShowDetails(false); setActiveStep(0)}}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth={'sm'}
        open={showDetails}
      >
        <DialogTitle
          onClose={() => {setShowDetails(false); setActiveStep(0)}}
          id="customized-dialog-title"
        >
          <Box style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
            <Box>
              <Typography variant="h6">{selectedCharge ? selectedCharge.name : ''}</Typography>
              <Typography variant="body2">{chargeVolts[0] ? chargeVolts[0].name : '' }{owner && owner.owner !== null ? ` - ${owner.owner}` : ''}</Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {setShowDetails(false); setActiveStep(0)}}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          style={{marginBottom: '3rem'}}
        >
          <AppBar
            position="static"
            color="transparent"
            style={{boxShadow: 'none', marginTop: '.5rem'}}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: tabIndex === 0 ? '#FEC12C' : '#A53693',
                }
              }}
              variant="fullWidth"
            >
              <Tab
                style={{fontSize: '1rem', color:'#FEC12C'}}
                label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.measurement.title'})}
              />
              <Tab
                style={{fontSize: '1rem', color:'#A53693'}}
                label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.setting.title'})}
              />
            </Tabs>
          </AppBar>
          { selectedCharge && chargeInfo ? (
            <Box style={{marginTop: '3rem'}}>
              {tabIndex === 0 && comunicaciones()}
              {tabIndex === 1 && medicion()}
            </Box>
          ):(
            <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height: '50vh'}}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>
      {receiptDialog}
    </div>
  );
}