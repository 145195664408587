/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { postForm } from 'js/actions/formsAction';
import { postDataCharge } from 'js/actions/chargeActions';
import { deleteLastPostForm } from 'js/actions/formsAction';

import {
  Typography,
  Button,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const intl = useIntl();
  const classes = useStyles();
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;

  //console.log(props)

  const completeCharge = charge => {
    dispatch(postDataCharge(charge));
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }
  

  const getInputs = chargeType => {
    switch (chargeType) {
      case '1':
        return [
          {
            input: data[2].data.inpunt1PhaseA,
            phase: '1',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          }
        ];

      case '2':
        return [
          {
            input: data[2].data.inputPhaseA,
            phase: '1',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          },
          {
            input: data[2].data.inputPhaseB,
            phase: '2',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseB,
            ajust: data[2].data.ajustPhaseB,
            relation: data[2].data.relationPhaseB,
            offset: data[2].data.offsetPhaseB
          }
        ];

      case '3':
        return [
          {
            input: data[2].data.inputPhaseA,
            phase: '1',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          },
          {
            input: data[2].data.inputPhaseB,
            phase: '2',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseB,
            ajust: data[2].data.ajustPhaseB,
            relation: data[2].data.relationPhaseB,
            offset: data[2].data.offsetPhaseB
          },
          {
            input: data[2].data.inputPhaseC,
            phase: '3',
            unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseC,
            ajust: data[2].data.ajustPhaseC,
            relation: data[2].data.relationPhaseC,
            offset: data[2].data.offsetPhaseC
          }
        ];
    }
  };

  const charge = {
    name: data[0].data.charge_name,
    description: data[0].data.charge_description,
    charge_voltage : data[0].data.charge_voltage ,
    ubication: data[0].data.charge_ubication,
    element_type: data[1].data.elementType,
    charge_type: data[1].data.chargeType,
    num_inputs: data[1].data.num_inputs,
    concentrator_id: data[1].data.concentratorPhase,
    group_id: props.ids.groupId, 
    is_father :data[2].data.nivel ,
    fp: {
      is_constant: !data[2].data.is_dynamic ,
      constant_value: data[2].data.fp
    },
    voltage: {
      is_constant:  !data[2].data.voltage_dynamic,
      constant_value: data[2].data.voltage
    },
    inputs: getInputs(data[1].data.num_inputs),
    consumes: [
      ['kw', '0.0000', '0.0000', '0.0000'],
      ['kwh', '0.00', '0.00', '0.00']
    ],

    demanda_min : data[3].data.demanda_min ,
    demanda_max : data[3].data.demanda_max ,
    consumo_min : data[3].data.consumo_min ,
    consumo_max : data[3].data.consumo_max ,

  };

  return (
    <Grid
      className={classes.root}
      container
    >
      <div className={classes.form}>
        <Typography variant="h4">{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.confTitle'})}</Typography>

        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.chargeName'})}: {charge.name}{' '}
        </Typography>
        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.description'})} : {charge.description}
        </Typography>
        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.elemType'})} : {charge.element_type}
        </Typography>

        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.chargeType'})}: {charge.charge_type}
        </Typography>

        <Button
          className={classes.button}
          color="secondary"
          onClick={() => completeCharge(charge)}
          size="large"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.confirmBtn'})}
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.backBtn'})}
        </Button>
      </div>
    </Grid>
  );
}

export default NameRoleForm;
