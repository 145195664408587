import React from 'react';
import { useSelector } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles } from '@material-ui/core/styles';

// Material components
import { Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function Account() {
  const intl = useIntl();
  const classes = useStyles();
  const user = useSelector(state => state.user.user)
  const rootClassName = classNames(classes.root);

  
  return (
    <Portlet
    className={rootClassName}
  >
    <PortletHeader>
      <PortletLabel
        subtitle={intl.formatMessage({id: 'app.myaccount.title'})}
        title={intl.formatMessage({id: 'app.myaccount.subtitle'})}
      />
    </PortletHeader>
    <PortletContent noPadding>
      <form
        
        noValidate
      >
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            helperText="Please specify the first name"
            label={intl.formatMessage({id: 'app.myaccount.firstNameLabel'})}
            margin="dense"
            disabled
            required
            value={user.personal_info.firstname}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label={intl.formatMessage({id: 'app.myaccount.lastNameLabel'})}
            margin="dense"
            disabled
            required
            value={user.personal_info.lastname}
            variant="outlined"
          />
        </div>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label={intl.formatMessage({id: 'app.myaccount.emailLabel'})}
            margin="dense"
            disabled
            required
            value={user.personal_info.email}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label={intl.formatMessage({id: 'app.myaccount.phoneNumbLabel'})}
            margin="dense"
            type="number"
            disabled
            value={user.personal_info.tel}
            variant="outlined"
          />
        </div>
      </form>
    </PortletContent>
    <PortletFooter className={classes.portletFooter}>
    <Link  to={{
          pathname: `/updateUser/${user.personal_info.id_user}`,
          state: {user: user }
        }}>
            <Button
        color="secondary"
        variant="outlined"
      >
        {intl.formatMessage({id: 'app.myaccount.editProfileBtn'})}
      </Button>
    </Link>
    
    </PortletFooter> 
  </Portlet>
  )
}

