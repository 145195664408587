import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {  GeneralReport } from './components';
import { Container, Button } from 'react-floating-action-button'
import { Link as LinkFloat } from 'react-floating-action-button'


// Component styles
import styles from './styles';

class ProductList extends Component {

  

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Corporativos">
        <div className={classes.root}>
          {/* <ProductsToolbar /> */}
          <div className={classes.content}>
            <GeneralReport />
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);
