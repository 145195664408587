export default theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
      },
      margin: {
        margin: theme.spacing(1)
      },
      withoutLabel: {
        marginTop: theme.spacing(3)
      },
      form:{
        width: '100%'
      },
      textField: {
        width: 500,
        margin:'20px auto',
        display: 'block'

      },
      thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      },
      
      thumb : {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        margin:'0 auto'
      },
      
      thumbInner : {
        display: 'flex',
        minWidth: 'max-content',
        overflow: 'hidden'
      },
      
      img:{
        display: 'block',
        width: 'auto',
        height: '100%'
    
      },
      container:{
        border: '3px dashed rgba(28,110,164,0.5)',
        borderRadius: '10px',
        height: '200px'
      }
})