import React, { useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { makeStyles  , Typography } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { postDataOnOffCard,showHomeworkInputs,getOnOffCards, postHomeworkData } from 'js/actions/onOffAcrtions';

const useStyles = makeStyles(styles);


export default function GroupForm(props) {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { meterId } = props.match.params;
  const { fpId } =  props.match.params;

  const user = useSelector(state=>state.user.user)
  //const concentratorid = props.props;
  const corpAdmin = useSelector(state=>state.user.corporative)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id

  //const { concentratorId, cardId } = props.match.params;

  //const cards = useSelector(state => state.controlCards.cards);
  const [cards , setCards] = React.useState([]);
  const inputs = useSelector(state => state.controlCards.inputs);

  const [controlCard, setControlCard] = React.useState(null);
 
  const [ade, setAde] = React.useState(1);

  

  const [name , setName  ] = React.useState(null);
  const [description , setDescription  ] = React.useState(null);

  useEffect(() => {
    //dispatch(getOnOffCards(cardId));
    if(fpId ){
      dispatch(getFP())
    }else{
       
    }
    dispatch(getCards());
  }, []);

  const onSubmit = values => {
    //console.log('DATA: ', values);
    if( fpId ){
      dispatch(putFPData(values)); 
    }else{
      dispatch(postFPData(values));
    }
  };


  const handleControlCard = card => {
    setControlCard(card.target.value);
    // dispatch(showHomeworkInputs(card.target.value))
  };

  const handleAde = ade => {
    setAde(ade.target.value);
    // dispatch(showHomeworkInputs(card.target.value))
  };

   

 
  const getCards = ( ) => {    
    return async dispatch => {
      axios
        .get(`https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/cards`, 

          {
            headers: { Authorization: 'Bearer ' + getToken( ) }
          }
        
        )
        .then(response => {
          console.log('Se obtubo todos los cards ');
          console.log( response.data.data);
          // dispatch(getAllCards(response.data.data));
          setCards(response.data.data);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };


  const getFP = (  ) => {    
    return async dispatch => {
      axios
        .get(`https://api.metimur.mx/api/fp/${fpId}`, 

          {
            headers: { Authorization: 'Bearer ' + getToken( ) }
          }
        
        )
        .then(response => {
          console.log('Se obtubo todos los cards ');
          console.log( response.data.data);
          // dispatch(getAllCards(response.data.data));
          setFPValues(response.data.data);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };


  const setFPValues=(data)=>{
     
    setName( data.name );
    setDescription(data.description);
    setAde( data.ade );
    setControlCard( data.card_id );

  }



  const postFPData = ( values ) => {
    return async dispatch => {
      //console.log('TAREJTA ' + JSON.stringify(card));
      //dispatch(postCardStarted());
  
      axios
        .post(
          `https://api.metimur.mx/api/fp`,
          {
            'name' :  values.name ,
            'description' : values.description ,
            'card_id' :  values.card_id  ,
            'meter_id' : meterId  , 
            'ade' : parseInt( values.ade )  
         
          },
          {
            headers: { Authorization: 'Bearer ' + getToken( ) }
          }
        )
        .then(response => {
        })
        .then(()=>{
          Swal.fire({
            title: 'FP Creado',
            text: 'FP creado exitosamente',
            icon: 'success',
          }).then(
            ()=>{history.push(`/powerfactor/${meterId}`)}
          );
        })
        .catch(error => {
          const errorMessage = error.response.data.message
          
          Swal.fire({
            title: 'Error',
            text: `<br/>${errorMessage.join('<br/>')}`,
            icon: 'error',
          });
        });
    };
  };


  const putFPData = ( values ) => {
    return async dispatch => {
      //console.log('TAREJTA ' + JSON.stringify(card));
      //dispatch(postCardStarted());
  
      axios
        .put(
          `https://api.metimur.mx/api/fp`,
          {
            'id' : fpId ,
            'name' :  values.name ,
            'description' : values.description ,
            'card_id' :  values.card_id  ,
            'meter_id' : meterId ,
            'ade' :  parseInt( values.ade )  
         
          },
          {
            headers: { Authorization: 'Bearer ' + getToken( ) }
          }
        )
        .then(response => {
        })
        .then(()=>{
          Swal.fire({
            title: 'FP Modificado',
            text: 'FP modificado exitosamente',
            icon: 'success',
          }).then(
            ()=>{history.push(`/powerfactor/${meterId}`)}
          );
        })
        .catch(error => {
          const errorMessage = error.response.data.message
          
          Swal.fire({
            title: 'Error',
            text: `<br/>${errorMessage.join('<br/>')}`,
            icon: 'error',
          });
        });
    };
  };  
  

  const handleChangeName=(event)=> {    setName(event.target.value)   }

  const handleChangeDescription=(event)=> {    setDescription(event.target.value)   }

  return (
    <DashboardLayout title="Creacion Factor de Potencia">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Factor de Potencia" />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Nombre"
                      name="name"
                      onChange={handleChangeName}
                      required
                      value={name}
                      //defaultValue={name}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Descripción"
                      name="description"
                      onChange={handleChangeDescription}
                      required
                      value={description}
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                          Tarjeta de Control
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="card_id"
                        onChange={handleControlCard}
                        value={controlCard}
                        //labelWidth={labelWidth}
                      >
                        {cards ? (cards.map(card => (
                          <MenuItem value={card.id}>
                            {card.name}
                          </MenuItem>
                        ))): (console.log('cargando tarjetas'))}
                
                      </Select>
                    </FormControl>
             
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                          ADE
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="ade"
                        onChange={handleAde}
                        value={ade}
                        //labelWidth={labelWidth}
                      >
                         
                        <MenuItem value={1}>ADE1</MenuItem>
                        <MenuItem value={2}>ADE2</MenuItem>
                                
                      </Select>
                    </FormControl>             
 
               

 




                  </div>
                </Grid>
              </Grid>

              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
