export const GET_CARD_STARTED = 'GET_CARD_STARTED';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_FAILURE = 'GET_CARD_FAILURE';

export const GET_CARD_INPUT_SUCCESS = 'GET_CARD_INPUT_SUCCESS';

export const GET_ALL_CARD_STARTED = 'GET_ALL_CARD_STARTED';
export const GET_ALL_CARD_SUCCESS = 'GET_ALL_CARD_SUCCESS';
export const GET_ALL_CARD_FAILURE = 'GET_ALL_CARD_FAILURE';

export const POST_CARD_STARTED = 'POST_CARD_STARTED';
export const POST_CARD_SUCCESS = 'POST_CARD_SUCCESS';
export const POST_CARD_FAILURE = 'POST_CARD_FAILURE';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export const PUT_CARD_STARTED = 'PUT_CARD_STARTED';

export const CLEAN_CARD= 'CLEAN_CARD';
