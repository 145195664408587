import * as dashboardGroupActionTypes from '../constants/groupDashboard-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function concentratorReducer(
  state = {
    monthMostConsume: [],
    dayMostConsume: [],
    comparativeYearConsume: [],
    topChargeInMonth: [],
    loadCharge: null,
    lastLoadCharge: null,
    receipt:[],
    loadHistory: null
  },
  action
) {
  switch (action.type) {
    case dashboardGroupActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS:
      return { ...state, monthMostConsume: action.chartMonthMostConsume};

    case dashboardGroupActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS:
      return { ...state, dayMostConsume: action.chartDayMostConsume};

    case dashboardGroupActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS:
      return { ...state, comparativeYearConsume: action.chartComparativeYearConsume};
    
    case dashboardGroupActionTypes.GET_TOP_CHARGE:
      return { ...state, topChargeInMonth: action.chartTopChargeInMonth};
    case dashboardGroupActionTypes.POST_LOAD_CHARGE:
      return { ...state, loadCharge: action.value};
    case dashboardGroupActionTypes.POST_LAST_LOAD_CHARGE:
      return { ...state, lastLoadCharge: action.value};
    case dashboardGroupActionTypes.GET_UNIFIQUED_RECEIPT_SUCCESS:
      return { ...state, receipt: action.receipt};
    case dashboardGroupActionTypes.GET_LOAD_HISTORY:
      return { ...state, loadHistory: action.value};
    default:
      return state;
  }
}

export default concentratorReducer;
