import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, TextField,Grid } from '@material-ui/core';

import { postForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';

import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function UserConcentratorForm(props) {

  const dispatch = useDispatch();

  const alert = props.alert||null 
  console.log("alerta de props ")
  console.log(alert);


  const onSubmit = values => {
   
    if( !state.data  || state.data.length<=0  || !state.data[0].name   ){     
      
      Swal.fire({
        title: 'Debe seleccionar al menos un contacto',
        text: ``,
        icon: 'warning',
      }).then(        
      );


      return;
    }
    dispatch(postForm(values));
    props.nextStep();
  };


  useEffect(() => {
    if( alert ){
       if( alert.contacts ){

        setState({
          ...state,
          data: alert.contacts
        })

       

       }

    }

  }, []);


  const classes = useStyles();

  const [state, setState] = React.useState({
    columns: [
      { title: 'Nombre', field: 'name' },
      { title: 'Apellido', field: 'lastname' },
      { title: 'Email', field: 'email' },
      { title: 'Celular', field: 'cel_number'},
    ],
    data: [
      {name:'', lastname:'' ,email:'',cel_number:''},
    ]
  });


  return (
    <Grid className={classes.root}>
    <MaterialTable
      columns={state.columns}
      data={state.data}
      icons={{        
        Delete: () => <DeleteIcon  />
      }}
      localization={{
        header: {
          actions: "Acciones"
        }
      }}
      options={{
        pageSize: 10,
        pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
      }
      }
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...state.data];
              data.push(newData);
              setState({ ...state, data });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...state.data];
              data[data.indexOf(oldData)] = newData;
              setState({ ...state, data });
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...state.data];
              data.splice(data.indexOf(oldData), 1);
              setState({ ...state, data });
            }, 600);
          })
      }}
      options={{
        search: false
      }}
      title="Ingrese contactos a los que se notificara"
    />

    <Button
      color="secondary"
      onClick={() => onSubmit(state.data)}
      variant="contained"
      style={{marginTop:'10px'}}
    >
      Guardar Contacto
    </Button>
    

  </Grid>


  );
}

export default UserConcentratorForm;
