import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux'
import { postForm } from 'js/actions/formsAction';
import { postDataRole} from 'js/actions/roleActions'
import {translatePermission} from 'helpers/translate'

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { getPermission } from 'js/actions/permissionActions';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function NameRoleForm(props){
  const intl = useIntl()
  const classes = useStyles()
  const formData = useSelector(state => state.forms)
  const dispatch = useDispatch()
  const {data} = formData;
  const permission = data[1].data

  const{ corpId } = props.props.props.match.params

  const role = {
    role_name: data[0].data.role_name, 
    is_public: data[0].data.is_public , 
    permissions:[], 
    corporative_id: corpId,
    is_global:false
  }

  Object.keys(permission).forEach(key=>{
    if(permission[key]==true){
      role.permissions.push(key)
    }
  })

  const completeRole = (role) => {
    dispatch(postDataRole(role))
  }

  const permissions = translatePermission(role.permissions)

  return (
    <div className={classes.fields}>
      <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.confirmation.title'})}</Typography>

      <Typography variant="body1"> {intl.formatMessage({id: 'app.roles.addRole.stepper.confirmation.roleName'})}   {role.role_name} </Typography>
      <Typography variant="body1"> {intl.formatMessage({id: 'app.roles.addRole.stepper.confirmation.permission'})}  </Typography>
        
      <List>

        {      
          permissions.map(el => (
            <ListItem>
              <ListItemText> {el} </ListItemText>
            </ListItem>
              
          ))}

          
      </List>
      <Button
        color="secondary"
        onClick={()=>completeRole(role)}
        size="large"
        variant="contained"
      >
        {intl.formatMessage({id: 'app.roles.addRole.stepper.confirmation.confirmBtn'})}
      </Button>

        
    </div>

  )
}
  

export default NameRoleForm;