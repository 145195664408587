export default theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  },
  row2: {
    display: 'flex',
    marginTop: theme.spacing.unit,
    textAlign: 'left'
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing.unit
  },
  hola:{
    textAlign:'left'
  },
  hola2:{
    textAlign:'right'
  },
  imgLogo:{
    width: '100px',
    height:'100px',
    display:'block',
    margin:'0 auto'
  }
});
