import React, { Component , useEffect } from 'react';

// Externals
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowUpward as ArrowUpwardIcon,
  FlashOn as PeopleIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { getContractedDemand } from 'js/actions/meterDashboardActions';



const useStyles = makeStyles(styles)

export default function ContractedDemand(props) {
  const intl = useIntl()
  const classes = useStyles()
  const {className, ...rest} = props
  const {meterId} = props;
  const {corpId} = props;
  const rootClassName = classNames(classes.root, className);
  const totalConsumes = useSelector(state=>state.meterDashboard.totalsactualmonth);
  const dispatch = useDispatch();
  const [contractedDemand,setContractedDemand] = React.useState(0);

  useEffect(() => {
    dispatch(getContractedDemand(corpId, meterId, getContractedDemandResponse ));
  }, []);


  const getContractedDemandResponse=( data  )=>{
    setContractedDemand(data.contracted_demand);
  }


  return (
    <Paper
    {...rest}
    className={rootClassName}
  >
    <div className={classes.content}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="body2"
        >
          {intl.formatMessage({id: 'app.meter.board.mainHead.contractedDemand'})}
        </Typography>
        <Typography
          className={classes.value}
          variant="h3"
        >
          {contractedDemand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kW
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <PeopleIcon className={classes.icon} />
      </div>
    </div>
  </Paper>
  )
}

