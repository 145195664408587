import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { showAllCards, deleteCard } from 'js/actions/cardAction';
import { Link } from 'react-router-dom';
import {history} from 'helpers/history'

import { makeStyles,Button } from '@material-ui/core';

import styles from './styles';
const useStyles = makeStyles(styles);

export default function ConcentratorTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cards = useSelector(state => state.cards.cards);
  const user = useSelector(state=>state.user.user)
  //const concentratorid = props.props;
  const corpAdmin = useSelector(state=>state.user.corporative)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id
  
  const meterAdmin = useSelector(state=>state.user.meter)
  const meterUser = user.meters
  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser.id



  const [state, setState] = React.useState({
    columns: [
      { title: 'Identificador', field: 'id' },
      { title: 'Nombre', field: 'name' },
      { title: 'Tipo', field: 'type' },
      { title: 'Direccion MAC', field: 'mac_address' },
      {
        title: 'Alta Tarjetas de Control',

        render: rowData => (
          <Link
            className={classes.iconTable}
            to={{
              pathname: `/newControlCard/${rowData.concentrator_id}/${rowData.id}`,
              //state: {card:rowData}
            }}
          >
            <Button color="secondary" variant='contained'> Crear Tarjeta de Control</Button>
          </Link>
        )
      },
      {
        title: 'Tarjetas de Control',

        render: rowData => (
          <Link
            className={classes.iconTable}
            to={{
              pathname:`/controlCards/${rowData.id}` ,
              state: {card:rowData}
            }}
          >
            <Button color="secondary" variant='outlined'> Ver Tarjetas de Control</Button>
          </Link>
        )
      }
    ],
    // actions: [
    //   {
    //     icon: 'delete',
    //     tooltip: 'Eliminar Tarjeta',
    //     onClick: (event, rowData) => deleteCardData(rowData.id)
    //   },
    //   {
    //     icon: 'edit',
    //     tooltip: 'Editar Tarjeta',
    //     onClick: (event, rowData) => {history.push({ 
    //       pathname: `/updateCard/${props.meterId}/${rowData.id}`,
    //       state:{rowData}
    //      })}
    //   }
    // ]
  });

  const deleteCardData = cardId => {
    dispatch(deleteCard(cardId));
  };

  useEffect(() => {
    dispatch(showAllCards(corporativeId,meterAdmin));
  }, []);

  useEffect(() => {
    setState({ ...state, data: cards });
  }, [cards]);

  return (
    <MaterialTable
      actions={state.actions}
      columns={state.columns}
      data={state.data}
      options={{
        showTitle:false,
        actionsColumnIndex: -1
      }}
    />
  );
}
