import React, { Component, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getDayWithMostConsume, dayWithMostConsume} from 'js/actions/groupDashboardActions'


// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, makeStyles } from '@material-ui/core';


// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

import {
  LaptopMac as LaptopMacIcon,
  PhoneIphone as PhoneIphoneIcon,
  Refresh as RefreshIcon,
  TabletMac as TabletMacIcon,
  Alarm as AlarmIcon,
  CalendarToday as CalendarIcon,
  TrendingUp as UpIcon
} from '@material-ui/icons';

import { IconButton, Typography } from '@material-ui/core';

import palette from 'theme/palette';

// Chart configuration
import { data, options } from './chart';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles)



export default function DayWithMostConsumeChart(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const dayMostConsume = useSelector(state=>state.groupDashboard.dayMostConsume)
  
  const {id, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  

  useEffect(()=>{
    dispatch(getDayWithMostConsume(id,2020,'01'))
  },[])

  

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title="Dia de Mayor Consumo del Mes Actual " />
        <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text"
          >
            2020 
          </Button>
        </PortletToolbar>
      </PortletHeader>
      <PortletContent>
        
   
          <div className={classes.device}>
            <CalendarIcon className={classes.deviceIcon} />
            <Typography variant="h2">Fecha</Typography>
            <Typography
              style={{ color: '#730065' }}
              variant="h1"
            >
              {dayMostConsume.day}
            </Typography>
          </div>
          {/* <div className={classes.device}>
            <AlarmIcon className={classes.deviceIcon} />
            <Typography variant="h2">Hora</Typography>
            <Typography
              style={{ color: '#00AFEF' }}
              variant="h1"
            >
              14:35 hrs
            </Typography>
          </div> */}
          <div className={classes.device}>
            <UpIcon className={classes.deviceIcon} />
            <Typography variant="h2">Consumo</Typography>
            <Typography
              style={{ color: '#FEC12C' }}
              variant="h1"
            >
              {Math.round(dayMostConsume.consume*100)/100} kWh
            </Typography>
          </div>
        
        {/* <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          />
        </div> */}
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </PortletFooter> */}
    </Portlet>
  );
}

















