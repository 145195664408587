import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowUpward as ArrowUpwardIcon,
  FlashOn as PeopleIcon,
  PriorityHigh as AlertIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles)


export default function Users(props) {
  const classes = useStyles();
  const {className,...rest} = props;
  const {groupId,chargeId} = props.ids;
  const rootClassName = classNames(classes.root, className)
  return (
    <Link
    to={`/group/${groupId}/charge/${chargeId}/alert`}
    >
    <Paper
      {...rest}
      className={rootClassName}
    >
      <div className={classes.content}>
      <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              Mis Facturas
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
              style={{height:'18px'}}
            >             
            </Typography>
          </div>
        <div className={classes.iconWrapper}>
          <AlertIcon className={classes.icon} />
        </div>
      </div>
    </Paper>
  </Link>
  )
}


