/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { postForm } from 'js/actions/formsAction';
import { postUpdateCharge } from 'js/actions/chargeActions';

import {
  Typography,
  Button,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const chargeData = props.charge
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;

  const completeCharge = (charge) => {
  //console.log("🚀 ~ file: index.jsx ~ line 30 ~ completeCharge ~ charge", charge)
    dispatch(postUpdateCharge(charge));
  }

  const getInputs = chargeType => {
    switch (chargeType) {
      case '1':
        return [
          {
            id:chargeData.inputs[0].id,
            input: data[2].data.inpunt1PhaseA,
            phase: '1',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseA,
            card_modbus: data[2].data.modbusPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation:data[2].data.relationPhaseA,
            offset:data[2].data.offsetPhaseA
          }
        ];

      case '2':
        return [
          {
            id:chargeData.inputs[0].id,
            input: data[2].data.inputPhaseA,
            phase: '1',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseA,
            card_modbus: data[2].data.modbusPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation:data[2].data.relationPhaseA,
            offset:data[2].data.offsetPhaseA
          },
          {
            id: chargeData.inputs[1] != undefined ? chargeData.inputs[1].id : '0',
            input: data[2].data.inputPhaseB,
            phase: '2',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseB,
            card_modbus: data[2].data.modbusPhaseB,
            ajust: data[2].data.ajustPhaseB,
            relation:data[2].data.relationPhaseB,
            offset:data[2].data.offsetPhaseB
          }
        ];

      case '3':
        return [
          {
            id:chargeData.inputs[0].id,
            input: data[2].data.inputPhaseA,
            phase: '1',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseA,
            card_modbus: data[2].data.modbusPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation:data[2].data.relationPhaseA,
            offset:data[2].data.offsetPhaseA
          },
          {
            
            id: chargeData.inputs[1] != undefined ? chargeData.inputs[1].id : '0',
            input: data[2].data.inputPhaseB,
            phase: '2',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseB,
            card_modbus: data[2].data.modbusPhaseB,
            ajust: data[2].data.ajustPhaseB,
            relation:data[2].data.relationPhaseB,
            offset:data[2].data.offsetPhaseB
          },
          {
            id: chargeData.inputs[2] != undefined ? chargeData.inputs[2].id : '0',
            input: data[2].data.inputPhaseC,
            phase: '3',
            unit: 'CORRIENTE',
            card_id: data[2].data.cardPhaseC,
            card_modbus: data[2].data.modbusPhaseC,
            ajust: data[2].data.ajustPhaseC,
            relation:data[2].data.relationPhaseC,
            offset:data[2].data.offsetPhaseC
          }
        ];
    }
  };

  const getNumInputs = chargeType => {
    switch (chargeType) {
      case 'MONOFASICA':
        return 1;

      case 'BIFASICA':
        return 2;

      case 'TRIFASICA':
        return 3;
    }
  };

  const charge = {
    id:chargeData.id,
    name: data[0].data.charge_name, 
    charge_voltage : data[0].data.charge_voltage ,
    description: data[0].data.charge_description,
    ubication: data[0].data.charge_ubication,
    element_type: data[1].data.elementType,
    charge_type: data[1].data.chargeType,
    num_inputs: data[1].data.num_inputs,
    concentrator_id: chargeData.concentrator_id,
    group_id: data[1].data.group_id,
    is_father :data[2].data.nivel ,
    fp: {
      is_constant: !data[2].data.is_dynamic ,
      constant_value: data[2].data.fp
    },
    voltage: {
      is_constant:!data[2].data.voltage_dynamic,
      constant_value: data[2].data.voltage
    },
    inputs: getInputs(data[1].data.num_inputs),
    consumes:[['kw','0.0000','0.0000','0.0000'],['kwh','0.00','0.00','0.00']] ,

    demanda_min : data[3].data.demanda_min ,
    demanda_max : data[3].data.demanda_max ,
    consumo_min : data[3].data.consumo_min ,
    consumo_max : data[3].data.consumo_max 
    
  };

  return (
    <Grid
      className={classes.root}
      container
    >
      <div className={classes.form}>
        <Typography variant="h4">{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.confTitle'})}</Typography>

        <Typography variant="body1"> {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.chargeName'})}: {charge.name} </Typography>
        <Typography variant="body1"> {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.description'})} : {charge.description}</Typography>
        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.elemType'})} : {charge.element_type}
        </Typography>
        
        <Typography variant="body1">
          {' '}
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.chargeType'})}: {charge.charge_type}
        </Typography>

        <Button
          color="secondary"
          onClick={() => completeCharge(charge)}
          size="large"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.updateBtn'})}
        </Button>
      </div>
    </Grid>
  );
}

export default NameRoleForm;
