import React, { Component, useEffect, useRef } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector   } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormControl, InputLabel,Select,MenuItem, Typography } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import { getDataInvoiceCharge, postUpdateCharge , updateDataInvoiceCharge } from 'js/actions/chargeActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useState } from 'react';
import { showCFDI, showPaymentMethods, showPaymentTypes } from 'js/actions/invoiceActions';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

export default function FormChargeInvoiceData(props) {
  const { charge , groupId } = props.location.state;

  console.log(charge)

  //const {chargeId,groupId } = props.match.params;
  const { getValues, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [typePrebilling, setTypePrebilling] = useState();  



  const [ rfc , setRfc ] = useState();  
  const [ razonSocial , setRazonSocial] = useState();   
  const [ cfdi , setCfdi] = useState();     
  const [ tipoDePago , setTipoDePago] = useState();     
  const [ metodoDePago , setMetodoDePago] = useState();  

  const [ state , setState] = useState();  
  const [ city , setCity] = useState();  

  const [ calle , setCalle] = useState();  
  const [ cp , setCP] = useState();  
  const [ numExt , setNumExt] = useState();  
  const [ colonia , setColonia] = useState();  


  const estados = useSelector(state => state.zepomex.states.estado);
  const cities = useSelector(state => state.zepomex.cities.municipios);
  const cps = useSelector(state => state.zepomex.cp.cp);
  const counties = useSelector(state => state.zepomex.counties.colonia);
 
  const payment_types = useSelector(state => state.invoice.payment_types);
  const payment_methods = useSelector(state => state.invoice.payment_methods);
  const cfdis = useSelector(state => state.invoice.cfdi);
 
  /*
  const [cfdi_form, setCfdiForm] = useState()
  const [payment_types_form, setPaymentTypeForm] = useState()
  const [payment_methods_form, setPaymentMethodForm] = useState()
*/

  
  console.log('chargeId:' + charge.id)
  console.log('groupId: '+ groupId)

  const handleCFDI = cfdi => {
    setCfdi(cfdi.target.value);
  };

  const handlePaymentTypes = payment_type => {
    setTipoDePago(payment_type.target.value);
  };

  const handlePaymentMethods = payment_methods => {
    setMetodoDePago(payment_methods.target.value);
  };



  
  const handleChangeState = state => {
    // setValue('state', state.target.value);
    setState(state.target.value);
    getCity(state.target.value);
  };

  const getCity = state => {
    dispatch(showCities(state));
  };

  const handleChangeCity = city => {
    // setValue ('city', city.target.value)
    setCity( city.target.value )
    //getCP(city.target.value)
  }

  const getCP = city => {
    dispatch(showCPS(city));
  };

  
  /*
  const handleCP = cp => {
   // setValue ('cp', cp.target.value)
    getCounties(cp.target.value)
  }*/
  

  const getCounties = cp =>  {
    dispatch(showCounties(cp))
  }

  const handleCounties = countie => {
    // setValue ('county', countie.target.value)
  }


  useOnMount(()=>{

    /*
    register({name: 'state', type:'text '});
    register({name:'city', type:'text'});
    register({name:'cp', type:'text'});
    register({name:'county', type:'text'}); */

    dispatch(showCountrySates());


  })




  // const   ctrlAdministrativeName  = useRef();

  /*
    charge_id 
rfc
razon_social
cfdi
tipo_de_pago
metodo_de_pago 
*/


  useEffect(() => {
    dispatch(showCFDI());
    dispatch(showPaymentTypes());
    dispatch(showPaymentMethods());
    dispatch( getDataInvoiceCharge( charge.id , responseGetChargeDataInvoice    ))
  }, [])


  useEffect(() => {
    // console.log("obteniendo datos de facturacion ")
  //  dispatch( getDataInvoiceCharge( charge.id , responseGetChargeDataInvoice    ))
  }, []);

  

  const responseGetChargeDataInvoice=(  data )=>{
      
    setRfc( data.rfc )
    setRazonSocial( data.razon_social )
    setCfdi( data.cfdi )
    setTipoDePago( data.tipo_de_pago )
    setMetodoDePago( data.metodo_de_pago ) 
    setCalle( data.calle )
    setCP( data.codigo_postal )
    setNumExt (data.numero_exterior )
    setColonia( data.colonia )      
    setState( data.estado )
    setCity( data.municipio )
       

  }



  const onSubmit = values => {
    dispatch(updateDataInvoiceCharge( charge.id , groupId  , values ));
  };

   
  const handleChangeRFC =(event)=> {  
    setRfc(event.target.value);      
  }

  const handleChangeRazonSocial =(event)=> {  
    setRazonSocial(event.target.value);      
  }


  const handleChangeCalle =(event)=> {  
    setCalle(event.target.value);      
  }  

  const handleChangeCP =(event)=> {  
    setCP(event.target.value);      
  }  

  const handleChangeNumExt =(event)=> {  
    setNumExt(event.target.value);      
  }  

  const handleChangeColonia =(event)=> {  
    setColonia(event.target.value);      
  }  





  return (
    <DashboardLayout title={charge.name}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            
           
            <Typography variant="h4"  >DATOS DE FACTURACIÓN</Typography>
            <Typography variant="h5"  >{`CARGA: ${charge.name} `} </Typography>
           
            
          </PortletHeader>
          <PortletContent noPadding>

         

            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
    

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="RFC"
                      name="rfc"
                      onChange={handleChangeRFC} 
                      required
                      value={rfc}
                      variant="outlined"
                    />         

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Razón Social"
                      name="razon_social"
                      onChange={handleChangeRazonSocial} 
                      required
                      value={razonSocial}
                      variant="outlined"
                    />     


                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          CFDI
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="cfdi"
                        onChange={handleCFDI}
                        value={cfdi}
                        //labelWidth={labelWidth}
                      >
                        {
                          cfdis.data ? cfdis.data.map(item => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>

                          )) : console.log('generando cfdis')
                        }
                          


                      </Select>
                    </FormControl>



                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          Tipo de Pago:
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="tipo_de_pago"
                        onChange={handlePaymentTypes}
                        value={tipoDePago}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_types.data ? payment_types.data.map(payment_types => (
                            <MenuItem value={payment_types.id}>{payment_types.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          Metodo de Pago:
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="metodo_de_pago"
                        onChange={handlePaymentMethods}
                        value={metodoDePago}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_methods.data ? payment_methods.data.map(payment_methods => (
                            <MenuItem value={payment_methods.id}>{payment_methods.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>                    
                   

                 
                     
                  </div>

                  <div  style={{marginBottom:'20px' , marginTop: '20px' }} >
                    <Typography variant="h4"  >DOMICILIO FISCAL</Typography>
                  </div>
                  <div>


                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        
                        required
                      >
                        Estado
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"  
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="state"
                        onChange={handleChangeState}
                        value={state}
                        //labelWidth={labelWidth}
                      >
                      
                        { estados ?  estados.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando estados') 
                        
                        }
                            
                      </Select>
                    </FormControl>


                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                       
                        required
                      >
                        Municipio / Ciudad
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label" 
                        name="city"
                        onChange={handleChangeCity}
                        value={city}
                        //labelWidth={labelWidth}
                      >
                       
                        { cities ?  cities.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>                    

                  

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Calle"
                      name="calle"
                      onChange={handleChangeCalle}
                      required
                      value={calle}
                      //defaultValue={meter.address}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      inputRef={register}   
                      label="Código Postal"
                      name="cp"
                      
                      onChange={handleChangeCP}
                      value={cp}
                      variant="outlined"
                    />               

                    <TextField
                      className={classes.textField}
                      inputRef={register} 
                      label="Número Exterior"
                      //name="address"
                      name="numExt"
                      onChange={handleChangeNumExt}
                      required
                      value={numExt}
                      variant="outlined"
                    />                           

                    <TextField
                      className={classes.textField}
                      id="county" 
                      inputRef={register}
                      label="Colonia" 
                      name="colonia"
                      onChange={handleChangeColonia}
                      value={colonia}
                      variant="outlined"
                    />



                  </div>





                </Grid>
               
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Actualizar
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
