import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import {
  Button,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function UserConcentratorForm( props ) {
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep()
  };

  const classes = useStyles();

  return (
     <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.textField}
        inputRef={register}
        label="Usuario FTP"
        name="user_ftp"
        required
        variant="outlined"
      />
      
     <Button
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
      >
        Guardar y Continuar
      </Button> 
    </form>
  );
}

export default UserConcentratorForm;
