import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
import { useIntl } from 'react-intl';
const useStyle = makeStyles(styles);

function Hook(props) {
  const classes = useStyle();
  const intl = useIntl()


  return (
    <DashboardLayout title="Creación de Roles">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.roles.addRole.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <FormStepper props={props}/>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default Hook;
