import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getMonthWithMostConsume} from 'js/actions/groupDashboardActions'

// Externals
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';

import { makeStyles, Typography } from '@material-ui/core';

import palette from 'theme/palette';

// Chart configuration
import { options } from './chart';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)

export default function MonthWithMostConsumeChart(props) { 
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id, className,typeEnergy, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  let monthMostConsume = useSelector(state=>state.groupDashboard.monthMostConsume)
  const year = new Date().getFullYear()

  useEffect(()=>{
    dispatch(getMonthWithMostConsume(id,year))
  },[])

  const data = {
    labels: [ 
      intl.formatMessage({id: 'app.months.jan'}) ,
      intl.formatMessage({id: 'app.months.feb'}),
      intl.formatMessage({id: 'app.months.mar'}),
      intl.formatMessage({id: 'app.months.apr'}),
      intl.formatMessage({id: 'app.months.may'}),
      intl.formatMessage({id: 'app.months.jun'}),
      intl.formatMessage({id: 'app.months.jul'}),
      intl.formatMessage({id: 'app.months.aug'}),
      intl.formatMessage({id: 'app.months.sep'}),
      intl.formatMessage({id: 'app.months.oct'}),
      intl.formatMessage({id: 'app.months.nov'}),
      intl.formatMessage({id: 'app.months.dec'})
    ],
    datasets: [
      {
        label: year,
        fill: false,
        backgroundColor:'#C69100',
        borderColor:'#C69100',
        lineTension: 0.1,
        // backgroundColor: palette.primary.main,
        data:monthMostConsume.months_consumes
      }]
  }

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={typeEnergy === 2 ? intl.formatMessage({id: 'app.chargeSolar.board.monthHighConsump.title'}) : intl.formatMessage({id: 'app.group.board.monthHighConsump.title'})} />
      </PortletHeader>
      <PortletContent>
      
        <div className={classes.stats}>
          <div className={classes.device}>
            <Typography
              style={{ color: '#730065', textTransform : 'uppercase'}}
              variant="h5"
            >
              {monthMostConsume.month_name}
            </Typography>
            <Typography
              style={{ color: palette.danger.main }}
              variant="h5"
            >
              {monthMostConsume.consume} kWh
            </Typography>
          </div>
        </div>
        <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
            redraw
          />
        </div>
      </PortletContent>
    </Portlet>
  )
}




