import * as onOffActionTypes from '../constants/onOffAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function controlCardReducer(state = { cards: [], homeworks:[], inputs:[] , loading: false }, action) {
  switch (action.type) {
    case onOffActionTypes.GET_ONOFF_CARD_SUCCESS:
        return {...state, cards: action.cardsOnOff};

    case onOffActionTypes.POST_ONOFF_CARD_STARTED:
      return {
        ...state,
        loading: true
      };

    case onOffActionTypes.GET_HOMEWORKS_SUCCESS:
        return {...state, homeworks: action.homeworks};


    case onOffActionTypes.POST_ONOFF_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.push(action.payload),
        loading: false
      };

    case onOffActionTypes.POST_HOMEWORKS_SUCCESS:
      return {
        ...state,
        cards: state.homeworks.push(action.payload),
        loading: false
      };

    case onOffActionTypes.POST_ONOFF_CARD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
      
    case onOffActionTypes.GET_ONOFF_CARD_INPUT_SUCCESS:
      return {...state, inputs: action.homeworkInputs};
    
    // case onOffActionTypes.DELETE_ONOFF_CARD_SUCCESS:
    //     return {
    //       ...state,
    //       cards: state.cards.filter(
    //         ({ id }) => id !== action.payload.id
    //       )
    //     };
    //   case onOffActionTypes.PUT_ONOFF_CARD_STARTED:
    //       return {
    //         ...state,
    //         loading: true
    //       };

    default:
      return state;
  }
}

export default controlCardReducer;
