export const GET_METER_STARTED = 'GET_METER_STARTED';
export const GET_METER_SUCCESS = 'GET_METER_SUCCESS';
export const GET_METER_FAILURE = 'GET_METER_FAILURE';

export const POST_METER_STARTED = 'POST_METER_STARTED';
export const POST_METER_SUCCESS = 'POST_METER_SUCCESS';
export const POST_METER_FAILURE = 'POST_METER_FAILURE';

export const DELETE_METER_SUCCESS = 'DELETE_METER_SUCCESS';

export const PUT_METER_STARTED = 'PUT_METER_STARTED';

export const POST_FOLDER_SUCCESS = 'POST_FOLDER_SUCCESS';
export const POST_FOLDER_FAILURE = 'POST_FOLDER_FAILURE';

export const POST_FOLDER_INTO_SUCCESS = 'POST_FOLDER_INTO_SUCCESS'
export const POST_FOLDER_INTO_FAILURE = 'POST_FOLDER_INTO_FAILURE'

export const GET_WEATHER_RANGE_SUCCESS = 'GET_WEATHER_RANGE_SUCCESS'
export const GET_WEATHER_RANGE_ERROR = 'GET_WEATHER_RANGE_ERROR'

export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS'
export const GET_FOLDER_FAILURE = 'GET_FOLDER_FAILURE'

export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';

export const GET_INTO_FOLDER_SUCCESS = 'GET_INTO_FOLDER_SUCCESS'
export const GET_INTO_FOLDER_FAILURE = 'GET_INTO_FOLDER_FAILURE'

export const POST_FILE_FAILURE = 'POST_FILE_FAILURE'
export const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS'