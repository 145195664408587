import * as controlRateActionTypes from 'js/constants/controlRateAction-types';
import axios from 'axios';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'
import getToken from 'helpers/auth';
import { FileSaver,saveAs } from 'file-saver';
import  FileDownload  from "js-file-download";

const getConfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken()}
  }
}


export const postControlRate = (files) => {
  const file = files[0]
  let formData = new FormData();
  formData.append("excel",file);

  return async dispatch => {
    axios
      .post(
        `https://api.metimur.mx/api/tariffs/import`,
        formData
        ,
        getConfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Control Tarifario Cargado Existosamente',
          icon: 'success',
        }).then(
          ()=>{history.push(`/controlRate`)}
        );
      })
      .catch(error => {
        
        console.log('ERROR API:' + error);
      });
  };
};


export const showRates = (control_rate,year) => {
  return async dispatch => {
    dispatch(getControlRateStarted())
    axios
      .get(
        `https://api.metimur.mx/api/tariffs/division/${control_rate.division}/rate/${control_rate.rateType}/year/${control_rate.year}/month/${control_rate.month} `,
        getConfig()
      )
      .then(response => {
        dispatch(getControlRateSucces(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getControlRateStarted = () =>{
  return{
    type: controlRateActionTypes.GET_CONTROL_RATE_STARTED
  }
}

export const getControlRateSucces = control_rate => {
 return {
   type: controlRateActionTypes.GET_CONTROL_RATE_SUCCESS,
   control_rate
 };
};

export const showLastUpdateRates = () => {
  return async dispatch => {
    dispatch(getLastUpdateRatesStarted())
    axios
      .get(
        `https://api.metimur.mx/api/rate/lastupdated `,
        { 
          headers: {
          'Content-Type': 'application/json' 
          }
          
          }
      )
      .then(response => {
        dispatch(getLastUpdateRatesSucces(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getLastUpdateRatesStarted = () =>{
  return{
    type: controlRateActionTypes.GET_LAST_UPDATE_RATE_STARTED
  }
}

export const getLastUpdateRatesSucces = last_update => {
 return {
   type: controlRateActionTypes.GET_LAST_UPDATE_RATE_SUCCESS,
   last_update
 };
};



export const getTotalReports = (reportData) => {
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .post(
        `https://api.metimur.mx/api/report/excel/totals`,
        {
          year: reportData.year,
          month: reportData.month,
          meter_id:reportData.meter,
          group_ids: [reportData.group],
          //charge_ids: [reportData.charge],
          type:reportData.type,
        },
        getConfig()
      )
      .then(response => {
        console.log('ready')
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};