import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {DescriptionCardForm, ComunicationCardForm, ConfigurationCardForm,ConfirmationForm, RangosMedicionForm } from '../index'
import { useDispatch, useSelector } from 'react-redux';
import { showTmpCharge } from 'js/actions/chargeActions';
import { useState } from 'react';
import { useIntl } from 'react-intl';


const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    margin: '0px auto'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: '32px',
    paddingRight: '32px'
  },
}));

 
function getStepContent(stepIndex, handleNext, handleBack, charge) {
  switch (stepIndex) {
    case 0:
      return (<DescriptionCardForm
        charge={charge}
        nextStep={handleNext}
        prevStep={handleBack}
      />)
    case 1:
      return (<ComunicationCardForm
        charge={charge}
        nextStep={handleNext}
        prevStep={handleBack}
      />)
    case 2:
      return (<ConfigurationCardForm
        charge={charge}
        nextStep={handleNext}
        prevStep={handleBack}
      />)
    case 3:
      return (<RangosMedicionForm
        charge={charge}
        nextStep={handleNext}
        prevStep={handleBack}
      />)
    case 4:
      return (<ConfirmationForm charge={charge} />);
    default:
      return 'Unknown stepIndex';
  }
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const intl = useIntl()
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const {chargeId} = props
  const steps = [intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.descrip.title'}), intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.title'}), intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.title'}), intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.measurRanges.title'})  , intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.confirmation.title'})];
  const dispatch = useDispatch()
  const charge = useSelector(state => state.charges.charge)

  const [tmpCharge, setTmpCharge] = useState({})

  useEffect(() => {
    dispatch(showTmpCharge(chargeId))
  }, [])

  useEffect(()=>{
    setTmpCharge({...tmpCharge,charge})
  },[charge])


  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep, handleNext, handleBack, charge)}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}
