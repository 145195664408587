import * as userActionTypes from '../constants/userAction-types';

//const token = getToken();
const initialState = {
  user: [],
  users: [],
  corporative: {},
  meter: {},
  group: {}
  //logged: false,
  //error: {}
};

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function userReducer(state = initialState, action) {  
  switch (action.type) {
    case userActionTypes.USER_LOGIN_STARTED:
      return {
        ...state,
        loading: true
      };
    case userActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        //logged: true,
        user: action.user,         
        users: []
        //error: {}
      };
    case userActionTypes.USER_LOGIN_FAILURE:
      return {
        user: {},
        logged: false,
        error: action.error
      };



    case userActionTypes.SET_CORP_ID:
      return {
        ...state,
        user: { ...state.user },
        users: { ...state.users },
        corporative: action.corpId
      };
    case userActionTypes.SET_METER_ID:
      return {
        ...state,
        user: { ...state.user },
        users: { ...state.users },
        corporative: state.corporative ,
        meter: action.meterId
      };

    case userActionTypes.SET_GROUP_ID:
      return {
        ...state,
        user: { ...state.user },
        users: { ...state.users },
        corporative: state.corporative ,
        meter: state.meter ,
        group: action.groupId
      };

    case userActionTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        user: { ...state.user },
        users: action.users
      };
    case userActionTypes.USERS_SIGNUP_STARTED:
      return {
        ...state,
        error: {}         
      };
    case userActionTypes.USERS_SIGNUP_SUCCESS:
      return {
        ...state,
        user: { ...state.user },
        users: state.users.push(action.payload),
        error: {} 
      };
    case userActionTypes.USERS_SIGNUP_FAILURE:
      return {
        users: {},
        error: action.error
      };
    case userActionTypes.USER_LOGOUT_SUCCESS:
      return {
        user: {},
        logged: false,
        error: {}
      };
    case userActionTypes.CLEAN_USER:
      return{
        ...state,
        user:{...state.user},
        users:action.payload,
      };
    case userActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          ({ id }) => id !== action.payload.id
        )
      };
    case userActionTypes.BLOCK_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => 
          user.id === action.payload.userId
            ? {...user, active: action.payload.active}
            : user
        )
      }
    default:
      return state;
  }
}

export default userReducer;
