import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

import { recoveredEmail } from "js/actions/userActions";
// Material helpers
import { makeStyles } from '@material-ui/core';


// Material components
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import useForm from 'react-hook-form';
const useStyles = makeStyles(styles);




export default function RecoveredEmail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  
  const onSubmit = (email) => {
    dispatch(recoveredEmail(email));
  };

  return (
    <div className={classes.root}>
    <Grid className={classes.grid} container>
      <Grid className={classes.quoteWrapper} item lg={5}>
        <div className={classes.quote} />
      </Grid>
      <Grid className={classes.content} item lg={7} xs={12}>
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid item lg={5}>
                <img
                  src="/images/logo-metimur.png"
                  alt=""
                  width="250px"
                />
              </Grid>
              <div className={classes.fields}>
                <Typography  style={{margin:'10px 0'}}>Ingresa tu correo electronico de recuperación </Typography>
                <TextField
                  className={classes.textField}
                  inputRef={register}
                  label="Correo Electronico"
                  name='email'
                  type="email"
                  variant="outlined"
                />
              </div>
              <Button
                  className={classes.signInButton}
                  color="secondary"
                  type='submit'
                  size="large"
                  variant="contained">
                  Recuperar Contraseña
                </Button>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
  )
}


