import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
import { useIntl } from 'react-intl';
const useStyle = makeStyles(styles);

function FormConcentrator(props) {
  const intl = useIntl()
  const classes = useStyle();
  const {meterId} = props.match.params

  return (
    <DashboardLayout title="Creacion de Concentradores">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <FormStepper meterId={meterId}/>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default FormConcentrator;
