import React, { useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';

import {  useDispatch } from 'react-redux';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  cleanCard
} from 'js/actions/cardAction';

// Custom components
import { UsersToolbar, UsersTable, ConcentratorTable } from './components';

// Component styles
import styles from './style';

import { Container, Button } from 'react-floating-action-button'
import { Link as LinkFloat } from 'react-floating-action-button'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(styles)


export default function  UserList(props) {

  const  classes = useStyles();
  const dispatch = useDispatch();
  const { meterId } = props.match.params
  const {id} = props.location.state

    
  useEffect(() => {
    dispatch(cleanCard());
  }, []);
  

  return (
    <DashboardLayout title="Tarjetas de Medición">
    <div className={classes.root}>
      <div className={classes.content}>
        <ConcentratorTable props={id} meterId={meterId}/>
      </div>
    </div>
    {/* <Container>
        <LinkFloat
        href= {`/newCard/${meterId}/${id}`}
        icon= "fa fa-plus"
        tooltip = "Crear Tarjeta"
        styles = {{backgroundColor:"#00AFEF", color: "#fff",width: "60px",height: "60px", fontSize:"1.5em"}}
        />
        <Button
          
          icon="fa fa-cog"
          rotate={true}
          styles={{backgroundColor: "#C69100", color: "#fff", width: "80px",height: "80px", fontSize:"1.5em"}}
        >
        </Button>
  
      </Container> */}
  </DashboardLayout>
  )
}
