import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {showLastUpdateRates} from 'js/actions/controlRateActions '


// Externals
import classNames from 'classnames';

// Material components
import { Button, makeStyles } from '@material-ui/core';


// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent
} from 'components';

import { Typography } from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)



export default function DayWithMostConsumeChart(props) {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const last_update= useSelector(state=>state.controlRate.last_update)
  
  const { className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  //const year = new Date().getFullYear()
  //const {corpId, meterId} = ids

  // const getCurrentMonth = () => {
  //   let month =  new Date().getMonth() + 1;
  //   if ( month<10) {return '0'+month} else {
  //     return month
  //   } 
  // }
  useEffect(()=>{
    dispatch(showLastUpdateRates())
  },[])

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({id: 'app.feeCtrl.title'})} />
        <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text"
          />
        </PortletToolbar>
      </PortletHeader>
      <PortletContent>
        
        <div className={classes.device}>
          <div className={classes.device}>
            <Typography variant="h2">{intl.formatMessage({id: 'app.feeCtrl.dateLbl'})}</Typography>
            <Typography
              style={{ color: '#00AFEF' }}
              variant="h1"
            >
              {last_update}
            </Typography>
          </div>
        </div>
      </PortletContent>
    </Portlet>
  );
}

















