import React, { useEffect } from 'react';
import MaterialTable from 'material-table';

import {MonoPhasicForm,BiPhasicForm,TriPhasicForm}  from '../../components'
import { Button, Select,MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { postForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';
import { showCards } from 'js/actions/cardAction';

export default function MaterialTableDemo(props) {
  const dispatch = useDispatch();
  const comunicationStepForm = useSelector(state => state.forms.data[1]);
  const { num_inputs,concentratorPhase } = comunicationStepForm.data;
  const {nextStep ,prevStep} = props

  const onSubmit = values => {
    dispatch(postForm(values));
  };

  const getPhaseData = (num_inputs, concentratorPhase) => {
    switch (num_inputs) {
      case '1':
        return <MonoPhasicForm
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        ></MonoPhasicForm>

      case '2':
        return <BiPhasicForm
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        ></BiPhasicForm> 

      case '3':
        return <TriPhasicForm
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        ></TriPhasicForm>
    }
  }

  return (
    <div>

      {getPhaseData(num_inputs , concentratorPhase)}
    </div>
  );
}
