/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as actionTypes from 'js/constants/action-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'


const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken(), 'content-type': 'multipart/form-data'}
  };
};

export const showCorporatives = () => {
  return async dispatch => {
    dispatch(getCorporativeStarted());
    axios
      .get('https://api.metimur.mx/api/corporative', getconfig())
      .then(response => {
        if( response.data.data && response.data.data.constructor.name==='Array' ){
          dispatch(getCorporatives(response.data.data.sort()));
        }else{
          dispatch(getCorporatives([response.data.data]));
        }
        
      })
      .catch(function(error) {
        dispatch(getCorporativesFailure())
        console.log('ERROR' + error);
      });
  };
};

export const getCorporativeStarted = () => ({
  type: actionTypes.GET_CORPORATIVE_STARTED,
  loading: true
});

export const getCorporatives = corporatives => {
  return {
    type: actionTypes.GET_CORPORATIVE_SUCCESS,
    corporatives,
    loading: false
  };
};

export const getCorporativesFailure = () => {
  return {
    type: actionTypes.GET_CORPORATIVE_FAILURE,
    loading: false
  }
}

export const postDataCorporative = (corporative, files) => {
  let formData = new FormData();
  formData.append('name',corporative.name);
  formData.append('city',corporative.city);
  formData.append('state',corporative.cstate);
  formData.append('description',corporative.description);
  formData.append('market_segment',corporative.market_segment);
  formData.append('preinvoice_for',corporative.preinvoice_for);
  formData.append('administrative_rfc',corporative.administrative_rfc);
  formData.append('administrative_cp' , corporative.administrative_cp  );
  formData.append('administrative_name',corporative.administrative_name);
  formData.append('administrative_tel' , corporative.administrative_tel )
  formData.append('administrative_email' , corporative.administrative_email )
  formData.append('administrative_email2' , corporative.administrative_email2 )    
  formData.append('logo',files[0]);

  



  return async dispatch => {
    dispatch(postCorporativeStarted());

    axios
      .post(
        'https://api.metimur.mx/api/corporative',
        formData
        ,
        getconfig()
      )
      .then(response => {
        //dispatch(postCorporativeSuccess(corporative));
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Concentrador ${corporative.name} creado exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push('/home')}
        );
      })
      .catch(error => {
        dispatch(postCorporativeFail(error.message));
        console.log('ERROR API:' + error);

        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        })  
      });
  };
};


export const postCorporativeStarted = () => ({
  type: actionTypes.POST_CORPORATIVE_STARTED
});

export const postCorporativeSuccess = data => {
  return {
    type: actionTypes.POST_CORPORATIVE_SUCCESS,
    payload: {
      name: data.name,
      city: data.city,
      state: data.cstate,
      description: data.description,
      market_segment: data.market_segment
    }
  };
};

export const postCorporativeFail = error => ({
  type: actionTypes.POST_CORPORATIVE_FAILURE,
  state: { error }
});

export const deleteCorporative = corpId => {
  return async dispatch => {

    dispatch(deleteCorporativeSuccess(corpId));
    axios.post(
      'https://api.metimur.mx/api/corporative/delete',
      { corporative_id: corpId },
      getconfig()
    )
    
    ;
  };
};

export const deleteCorporativeSuccess = corpId => {
  return {
    type: actionTypes.DELETE_CORPORATIVE_SUCCESS,
    payload:{id: corpId}
  };
};

export const updateDataCorporative = (corporative,id, files) => {
  let formData = new FormData();
  formData.append('corporative_id',id);
  formData.append('name',corporative.name);
  formData.append('city',corporative.city);
  formData.append('state',corporative.cstate);
  formData.append('description',corporative.description);
  formData.append('market_segment',corporative.market_segment);
  formData.append('preinvoice_for',corporative.preinvoice_for);
  formData.append('administrative_rfc',corporative.administrative_rfc);
  formData.append('administrative_cp' , corporative.administrative_cp  );  
  formData.append('administrative_name',corporative.administrative_name);
  formData.append('administrative_tel' , corporative.administrative_tel )
  formData.append('administrative_email' , corporative.administrative_email )
  formData.append('administrative_email2' , corporative.administrative_email2 )   
  if( files && files[0] ){
    formData.append('logo',files[0]);
  }else{
    formData.append('logo', null );
  }

  return async dispatch => {
    dispatch(putCorporativeStarted());

    axios
      .post(
        'https://api.metimur.mx/api/corporative/update',
        formData
        ,
        getconfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Actualización Correcta',
          text: `Concentrador ${corporative.name} actualizado exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push('/home')}
        );
      })
      .catch(error => {
        dispatch(postCorporativeFail(error.message));
        console.log('ERROR API:' + error);
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });

      });
  };
};

export const putCorporativeStarted = () => ({
  type: actionTypes.PUT_CORPORATIVE_STARTED
});

export const postPrefacturationLevel = (prefacturationLevel) => {
  return async dispatch => {
    //dispatch(postMeterStarted());

    axios
      .post(
        'https://api.metimur.mx/api/corporative/config',
        {
          corporative_id: prefacturationLevel.corporativeId ? prefacturationLevel.corporativeId : 0,
          config :
          {
            preinvoicing : 
                  {
                    preinvoicing_by : prefacturationLevel.prefacturationLevel
                  }
          }
        },
        getconfig()
      )
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        //dispatch(postMeterFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};

export const blockCorporativeAdmin = (corporative_id, active) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/admin/user/activation_corporative',
        {
          corporative_id: corporative_id,
          active: active
        },
        getconfig()
      )
      .then(function(response){
        dispatch(blockCorporativeSuccess(corporative_id, active));
      })
      .catch(function(error){
        console.log('error: ' + error)
      });
  }
}

export const blockCorporativeSuccess = (corporative_id, active) => {
  return {
    type: actionTypes.BLOCK_CORPORATIVE_SUCCESS,
    payload: { corporative_id, active}
  }
}
