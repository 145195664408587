export default theme => ({
  root: {
    height: 'fit-content !important'
  },
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartWrapper: {
    height: '400px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  stats: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing.unit
  },
  deviceIcon: {
    color: 'black',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});
