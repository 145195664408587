import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Grid } from '@material-ui/core';

import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';
import {   getGroupsList } from 'js/actions/groupActions';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

function UserConcentratorForm(props) {
  const intl = useIntl()
  const user = useSelector(state => state.user.user);
  const meterAdmin = useSelector(state => state.user.meter);
  const corporativeAdmin = useSelector(state => state.user.corporative.id);
  //let concentrators = useSelector(state => state.concentrators.concentrators);
  const groupsList = useSelector(state => state.groups.groupsList  || []  );
  const { charge } = props;

  useEffect(() => {
    dispatch(getGroupsList(corporativeAdmin, meterAdmin));
  }, [])

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const meterUser = user.meters;
  const meterId =
    meterUser === undefined || meterUser === null
      ? meterAdmin
      : meterUser[0].id;

  const getStringCharge = value => {
    switch (value) {
      case '1':
        return 'MONOFASICA';
    
      case '2':
        return 'BIFASICA';
    
      case '3':
        return 'TRIFASICA';
    }
  };

  const [num_inputs, setNumInputs] = useState(charge.num_inputs);

  const [unit, setUnit] = useState(charge.inputs[0].unit);
  const [elementType, setElementType] = useState(charge.element_type);
  const [group, setGroup] = useState(charge.group_id);
  const [chargeType, setChargeType] = useState(getStringCharge(charge.charge_type));



  const onSubmit = values => {
    //console.log(values)
    dispatch(postForm(values));
    props.nextStep();
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm());
    props.prevStep();
  };

  const handleNumInputs = numInputs => {
    setNumInputs(numInputs.target.value);
  };

  const handleUnit = unit => {
    setUnit(unit.target.value);
  };

  const handleElementTypeChange = elementType => {
    setElementType(elementType.target.value);
  };

  const handleChargeTypeChange = chargeType => {
    setChargeType(chargeType.target.value);
  };

  const handleGroupChange = group =>{
    setGroup(group.target.value)
  }

  useEffect(() => {
    dispatch(showConcentrators(meterId));
  }, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            //inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.unitsLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="unit"
            onChange={handleUnit}
            value={unit}

            //labelWidth={labelWidth}
          >
            <MenuItem value={'CORRIENTE'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.units.current'})}</MenuItem>
            <MenuItem value={'VOLTAJE'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.units.voltage'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElemLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="elementType"
            onChange={handleElementTypeChange}
            value={elementType}

            //labelWidth={labelWidth}
          >
            <MenuItem value={'CARGA'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElem.charge'})}</MenuItem>
            <MenuItem value={'CAPACITOR'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElem.capacitor'})}</MenuItem>
            <MenuItem value={'TRANSFORMADOR'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElem.transformer'})}</MenuItem>
            <MenuItem value={'TRANSFORMADOR'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.tipElem.engine'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.measureTypeLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="chargeType"
            onChange={handleChargeTypeChange}
            value={chargeType}

            //labelWidth={labelWidth}
          >
            <MenuItem value={'MONOFASICA'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.measureType.mono'})}</MenuItem>
            <MenuItem value={'BIFASICA'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.measureType.bi'})}</MenuItem>
            <MenuItem value={'TRIFASICA'}>{intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.measureType.tri'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            //inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.inputsUsed'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="num_inputs"
            onChange={handleNumInputs}
            value={num_inputs}

            //labelWidth={labelWidth}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>


        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.groupLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="group_id"
            onChange={handleGroupChange}
            value={group}
          >
            
            {groupsList.map(group => (
              <MenuItem value={group.id}>{group.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid item>

          <Button
            className={classes.button}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.saveAndContinueBtn'})}
          </Button>
          <Button
            className={classes.backButton}
            onClick={handleBack}
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.comunications.returnBtn'})}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}

export default UserConcentratorForm;
