import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowUpward as ArrowUpwardIcon,
  FlashOn as PeopleIcon,
  Timeline as AlertIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function Users(props) {
  const intl = useIntl();
  const classes = useStyles();
  const { className, ...rest } = props;
  const { group } = props;
  const rootClassName = classNames(classes.root, className);
  return (
    <Link
      to={{
        pathname: `/group/${group.id}`,
        state: { group: group }
      }}
    >
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              {intl.formatMessage({ id: 'app.group.board.mainHead.charges' })}
            </Typography>
            <Typography
              className={classes.value}
              style={{ height: '18px' }}
              variant="h3"
            />
          </div>
          <div className={classes.iconWrapper}>
            <AlertIcon className={classes.icon} />
          </div>
        </div>
      </Paper>
    </Link>
  );
}
