import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, TextField,Grid } from '@material-ui/core';

import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useState } from 'react';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}


function UserConcentratorForm(props) {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const concentrators = useSelector(state => state.concentrators.concentrators);
  const { meterId } = props.ids;
  const {card} = props

  const [cardType,setCardType] = useState(card.type)
  const [concentrator,setConcentrator] = useState(card.concentrator_id)
  const [comunicationType,setComunicationType] = useState(card.communication_channel)

  useEffect(() => {
    dispatch(showConcentrators(meterId));
  }, []);

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }

  const onSubmit = values => {
     dispatch(postForm(values));
     props.nextStep();
    //console.log('DATA: ', values);
  };

  const handleCardTypeChange = cardType => {
    setCardType(cardType.target.value);
  };

  const handleConcentratorChange = concentrator => {
    setConcentrator(concentrator.target.value);
  };

  const handleComunicationTypeChange = comunictionType => {
    setComunicationType(comunictionType.target.value)
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <FormControl
        className={classes.textField}
        variant="outlined"
      >
        <InputLabel
          id="demo-simple-select-outlined-label"
          
        >
          Tipo de Tarjeta
        </InputLabel>
        <Select
          inputRef={register}
          id="demo-simple-select-outlined"
          labelId="demo-simple-select-outlined-label"
          onChange={handleCardTypeChange}
          value={cardType}
          name='cardType'
        >

          <MenuItem value={'OMA-M14E'}>OMA-M14E</MenuItem>
        </Select>
      </FormControl>

      <TextField
        className={classes.textField}
        id="outlined-number"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={register}
        label="Numero de Serie"
        required
        type='number'
        margin="normal"
        defaultValue={card.serial_number}
        name="serial_number"
        variant="outlined"
      />

      <FormControl
        className={classes.textField}
        variant="outlined"
      >
        <InputLabel
          id="demo-simple-select-outlined-label"
         
        >
          Concentrador
        </InputLabel>
        <Select
         inputRef={register}
          id="demo-simple-select-outlined"
          labelId="demo-simple-select-outlined-label"
          onChange={handleConcentratorChange}
          value={concentrator}
          name='concentrator'
          //labelWidth={labelWidth}
        >
         
          {concentrators.map(concentrator => (
            <MenuItem value={concentrator.id}>{concentrator.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classes.textField}
        variant="outlined"
      >
        <InputLabel
          id="demo-simple-select-outlined-label"
          
        >
          Via de Comunicacion
        </InputLabel>
        <Select
          inputRef={register}
          id="demo-simple-select-outlined"
          labelId="demo-simple-select-outlined-label"
          onChange={handleComunicationTypeChange}
          value={comunicationType}
          name='comunicationType'
          //labelWidth={labelWidth}
        >

          <MenuItem value={'RS485'}>RS485</MenuItem>
          <MenuItem value={'ETHERNET'}>ETHERNET</MenuItem>
        </Select>
      </FormControl>

      <TextField
        className={classes.textField}
        id="outlined-number"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={register}
        label="Dirección MAC"
        defaultValue={card.mac_address}
        margin="normal"
        name="mac_address"
        variant="outlined"
      />

      <Button
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
        className={classes.button}
      >
        Guardar y Continuar
      </Button>
      <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          Regresar 
        </Button>
    </form>

    </Grid>



  );
}

export default UserConcentratorForm;
