import * as ZEPOMEXactionsTypes from 'js/constants/ZEPOMEX-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import swal from 'sweetalert';


const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showCountrySates = () => {
  return async dispatch => {
    dispatch(getSateStarted());
     axios
      .get(`https://api.copomex.com/query/get_estados?token=7fb2f19d-6e57-4f5a-b1e9-66f0719bd9e5`)
      .then(response => {
        dispatch(getSatesSucces(response.data.response));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getSateStarted = () => ({
  type: ZEPOMEXactionsTypes.GET_STATES_STARTED,
  loading: true
});

export const getSatesSucces = estados => {
  return {
    type: ZEPOMEXactionsTypes.GET_STATES_SUCCESS,
    estados,
  };
};

export const showCities = ( state ) => {
  return async dispatch => {
    dispatch(getCitiesStarted());
     axios
      .get(`https://api.copomex.com/query/get_municipio_por_estado/${state}?token=7fb2f19d-6e57-4f5a-b1e9-66f0719bd9e5`)
      .then(response => {
        dispatch(getCitiessSucces(response.data.response));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getCitiesStarted = () => ({
  type: ZEPOMEXactionsTypes.GET_CITIES_STARTED,
  loading: true
});

export const getCitiessSucces = cities => {
  return {
    type: ZEPOMEXactionsTypes.GET_CITIES_SUCCESS,
    cities,
  };
};

export const showCPS = ( city ) => {
  return async dispatch => {
    dispatch(getCPStarted());
     axios
      .get(`https://api.copomex.com/query/get_cp_por_municipio/${city}?token=7fb2f19d-6e57-4f5a-b1e9-66f0719bd9e5`)
      .then(response => {
        dispatch(getCPSucces(response.data.response));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getCPStarted = () => ({
  type: ZEPOMEXactionsTypes.GET_CP_STARTED,
  loading: true
});

export const getCPSucces = cp => {
  return {
    type: ZEPOMEXactionsTypes.GET_CP_SUCCESS,
    cp,
  };
};

export const showCounties =  cp  => {
  return async dispatch => {
    dispatch(getCountiesStarted());
     axios
      .get(`https://api.copomex.com/query/get_colonia_por_cp/${cp}?token=7fb2f19d-6e57-4f5a-b1e9-66f0719bd9e5`)
      .then(response => {
        dispatch(getCountiesSucces(response.data.response));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getCountiesStarted = () => ({
  type: ZEPOMEXactionsTypes.GET_COUNTIES_STARTED,
  loading: true
});

export const getCountiesSucces = counties => {
  return {
    type: ZEPOMEXactionsTypes.GET_COUNTIES_SUCCESS,
    counties,
  };
};