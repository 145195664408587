export const GET_USER_ALERT_STARTED = 'GET_USER_ALERT_STARTED';
export const GET_USER_ALERT_SUCCESS = 'GET_USER_ALERT_SUCCESS';
export const GET_USER_ALERT_FAILURE = 'GET_USER_ALERT_FAILURE';

export const POST_USER_ALERT_STARTED = 'POST_USER_ALERT_STARTED';
export const POST_USER_ALERT_SUCCESS = 'POST_USER_ALERT_SUCCESS';
export const POST_USER_ALERT_FAILURE = 'POST_USER_ALERT_FAILURE';

export const DELETE_USER_ALERT_STARTED = 'DELETE_USER_ALERT_STARTED';
export const DELETE_USER_ALERT_SUCCESS = 'DELETE_USER_ALERT_SUCCESS';

export const PUT_USER_ALERT_SUCCESS = 'PUT_USER_ALERT_SUCCESS';

export const GET_USER_ALERTS_CNS_STARTED = 'GET_USER_ALERTS_CNS_STARTED';
export const GET_USER_ALERTS_CNS_SUCCESS = 'GET_USER_ALERTS_CNS_SUCCESS';
export const GET_USER_ALERTS_CNS_FAILURE = 'GET_USER_ALERTS_CNS_FAILURE';

