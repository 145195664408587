/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormGroup, FormLabel, makeStyles } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField,
  Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { getChargeList, updateDataGroup } from 'js/actions/groupActions';
import { useIntl } from 'react-intl';
import theme from 'theme';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}



export default function GroupForm(props) {
  const intl = useIntl()
  const {group} = props.location.state
  
  const { getValues, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {meterId, corpId} = props.match.params;
  const {charge_types} = useSelector(state => state.groups);
  const [unificar, setUnificar] = React.useState(group.bill && group.bill === 1 ? {yes: true, no: false} : {yes: false, no: true})
  const energys = Object.values(charge_types);
  const [iva, setIva] = useState(group.iva ? group.iva : 16);
  
  const getCurrentDay = () => {
    const day = new Date()

    const currentDay = day.getDate()

    return 5
  }



  const tarifas = [
    //'DB1',
    //'DB2',
    'PDBT',
    'GDBT',
    //'RABT',
    //'RAMT',
    //'APBT',
    //'APMT',
    'GDMTO',
    'GDMTH',
    //'DIST',
    //'DIT'
  ];

  useEffect(()=>{
    dispatch(getChargeList());
  },[]);

  const getCustomTariff = (customTariffValidator) => {
    if(  customTariffValidator==null || customTariffValidator=='' ){
      return false;
    }

    switch ( parseInt( customTariffValidator)) {
      case 0:
        return false
      case 1:
        return true
          
    
      default:
        break;
    }
  }

  const [state, setState] = React.useState({
    customTariffValidator: getCustomTariff(group.is_custom_tariff)
  });
  const { customTariffValidator } = state;

  const onSubmit = values => {
    values.rateType = rateType
    values.bill = bill;
    values.type_energy = typeEnergy;
    dispatch(updateDataGroup(values,group, iva));
  };

  const handleChargeType = event => {
    setTypeEnergy(event.target.value); 
  };

  const [rateType, setRateType] = React.useState(group.rate_type);
  const [bill, setBill] = React.useState(group.bill ? group.bill : false);
  const [typeEnergy, setTypeEnergy] = React.useState(energys[group.type_energy]);

  const handleRateType = event => {
    setRateType(event.target.value);
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleUnificar = event => {
    const {name, checked} = event.target;
    if(name === 'yes' && checked){
      setUnificar({
        yes: true,
        no: false
      })
      setBill(true);
    }else if(name === 'no' && checked){
      setUnificar({
        yes: false,
        no: true
      });
      setBill(false);
    }
  }

  const getTariffSelectorComponent = () => {
    if (customTariffValidator === false) {
      return (
        <div>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                defaultChecked={customTariffValidator}
                inputRef={register}
                name="is_custom_tariff"
                onChange={handleChange('customTariffValidator')}
              />
            }
            label={intl.formatMessage({ id: 'app.groupsCards.editGroup.customRateLbl' })}
          />
          <FormControl
            className={classes.textField}
            variant="outlined"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {intl.formatMessage({ id: 'app.groupsCards.editGroup.rateTypeLbl' })}
            </InputLabel>
            <Select
              id="demo-simple-select-outlined"
              inputRef={register}
              labelId="demo-simple-select-outlined-label"
              name="rateType"
              onChange={handleRateType}
              value={rateType}
            >
              {tarifas.map(el => (
                <MenuItem
                  key={el}
                  value={el}
                >{el}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    } else {
      return (
        <TextField
          className={classes.textField}
          defaultValue={group.custom_tariff}
          inputRef={register}
          label={intl.formatMessage({ id: 'app.groupsCards.editGroup.customRateLbl' })}
          name="custom_tariff"
          variant="filled"
        />
      );
    }
  };

  return (
    <DashboardLayout title="Creacion de Grupos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.groupsCards.editGroup.title'})} />
            <Typography variant="caption">{intl.formatMessage({id: 'app.groupsCards.editGroup.msg'})}  </Typography>
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={group.name}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.groupNameLbl'})}
                      name="name"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.description}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.description'})}
                      name="description"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.monitoring_fee}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.monitChargeLbl'})}
                      name="monitoring_fee"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={group.flat_fee}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.editGroup.fixedChargeLbl'})}
                      name="flat_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    { getCurrentDay() > 5 ? (null): ( getTariffSelectorComponent() )}
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2}}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        inputRef={useOnMount}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.typeEnergy'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-label-outlined"
                        onChange={handleChargeType}
                        value={typeEnergy}
                      >
                        {charge_types ? Object.values(charge_types).map(el => (
                          <MenuItem
                            value={el}
                          >{el}</MenuItem>
                        )): (<MenuItem />)}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >{intl.formatMessage({id: 'app.chargesCards.unifyLoad'})}</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.yes}
                              color="secondary"
                              name="yes"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.yes'})}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.no}
                              color="secondary"
                              name="no"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.no'})}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.ivaTitle'})}
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 8}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(8)}}
                            />
                          }
                          label="8%"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 16}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(16)}}
                            />
                          }
                          label="16%"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>

              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                //onClick={this.handleSubmit}
                style={{marginTop:'2.5em'}}
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groupsCards.editGroup.registerBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}