import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import { Button, TextField, Grid , Select, MenuItem } from '@material-ui/core';


import InputLabel from '@material-ui/core/InputLabel';
 
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { getChargeVoltage } from 'js/actions/chargeActions';
 
const useStyles = makeStyles(styles);

function NameConcentratorForm(props) {
  const intl = useIntl()
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const {charge} = props;
  const [chargeName, setChargeName] = React.useState(null);
  const corporative = useSelector(state => state.user.corporative );
  const [chargeVolts, setChargeVolts]=React.useState(false);
  const [chargeVolt, setChargeVolt]=React.useState(null);

  
  useEffect(() => {
    setChargeName( charge.name  )
    dispatch(getChargeVoltage(corporative.id , funcRespChargeVoltage ));
  }, [charge]);
  

  const handleChargeVoltage = val => {
    setChargeVolt(val.target.value);
  };

  const funcRespChargeVoltage = info => {
    if( info && info.length>0 ){
      setChargeVolts(info);
    }
  } 

  const displayChargeVoltage = () => {
     
    if(  chargeVolts  ){
      return (
 
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
          >
            {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.descrip.chargeVoltageLbl'})}
          </InputLabel>

          <Select
           
         
            inputRef={register}
            label="Carga Voltage"
            name="charge_voltage"
            onChange={handleChargeVoltage}
            value={chargeVolt}
          >
            
            {chargeVolts.map(item => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>





      );



    }else{
      return null;
    }
    
    
     
  } 


  const onSubmit = values => {
    if(!isValid( values.charge_name )){
      Swal.fire({
        title: 'Error',
        text:  'El nombre de la medición no puede tener los simbolos  / \\ : ? * ',
        icon: 'error',
      })    
      return;
    }
    dispatch(postForm(values));
    props.nextStep();
  };

  const isValid = txt => {       
    let bValid=true ;
    let restricted = [ '/' ,  '\\'  , ':' , '?' ,  '*' ];
        
    for( var idx=0; idx< restricted.length; idx++  ){        
      if (txt.indexOf(restricted[idx]) >-1) {
        return false;
      }
    }  
    return bValid;

  }

  const classes = useStyles();

  const handleChangeName = (event) => {
    
    setChargeName( event.target.value );

    
  };

   

   
  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.descrip.chargeNameLbl'})}
          name="charge_name"
          onChange={event =>
            handleChangeName(event )
          }
          required
          value={chargeName}  
          variant="outlined"
        />    


        { displayChargeVoltage() }


        {/* <TextField
          className={classes.textField}
          inputRef={register}
          deafultValue={charge.description}
          label="Descripcion"
          name="charge_description"
          required
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          defaultValue={charge.ubication}
          label="Ubicación de  Medición"
          name="charge_ubication"
          variant="outlined"
        /> */}

        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.descrip.saveAndContinueLbl'})}
        </Button>
      </form>
    </Grid>
  );
}

export default NameConcentratorForm;
