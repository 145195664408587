/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { setMeterId } from 'js/actions/userActions.js';

import { makeStyles, Typography, Grid, Button, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';

import { Paper } from 'components';

import MaterialTable from 'material-table';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import ReactSpeedometer from 'react-d3-speedometer';
import { Settings } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { deleteMeter } from 'js/actions/meterActions';
import { getConsumesLastMonth } from 'js/actions/preInvoiceMeterAction';
import { isAllowed } from 'helpers';
const useStyles = makeStyles(styles);

export default function MeterCar(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const classes = useStyles();
  const { className, meter, setShowDetails, setSelectedCorp, setOpenDocuments, corpId } = props;
  const rootClassName = classNames(classes.root, className);
  const { consumes } = meter;
  const consumesTable = [consumes[0], consumes[1]];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let minVoltage=0;
  let maxVoltage=500;

  if( meter.rated_voltage>0 ){
    minVoltage= Math.floor( meter.rated_voltage*0.85);
    maxVoltage= Math.floor( meter.rated_voltage*1.15);
  }

 
  let voltPart0= Math.floor (meter.rated_voltage*0.85);
  let voltPart1= Math.floor (meter.rated_voltage*0.9  ) ;
  let voltPart2= Math.floor (meter.rated_voltage*0.95  ); 
  let voltPart3= Math.floor (meter.rated_voltage*1.05  ); 
  let voltPart4= Math.floor (meter.rated_voltage*1.1  );
  let voltPart5= Math.floor (meter.rated_voltage*1.15  );


  let voltValue= meter.voltage;
  if( meter.voltage<minVoltage){
    voltValue=minVoltage;
  }else if(meter.voltage>maxVoltage ){
    voltValue=maxVoltage;
  }

  let fpValue= typeof meter.fp === 'object' && meter.fp !== undefined && meter.fp !== null ? meter.fp.value * 100 : 0;
  // if( meter.fp.value <70){
  //   fpValue=70;
  // }else if(meter.fp.value>100 ){
  //   fpValue=100;
  // }

  const setAdminMeterId = meterId => {
    dispatch(setMeterId(meterId));
  };
  

  const columns = [
    {
      title: '' ,
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%',
        MinWidth: '10%'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.baseCol'}),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.intermidateCol'}),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.tipCol'}),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  const data = [];

  let obj;

  consumesTable.forEach(element => {
    obj = {
      med: element[0],
      bas: element[1],
      int: element[2],
      punt: element[3]
    };

    data.push(obj);
  });


  const handleShowDetails = () => {
    setSelectedCorp(meter);
    setShowDetails(true);
    setAnchorEl(null)
  }

  const handleShowDocuments = () => {
    setSelectedCorp(meter);
    setOpenDocuments(true);
    setAnchorEl(null)
  }

  const deleteMeterData = (meterId, corpId) => {
    Swal.fire({
      title: `${intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.title'
      })} `,
      text: `${intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.text'
      })} ${meterId.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: 'app.metersCards.deleteMetBtn.confirmation'
      })
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({
            id: 'app.metersCards.deleteMetBtn.titleConfirmation'
          }),
          text: intl.formatMessage({
            id: 'app.metersCards.deleteMetBtn.textConfirmation'
          }),
          icon: 'success'
        });
        dispatch(deleteMeter(meterId.id, corpId));
      }
    });
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const getConsumesLastMonthData = (corpId, meterId, year, month) => {
    dispatch(getConsumesLastMonth(corpId, meterId, year, month));
  };

  const isAllow = isAllowed(user.permissions, ['meter_read']) ? 6 : 12;


  return (
    <Paper className={rootClassName}>
      <div className={classes.details}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            alignItems="flex-start"
            container
            item
            justifyContent="space-between"
            spacing={1}
            style={{margin: '2px 2px 1rem', justifyContent: 'space-between'}}
            xs={12}
          >
            {/* <Grid
              className={classes.buttonMore}
              item
              lg={6}
              style={{justifyContent: 'flex-start'}}
              xs={6}
            >
              <Button
                color="secondary"
                onClick={handleShowDocuments}
                variant="contained"
              >
                Documentos 
              </Button>
            </Grid> */}
            <Grid
              item
              lg={6}
              style={{paddingRigth: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}
              xs={6}
            >
              <img
                src="/images/weather.png"
                style={{width: '35px', marginRight: '10px'}}
              />
              <Typography
                style={{fontWeight: '500'}}
                variant="body1"
              >
                {meter.weather ? `${meter.weather} C°` : ''}
              </Typography>
            </Grid>
            <Grid
              className={classes.buttonMore}
              item
              lg={6}
              style={{paddingRigth: 0}}
              xs={6}
            >
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label="settings"
                className={classes.settings}
                onClick={handleShowMenu}
              >
                <Tooltip title={intl.formatMessage({ id: 'app.chargesCards.addCharge.stepper.setting.title' })}>
                  <Settings />
                </Tooltip>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                elevation={1}
                id="simple-menu"
                keepMounted
                onClose={handleCloseMenu}
                open={open}
              >
                <MenuItem onClick={handleShowDetails}>
                  {intl.formatMessage({id: 'app.details.btn'})}
                </MenuItem>
                {/* <MenuItem
                  onClick={() =>
                    getConsumesLastMonthData(
                      meter.corporative_id,
                      meter.id,
                      new Date().getFullYear(),
                      new Date().getMonth()
                    )
                  }
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to={{
                      pathname: `/invoiceDataMeter/${meter.id}`,
                      state: { meter }
                    }}
                  >
                    {intl.formatMessage({ id: 'app.metersCards.preInvBtn' })}
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    getConsumesLastMonthData(
                      meter.corporative_id,
                      meter.id,
                      new Date().getFullYear(),
                      new Date().getMonth()
                    )
                  }
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#000' }}
                    to={{
                      pathname: `/preinvoice/${meter.id}`,
                      state: { meter }
                    }}
                  >
                    {intl.formatMessage({ id: 'app.metersCards.invDataBtn' })}
                  </Link>
                </MenuItem> */}
                {isAllowed(user.permissions, ['meter_delete']) && (
                  <MenuItem onClick={() => deleteMeterData(meter, corpId)}>{intl.formatMessage({ id: 'app.metersCards.deleteMetBtn' })}</MenuItem>
                )}
                {isAllowed(user.permissions, ['meter_update']) && (
                  <MenuItem>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to={{
                        pathname: `/updateMeter/${meter.id}`,
                        state: { meter }
                      }}
                    >
                      {intl.formatMessage({ id: 'app.metersCards.editMeterBtn' })}
                    </Link>
                  </MenuItem>
                )}
                {/* <MenuItem onClick={handleShowDocuments}>Documentos</MenuItem> */}
              </Menu>
              {/* <Button
                color="secondary"
                onClick={handleShowDetails}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.details.btn'})}  
              </Button> */}
            </Grid>
            <Grid
              container
              direction="column"
              item
              justifyContent="center"
              lg={7}
              style={{ padding: '2px' }}
              xs={7}
            >
              <Typography variant="h5">
                {meter.name}             
              </Typography>
              <Typography
                className={classes.heightLimit}
                variant="h5"
              >             
                {meter.description}
              </Typography>
              <Typography
                style={{backgroundColor: 'white'}}
                variant="body1"
              >             
                {intl.formatMessage({id: 'app.metersCards.card.city'})}: {meter.city}<br />
                {intl.formatMessage({id: 'app.metersCards.card.state'})}: {meter.state}
              </Typography>
                     
            </Grid>
            <Grid
              direction="column"
              item
              justifyContent="right"
              lg={4}
              style={{ textAlign: 'right' }}
              xs={4} 
            >
              <div  style={{   textAlign: 'right' , padding: '0px' , margin:'0px' }}      >
                <img
                  src="/images/icono3_co2.png"
                  style={{ width: '50px' , height: '50px' }}
                />
                <Typography
                  style={{ color:'#FF8024' , fontWeight :'bold' }}
                  variant="h5"
                >
                  {meter.consumes.co2} kg * 
                </Typography>                    
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <MaterialTable
              className={classes.table}
              columns={columns}
              data={data}
              options={{
                filtering: false,
                search: false,
                sorting: false,
                toolbar: false,
                paging: false,
                padding: 0,
                rowStyle: {
                  fontSize: 15,
                },
                headerStyle: {
                  textAlign: 'center',
                  padding: '0',
                  fontSize: '0.9em',
                  fontWeight: '900',
                  minWidth: '25%'
                }
              }}
              sx={{borderRadius: 1}}
            />
          </Grid>

          <Grid
            item
            lg={12}
            style={{display:'flex', gap: '2rem', justifyContent: 'flex-end', marginRight: '1rem'}}
            xl={12}
            xs={12}
          >
            <Typography
              className={classes.description}             
              style={{ marginTop: '5px', color: '#000' }}
              variant="h5"
            >
              {intl.formatMessage({id: 'app.metersCards.card.estAmtPerDay'})}
            </Typography>
          
            <Typography
              className={classes.description}
              style={{ color: '#218A1D', fontWeight: '600', marginTop: '5px' }}
              variant="h5"
            >
              $ {meter.consumes.total}
            </Typography>
          </Grid>  

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            style={{ textAlign:'center' }}
            xl={6}
            xs={12}
          >
      
            <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
              <ReactSpeedometer
                // currentValueText={fpValue}
                customSegmentStops={[70,80,90,100 ]}
                fontFamily={'Lato, Helvetica Neue,Arial'}
                forceRender
                height={120}
                labelFontSize={'12px'}
                maxValue={100}
                minValue={70}
                needleColor={'#000'}
                needleHeightRatio={0.7}
                paddingHorizontal={17}
                paddingVertical={17}
                ringWidth={30}
                segmentColors={['#EB0000', '#FAC800', '#00C454' ]}
                textColor={'#000'}
                value={fpValue}
                valueTextFontSize={'20px'}
                valueTextFontWeight={'600'} 
                width={135}
 
              />
            </div>

            <Typography
              className={classes.description}
              style={{  margin :'0px' ,  padding: '0px'  ,  color: '#000' , fontWeight :'600' }}
              variant="h5"
            >
              {intl.formatMessage({id: 'app.metersCards.card.fpLabel'})}
            </Typography>


          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            style={{ textAlign:'center' }}
            xl={6}
            xs={12}
          >
       

            <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
              <ReactSpeedometer
                currentValueText={'' +  meter.voltage}
                customSegmentStops={[voltPart0, voltPart1, voltPart2 , voltPart3 , voltPart4 , voltPart5 ]}
                fontFamily={'Arial'}
                forceRender
                height={120}
                labelFontSize={'12px'}
                maxValue={maxVoltage}
                minValue={minVoltage}
                needleColor={'#000'}
                needleHeightRatio={0.7}
                paddingHorizontal={17}
                paddingVertical={17}
                ringWidth={30}
                segmentColors={['#EB0000', '#FAC800', '#00C454','#FAC800' , '#EB0000' ]}
                textColor={'#000'}
                value={voltValue}
                valueTextFontSize={'20px'}
                valueTextFontWeight={'600'}
                width={135}
 
              />
            </div>
            <Typography
              className={classes.description}
              style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}
              variant="h5"
            >               
              {intl.formatMessage({id: 'app.metersCards.card.voltageLabel'})}
            </Typography>


          </Grid>
  
          <Grid
            item
            xs={isAllow}
          >
            <Link
              onClick={() => setAdminMeterId(meter.id)}
              to={{
                pathname: `/meter/${meter.id}`  ,
                state: {
                  meter
                }
              }}
            >
              <Button
                color="secondary"
                style={{ width: '100%' }}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groups.nav.groupLab2'})}
              </Button>
            </Link>
          </Grid>
          {isAllowed(user.permissions, ['meter_read']) && (
            <Grid
              item
              xs={6}
            >
              <Link
                to={{
                  pathname: `/meterDashboard/${meter.corporative_id}/${meter.id}`,                 
                  state:{meter}
                }}
              >
                <Button
                  color="secondary"
                  style={{ width: '100%' }}
                  variant="contained"
                >
                  {intl.formatMessage({id: 'app.metersCards.card.boardBtn'})}
                </Button>
              </Link>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center' }}
              variant="body1"
            >
              {intl.formatMessage({id: 'app.metersCards.card.meassCurrMonth'})}
            </Typography>
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center'    }}
              variant="body1"
            > 
              {intl.formatMessage({id: 'app.metersCards.card.co2Msg'})}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
