// Chart data
export const data = {
  labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre', 'Octubre','Noviembre','Diciembre',],
  datasets: [
    {
      label: '2020',
      fill: false,
      backgroundColor:'#C69100',
      borderColor:'#C69100',
      lineTension: 0.1,
      // backgroundColor: palette.primary.main,
      data: [38.640, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  
  ]
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        let value = tooltipItem.value;
        label += `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kWh`;
        return label;
      }
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        callback: function(value) {
          return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        }
      }
    }]
  }
};
