import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid
} from '@material-ui/core';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '@material-ui/core/TextField';

import { showRates } from 'js/actions/controlRateActions ';

import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  signUpButton: {
    backgroundColor:'#FEC12C', 
    width:'250px', 
    height:'45px',
    marginTop:'20px',
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  }
}));

const defaultSelectValues = {
  rateType: null,
  division: null,
  year: null,
  month: null
};

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

export default function InputAdornments() {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const control_rate = useSelector(state => state.controlRate.control_rate);
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });

  let year = new Date().getFullYear()
  let arr_years=[ year.toString() , (year-1).toString()  , (year-2).toString() , (year-3).toString()  , (year-4).toString() , (year-5).toString()  ]
  let defaultYear=year.toString()

  const onSubmit = values => {
    //console.log('DATA: ', values);

    if( !values.month ){
      Swal.fire({
        title: 'Error',
        text:  'Favor de seleccionar el mes' ,
        icon: 'error',
      })

      return;

    }



    dispatch(showRates(values,year));
    setState({
      showTable: true
    });
  };

  const tarifas = ['GDBT', 'PDBT', 'GDMTO', 'GDMTH'];

  const divisiones = [
    'Norte',
    'Baja California',
    'Baja California Sur',
    'Bajío',
    'Centro Occidente',
    'Centro Oriente',
    'Centro Sur',
    'Golfo Centro',
    'Golfo Norte',
    'Jalisco',
    'Valle de México Centro',
    'Valle de México Norte',
    'Valle de México Sur',
    'Noroeste',
    'Oriente',
    'Peninsular',
    'Sureste'
  ];

  useOnMount(() => {
    register({ name: 'rateType', type: 'text' });
    register({ name: 'division', type: 'text' });
    register({ name: 'year', type: 'text' });
    register({ name: 'month', type: 'text' });
    setValue('year', defaultYear   );
  });

  const handleRateType = rateType => {
    setValue('rateType', rateType.target.value);
    //getCity(state.target.value);
  };

  const handleDivision = division => {
    setValue('division', division.target.value);
    //getCity(state.target.value);
  };

  const handleYear = year => {
    setValue('year', year.target.value);
    //getCity(state.target.value);
  };

  const handleMonth = month => {
    setValue('month', month.target.value);
    //getCity(state.target.value);
  };

  const values = getValues();

  const [state, setState] = React.useState({
    showTable: false
  });
  return (
    <div>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={3}
        >  
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.feeCtrl.divisionLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleDivision}
                value={values.division}
                //labelWidth={labelWidth}
              >
                
                {divisiones
                  ? divisiones.map(el => <MenuItem value={el}>{el}</MenuItem>)
                  : ''}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.feeCtrl.feeTypeLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleRateType}
                value={values.rateType}
                //labelWidth={labelWidth}
              >
              
                {tarifas
                  ? tarifas.map(el => <MenuItem value={el}>{el}</MenuItem>)
                  :''}
              </Select>
            </FormControl>
          </Grid>


          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.feeCtrl.yearLbl'})}
              </InputLabel>
              <Select
                defaultValue={defaultYear}
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleYear}
                //labelWidth={labelWidth}
                value={values.year}
              >
              
                {
                  arr_years.map(el => <MenuItem value={el}>{el}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>


          <Grid
            item
            lg={3}
            md={6}
            xs={12}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.feeCtrl.monthLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleMonth}
                value={values.month}
                //labelWidth={labelWidth}
              >
                
                <MenuItem value="01">{intl.formatMessage({id: 'app.months.jan'})}</MenuItem>
                <MenuItem value="02">{intl.formatMessage({id: 'app.months.feb'})}</MenuItem>
                <MenuItem value="03">{intl.formatMessage({id: 'app.months.mar'})}</MenuItem>
                <MenuItem value="04">{intl.formatMessage({id: 'app.months.apr'})}</MenuItem>
                <MenuItem value="05">{intl.formatMessage({id: 'app.months.may'})}</MenuItem>
                <MenuItem value="06">{intl.formatMessage({id: 'app.months.jun'})}</MenuItem>
                <MenuItem value="07">{intl.formatMessage({id: 'app.months.jul'})}</MenuItem>
                <MenuItem value="08">{intl.formatMessage({id: 'app.months.aug'})}</MenuItem>
                <MenuItem value="09">{intl.formatMessage({id: 'app.months.sep'})}</MenuItem>
                <MenuItem value="10">{intl.formatMessage({id: 'app.months.oct'})}</MenuItem>
                <MenuItem value="11">{intl.formatMessage({id: 'app.months.nov'})}</MenuItem>
                <MenuItem value="12">{intl.formatMessage({id: 'app.months.dec'})}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Button
            className={classes.signUpButton}
            color="secondary"
            //onClick={this.handleSubmit}
            size="large"
            type="submit"
            variant="contained"
          >
            {intl.formatMessage({id: 'app.feeCtrl.searchBtn'})}
          </Button>

          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {state.showTable && (
              <Grid item>
                <div>
                  <FormControl
                    className={classes.margin}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      {intl.formatMessage({id: 'app.feeCtrl.distributionLbl'})}
                    </InputLabel>
                    <OutlinedInput
                      disabled
                      id="outlined-adornment-amount"
                      //onChange={handleChange('amount')}
                      labelWidth={60}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      value={control_rate.distribucion}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.margin}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      {intl.formatMessage({id: 'app.feeCtrl.capacityLbl'})}
                    </InputLabel>
                    <OutlinedInput
                      disabled
                      id="outlined-adornment-amount"
                      //onChange={handleChange('amount')}
                      labelWidth={60}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      value={control_rate.capacidad}
                    />
                  </FormControl>
                  {control_rate.cenace ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label="CENACE"
                      value={
                        control_rate.cenace && Array.isArray(control_rate.cenace)
                          ? control_rate.cenace[0]  
                          :  control_rate.cenace ? control_rate.cenace : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label="CENACE"
                      value={control_rate.cenace && Array.isArray(control_rate.cenace)  ? control_rate.cenace[0] :  control_rate.cenace? control_rate.cenace: 0}
                      variant="outlined"
                    />
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">I</InputAdornment>
                        )
                      }}
                      value={control_rate.cenace && Array.isArray(control_rate.cenace ) ? control_rate.cenace[1] : 0}
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">P</InputAdornment>
                        )
                      }}
                      value={control_rate.cenace && Array.isArray(control_rate.cenace) ? control_rate.cenace[2] : 0}
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                </div>
                <div>
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label={intl.formatMessage({id: 'app.feeCtrl.transmisionLbl'})}
                      value={
                        control_rate.transmision && Array.isArray( control_rate.transmision) ? control_rate.transmision[0] : control_rate.transmision ? control_rate.transmision : 0 
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">I</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.transmision && Array.isArray( control_rate.transmision) ? control_rate.transmision[1] : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">P</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.transmision  && Array.isArray( control_rate.transmision)  ? control_rate.transmision[2] :  0 
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                </div>
                <div>
                  {control_rate.scnmem ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label="SCnMEM"
                      value={control_rate.scnmem && Array.isArray(control_rate.scnmem) ? control_rate.scnmem[0]   : control_rate.scnmem ? control_rate.scnmem :0}
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.scnmem ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">I</InputAdornment>
                        )
                      }}
                      value={control_rate.scnmem && Array.isArray(control_rate.scnmem) ? control_rate.scnmem[1] : 0}
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">P</InputAdornment>
                        )
                      }}
                      value={control_rate.scnmem && Array.isArray(control_rate.scnmem) ? control_rate.scnmem[2] :  0}
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                </div>
                <div>
                  {control_rate.generacion ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label={intl.formatMessage({id: 'app.feeCtrl.generationLbl'})}
                      value={
                        control_rate.generacion && Array.isArray(control_rate.generacion ) ? control_rate.generacion[0] : control_rate.generacion ? control_rate.generacion: 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.scnmem ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">I</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.generacion && Array.isArray(control_rate.generacion ) ? control_rate.generacion[1] : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.transmision ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">P</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.generacion && Array.isArray(control_rate.generacion) ? control_rate.generacion[2] : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                </div>
                <div>
                  {control_rate.total ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">B</InputAdornment>
                        )
                      }}
                      label={intl.formatMessage({id: 'app.feeCtrl.total'})}
                      value={
                        control_rate.total && control_rate.total.B ? control_rate.total.B : control_rate.total && control_rate.total.variable ? control_rate.total.variable : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.total ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">I</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.total && control_rate.total.I ? control_rate.total.I : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.total ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">P</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.total && control_rate.total.P  ? control_rate.total.P : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                  {control_rate.total ? (
                    <TextField
                      className={clsx(classes.margin, classes.textField)}
                      disabled
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">DYC</InputAdornment>
                        )
                      }}
                      value={
                        control_rate.total ? control_rate.total.dyc : 0
                      }
                      variant="outlined"
                    />
                  ) : (
                    ' '
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
