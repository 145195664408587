// Palette
import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    padding: '9px 12px !important',
    textAlign:'center',
    
  },
  head: {
    fontSize: '14px',
    opacity:'1',
    color: palette.text.primary
  }
};
