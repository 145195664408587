import * as systemAlerActionTypes from '../constants/systemAlertAction-types';


export default function alertsSystemReducer(
  state = { systemAlert: [], loading: false },
  action
) {
  switch (action.type) {
    case systemAlerActionTypes.GET_SYSTEM_ALERT_STARTED:
      return {
        ...state,
        loading: true
      };
    case systemAlerActionTypes.GET_SYSTEM_ALERT_SUCCESS:
      return { ...state, 
        systemAlert: action.systemAlert, 
        loading: false 
      };

    case systemAlerActionTypes.POST_SYSTEM_ALERT_STARTED:
      return {
        ...state,
        loading: true
      };
    case systemAlerActionTypes.POST_SYSTEM_ALERT_SUCCESS:
      return {
        ...state,
        alertSystem: state.alertSystem.push(action.payload),
        loading: false
      };

    case systemAlerActionTypes.POST_SYSTEM_ALERT_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

   

    case systemAlerActionTypes.DELETE_SYSTEM_ALERT_STARTED:
      return { ...state, loading: true };

    case systemAlerActionTypes.DELETE_SYSTEM_ALERT_SUCCESS:
      return state.alertSystem.filter(
        post => post._id !== action.payload.corporative_id
      );

    default:
      return state;
  }
}

// export default rootReducer;
