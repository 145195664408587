export const GET_TOTAL_ACTUAL_MONTH_SUCCESS = 'GET_TOTAL_ACTUAL_MONTH_SUCCESS';

export const GET_MONTH_MOST_CONSUME_SUCCESS = 'GET_MONTH_MOST_CONSUME_SUCCESS';

export const GET_COMPARATIVE_YEARS_CONSUME_SUCCESS = 'GET_COMPARATIVE_YEARS_CONSUME_SUCCESS';

export const GET_COMPARATIVE_YEARS_TARIFF_SUCCESS = 'GET_COMPARATIVE_YEARS_TARIFF_SUCCESS';

export const GET_DAY_WITH_MOST_CONSUME_SUCCESS = 'GET_DAY_WITH_MOST_CONSUME_SUCCESS';

export const GET_CHARGE_WITH_MOST_CONSUME_SUCCESS = 'GET_CHARGE_WITH_MOST_CONSUME_SUCCESS';

