export default theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  title: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  table: {
    minWidth: 650,
    maxWidth: '100vw'
  },
  positionLeft: {
    position: 'sticky',
    left: '0',
    background: 'white',
    zIndex: 1
  },
});
  