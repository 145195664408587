export const translatePermission = permissions => {
  const tpermission = [];

  permissions.forEach(permission => {
    switch (permission.name || permission) {
      case 'corporative_read':
        tpermission.push('Lectura de Corporativos');
        break;

      case 'corporative_create':
        tpermission.push('Creación de Corporativos');
        break;

      case 'corporative_update':
        tpermission.push('Actualización de Corporativos');
        break;

      case 'corporative_delete':
        tpermission.push('Borrado de Corporativos');
        break;

      case 'charge_read':
        tpermission.push('Lectura de Mediciones');
        break;

      case 'charge_create':
        tpermission.push('Creación de Mediciones');
        break;

      case 'charge_update':
        tpermission.push('Actualización de Mediciones');
        break;

      case 'charge_delete':
        tpermission.push('Borrado de Mediciones');
        break;

      case 'meter_read':
        tpermission.push('Lectura de Medidores');
        break;

      case 'meter_create':
        tpermission.push('Creación de Medidores');
        break;

      case 'meter_update':
        tpermission.push('Actualización de Medidores');
        break;

      case 'meter_delete':
        tpermission.push('Borrado de Medidores');
        break;

      case 'role_read':
        tpermission.push('Lectura de Roles');
        break;

      case 'role_create':
        tpermission.push('Creación de Roles');
        break;

      case 'role_update':
        tpermission.push('Actualización de Roles');
        break;

      case 'role_delete':
        tpermission.push('Borrado de Roles');
        break;

      case 'group_read':
        tpermission.push('Lectura de Grupos');
        break;

      case 'group_create':
        tpermission.push('Creación de Grupos');
        break;

      case 'group_update':
        tpermission.push('Actualización de Grupos');
        break;

      case 'group_delete':
        tpermission.push('Borrado de Grupos');
        break;

      case 'user_read':
        tpermission.push('Lectura de Usuarios');
        break;

      case 'user_create':
        tpermission.push('Creación de Usuarios');
        break;

      case 'user_update':
        tpermission.push('Actualización de Usuarios');
        break;

      case 'user_delete':
        tpermission.push('Borrado de Usuarios');
        break;

      case 'concentrator_read':
        tpermission.push('Lectura de Concentradores');
        break;

      case 'concentrator_create':
        tpermission.push('Creación de Concentradores');
        break;

      case 'concentrator_update':
        tpermission.push('Actualización de Concentradores');
        break;

      case 'concentrator_delete':
        tpermission.push('Borrado de Concentradores');
        break;

      case 'card_read':
        tpermission.push('Lectura de Tarjetas Medición');
        break;

      case 'card_create':
        tpermission.push('Creación de Tarjetas Medición');
        break;

      case 'card_update':
        tpermission.push('Actualización de Tarjetas Medición');
        break;

      case 'card_delete':
        tpermission.push('Borrado de Tarjetas Medición');
        break;

      case 'alert_read':
        tpermission.push('Lectura de Alertas');
        break;

      case 'alert_create':
        tpermission.push('Creación de Alertas');
        break;

      case 'alert_update':
        tpermission.push('Actualización de Alertas');
        break;

      case 'alert_delete':
        tpermission.push('Borrado de Alertas');
        break;

      case 'tariff_read':
        tpermission.push('Lectura de Tarifas');
        break;

      case 'tariff_create':
        tpermission.push('Creación de Tarifas');
        break;

      case 'tariff_update':
        tpermission.push('Actualización de Tarifas');
        break;

      case 'tariff_delete':
        tpermission.push('Borrado de Tarifas');
        break;
      case 'onoff_task_read':
        tpermission.push('Lectura de Tareas');
        break;

      case 'onoff_task_create':
        tpermission.push('Creación de Tareas');
        break;

      case 'onoff_task_update':
        tpermission.push('Actualización de Tareas');
        break;

      case 'onoff_task_delete':
        tpermission.push('Borrado de Tareas');
        break;
      case 'onoff_card_read':
        tpermission.push('Lectura de Tarjetas de Control');
        break;

      case 'onoff_card_create':
        tpermission.push('Creación de Tarjetas de Control');
        break;

      case 'onoff_card_update':
        tpermission.push('Actualización de Tarjetas de Control');
        break;

      case 'onoff_card_delete':
        tpermission.push('Borrado de Tarjetas de Control');
        break;
      case 'prefacture_read':
        tpermission.push('Lectura de Prefacturacion');
        break;

      case 'prefacture_create':
        tpermission.push('Creación de Prefacturacion');
        break;

      case 'prefacture_update':
        tpermission.push('Actualización de Prefacturacion');
        break;

      case 'prefacture_delete':
        tpermission.push('Borrado de Prefacturacion');
        break;

      default:
        break;
    }
  });

  return tpermission;
};
