// Palette
import palette from 'theme/palette';

// Chart data
export const data = {
  labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto'],
  datasets: [
    {
      label: '2018',
      yAxisID:'A',
      // backgroundColor: palette.primary.main,
      backgroundColor: "#D64539",
      data: [18.00, 10.50, 14.50,18.00, 10.50, 14.50,16.00, 12.50, ]
    },
    {
      label: ' 2019',
      yAxisID:'A',
      // backgroundColor: palette.primary.main,
      backgroundColor: "#8CBA42",
      data: [16.00, 12.50, 14.50,16.00, 12.50, 14.50,12.50, 14.50 ]
    },
    // {
    //   label: ' kWh',
    //   yAxisID:'B',
    //   // backgroundColor: palette.common.neutral,
    //   backgroundColor: '#0080BC',
    //   data: [101, 220, 165 ]
    // },
  
  ]
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: true },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        id: 'A',
        position:'left',
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      },
      // {
      //   id: 'B',
      //   position:'right',
      //   ticks: {
      //     fontColor: "#0080BC",
      //     beginAtZero: true,
      //     min: 0
      //   },
      //   gridLines: {
      //     borderDash: [2],
      //     borderDashOffset: [2],
      //     color: palette.divider,
      //     drawBorder: false,
      //     zeroLineBorderDash: [2],
      //     zeroLineBorderDashOffset: [2],
      //     zeroLineColor: palette.divider
      //   }
      // }
    ]
  }
};
