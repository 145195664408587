//USERS
export const GET_USER = 'GET_USER';
export const POST_USER = 'POST_USER';
export const GET_LIST_USER = 'GET_LIST_USER';

//ROLE
export const GET_ROLE = 'GET_ROLE';
export const POST_ROLE = 'POST_ROLE';

export const GET_CORPORATIVE_STARTED = 'GET_CORPORATIVE_STARTED';
export const GET_CORPORATIVE_SUCCESS = 'GET_CORPORATIVE_SUCCESS';
export const GET_CORPORATIVE_FAILURE = 'GET_CORPORATIVE_FAILURE';

export const BLOCK_CORPORATIVE_SUCCESS = 'BLOCK_CORPORATIVE_SUCCESS';

export const POST_CORPORATIVE_STARTED = 'POST_CORPORATIVE_STARTED';
export const POST_CORPORATIVE_SUCCESS = 'POST_CORPORATIVE_SUCCESS';
export const POST_CORPORATIVE_FAILURE = 'POST_CORPORATIVE_FAILURE';

export const DELETE_CORPORATIVE_STARTED = 'DELETE_CORPORATIVE_STARTED';
export const DELETE_CORPORATIVE_SUCCESS = 'DELETE_CORPORATIVE_SUCCESS';
export const DELETE_CORPORATIVE_FAILURE = 'DELETE_CORPORATIVE_FAILURE';

export const PUT_CORPORATIVE_STARTED = 'PUT_CORPORATIVE_STARTED';

//GROUP
export const GET_GROUP = 'GET_GROUP';
export const POST_GROUP = 'POST_GROUP';

//CARD
export const GET_CARD = 'GET_CARD';
export const POST_CARD = 'POST_CARD';

//CHARGE
export const GET_CHARGE = 'GET_CHARGE';
export const POST_CHARGE = 'POST_CHARGE';
