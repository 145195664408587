/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect } from 'react';

// Palette
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';


// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';

import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getComparativeYearTariff } from 'js/actions/meterDashboardActions';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function SalesChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  const year = new Date().getFullYear();
  const lastYear = year - 1;
  const years = [year, lastYear];
  const { corpId, meterId } = ids;

  const comparativeYearTariff = useSelector(
    state => state.meterDashboard.comparativeYearTariff
  );

  const basact = [];
  const basant = [];
  const intact = [];
  const intant = [];
  const punact = [];
  const punant = [];

  const tuact = [];
  const tuant = [];


  const actualYearTariff = [];
  const lastYearTariff = [];
  const [data1, setData1] = React.useState( );
  const monthNames=[
    intl.formatMessage({id: 'app.months.jan'}) ,
    intl.formatMessage({id: 'app.months.feb'}),
    intl.formatMessage({id: 'app.months.mar'}),
    intl.formatMessage({id: 'app.months.apr'}),
    intl.formatMessage({id: 'app.months.may'}),
    intl.formatMessage({id: 'app.months.jun'}),
    intl.formatMessage({id: 'app.months.jul'}),
    intl.formatMessage({id: 'app.months.aug'}),
    intl.formatMessage({id: 'app.months.sep'}),
    intl.formatMessage({id: 'app.months.oct'}),
    intl.formatMessage({id: 'app.months.nov'}),
    intl.formatMessage({id: 'app.months.dec'})
  ];
    
  useEffect(() => {
    dispatch(getComparativeYearTariff(corpId, meterId, years));
  }, []);

  let isGDMTH=true;

  useEffect(() => {
    
    if (comparativeYearTariff) {
      Object.values(comparativeYearTariff).forEach((year, index) => {
        if (index == 0) {         
          actualYearTariff.push(year);
          if( actualYearTariff[0].GDMTH ){
            isGDMTH=true;
            Object.values(actualYearTariff[0].GDMTH).forEach(element => {
              for (
                let index = 0;
                index < Object.values(element).length;
                index++
              ) {
                const element2 = Object.values(element)[index];
  
                switch (index) {
                  case 0:
                    basant.push(element2);
                    break;
  
                  case 1:
                    intant.push(element2);
                    break;
  
                  case 2:
                    punant.push(element2);
                    break;
  
                  default:
                    break;
                }
              }
            });

          }else{
            if( actualYearTariff[0].GDMTO ){
              isGDMTH=false;
              Object.values(actualYearTariff[0].GDMTO).forEach(element => {
                tuant.push( element );              
              });
            }
          }


        } else {
          lastYearTariff.push(year);
          if( lastYearTariff[0].GDMTH ){
            isGDMTH=true;
            Object.values(lastYearTariff[0].GDMTH).forEach(element => {
              for (
                let index = 0;
                index < Object.values(element).length;
                index++
              ) {
                const element2 = Object.values(element)[index];
  
                switch (index) {
                  case 0:
                    basact.push(element2);
                    break;
  
                  case 1:
                    intact.push(element2);
                    break;
  
                  case 2:
                    punact.push(element2);
                    break;
  
                  default:
                    break;
                }
              }
            });

          }else{
            if( lastYearTariff[0].GDMTO ){
              isGDMTH=false;
              Object.values(lastYearTariff[0].GDMTO).forEach(element => {
                tuact.push( element );              
              });
            }
          }

        }
      });

      if(!isGDMTH){
        setData1( {
          labels: monthNames ,
          datasets: [       
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.prevYear'}),
              yAxisID: 'A',
              // backgroundColor: palette.primary.main,
              backgroundColor: 'red',
              data: tuant
            },                    
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.currYear'}),
              yAxisID: 'A',
              // backgroundColor: palette.primary.main,
              backgroundColor: '#00AFEF',
              data: tuact
            } 
                     
          ]
        }  );        


      }else{

        setData1( {
          labels: monthNames ,
          datasets: [
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.baseCurrYear'}),
              yAxisID: 'A',            
              backgroundColor: '#00AFEF',
              data: basact
            },
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.basePrevYear'}),
              yAxisID: 'A',            
              backgroundColor: '#3A5F0B',
              data: basant
            },
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.intermedCurrYear'}),
              yAxisID: 'A',            
              backgroundColor: '#ebc057',
              data: intact
            },
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.intermedPrevYear'}),
              yAxisID: 'A',            
              backgroundColor: '#FFCC00',
              data: intant
            },
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.tipCurrYear'}),
              yAxisID: 'A',            
              backgroundColor: '#ED685E',
              data: punact
            },
            {
              label: intl.formatMessage({id: 'app.meter.board.ratesVariation.dataset.labels.tipPrevYear'}),
              yAxisID: 'A',            
              backgroundColor: 'red',
              data: punant
            }
          ]
        }  );


      }




    } else {
      console.log('no estan las tarifas');
    }
  }, [comparativeYearTariff]);

  console.log(basact)

     
  const formatMoney=(value)=>{
    return '$ 0.9999';   
  }
     
 
  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary   ,
      callbacks: {               
        label: function(tooltipItem, data) {         
          return  `${   data.datasets[tooltipItem.datasetIndex ].label + ' $ '+ tooltipItem.value }`;
        },

      },
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary  
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
            min: 0,
            callback: function(value) {
              return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    } 
  
  };
  

  return (
    
        
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader
        noDivider
      >
        <PortletLabel    title={intl.formatMessage({id: 'app.meter.board.ratesVariation.title'})} />
        <PortletToolbar />
      </PortletHeader>
      <PortletContent>
        <div  className={classes.chartWrapper}>         
          <Bar
            data={data1}
            key={Math.random()}
            options={options}
            redraw
          />                
        </div>
      </PortletContent>
    </Portlet>
       
 
    
  );


 

 

}




SalesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};
