/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as chargeActionTypes from 'js/constants/chargeAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};



export const getChargeVoltage = ( corporativeId , fnResult ) => {
  return async dispatch => {
       
    axios
      .get(
        `https://api.metimur.mx/api/chargesvoltage/${corporativeId}`,
        getconfig()
      )
      .then(response => {
        fnResult( response.data.data);
      })
      .catch(function(error) {      
        console.log('error:' + error);         
      });
  };
};


export const showCharge = groupId => {
  return async dispatch => {
    dispatch (getChargeStarted())
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupId}/charges`,
        getconfig()
      )
      .then(response => {
        dispatch(getCharges(Object.values(response.data.data)));
      })
      .catch(function(error) {      
        console.log('error:' + error);
        dispatch(getChargesFail(error.data));
      });
  };
};

export const showAllCharge = groupId => {
  return async dispatch => {
    dispatch (getChargeStarted())
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupId}/charges/list`,
        getconfig()
      )
      .then(response => {
        console.log(response.data)

        dispatch(getCharges(Object.values(response.data.data)));

      })
      .catch(function(error) {       
        console.log('error:' + error);
      });
  };
};

export const getChargeStarted = () => {
  return {
    type: chargeActionTypes.GET_CHARGE_STARTED,
    loading:true
  };
};

export const getCharges = charges => {
  return {
    type: chargeActionTypes.GET_CHARGE_SUCCESS,
    charges,
    loading: false
  };
};


export const getChargesFail = error => ({
  type: chargeActionTypes.GET_CHARGE_FAILURE ,
  state: { error }
});

export const postDataCharge = charge => {
  return async dispatch => {
    dispatch(postChargeStarted());  
    axios
      .post(
        'https://api.metimur.mx/api/charge',
        {
          name: charge.name,
          description: charge.description,
          ubication: charge.ubication,
          element_type: charge.element_type,
          charge_type: charge.charge_type,
          num_inputs: charge.num_inputs,
          concentrator_id: charge.concentrator_id,
          group_id:charge.group_id,
          fp: charge.fp,          
          is_constant : charge.is_constant ,
          voltage: charge.voltage,
          inputs: charge.inputs, 
          charge_voltage : charge.charge_voltage ,
          is_father : charge.is_father ,
          demanda_min: charge.demanda_min , 
          demanda_max: charge.demanda_max ,
          consumo_min: charge.consumo_min ,
          consumo_max: charge.consumo_max

        },
        getconfig()
      )
      .then(response => {
        dispatch(postChargeSuccess(charge));
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Carga ${charge.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/group/${charge.group_id}`)}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al crear',
            html: `Se encontraron los siguientes errores al crear la carga:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postChargeFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const postChargeStarted = () => ({
  type: chargeActionTypes.POST_CHARGE_STARTED
});

export const postChargeSuccess = charge => {
  return {
    type: chargeActionTypes.POST_CHARGE_SUCCESS,
    payload: {
      name: charge.name,
      description: charge.description,
      ubication: charge.ubication,
      element_type: charge.element_type,
      charge_type: charge.charge_type,
      num_inputs: charge.num_inputs,
      concentrator_id: charge.concentrator_id,
      fp: charge.fp,       
      voltage: charge.voltage,
      inputs: charge.inputs,
      consumes:charge.consumes
    }
  };
};

export const postChargeFail = error => ({
  type: chargeActionTypes.POST_CHARGE_FAILURE,
  state: { error }
});


export const postUpdateCharge = charge => {
  return async dispatch => {
    dispatch(postUpdateChargeStarted());
    console.log('postUpdateChargeStarted')
    console.log( charge )
    

    axios
      .put(
        'https://api.metimur.mx/api/charge',
        {
          id:charge.id,
          name: charge.name,
          description: charge.description,
          ubication: charge.ubication,
          element_type: charge.element_type,
          charge_type: charge.charge_type,
          num_inputs: charge.num_inputs,
          concentrator_id: charge.concentrator_id,
          group_id:charge.group_id,
          fp: charge.fp, 
          is_constant : charge.is_constant  ,
          voltage: charge.voltage,
          inputs: charge.inputs,  
          charge_voltage : charge.charge_voltage ,
          is_father : charge.is_father ,
          demanda_min: charge.demanda_min ,
          demanda_max: charge.demanda_max ,
          consumo_min: charge.consumo_min ,
          consumo_max: charge.consumo_max
        },
        getconfig()
      )
      .then(response => {
        dispatch(postUpdateChargeSuccess(charge));
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Carga ${charge.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/group/${charge.group_id}`)}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al actualizar',
            html: `Se encontraron los siguientes errores al actualizar la Tarjeta:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postUpdateChargeFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const postUpdateChargeStarted = () => ({
  type: chargeActionTypes.POST_CHARGE_STARTED
});

export const postUpdateChargeSuccess = charge => {
  return {
    type: chargeActionTypes.POST_CHARGE_SUCCESS,
    payload: {
      name: charge.name,
      description: charge.description,
      ubication: charge.ubication,
      element_type: charge.element_type,
      charge_type: charge.charge_type,
      num_inputs: charge.num_inputs,
      concentrator_id: charge.concentrator_id,
      fp: charge.fp,
      voltage: charge.voltage,
      inputs: charge.inputs,
      consumes:charge.consumes
    }
  };
};

export const postUpdateChargeFail = error => ({
  type: chargeActionTypes.POST_CHARGE_FAILURE,
  state: { error }
});


export const showTmpCharge = chargeId => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeId}`,
        getconfig()
      )
      .then(response => {
        dispatch(getTmpCharges(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getTmpCharges = chargeTmp => {
  return {
    type: chargeActionTypes.GET_CHARGE_TMP_SUCCESS,
    chargeTmp,
  };
};



export const deleteCharge = (chargeId) => {
  return async dispatch => {

    dispatch(deleteChargeSuccess(chargeId));
    axios.post(
      'https://api.metimur.mx/api/charge/delete',
      { id: chargeId},
      getconfig()
    )
    ;
  };
};

export const deleteChargeSuccess = chargeId => {
  return {
    type: chargeActionTypes.DELETE_CHARGE_SUCCESS,
    payload:{id: chargeId} 
  };
};

export const postDataVoltage = charge => {
  return async dispatch => {
    dispatch(postVoltageStarted());

    axios
      .post(
        'https://api.metimur.mx/api/voltage',
        {
          name: charge.name,
          description: charge.description,
          ubication: charge.ubication,
          element_type: charge.element_type,
          charge_type: charge.charge_type,
          num_inputs: charge.num_inputs,
          concentrator_id: charge.concentrator_id,
          group_id:charge.group_id,
          meter_id: charge.meter_id,
          fix_fp: charge.fix_fp,
          fix_voltage: charge.fix_voltage,
          inputs: charge.inputs,
        },
        getconfig()
      )
      .then(response => {
        dispatch(postVoltageSuccess(charge));
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Voltaje ${charge.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/group/${charge.group_id}`)}
        );
      })
      .catch(error => {
        dispatch(postVoltageFail(error.message));
        console.log('ERROR API:' + error);

        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};

export const postVoltageStarted = () => ({
  type: chargeActionTypes.POST_VOLTAGE_STARTED
});

export const postVoltageSuccess = charge => {
  return {
    type: chargeActionTypes.POST_VOLTAGE_SUCCESS,
    payload: {
      name: charge.name,
      description: charge.description,
      ubication: charge.ubication,
      element_type: charge.element_type,
      charge_type: charge.charge_type,
      num_inputs: charge.num_inputs,
      concentrator_id: charge.concentrator_id,
      fp: charge.fp,
      voltage: charge.voltage,
      inputs: charge.inputs,
      consumes:charge.consumes
    }
  };
};

export const postVoltageFail = error => ({
  type: chargeActionTypes.POST_VOLTAGE_FAILURE,
  state: { error }
});


export const showVoltage = (corporativeId, meterId) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/voltages`,
        getconfig()
      )
      .then(response => {
        dispatch(getVoltage(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getVoltage = voltage => {
  return {
    type: chargeActionTypes.GET_VOLTAGE_SUCCESS,
    voltage
  };
};

export const cleanVoltage = () => ({
  type: chargeActionTypes.CLEAN_VOLTAGE,
  payload:{
    data:[]
  }
});

export const updateDataVoltage = (charge) => {
  return async dispatch => {
    dispatch(updateVoltageStarted());

    axios
      .put(
        'https://api.metimur.mx/api/voltage/update',
        {
          id:charge.id,
          name: charge.name,
          description: charge.description,
          ubication: charge.ubication,
          element_type: charge.element_type,
          charge_type: charge.charge_type,
          num_inputs: charge.num_inputs,
          concentrator_id: charge.concentrator_id,
          fp: charge.fp,
          voltage: charge.voltage,
          inputs: charge.inputs,
          consumes:charge.consumes
        },
        getconfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Actualiazion Correcta',
          text: `Concentrador ${charge.name} actualizado exitosamente`,
          icon: 'success',
        }).then(
          //()=>{history.push(`/hardwareConfiguration/${concentrator.meter_id}`)}
        );
      })
      .catch(error => {
        dispatch(postVoltageFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};

export const updateVoltageStarted = () => ({
  type: chargeActionTypes.PUT_VOLTAGE_SUCCESS
});


export const updateDataInvoiceCharge = (chargeId , groupId , chargeData ) => {
  return async dispatch => {
    // dispatch(postVoltageStarted());

    axios
      .post(
        'https://api.metimur.mx/api/charge/invoice',
        {
          charge_id : chargeId ,
          rfc : chargeData.rfc  ,
          razon_social : chargeData.razon_social ,
          cfdi  : chargeData.cfdi ,
          tipo_de_pago : chargeData.tipo_de_pago ,
          metodo_de_pago : chargeData.metodo_de_pago   ,

          calle : chargeData.calle , 
          codigo_postal : chargeData.cp ,
          numero_exterior : chargeData.numExt ,
          colonia : chargeData.colonia ,
          estado : chargeData.state ,
          municipio : chargeData.city ,
          pais : '52'

        },
        getconfig()
      )
    
      .then(()=>{
        Swal.fire({
          title: 'Datos de Facturación',
          text: 'Los datos de facturación se actualizaron correctamente',
          icon: 'success',
        }).then(
          ()=>{history.push(`/group/${groupId}`)}
        );
      })
      .catch(error => {

        Swal.fire({
          title: 'Datos de Facturación',
          text: 'Error al actualizar los datos',
          icon: 'error',
        }).then(
          ()=>{history.push(`/group/${groupId}`)}
        );
         
        console.log('ERROR API:' + error);
      });
  };
};


export const getDataInvoiceCharge = ( chargeID , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeID}/invoicedata`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data );
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const getDataInvoiceSearch = (chargeID) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeID}/invoiceDataSearch`,
        getconfig()
      )
      .then(response => {
        dispatch(dataInvoiceSearch(response.data.data));
        console.log(response.data.data);
      })
      .catch(function(error){
        console.log(error)
      })
  }
}

export const dataInvoiceSearch = charge => {
  return {
    type: chargeActionTypes.GET_CHARGE_INVOICE_SUCCESS,
    charge
  }
}


export const changeChargeStatus = (charge , newStatus , groupId )=> {
  return async dispatch => {
    // dispatch(postUpdateChargeStarted());
    console.log('changeChargeStatus')
    console.log( charge )
    

    axios
      .post(
        'https://api.metimur.mx/api/charge/changestatus',
        {
          charge_id:charge.id,
          charge_status: newStatus
        },
        getconfig()
      )
      .then(response => {
        Swal.fire({
          title: 'Cambio de estatus',
          text: `El estatus de la carga ${charge.name} cambió exitosamente`,
          icon: 'success',
        }) ;
        // dispatch(changeChargeStatusSuccess(charge));
      })
      .then(()=>{
        dispatch( showCharge(groupId));
      })
      .catch(error => {
        // dispatch(postUpdateChargeFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};


export const changeChargeStatusSuccess = charge => {
  return {
    type: chargeActionTypes.POST_CHARGE_SUCCESS,
    payload: {
      name: charge.name,
      description: charge.description,
      ubication: charge.ubication,
      element_type: charge.element_type,
      charge_type: charge.charge_type,
      num_inputs: charge.num_inputs,
      concentrator_id: charge.concentrator_id,
      fp: charge.fp,
      voltage: charge.voltage,
      inputs: charge.inputs,
      consumes:charge.consumes
    }
  };
};

export const getChargeOwnerData = (chargeId) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeId}/invoiceDataSearch`,
        {
          params: {
            charge_id: chargeId
          }
        },
        getconfig()
      )
      .then(response => {
        const chargeOwner = response.data.data.charge_owner
        dispatch(getChargeOwnerSuccess(chargeOwner));
      })
      .catch(function(error) {
        dispatch(getChargeOwnerFailure(error))
        console.log(error);
      });
  };
};

export const getChargeOwnerSuccess = chargeOwner => {
  return {
    type: chargeActionTypes.GET_CHARGE_OWNER_SUCCESS,
    chargeOwner,
  };
};
export const getChargeOwnerFailure = error => {
  return {
    type: chargeActionTypes.GET_CHARGE_OWNER_FAILURE,
    error,
  };
};

export const updateChargeOwnerData = (chargeId, newName) => {
  return async dispatch => {
    axios
      .post(
        `https://api.metimur.mx/api/charge/${chargeId}/invoiceDataStore`,
        {
          charge_id: chargeId,
          charge_owner: newName
        },
        getconfig()
      )
      .then(response => {
        dispatch(updateChargeOwnerSuccess(newName));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const updateChargeOwnerSuccess = (newName) => {
  return {
    type: chargeActionTypes.UPDATE_CHARGE_OWNER_SUCCESS,
    newName
  };
};