import React, { Component, useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormControl, InputLabel,Select,MenuItem } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import {  updateDataMeter} from 'js/actions/meterActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function MeterForm(props) {
  const intl = useIntl()
  const { meter } = props.location.state;

  console.log(meter)


  const { getValues, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const estados = useSelector(state => state.zepomex.states.estado);
  const cities = useSelector(state => state.zepomex.cities.municipios);
  const cps = useSelector(state => state.zepomex.cp.cp);
  const counties = useSelector(state => state.zepomex.counties.colonia);
  const { corpId } = props.match.params;

  const getState = (state) =>{
    if(state === 'NUEVO LEON'){
      return 'NUEVO LEÓN'
    }else{
      return state
    }
  }

  const [state,  setSate]  = useState(meter.state)
  const [city,  setCity]  = useState(meter.city)
  const [cp,  setCP]  = useState(meter.cp)
  const [countie,  setCountie]  = useState(meter.county)

  console.log(meter.county)

  const onSubmit = values => {
    //console.log('DATA: ', values);
    dispatch(updateDataMeter(values,meter,corpId));
  };

  const handleState = state => {
    setSate(state.target.value);
    getCity(state.target.value);
  };

  const getCity = state => {
    dispatch(showCities(state));
  };

  const handleCity = city => {
    setCity (city.target.value)
    getCP(city.target.value)
  }

  const getCP = city => {
    dispatch(showCPS(city));
  };

  
  const handleCP = cp => {
    setCP (cp.target.value)
    getCounties(cp.target.value)
  }
  

  const getCounties = cp =>  {
    dispatch(showCounties(cp))
  }

  const handleCounties = countie => {
    setCountie (countie.target.value)
  }

  useEffect(() => {
    dispatch(showCountrySates());
    dispatch(showCities(state));
    dispatch(showCPS(city))
    dispatch(showCounties(cp))
  },[]);

  return (
    <DashboardLayout title={meter.name}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.metersCards.editMeter.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.name}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.mterNameLbl'})}
                      name="name"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.description}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.descriptLbl'})}
                      name="description"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.country}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.countryLbl'})}
                      name="country"
                      // onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        //inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.stateLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="state"
                        onChange={handleState}
                        value={state}
                        //labelWidth={labelWidth}
                      >
                      
                        { estados ?  estados.map(el => (
                          <MenuItem value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}>{el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</MenuItem>
                        ))  : console.log('cargando estados') 
                        
                        }
                            
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.cityLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="city"
                        onChange={handleCity}
                        value={city}
                        //labelWidth={labelWidth}
                      >
                       
                        { cities ?  cities.map(el => (
                          <MenuItem value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}>{el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>                    
                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
  
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.postalCodeLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="cp"
                        onChange={handleCP}
                        value={cp}
                        //labelWidth={labelWidth}
                      >
                      
                        { cps ?  cps.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.editMeter.neighborhoodLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="county"
                        onChange={handleCounties}
                        value={countie}
                        //labelWidth={labelWidth}
                      >
                       
                        { counties ?  counties.map(el => (
                          <MenuItem value={el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}>{el.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.address}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.addressLbl'})}
                      name="address"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    />
                      
                      
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.payment_deadline}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.payDeadLineLbl'})}
                      name="payment_deadline"
                      //onChange={this.handleChange}
                      required
                      variant="outlined"
                    /> 
                  
                    <TextField
                      className={classes.textField}
                      defaultValue={meter.contracted_demand}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.contractedDemandLbl'})}                      
                      //onChange={this.handleChange}
                      name="contracted_demand"
                      variant="outlined"
                    /> 
                  


                      
                  </div>
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.editMeter.submit'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
