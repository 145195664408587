export default theme => ({
  root: {
    maxWidth: '100%',
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    width: '320px',
    height: '320px',
    borderRadius: '50%',
    backgroundColor: '#00AFEF',
    border: '5px solid #0080BC'
  },
  imageWrapper: {
    height: '80px',
    width: '80px',
    margin: '0 auto',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '4em',
    color: '#fff'
  },
  image: {
    width: '100%'
  },
  details: {},
  title: {
    fontSize: '2.5em',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#fff',
    marginTop: theme.spacing.unit * 2
  },
  description: {
    lineHeight: '16px',
    height: theme.spacing.unit * 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    // color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit
  },
  descritionButton: {
    margin: '35px',

    width: '240px',

    height: '240px',

    padding: '15px'
  }
});
