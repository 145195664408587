import React, { useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { postDataOnOffCard} from 'js/actions/onOffAcrtions';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  rateType: null
};

export default function GroupForm(props) {
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {concentratorId, cardId} = props.match.params;
  const user = useSelector(state=>state.user.user)

  const meterAdmin = useSelector(state=>state.user.meter)
  const meterUser = user.meters
  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser[0].id
 


  const direction = [ '01','02','03','04','05','06','07' ];

  const [state, setState] = React.useState({
    customTariffValidator: false
  });

  const [direction_id, setDirection] = React.useState(null);

  const values = getValues();

  const onSubmit = values => {
    //console.log('DATA: ', values);
    dispatch(postDataOnOffCard(values,concentratorId,cardId,meterId));
  };

  const handleDirection = direction => {
    setDirection(direction.target.value);

  };

 

  return (
    <DashboardLayout title="Creacion de Tarjeta de Control">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Tarjeta de Control" />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Nombre de Tarjeta de Control"
                      name="name"
                      required
                      //value={name}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Descripcion"
                      name="description"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Ubicación"
                      name="ubication"
                      required
                      //value={description}
                      variant="filled"
                    />                 
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          //inputRef={useOnMount}
                        >
                          Direccion de Tarjeta
                        </InputLabel>
                        <Select
                          id="demo-simple-select-outlined"
                          inputRef={register}
                          labelId="demo-simple-select-outlined-label"
                          onChange={handleDirection}
                          name='direction_id'
                          value={direction_id}
                          //labelWidth={labelWidth}
                        >
                        
                          {direction
                            ? direction.map(el => (
                              <MenuItem value={el}>{el}</MenuItem>
                            ))
                            : console.log('cargando direction')}
                        </Select>
                      </FormControl>
                   
                  </div>
                </Grid>
              </Grid>

              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
