/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormGroup, FormLabel, makeStyles } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { getChargeList, postDataGroup } from 'js/actions/groupActions';
import { useIntl } from 'react-intl';
import theme from 'theme';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  rateType: null
};

export default function GroupForm(props) {
  const intl = useIntl()
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {meterId, corpId} = props.match.params;
  const {charge_types} = useSelector(state => state.groups);
  const [unificar, setUnificar] = useState({
    yes: false,
    no: true
  })
  const [iva, setIva] = useState(16)

  const tarifas = [
    //'DB1',
    //'DB2',
    'PDBT',
    'GDBT',
    //'RABT',
    //'RAMT',
    //'APBT',
    //'APMT',
    'GDMTO',
    'GDMTH',
    //'DIST',
    //'DIT'
  ];

  const [state, setState] = React.useState({
    customTariffValidator: false
  });

  useEffect(()=>{
    dispatch(getChargeList());
  },[dispatch]);

  const values = getValues();
  const { customTariffValidator } = state;

  const onSubmit = values => {
    dispatch(postDataGroup(values,meterId,corpId, iva));
  };

  const handleRateType = rateType => {
    setValue('rateType', rateType.target.value);
  };

  const handleChargeType = event => {
    setValue('type_energy', event.target.value); 
  };

  const handleUnificar = event => {
    const {name, checked} = event.target;
    if(name === 'yes' && checked){
      setUnificar({
        yes: true,
        no: false
      })
      setValue('bill', true);
    }else if(name === 'no' && checked){
      setUnificar({
        yes: false,
        no: true
      });
      setValue('bill', false);
    }
  }

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useOnMount(() => {
    register({ name: 'rateType', type: 'text' });
    register({ name: 'bill', type: 'checkbox'});
    register({ name: 'type_energy', type: 'text' });
  });

  return (
    <DashboardLayout title="Creacion de Grupos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.groupsCards.addGroup.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.groupNameLbl'})}
                      name="name"
                      required
                      //value={name}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.description'})}
                      name="description"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.monitChargeLbl'})}
                      name="monitoring_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.fixedChargeLbl'})}
                      name="flat_fee"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <FormControlLabel
                      className={classes.textField}
                      control={
                        <Checkbox
                          checked={customTariffValidator}
                          inputRef={register}
                          name="is_custom_tariff"
                          onChange={handleChange('customTariffValidator')}
                        />
                      }
                      label={intl.formatMessage({id: 'app.groupsCards.addGroup.customRateLbl'})}
                      
                    />

                    {customTariffValidator == false ? (
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          inputRef={useOnMount}
                        >
                          {intl.formatMessage({id: 'app.groupsCards.addGroup.rateTypeLbl'})}
                        </InputLabel>
                        <Select
                          id="demo-simple-select-outlined"
                          labelId="demo-simple-select-outlined-label"
                          onChange={handleRateType}
                          value={values.rateType}
                          //labelWidth={labelWidth}
                        >
                          
                          {tarifas
                            ? tarifas.map(el => (
                              <MenuItem value={el}>{el}</MenuItem>
                            ))
                            : <MenuItem />}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        label={intl.formatMessage({id: 'app.groupsCards.addGroup.customRateLbl'})}
                        name="custom_tariff"
                        //value={description}
                        variant="filled"
                      />
                    )}
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2}}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        inputRef={useOnMount}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.typeEnergy'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-label-outlined"
                        onChange={handleChargeType}
                        value={values.type_energy}
                      >
                        {charge_types ? Object.values(charge_types).map(el => (
                          <MenuItem
                            value={el}
                          >{el}</MenuItem>
                        )): (<MenuItem />)}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >{intl.formatMessage({id: 'app.chargesCards.unifyLoad'})}</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.yes}
                              color="secondary"
                              name="yes"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.yes'})}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={unificar.no}
                              color="secondary"
                              name="no"
                              onChange={handleUnificar}
                            />
                          }
                          label={intl.formatMessage({id: 'app.chargesCards.unifyLoad.no'})}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      style={{marginTop: theme.spacing.unit * 2}}
                    >
                      <FormLabel
                        component="legend"
                        style={{fontWeight: 500}}
                      >
                        {intl.formatMessage({id: 'app.groupsCards.addGroup.ivaTitle'})}
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 8}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(8)}}
                            />
                          }
                          label="8%"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={iva === 16}
                              color="secondary"
                              name="ivaCustomizable"
                              onChange={() => {setIva(16)}}
                            />
                          }
                          label="16%"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>

              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                //onClick={this.handleSubmit}
                style={{marginTop:'2.5em'}}
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groupsCards.addGroup.registerBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}