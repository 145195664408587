import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { deleteLastPostForm } from 'js/actions/formsAction';
import { postDataConcentrator } from 'js/actions/concentratorActions';
//import { cleanForm } from 'js/actions/formsAction'

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const intl = useIntl();
  const classes = useStyles();
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;
  const {meterId} = props.ids

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }
  

  const concentrator = {
    concentrator_name: data[0].data.concentrator_name,
    description:data[0].data.concentrator_description,
    user_ftp: 'concentradores@optimo-automatizacion.com',
    type: data[1].data.concentratorType,
    connection_period: '55',
    baudrate: '9600',
    //group_id: groupId,
    meter_id: meterId
  };
  
  function completeConcentrator ( concentrator){
    dispatch(postDataConcentrator(concentrator));
  };

  return (
    <Grid container className={classes.root}>
      <div className={classes.form}>
        
        <Typography variant="h4">{intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.title'})}</Typography>

        <Typography variant="body1"> {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.name'})}: {concentrator.concentrator_name} </Typography>

        <Typography variant="body1"> {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.description'})}: {concentrator.description} </Typography>

        <Typography variant="body1"> {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.type'})} : {concentrator.type}</Typography>
        


        <Button
          color="secondary"
          size="large"
          onClick={()=>completeConcentrator(concentrator)}
          variant="contained"
          className={classes.button}
        >
          {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.confirmBtn'})}
        </Button>
        <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.confirm.returnBn'})}
        </Button>
      </div>
    </Grid>
  );
}

export default NameRoleForm;
