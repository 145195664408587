import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, Button, Typography } from '@material-ui/core';

// Shared components
import { DisplayMode, SearchInput } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(styles);



export default function ProductsToolbar(props) {

  const {corpData, className} = props
  const classes = useStyles()
  const rootClassName = classNames(classes.root );


  return (
    <div className={rootClassName}>
        <Grid
          container
          direction="row"
        >
          <Grid item sm={4} md={4}
          >
            
            <span className={classes.spacer} />
              <Typography variant="h2" className={classes.hola}>{corpData.name}</Typography>
           
          
              <span className={classes.spacer} />
              <Typography variant="body1"className={classes.hola}>{corpData.market_segment}</Typography>
            
              <span className={classes.spacer} />
              <Typography variant="body1"className={classes.hola}>{corpData.city}</Typography>
           
              <span className={classes.spacer} />
              <Typography variant="body1"className={classes.hola}>{corpData.state}</Typography>

           
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
          >
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
          >
            <img src={corpData.logo} alt="corp-logo" className={classes.imgLogo}/>
            
          </Grid>
        </Grid>
      </div>
  )
}




















// class ProductsToolbar extends Component {
//   render() {
//     const { classes, className, corpData } = this.props;

//     const rootClassName = classNames(classes.root, className);

//     return (
//       <div className={rootClassName}>
//         <Grid
//           container
//           direction="row"
//           sm={12}
//         >
//           <Grid
//             item
//             sm={5}
//           >
           
//               <span className={classes.spacer} />
//               <Typography variant="h2">{corpData.name}</Typography>

          
//               <span className={classes.spacer} />
//               <Typography variant="body1">{corpData.market_segment}</Typography>
          
    
//               <span className={classes.spacer} />
//               <Typography variant="body1">{corpData.city}</Typography>
       
//               <span className={classes.spacer} />
//               <Typography variant="body1">{corpData.state}</Typography>
          
           
//           </Grid>
//           <Grid
//             item
//             sm={2}
//           >
//             <img src="https://dummyimage.com/200x100/0080bc/ffffff" alt="corp-logo"/>
//           </Grid>
//           <Grid
//             item
//             sm={5}
//           >
            
//               <span className={classes.spacer} />
//               <Typography variant="h2" className={classes.hola}>{corpData.name}</Typography>
           
          
//               <span className={classes.spacer} />
//               <Typography variant="body1"className={classes.hola}>{corpData.market_segment}</Typography>
            
//               <span className={classes.spacer} />
//               <Typography variant="body1"className={classes.hola}>{corpData.city}</Typography>
           
//               <span className={classes.spacer} />
//               <Typography variant="body1"className={classes.hola}>{corpData.state}</Typography>

//           </Grid>
//         </Grid>
//       </div>
//     );
//   }
// }

ProductsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

// export default withStyles(styles)(ProductsToolbar);
