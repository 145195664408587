import * as actionTypes from '../constants/action-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

export default function corporativeReducer(
  state = { corporatives: [], loading: true },
  action
) {
  switch (action.type) {
    case actionTypes.GET_CORPORATIVE_STARTED:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_CORPORATIVE_SUCCESS:
      return { ...state, 
        corporatives: action.corporatives, 
        loading: false 
      };
    case actionTypes.GET_CORPORATIVE_FAILURE:
      return {
        ...state,
        loading: false
      }
    case actionTypes.POST_CORPORATIVE_STARTED:
      return {
        ...state,
        loading: true
      };
    case actionTypes.POST_CORPORATIVE_SUCCESS:
      return {
        ...state,
        corporatives: state.corporatives.push(action.payload),
        loading: false
      };
    
    case actionTypes.POST_CORPORATIVE_FAILURE:
      return {
        ...state,
        loading: false
      };
    
    case actionTypes.PUT_CORPORATIVE_STARTED:
      return {
        ...state,
        loading: true
      };

    case actionTypes.DELETE_CORPORATIVE_SUCCESS:
      return {
        ...state,
        corporatives: state.corporatives.filter(({id}) => id !== action.payload.id)
      }
    case actionTypes.BLOCK_CORPORATIVE_SUCCESS:
      return {
        ...state,
        corporatives: state.corporatives.map(corporative => 
          corporative.id === action.payload.corporative_id
            ? {...corporative, active: action.payload.active}
            : corporative
        )
      }
    default:
      return state;
  }
}

// export default rootReducer;
