/* eslint-disable linebreak-style */
export default theme => ({
  root: {
    padding: 0,
  },
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartWrapper: {
    height: '400px',
    position: 'relative'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
