import * as meterActionTypes from '../constants/meterActions-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

const initialState = {
  meters: [], 
  folders: [],
  folder: null,
  file: null, 
  folderInfo: null,
  loading: true, 
  error: null,
  weather: null
}

function meterReducer(state = initialState, action) {
  switch (action.type) {
    case meterActionTypes.POST_METER_STARTED:
      return {
        ...state,
        loading: true
      };
    case meterActionTypes.POST_METER_SUCCESS:
      return {
        ...state,
        meters: state.meters.push(action.payload),
        loading: false
      };
    case meterActionTypes.POST_METER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case meterActionTypes.GET_METER_STARTED:
      return {
        ...state,
        loading: true
      };
    case meterActionTypes.GET_METER_SUCCESS:
      return { ...state, meters: action.meters, loading: false };

    case meterActionTypes.GET_METER_FAILURE:
      return { ...state, meters: [], loading: false };      

    case meterActionTypes.DELETE_METER_SUCCESS:
      return {
        ...state,
        meters: state.meters.filter(({ id }) => id !== action.payload.id)
      };

    case meterActionTypes.PUT_METER_STARTED:
      return {
        ...state,
        loading: true
      };
    case meterActionTypes.GET_FOLDER_SUCCESS:
      return {
        ...state,
        folder: action.folder,
        loading: false
      }
    case meterActionTypes.GET_FOLDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case meterActionTypes.GET_INTO_FOLDER_SUCCESS:
      return {
        ...state,
        folderInfo: action.folderInfo,
        loading: false
      }
    case meterActionTypes.GET_INTO_FOLDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case meterActionTypes.POST_FOLDER_SUCCESS:
      return {
        ...state,
        folders: state.folders.push(action.payload),
        loading: false
      };
    case meterActionTypes.POST_FOLDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case meterActionTypes.POST_FILE_SUCCESS:
      return {
        ...state,
        file: state.file.push(action.payload),
        loading: false
      };
    case meterActionTypes.POST_FILE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case meterActionTypes.GET_WEATHER_RANGE_SUCCESS:
      return {
        ...state,
        weather: action.weatherRange,
        loading: false
      }
    case meterActionTypes.GET_WEATHER_RANGE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case meterActionTypes.POST_FOLDER_INTO_SUCCESS:
      return {
        ...state,
        folders: state.folders.push(action.payload),
        loading: false
      };
    case meterActionTypes.POST_FOLDER_INTO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    default:
      return state;
  }
}

export default meterReducer;
