import React, { useEffect } from 'react';
import MaterialTable from 'material-table';

import {MonoPhasicForm,BiPhasicForm,TriPhasicForm}  from '../../components'
import { Button, Select,MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { postForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';
import { showCards } from 'js/actions/cardAction';

export default function MaterialTableDemo(props) {
  const dispatch = useDispatch();
  const comunicationStepForm = useSelector(state => state.forms.data[1]);
  const { chargeType,concentratorPhase } = comunicationStepForm.data;
  const {nextStep ,prevStep, voltage} = props

  const onSubmit = values => {
    dispatch(postForm(values));
  };

  const getPhaseData = (chargeType, concentratorPhase, voltage) => {
    switch (chargeType) {
      case 'MONOFASICA':
        return <MonoPhasicForm concentrator={concentratorPhase} voltage={voltage} nextStep={nextStep} prevStep={prevStep}></MonoPhasicForm>

      case 'BIFASICA':
        return <BiPhasicForm concentrator={concentratorPhase} voltage={voltage} nextStep={nextStep} prevStep={prevStep}></BiPhasicForm> 

      case 'TRIFASICA':
        return <TriPhasicForm concentrator={concentratorPhase} voltage={voltage}  nextStep={nextStep} prevStep={prevStep}></TriPhasicForm>
    }
}

  return (
    <div>

        {getPhaseData(chargeType , concentratorPhase, voltage)}
    </div>
  );
}
