/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  List,
  ListItemText,
  ExpansionPanel,
  Grid
} from '@material-ui/core';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import CorporativeCard from '../CorporativeCard';
import { getUserAlertsCns } from 'js/actions/userAlertsAction';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import { attendAlert } from 'js/actions/userAlertsAction';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  dangerHeading: {
    backgroundColor: 'red'
  },
  dangerText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '900',
    color: '#fff'
  },

  alertHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#EAAB36',
    color: '#fff'
  },

  alertText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '900'
  },

  nameHeading: {
    width: '100%'
  }
}));

export default function SimpleExpansionPanel(props) {
  const intl = useIntl();
  const classes = useStyles();
  const data = useSelector(state => state.systemAlert.systemAlert);

  const alert_prior = [
    intl.formatMessage({ id: 'app.alerts.priority.low' }),
    intl.formatMessage({ id: 'app.alerts.priority.medium' }),
    intl.formatMessage({ id: 'app.alerts.priority.high' })
  ];

  const columns = [
    {
      title: intl.formatMessage({ id: 'app.alerts.grid.dateCol' }),
      field: 'created_at',
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'left',
        width: '15%'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.alerts.grid.descCol' }),
      field: 'description',
      cellStyle: {
        textAlign: 'left',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.alerts.grid.levelCol' }),
      field: 'lvl',
      cellStyle: {
        textAlign: 'left',
        fontWeight: '500',
        width: '15%',
        fontSize: '.95em'
      },
      render: rowData => {
        return alert_prior[rowData.lvl - 1] + ' ';
      }
    },
    {
      title: intl.formatMessage({ id: 'app.alerts.grid.locCol' }),
      field: 'ubication',
      cellStyle: {
        textAlign: 'left',
        fontWeight: '500',
        width: '40%',
        fontSize: '.95em'
      }
    }
  ];

  const actions = [
    {
      icon: ClearIcon,
      tooltip: intl.formatMessage({
        id: 'app.alerts.grid.action.clear.toolTip'
      }),

      onClick: (event, rowData) => {
        console.log(rowData.description);
        markAttended(rowData);
      }
    }
  ];

  const markAttended = alert => {
    console.log('se atendera alerta ' + alert.description);
    //dispatch(attendAlert(alert.id , chargeId ));
  };

  const showAlertTable = () => {
    return (
      <div
        className={classes.root}
        container
      >
        <MaterialTable
          columns={columns}
          // data={data.messages}
          options={{
            search: true,
            sorting: true,
            toolbar: true,
            paging: true,
            padding: 10,
            headerStyle: { padding: 5 }
          }}
          title={intl.formatMessage({ id: 'app.alerts.grid.title' })}
          // title={intl.formatMessage({ id: 'app.alerts.grid.title' })}
        />
      </div>
    );
  };

  if (!data.messages) {
    return null;
  } else {
    return (
      <div className={classes.root}>
        <div>{showAlertTable()}</div>
      </div>
    );
  }
}
