import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles, Typography, Button, Grid } from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import ReactSpeedometer from 'react-d3-speedometer';
const useStyles = makeStyles(styles);

export default function VoltageIndicator(props) {
  const intl = useIntl() 
  const classes = useStyles();
  const { charge } = props;
   
  let minVoltage=0;
  let maxVoltage=500;

  
  if( charge.rated_voltage>0 ){
    minVoltage= Math.floor( charge.rated_voltage*0.85);
    maxVoltage= Math.floor( charge.rated_voltage*1.15);
  }


  let voltPart0= Math.floor (charge.rated_voltage*0.85);
  let voltPart1= Math.floor (charge.rated_voltage*0.9  ) ;
  let voltPart2= Math.floor (charge.rated_voltage*0.95  ); 
  let voltPart3= Math.floor (charge.rated_voltage*1.05  ); 
  let voltPart4= Math.floor (charge.rated_voltage*1.1  );
  let voltPart5= Math.floor (charge.rated_voltage*1.15  );

  let voltValue= charge.voltage;
  if( charge.voltage<minVoltage){
    voltValue=minVoltage;
  }else if(charge.voltage>maxVoltage ){
    voltValue=maxVoltage;
  }
 

  const displayVoltage = () => {

    
      return (
        <ReactSpeedometer
        value={voltValue}
        forceRender={true}
        minValue={minVoltage}
        maxValue={maxVoltage}
        fontFamily={ 'Arial' }
        labelFontSize={'12px'}
        customSegmentStops={[voltPart0, voltPart1, voltPart2 , voltPart3 , voltPart4 , voltPart5 ]}
        segmentColors={["#EB0000", "#FAC800", "#00C454","#FAC800" , "#EB0000" ]}
        height={130}
        width={150}
        needleHeightRatio={0.7}
        ringWidth={30}
        needleColor={'#000'}
        valueTextFontSize={'20px'}
        valueTextFontWeight={'600'}
        paddingHorizontal={17}
        paddingVertical={17}
        currentValueText={ "" +  charge.voltage }
        textColor={'#000'}

    />
       );  
   


  }  


  return (
 

          <Grid  style={{ textAlign:'center' }}   item xs={12}>           
            <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
                { displayVoltage() }
            </div>
            <Typography
              className={classes.description}
              variant="h5"
              style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}>               
              {intl.formatMessage({id: 'app.metersCards.card.voltageLabel'})}
            </Typography>
          </Grid>
  



 
  );

           
}
