import React, { Component } from 'react';

import {NavLink} from 'react-router-dom'
// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography,Button} from '@material-ui/core';

import { Container,Button as ButtonFAB } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { RoleTable } from './components';

import { isAllowed } from 'helpers/auth';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

// Component styles
import styles from './style';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)


export default function UserList(props) {
  const intl = useIntl()
  const classes = useStyles();
  const user = useSelector(state=>state.user.user)

  return (
    <DashboardLayout title="Roles">
    <div className={classes.root}>
      <div className={classes.content}>
        <RoleTable/>
      </div>
    </div>
    {isAllowed(user.permissions,['role_create'])&&<Container styles={{ marginRight: '-20px', marginBottom: '-20px',zIndex:900 }} >
      <LinkFloat
        href={'/newRole'}
        icon="fa fa-plus"
        styles={{
          backgroundColor: '#00AFEF',
          color: '#fff',
          width: '60px',
          height: '60px',
          fontSize: '1.5em'
        }}
        tooltip={intl.formatMessage({id: 'app.roles.addRoleBtn'})}
      />
      <ButtonFAB
        icon="fa fa-cog"
        rotate
        styles={{
          backgroundColor: '#C69100',
          color: '#fff',
          width: '80px',
          height: '80px',
          fontSize: '1.5em'
        }}
      />
    </Container>}
    
  </DashboardLayout>
  )
}


