import React, { useState} from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';
import { cleanForm } from 'js/actions/formsAction';
import  {useEffect} from 'react';

import { Button, TextField, Grid, MenuItem,FormControl,Select,InputLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
 // return React.useEffect(handler, []);
}

const defaultSelectValues = {    
};


function NameConcentratorForm(props) {

  const alert = props.alert||null 
  console.log("alerta de props ")
  console.log(alert);


  const [level,setLevel] = useState( alert ? alert.lvl : null  );

  const form = useSelector(state => state.form);
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
  };

  
  useEffect(() => {
    dispatch(cleanForm())
  }, [])

  const handlLvlAlertChange = lvlAlert => {
    //setValue('lvlAlert', lvlAlert.target.value);
    setLevel(  lvlAlert.target.value);
  };

  useOnMount(() => {
  //  register({ name: 'lvlAlert', type: 'text' });

  });

  const values = getValues();



  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextField
          className={classes.textField}
          inputRef={register}
          label="Nombre de Alerta"
          name="name_alert"
          required
          defaultValue = { alert ? alert.name : null  }
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label="Mensaje "
          name="message_alert"  
          defaultValue = { alert ? alert.message : null  }
          required
          variant="outlined"
        />

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            Prioridad de la alerta
          </InputLabel>
          <Select
            inputRef={register}
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handlLvlAlertChange}
            value={level}
            name="lvlAlert"
            //labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value={'1'}>1. BAJO</MenuItem>
            <MenuItem value={'2'}>2. MEDIO</MenuItem>
            <MenuItem value={'3'}>3. ALTO</MenuItem>
          </Select>
        </FormControl>  

        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          className={classes.button}
        >
          Guardar y Continuar
        </Button>
      </form>
    </Grid>
  );
}

export default NameConcentratorForm;
