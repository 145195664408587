/* eslint-disable no-console */
import * as dashboardGroupActionTypes from 'js/constants/groupDashboard-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { error } from 'highcharts';

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getMonthWithMostConsume = (groupid , year) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupid}/consumes/monthmostconsume/${year}`,
        getconfig()
      )
      .then(response => {
        dispatch(monthWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log( error);
      });
  };
};

export const monthWithMostConsume = chartMonthMostConsume => {
  return {
    type: dashboardGroupActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS,
    chartMonthMostConsume
  };
};



export const getDayWithMostConsume = (groupid , year, month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupid}/consumes/daymostconsume/${year}/month/${month}`,
        getconfig()
      )
      .then(response => {
        dispatch(dayWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const dayWithMostConsume = chartDayMostConsume => {
  return {
    type: dashboardGroupActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS,
    chartDayMostConsume
  };
};

export const getTopChargeInMonth = (groupid ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupid}/topcharges`,
        getconfig()
      )
      .then(response => {
        dispatch(topChargeInMonth(response.data.data));
      })
      .catch(function(error){
        console.log('error: ' + error);
      });
  };
};

export const topChargeInMonth = chartTopChargeInMonth => {
  return {
    type: dashboardGroupActionTypes.GET_TOP_CHARGE,
    chartTopChargeInMonth
  }
}

export const postLoadCharge = (startDay, endDay, chargeId) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/charge/loadfactor',
        {
          start_date: startDay,
          charge_id: chargeId,
          end_date: endDay,
        },
        getconfig(),
      )
      .then(response => {
        dispatch(postLoadChargeSuccess(response.data.data));
      })
      .catch(error => {
        console.log('ERROR API: ' + error);
      })
  }
}


export const postLoadChargeSuccess = value => {
  return {
    type: dashboardGroupActionTypes.POST_LOAD_CHARGE,
    value
  }
}

export const postLastLoadCharge = (startDay, endDay, chargeId) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/charge/loadfactor',
        {
          start_date: startDay,
          charge_id: chargeId,
          end_date: endDay,
        },
        getconfig(),
      )
      .then(response => {
        dispatch(postLastLoadChargeSuccess(response.data.data));
      })
      .catch(error => {
        console.log('ERROR API: ' + error);
      })
  }
}


export const postLastLoadChargeSuccess = value => {
  return {
    type: dashboardGroupActionTypes.POST_LAST_LOAD_CHARGE,
    value
  }
}

export const getLoadFactorHistory = chargeId => {
  return async dispatch =>{
    axios
      .get(
        `https://api.metimur.mx/api/charge/loadfactorhistory/${chargeId}`,
        getconfig()
      )
      .then(response => {
        dispatch(getLoadHistorySuccess(response.data.data));       
      })
      .catch(error => {
        console.log('ERROR API: ' + error);
      })
  }
}


export const getLoadHistorySuccess = value => {
  return {
    type: dashboardGroupActionTypes.GET_LOAD_HISTORY,
    value
  }
}


export const getComparativeYearConsume = (groupid , year) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupid}/consumes/comparativeyearconsume/${year}`,
        getconfig()
      )
      .then(response => {
        dispatch(comparativeYearConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const comparativeYearConsume = chartComparativeYearConsume => {
  return {
    type: dashboardGroupActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS,
    chartComparativeYearConsume
  };
};

export const getUnifiquedReceipt = (groupid , year, month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupid}/receiptunified`,
        {
          params: {
            id : groupid ,
            year: year ,
            month : month  
          }
        },
        getconfig()
      )
      .then(response => {
        dispatch(unifiquedReceipt(response.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const unifiquedReceipt = unifiquedReceipt => {
  return {
    type: dashboardGroupActionTypes.GET_UNIFIQUED_RECEIPT_SUCCESS,
    unifiquedReceipt
  };
}