import React, { Component } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

import { Paper } from 'components';
import MaterialTable from 'material-table'



// Component styles
import styles from './styles';

class GroupCard extends Component {
  render() {
    const { classes, className, group } = this.props;
    const rootClassName = classNames(classes.root, className);

    const data = [];

    let obj;
    
    const columns = [
        {title:'Medicion', field:'med', cellStyle:{textAlign:'center',fontSize: '12pt',}},
        {title:'Base', field:'bas',

        cellStyle: {
          backgroundColor: '#8CBA42',
          color: '#FFF',
          fontWeight:'900',
          width:'25%',
          fontSize: '12pt',
          textAlign: 'center'
        },
      },
        {title:'Intermedia', field :'int',
        cellStyle: {
          backgroundColor: '#EAAB36',
          color: '#FFF',
          fontWeight:'900',
          width:'25%',
          fontSize: '12pt',
          textAlign: 'center'
          
        },},
        {title:'Punta',field:'punt',
        cellStyle:{
          backgroundColor:'#D64539',
          color:'#FFF',
          fontWeight:'900',
          width:'25%',
          fontSize: '12pt',
          textAlign: 'center'
        }
      
      },
    ];

    group.consumes.forEach(element => {
      obj={
        med: element[0],
        bas: element[1],
        int: element[2],
        punt: element[3]
      }

      data.push(obj)
      
    });

    return (
     
          <MaterialTable
          columns={columns}
          data={data}
          options={{
            filtering: false,
            search: false,
            sorting:false,
            toolbar: false,
            paging:false,
            padding:0,
            headerStyle:{textAlign: 'center', padding:0}

          }}
          />

    );
  }
}

GroupCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  corporative: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupCard);
