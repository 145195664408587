import React, { Component , useEffect } from 'react';
import palette from 'theme/palette';
import { useSelector , useDispatch } from 'react-redux';
 

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';


// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button,Typography } from '@material-ui/core';

import {  getConsumtionAndWeight } from 'js/actions/chargeDashboardActions';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Chart configuration
import { options } from './chart.js';

// Component styles
import styles from './styles.jsx';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function ConsumptionAndWeightChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chargeId } = props;
  const { className,consumes, ...rest } = props;

  const [dataBilling, setDataBilling] = React.useState([] );
  const [dataConsume, setDataConsume] = React.useState([] );
 



  const [month, setMonth] = React.useState( 0 );
  const [lastMonth , setLastMonth ] = React.useState( 0);

  const [monthYear, setMonthYear] = React.useState( 0 );
  const [lastMonthYear , setLastMonthYear ] = React.useState( 0);

  const [lastYearMonth, setLastYearMonth] = React.useState(0 );
  const [lastYear, setLastYear] = React.useState(0 );

 
 

  useEffect(() => {
    dispatch(getConsumtionAndWeight(chargeId, funcRespConsAndWeight ));
  }, []);


  const funcRespConsAndWeight = (  data ) => {
    console.log("ConsumptionAndWeightData");
    console.log( data );
    if(!data){
      return;
    }
    
    setDataBilling([data[0].month.billing  , data[0].last_month.billing , data[0].last_year.billing ])
    setDataConsume([data[0].month.consume , data[0].last_month.consume , data[0].last_year.consume  ])
 
    setMonth(   data[0].month.month     );
    setLastMonth(  data[0].last_month.month    );

    setMonthYear(   data[0].month.year     );
    setLastMonthYear(  data[0].last_month.year    );

    setLastYear(  data[0].last_year.year   );
    setLastYearMonth( data[0].last_year.month );
    
};
 
 

  const getMonthLabel = (    ) => {
      
       if(month==1){
         return  intl.formatMessage({id: 'app.months.jan' }) + " - " + monthYear
       }else if( month==2 ){
        return  intl.formatMessage({id: 'app.months.feb' }) + " - " + monthYear
       }else if( month==3 ){
        return  intl.formatMessage({id: 'app.months.mar' }) + " - " + monthYear
       }else if( month==4 ){
        return  intl.formatMessage({id: 'app.months.apr' }) + " - " + monthYear
       }else if( month==5 ){
        return  intl.formatMessage({id: 'app.months.may' }) + " - " + monthYear
       }else if( month==6 ){
        return  intl.formatMessage({id: 'app.months.jun' }) + " - " + monthYear
       }else if( month==7 ){
        return  intl.formatMessage({id: 'app.months.jul' }) + " - " + monthYear
       }else if( month==8 ){
        return  intl.formatMessage({id: 'app.months.aug' }) + " - " + monthYear
       }else if( month==9 ){
        return  intl.formatMessage({id: 'app.months.sep' }) + " - " + monthYear
       }else if( month==10 ){
        return  intl.formatMessage({id: 'app.months.oct' }) + " - " + monthYear
       }else if( month==11 ){
        return  intl.formatMessage({id: 'app.months.nov' }) + " - " + monthYear
       }else if( month==12 ){
        return  intl.formatMessage({id: 'app.months.dec' }) + " - " + monthYear
       }else {
        return ""
       }
        
 
  }


  const getLastMonthLabel = (    ) => {
 
    if(lastMonth==1){
      return  intl.formatMessage({id: 'app.months.jan' }) + " - " + lastMonthYear
    }else if( lastMonth==2 ){
     return  intl.formatMessage({id: 'app.months.feb' }) + " - " + lastMonthYear
    }else if( lastMonth==3 ){
     return  intl.formatMessage({id: 'app.months.mar' }) + " - " + lastMonthYear
    }else if( lastMonth==4 ){
     return  intl.formatMessage({id: 'app.months.apr' }) + " - " + lastMonthYear
    }else if( lastMonth==5 ){
     return  intl.formatMessage({id: 'app.months.may' }) + " - " + lastMonthYear
    }else if( lastMonth==6 ){
     return  intl.formatMessage({id: 'app.months.jun' }) + " - " + lastMonthYear
    }else if( lastMonth==7 ){
     return  intl.formatMessage({id: 'app.months.jul' }) + " - " + lastMonthYear
    }else if( lastMonth==8 ){
     return  intl.formatMessage({id: 'app.months.aug' }) + " - " + lastMonthYear
    }else if( lastMonth==9 ){
     return  intl.formatMessage({id: 'app.months.sep' }) + " - " + lastMonthYear
    }else if( lastMonth==10 ){
     return  intl.formatMessage({id: 'app.months.oct' }) + " - " + lastMonthYear
    }else if( lastMonth==11 ){
     return  intl.formatMessage({id: 'app.months.nov' }) + " - " + lastMonthYear
    }else if( lastMonth==12 ){
     return  intl.formatMessage({id: 'app.months.dec' }) + " - " + lastMonthYear
    }else {
     return ""
    }
     

}




const getLastYearMonthLabel = (    ) => {
 
  if(lastYearMonth==1){
    return  intl.formatMessage({id: 'app.months.jan' }) + " - " + lastYear
  }else if( lastYearMonth==2 ){
   return  intl.formatMessage({id: 'app.months.feb' }) + " - " + lastYear
  }else if( lastYearMonth==3 ){
   return  intl.formatMessage({id: 'app.months.mar' }) + " - " + lastYear
  }else if( lastYearMonth==4 ){
   return  intl.formatMessage({id: 'app.months.apr' }) + " - " + lastYear
  }else if( lastYearMonth==5 ){
   return  intl.formatMessage({id: 'app.months.may' }) + " - " + lastYear
  }else if( lastYearMonth==6 ){
   return  intl.formatMessage({id: 'app.months.jun' }) + " - " + lastYear
  }else if( lastYearMonth==7 ){
   return  intl.formatMessage({id: 'app.months.jul' }) + " - " + lastYear
  }else if( lastYearMonth==8 ){
   return  intl.formatMessage({id: 'app.months.aug' }) + " - " + lastYear
  }else if( lastYearMonth==9 ){
   return  intl.formatMessage({id: 'app.months.sep' }) + " - " + lastYear
  }else if( lastYearMonth==10 ){
   return  intl.formatMessage({id: 'app.months.oct' }) + " - " + lastYear
  }else if( lastYearMonth==11 ){
   return  intl.formatMessage({id: 'app.months.nov' }) + " - " + lastYear
  }else if( lastYearMonth==12 ){
   return  intl.formatMessage({id: 'app.months.dec' }) + " - " + lastYear
  }else {
   return ""
  }
   

}


  const data = {
    labels: [  getMonthLabel() , getLastMonthLabel() , getLastYearMonthLabel() ],
    datasets: [
      {
        label: ' kWh',
        yAxisID: 'A',
        backgroundColor: ['#A53693', '#A53693', '#A53693'],
        data: dataBilling
      },
      {
        label: ' $',
        yAxisID: 'B',
        backgroundColor: ['#8CBA42', '#8CBA42', '#8CBA42'],
        data:  dataConsume
      }
    ]
  };

  const options = { responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: { top:20 }  },
    scales: {
      xAxes: [
        { 
          
          barPercentage: 0.9,
          categoryPercentage: 0.8,
          ticks: {
            fontColor: palette.text.secondary,
            fontSize: 20,
            padding: 20
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position:'left',
          ticks: {
            fontColor: "#A53693",
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        },
        {
          id: 'B',
          position:'right',
          ticks: {
            fontColor: "#8CBA42",
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
    animation: {
      onComplete: function () {
          var chartInstance = this.chart,
              ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "#000";
          ctx.textBaseline = 'top';
          ctx.font = "20px Arial";

    
          this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  if( data ){
                     ctx.fillText(data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), bar._model.x, bar._model.y -20 );
                     //ctx.fillText(data.toLocaleString() , bar._model.x, bar._model.y -20 );
                  }

              });
          });
      }
  }

  };


  const rootClassName = classNames(classes.root, className);

  return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title={intl.formatMessage({id: 'app.charge.board.consumpAndWeightChart.title'})} />
          <PortletToolbar>
            {/*  */}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar
              data={data}
              options={options}
            />
     
          </div>
        </PortletContent>
        {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
      </Portlet>
  );
}
