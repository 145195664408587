/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  Timeline as AlertIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)


export default function Users(props) {
  const intl = useIntl()
  const classes = useStyles();
  const {className,...rest} = props;
  const {meterId} = props;
  const {meter} = props;
  const rootClassName = classNames(classes.root, className)
  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      <Link
        to={{
          pathname:`/meter/${meterId}`,
          state:{meter:meter}
        }}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              {intl.formatMessage({id: 'app.meter.board.mainHead.groups'})}
            </Typography>
            <Typography
              className={classes.value}
              style={{height:'18px'}}
              variant="h3"
            />
          </div>
          <div className={classes.iconWrapper}>
            <AlertIcon className={classes.icon} />
          </div>
        </div>
      </Link>
    </Paper>
  )
}


