export default theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  table: {
    minWidth: 650,
    maxWidth: '100vw'
  },
  title: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  myLink : {     
    fontWeight: '600' ,
    textDecoration: 'none' ,
    color: '#0677BB' ,
    cursor : 'pointer' ,
    fontFamily : 'Roboto' ,
    fontSize : '14px' ,
    padding: '0px' ,
    margin : '0px'
  },
  roundButton: {     
    borderRadius: '15px' ,
    border : 'none' ,
    textDecoration: 'none' ,
    color: '#fff' ,
    cursor : 'pointer' ,
    fontFamily : 'Roboto' ,
    fontSize : '12px' ,
    paddingLeft: '12px' ,
    paddingRight: '12px' ,
  },
  positionLeft: {
    position: 'sticky',
    left: '0',
    background: 'white',
    zIndex: 1
  },
  positionRight: {
    position: 'sticky',
    right: '0',
    background: 'white',
    zIndex: 1
  },
  topBar: {
    display: 'flex',
    justifyContent: 'center',
    gap: '.5rem',
    width: '30%',
    [theme.breakpoints.down('md')]:{
      width: 'auto',
      marginBottom: theme.spacing.unit
    }
  }
});
  