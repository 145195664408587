import React, { useEffect } from 'react';
import {
  StyleSheet,
  Font

} from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell
} from '@david.kucsai/react-pdf-table';

import Moment from 'react-moment';

import i2b from 'imageurl-base64';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, makeStyles, Button } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { GDMTHReceipt, PDBTReceipt, GDMTOReceipt, CUSTOMReceipt  } from './components';
import { useDispatch, useSelector } from 'react-redux';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  listItem: {
    padding: theme.spacing(1, 0)
  },
  total: {
    fontWeight: 700
  },
  title: {
    marginTop: theme.spacing(2)
  },
  paper: {
    margin: '60px auto',
    width: '640px',
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
});

const useStyle = makeStyles(styles);

export default function Receipt(props) {
  // const { chargeId } = props.match.params;
  // const classes = useStyle();
  // const dispatch = useDispatch();
  const receipt = useSelector(state => state.chargeDashboard.receipt.data);

  const user = useSelector(state => state.user.user);
  //const concentratorid = props.props;
  const corpAdminLogo = useSelector(state => state.user.corporative);

  const getReceipt = type => {
    switch (type) {
      case 'GDMTH':
        return <GDMTHReceipt />;

      case 'PDBT':
        return <PDBTReceipt />;
      case 'GDMTO':
          return <GDMTOReceipt />;
  
      case 'CUSTOM':
          return <CUSTOMReceipt />;
  
      default:
        break;
    }
  };


  return (
    <DashboardLayout title="Recibo de Pago">
      
      <div style={{margin:'0 auto'}}>
      {getReceipt(receipt.consumes.billing.recipe_type)}
      </div>
    </DashboardLayout>
  );
}
