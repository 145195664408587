import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Grid } from '@material-ui/core';

import { postForm,deleteLastPostForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  concentratorPhase: null,
  concentratorType: null,
  baudrate: null
};

function UserConcentratorForm(props) {
  const intl = useIntl();
  const user = useSelector(state=>state.user.user)
  const meterAdmin = useSelector(state=>state.user.meter)

  const meterUser = user.meters

  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser[0].id

  let concentrators = useSelector(state => state.concentrators.concentrators);

  const [num_inputs, setNumInputs] = useState(null)
  const [unit, setUnit] = useState(null)

  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();

  const onSubmit = values => {
    if(values.concentratorPhase !== undefined && values.chargeType !== undefined && values.elementType !== undefined && values.unit !== '' && values.numInputs !== '') {
      dispatch(postForm(values));
      props.nextStep();
    } else {
      Swal.fire({
        title: intl.formatMessage({id:'app.chargesCards.addCharge.stepper.comunications.dataWrongTitle'}),
        text: intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.dataWrongText'}),
        icon: 'error'
      })
    }
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }


  const handleNumInputs = numInputs => {
    setNumInputs(numInputs.target.value);
  };

  const handleUnit = unit => {
    setUnit(unit.target.value);
  };

  const handleCardElementChange = elementType => {
    setValue('elementType', elementType.target.value);
  };

  const handleCardTypeChange = chargeType => {
    setValue('chargeType', chargeType.target.value);
  };

  const handleConcentratorPhase = concentratorPhase => {

    setValue('concentratorPhase', concentratorPhase.target.value)
  }

  useOnMount(() => {
    register({ name: 'elementType', type: 'text' });

    register({ name: 'chargeType', type: 'text' });

    register({ name: 'concentratorPhase', type: 'text'})
  });

  useEffect(()=>{
    dispatch(showConcentrators(meterId));
  },[])

  const values = getValues();

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            //inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.unitsLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="unit"
            onChange={handleUnit}
            value={unit}

            //labelWidth={labelWidth}
          >
           
            <MenuItem value={'CORRIENTE'}>{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.units.current'})}</MenuItem>            
            <MenuItem value={'VOLTAJE'}>{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.units.voltage'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.tipElemLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleCardElementChange}
            value={values.elementType}

            //labelWidth={labelWidth}
          >
            
            <MenuItem value={'CARGA'}> {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.tipElem.charge'})}</MenuItem>
            <MenuItem value={'CAPACITOR'}> {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.tipElem.capacitor'})}</MenuItem>
            <MenuItem value={'TRANSFORMADOR'}> {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.tipElem.transformer'})}</MenuItem>
            <MenuItem value={'TRANSFORMADOR'}> {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.tipElem.engine'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.measureTypeLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleCardTypeChange}
            value={values.chargeType}

            //labelWidth={labelWidth}
          >
            
            <MenuItem value={'MONOFASICA'}>{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.measureType.mono'})}</MenuItem>
            <MenuItem value={'BIFASICA'}>{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.measureType.bi'})}</MenuItem>
            <MenuItem value={'TRIFASICA'}>{intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.measureType.tri'})}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            //inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.inputsUsed'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="num_inputs"
            onChange={handleNumInputs}
            value={num_inputs}

            //labelWidth={labelWidth}
          >
           
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.concentratorLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleConcentratorPhase}
            value={values.concentratorPhase}
            //labelWidth={labelWidth}
          >
            
            {concentrators.map(concentrator => (
              <MenuItem value={concentrator.id}>{concentrator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"

        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.saveAndContinueBtn'})}
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.returnBtn'})}
        </Button>
      </form>
    </Grid>
  );
}

export default UserConcentratorForm;
