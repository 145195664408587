 import {IntlProvider} from 'react-intl';
import React, { useState } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {store, persistor} from "js/store/index";
import App from "./App.jsx";
import * as serviceWorker from './common/serviceWorker';

import {PersistGate} from 'redux-persist/integration/react'

import French from './lang/fr.json';
import Spanish from './lang/sp.json';
import English from './lang/en.json';

const locale = navigator.language;

 

var lang = "sp"
if ( locale==="en-US") {
    lang = English;
} else {   
      lang = Spanish;   
}


//const [gblLocale, setGblLocale] = useState(locale);
 // const [gblMessages, setGblMessages] = useState(lang);
 


render(
    
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} >
    
      <App />
       
    </PersistGate>
  </Provider>, 
  
  document.getElementById("root")
);


serviceWorker.unregister();