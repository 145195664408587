export const GET_CFDI_STARTED = 'GET_CFDI_STARTED';
export const GET_CFDI_SUCCESS = 'GET_CFDI_SUCCESS';
export const GET_CFDI_FAILURE = 'GET_CFDI_FAILURE';

export const GET_PAYMENT_TYPES_STARTED = 'GET_PAYMENT_TYPES_STARTED';
export const GET_PAYMENT_TYPES_SUCCESS = 'GET_PAYMENT_TYPES_SUCCESS';
export const GET_PAYMENT_TYPES_FAILURE = 'GET_PAYMENT_TYPES_FAILURE';

export const GET_PAYMENT_METHODS_STARTED = 'GET_PAYMENT_METHODS_STARTED';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE';

