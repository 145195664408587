import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
//import { postForm } from 'js/actions/formsAction';
import { postDataCard } from 'js/actions/cardAction';
import { deleteLastPostForm } from 'js/actions/formsAction';

import {
  Typography,
  Button,Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const classes = useStyles();
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data } = formData;
  const user = useSelector(state=>state.user.user)


  const meterAdmin = useSelector(state=>state.user.meter)
  const meterUser = user.meters
  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser[0].id


  const card = {
    name: data[0].data.card_name,
    description: data[0].data.card_description,
    communication_channel: data[1].data.comunicationType,
    mac_address: data[1].data.mac_address,
    type: data[1].data.cardType,
    concentrator_id: data[1].data.concentrator,
    serial_number: data[1].data.serial_number,
    meter: meterId
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }


  function completeCard(card) {
    dispatch(postDataCard(card));
    //console.log('ADE1',card.charges.ADE1)
  }

  return (
    <Grid
      className={classes.root}
      container
    >
      <div className={classes.form}>
        <Typography variant="h4">Confirmacion de Tarjeta</Typography>

        <Typography variant="body1"> Nombre de Tarjeta: {card.name} </Typography>
        <Typography variant="body1"> Descripcion : {card.description}</Typography>
        <Typography variant="body1">
          {' '}
        Via de Comunicacion : {card.communication_channel}
        </Typography>
        <Typography variant="body1"> Tipo de Tarjeta : {card.type}</Typography>
        <Typography variant="body1">
          {' '}
        Direccion Mac: {card.mac_address}
        </Typography>
        <Typography variant="body1"> Serial: {card.serial_number}</Typography>

        <Button
          className={classes.button}
          color="secondary"
          onClick={() => completeCard(card)}
          size="large"
          variant="contained"
        >
        Confirmar
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.backBtn'})}
        </Button>
      </div>

    </Grid>
    
  );
}

export default NameRoleForm;
