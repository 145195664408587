import { combineReducers } from 'redux';
import corporatives from './corporativeReducer';
import meters from './meterReducers'
import groups from './groupReducer'
import user from './userReducer'
import roles from './roleReducer'
import permission from './permissionReducer'
import users from './usersReducer'
import cards from './cardReducer'
import controlCards from './onOffReducer'
import concentrators from './concentratorReducer'
import charges from './chargeReducer'
import form from './formsReducer'
import meterDashboard from './meterDashboardReducer'
import groupDashboard from './groupDashboardReducer'
import chargeDashboard from './chargeDashboardReducer'
import systemAlert from './systemAlertReducer'
import userAlert from './userAlertReducer'
import ZEPOMEX from './ZEPOMEXReducer'
import controlRate from './controlRateReducer'
import preInvoiceGroup from './preInvoiceGroupReducer'
import preInvoiceMeter from './preInvoiceMeterReducer'
import invoice from './InvoiceReducer'
import prefs from './prefsReducer'

const rootReducer =(combineReducers)({
    user : user,
    users: users,
    roles: roles,
    preInvoiceGroup: preInvoiceGroup,
    preInvoiceMeter: preInvoiceMeter,
    corporatives: corporatives, 
    meters: meters,
    groups: groups,
    concentrators: concentrators,
    cards: cards,
    controlCards:controlCards,
    charges: charges,
    forms: form,
    meterDashboard: meterDashboard,
    groupDashboard: groupDashboard,
    chargeDashboard: chargeDashboard,
    systemAlert: systemAlert,
    userAlert: userAlert,
    zepomex: ZEPOMEX,
    controlRate: controlRate,
    invoice: invoice,
    prefs : prefs
});

const appReducer = (state, action) => {
    if (action.type === 'USERS_LOGOUT_SUCCESS') {
        state = undefined
      }
    return rootReducer(state, action)
  }

export default appReducer