export default function getToken() {
  return localStorage.getItem ('access_token');
}

export const isAuthenticated = user => !!user;

export const isAllowed = (permissions, rights) =>{
  const permissions_list = [];
  permissions.forEach(element => {
    permissions_list.push(element.name)
  });
  return rights.some(right => permissions_list.includes(right))
}

export const hasRole = (user, roles) =>
  roles.some(role => user.roles.includes(role));
