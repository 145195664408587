import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import {
  showConcentrators,
  deleteConcentrator
} from 'js/actions/concentratorActions';

import {
  showVoltage
} from 'js/actions/chargeActions';

import { Link } from 'react-router-dom';
import { history } from 'helpers/history';
import { isAllowed } from 'helpers/auth';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import styles from './styles';
const useStyles = makeStyles(styles);

export default function ConcentratorTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const concentrators = useSelector(state => state.charges.voltage);
  const user = useSelector(state => state.user.user);
  const { meterId,corporativeId } = props;

  console.log(props);

  const [state, setState] = React.useState({
    columns: [
      { title: 'Identificador', field: 'id' },
      { title: 'Nombre', field: 'name' },
      { title: 'Descripcion', field: 'description' },
      { title: 'Tipo', field: 'type' },
      {
        title: 'Tarjetas',

        render: rowData => (
          <Link
            className={classes.iconTable}
            to={{
              pathname: `/card/${rowData.meter_id}`,
              state: rowData
            }}>
            <Button color="secondary" variant="outlined">
              {' '}
              Ver Tarjetas Configuradas
            </Button>
          </Link>
        )
      }
    ],

    data: []
  });

  const deleteConcentratorData = concentratorId => {
    dispatch(deleteConcentrator(concentratorId));
  };

  useEffect(() => {
    dispatch(showConcentrators(meterId));
    dispatch(showVoltage(corporativeId,meterId));
  }, []);

  useEffect(() => {
    setState({ ...state, data: concentrators });
  }, [concentrators]);

  const permissionsActions = () => {
    const actions = [];

    if (isAllowed(user.permissions, ['concentrator_delete'])) {
      actions.push({
        icon: 'delete',
        tooltip: 'Eliminar Voltaje',
        onClick: (event, rowData) => deleteConcentratorData(rowData.id)
      });
    }

    if (isAllowed(user.permissions, ['concentrator_update'])) {
      actions.push({
        icon: 'edit',
        tooltip: 'Editar Voltaje',
        onClick: (event, rowData) =>
          history.push({
            pathname: `/updateVoltage/${rowData.id}`,
            state: { rowData }
          })
      });
    }

    return actions;
  };

  return (
    <MaterialTable
      actions={permissionsActions()}
      columns={state.columns}
      data={state.data}
      options={{
        showTitle: false,
        actionsColumnIndex: -1
      }}
    />
  );
}
