import React, { useEffect }  from 'react';

import {
  cleanUsers
} from 'js/actions/userActions';
// Material components
import { CircularProgress, Typography } from '@material-ui/core';

import { useDispatch } from 'react-redux';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services

// Custom components
import { UsersTable, ProductsToolbar, UsersToolbar } from './components';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Component styles
import styles from './style';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)


export default function UserList(props) {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cleanUsers());
  }, []);
  


  return (
    <DashboardLayout title="Usuarios Metimur">
    <div className={classes.root}>
      <div className={classes.content}>
         <UsersTable /> 
      </div>
    </div>
    <Container styles={{ marginRight: '-20px', marginBottom: '-20px',zIndex:900 }}>
      <LinkFloat
        href={`/newMetimurUser`}
        icon="fa fa-plus"
        styles={{
          backgroundColor: '#00AFEF',
          color: '#fff',
          width: '60px',
          height: '60px',
          fontSize: '1.5em'
        }}
        tooltip={intl.formatMessage({id: 'app.metusers.addUserBtn'})}
      />
      <Button
        icon="fa fa-cog"
        rotate
        styles={{
          backgroundColor: '#C69100',
          color: '#fff',
          width: '80px',
          height: '80px',
          fontSize: '1.5em'
        }}
      />
    </Container>
  </DashboardLayout>
  )
}

