import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeWorkData, deleteCard , deleteHomeworkData } from 'js/actions/onOffAcrtions';
import { Link } from 'react-router-dom';
import {history} from 'helpers/history'

import { makeStyles,Button } from '@material-ui/core';
import axios from 'axios';
import getToken from 'helpers/auth';
import Swal from 'sweetalert2';

import styles from './styles';
const useStyles = makeStyles(styles);

export default function ConcentratorTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const homeworks = useSelector(state => state.controlCards.homeworks);
  //const user = useSelector(state=>state.user.user)
  //const card = props.props;
  const [fps , setFps  ] = React.useState( []);
  const { meterId } = props;


 

  const deleteFP = fpId => {
      //dispatch(deleteCard(cardId));
  };


  useEffect(() => {
     dispatch(loadFps());
  }, []);

  useEffect(() => {
    //setFps({ ...state, data:  } );
  }, [fps]);


    const loadFps = ( ) => {    
       

    return async dispatch => {
      axios
        .get(`https://api.metimur.mx/api/meter/${meterId}/fp`, 

        {
          headers: { Authorization: 'Bearer ' + getToken( ) }
        }
        
         )
        .then(response => {
           console.log("Se obtubo todos los fp ");         
           setFps(response.data.data);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };



  const [state, setState] = React.useState({
    columns: [
      { title: 'Identificador', field: 'id' },
      { title: 'Nombre', field: 'name' },  
      { title: 'Descripción', field: 'description' },  
      { title: 'Ade', 
        field: 'ade'  , 
        render: rowData =>{ if( rowData.ade==1 ) {  return 'ADE1' } else if(  rowData.ade==2  ) { return  'ADE2'   }else { return '' }  }

      },  
      { title: 'Acción', field: 'action'  },  
      
  
    ],
    actions: [
      {
        icon: 'delete',
        tooltip: 'Eliminar FP',
        onClick: (event, rowData) => deleteFP(rowData.id)
      },
      {
        icon: 'edit',
        tooltip: 'Editar FP',
        onClick: (event, rowData) => {history.push({ 
          pathname: `/editPowerFactor/${meterId}/${rowData.id}`,
          state:{hw:rowData}
         })}
      }
    ]
  });

 

  return (
    <MaterialTable
      actions={state.actions}
      columns={state.columns}
      data={fps}
      options={{
        showTitle:false,
        actionsColumnIndex: -1
      }}
    />
  );
}
