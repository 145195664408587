export const GET_ONOFF_CARD_STARTED = 'GET_ONOFF_CARD_STARTED';
export const GET_ONOFF_CARD_SUCCESS = 'GET_ONOFF_CARD_SUCCESS';
export const GET_ONOFF_CARD_FAILURE = 'GET_ONOFF_CARD_FAILURE';

export const POST_ONOFF_CARD_STARTED = 'POST_ONOFF_CARD_STARTED';
export const POST_ONOFF_CARD_SUCCESS = 'POST_ONOFF_CARD_SUCCESS';
export const POST_ONOFF_CARD_FAILURE = 'POST_ONOFF_CARD_FAILURE';

export const GET_HOMEWORKS_STARTED = 'GET_HOMEWORKS_STARTED';
export const GET_HOMEWORKS_SUCCESS = 'GET_HOMEWORKS_SUCCESS';
export const GET_HOMEWORKS_FAILURE = 'GET_HOMEWORKS_FAILURE';

export const POST_HOMEWORKS_STARTED = 'POST_HOMEWORKS_STARTED';
export const POST_HOMEWORKS_SUCCESS = 'POST_HOMEWORKS_SUCCESS';
export const POST_HOMEWORKS_FAILURE = 'POST_HOMEWORKS_FAILURE';

export const GET_ONOFF_CARD_INPUT_SUCCESS = 'GET_ONOFF_CARD_INPUT_SUCCESS'


export const DELETE_ONOFF_CARD_SUCCESS = 'DELETE_ONOFF_CARD_SUCCESS';

export const PUT_ONOFF_CARD_STARTED = 'PUT_ONOFF_CARD_STARTED';
