 import * as userAlertTypes from '../constants/userAlertsAction-types';


export default function userSystemReducer(
  state = {  userAlerts: [], loading: false },
  action
) {
  switch (action.type) {
    case userAlertTypes.GET_USER_ALERT_STARTED:
      return {
        ...state,
        loading: true
      };
    case userAlertTypes.GET_USER_ALERT_SUCCESS:
      return { ...state, 
        userAlerts: action. userAlerts, 
        loading: false 
      };

      case userAlertTypes.GET_USER_ALERTS_CNS_STARTED:
        return {
          ...state,
          loading: true
        };
      case userAlertTypes.GET_USER_ALERTS_CNS_SUCCESS:
        return { ...state, 
          userAlertsCns: action.userAlertsCns , 
          loading: false 
        };      

    case userAlertTypes.POST_USER_ALERT_STARTED:
      return {
        ...state,
        loading: true
      };
    case userAlertTypes.POST_USER_ALERT_SUCCESS:
      return {
        ...state,
        userAlerts: state.userAlerts.push(action.payload),
        loading: false
      };

    case userAlertTypes.POST_USER_ALERT_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

   

    case userAlertTypes.DELETE_USER_ALERT_STARTED:
      return { ...state, loading: true };

    case userAlertTypes.DELETE_USER_ALERT_SUCCESS:
      return state.alertSystem.filter(
        post => post._id !== action.payload.corporative_id
      );

    default:
      return state;
  }
}

// export default rootReducer;
