import React, { useEffect } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';

import { getGeneralReport } from 'js/actions/chargeDashboardActions';

import { Bar } from 'react-chartjs-2';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody
} from '@material-ui/core';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport
  );

  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month =  new Date().getMonth() + 1;
    if ( month<10) {return '0'+month} else {
      return month
    } 
  }
  const data = {
    labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre', 'Octubre','Noviembre','Diciembre',],
    datasets: [
      {
        label: '2020',
        fill: false,
        backgroundColor:'#C69100',
        borderColor:'#C69100',
        lineTension: 0.1,
        // backgroundColor: palette.primary.main,
        data: []
      }
    
    ]
  }


  const generalReportArray = Object.values(generalReport);
  const lastDayGeneralReport = generalReportArray[generalReportArray.length -1]

  lastDayGeneralReport.forEach(element => {
      
  })

    // useEffect(() => {
  //   dispatch(getGeneralReport(ids, year,getCurrentMonth()));
  // }, []);

  // Object.values(generalReport).forEach(element => {
  //   element.forEach(element2 => {
  //     obj = {
  //       hora: element2.Hora,
  //       medicion: element2.KWh,
  //       color: element2.color
  //     };
  //     data.push(obj);
  //   });
  // });

  // const res = data.reduce((acc, curr) => {
  //   if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
  //   acc[curr.hora].push(curr);
  //   return acc;
  // }, {});

  return (
    <div className={classes.root}>
        <Portlet
     
      >
        <PortletHeader noDivider>
          <PortletLabel title="Demanda y Consumo (kW / kWh)" />
          <PortletToolbar>
           
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar
              data={data}
              //options={options}
            />
          </div>
        </PortletContent>
        {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
      </Portlet>
    </div>
  );
}
