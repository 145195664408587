export default theme => ({
  root: {height: '100%'},
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartContent:{
    padding: '10px'
  },
  chartWrapper: {
    height: '400px',
    position: 'relative',
    margin: '0 auto'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
