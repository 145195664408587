import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dashboard as DashboardLayout } from 'layouts';
import MaterialTable from 'material-table';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  Paper
} from 'components';

// Material helpers
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  CircularProgress
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { updateDataMeter } from 'js/actions/meterActions';
import { makeStyles } from '@material-ui/styles';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';
import {
  postPreviewFactor,
  applyFactor
} from 'js/actions/preInvoiceMeterAction';
import useForm from 'react-hook-form';
import { PortletFooter } from 'components';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function MeterForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const { meter } = props.location.state;

  let charges = useSelector(state => state.charges.charges);

  let lastMonthConsumes = useSelector(
    state => state.preInvoiceMeter.lastMonthConsumes
  );

  let previewFactor = useSelector(
    state => state.preInvoiceMeter.previewInvoice
  );

   let { groups_data, meter_totals } = lastMonthConsumes;

  const year = new Date().getFullYear();
  const getLastMonth = () => {
    const month = new Date();
   
    const lastMonth = month.getMonth();

    switch (lastMonth - 1) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 4;
      case 4:
        return 5;
      case 5:
        return 6;
      case 6:
        return 7;
      case 7:
        return 8;
      case 8:
        return 9;
      case 9:
        return 10;
      case 10:
        return 11;
      case 11:
        return 12;
      default:
        break;
    }
  };

  const [open, setOpen] = useState(false);
  const [factor, setFactor] = useState(0);
   const [consumes, setConsumes] = useState({});
  const [groupsInfo, setGroupsInfo] = useState({});
  const [previewTotals, setPreviewTotal] = useState([]);

  const columns = [
    {
      title: '',
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        MinWidth: '25%'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.preInvoice.grid.baseCol'}),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.preInvoice.grid.intermedCol'}),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.preInvoice.grid.tipCol'}),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    }
  ];

   const data = consumes.consumes;

  useEffect(() => {
    setConsumes({
      ...consumes,
      consumes: [
        {
          med: 'kwh',
          bas: (meter_totals == undefined ? 0 : meter_totals.base_kwh),
          int: (meter_totals == undefined ? 0 : meter_totals.intermedio_kwh),
          punt: (meter_totals == undefined ? 0 :  meter_totals.punta_kwh )
        },
         {
           med: 'kw',
           bas: (meter_totals == undefined ? 0 : meter_totals.base_demand),
           int: (meter_totals == undefined ? 0 : meter_totals.intermedio_demand),
           punt: (meter_totals == undefined ? 0 : meter_totals.punta_demand)
         }
      ]
    });
  }, [lastMonthConsumes]);

  useEffect(() => {
    setGroupsInfo({
      ...groupsInfo,
      groupsInfo: groups_data,
      billing:(meter_totals == undefined ? 0 : meter_totals.billing_total )
    });
  }, [lastMonthConsumes]);





  useEffect(() => {
    setPreviewTotal({ ...previewTotals, preview: previewFactor });
  }, [previewFactor]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = values => {
    setFactor(values.factor);
    const preview = {
      year: year,
      month: getLastMonth(),
      meterId: meter.id,
      corporativeId: meter.corporative_id,
      factor: values.factor
    };

    console.log(preview)

    dispatch(postPreviewFactor(preview));
    handleOpen();
  };

  const apply = () => {
    const applyValues = {
      year: year,
      month: getLastMonth(),
      meterId: meter.id,
      corporativeId: meter.corporative_id,
      factor: factor
    };
     dispatch(applyFactor(applyValues));
  };

  return (
    <DashboardLayout>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={`${intl.formatMessage({id: 'app.metersCards.preInvoice.title'})} ${meter.name}`} />
          </PortletHeader>
          <PortletContent noPadding>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <div className={classes.fields}>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography variant="h3">{intl.formatMessage({id: 'app.metersCards.preInvoice.groupData.title'})}</Typography>
                      <Typography>{intl.formatMessage({id: 'app.metersCards.preInvoice.groupData.name'})} {meter.name} </Typography>
                      <Typography>{intl.formatMessage({id: 'app.metersCards.preInvoice.groupData.descrip'})} {meter.description} </Typography>
                    </div>

                    <Typography>{intl.formatMessage({id: 'app.metersCards.preInvoice.groupData.state'})} {meter.state} </Typography>
                    <Typography>{intl.formatMessage({id: 'app.metersCards.preInvoice.groupData.city'})} {meter.city} </Typography>

                    <Typography variant="h3" style={{ marginBottom: '15px' }}>
                    {intl.formatMessage({id: 'app.metersCards.preInvoice.measurments'})}
                    </Typography>
                    <div style={{ marginBottom: '20px' }}>
                      <MaterialTable
                        className={classes.table}
                        columns={columns}
                        data={data}
                        options={{
                          filtering: false,
                          search: false,
                          sorting: false,
                          toolbar: false,
                          paging: false,
                          padding: 0,
                          headerStyle: {
                            textAlign: 'center',
                            padding: '0',
                            fontSize: '1em',
                            fontWeight: '900',
                            minWidth: '25%'
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Grid container spacing={4}>
                      <Grid item>
                        <Typography
                          className={classes.description}
                          variant="h3"
                          style={{ marginTop: '5px', color: 'carbon' }}>
                          {intl.formatMessage({id: 'app.metersCards.preInvoice.amount'})}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.description}
                          variant="h3"
                          style={{
                            color: '#218A1D',
                            fontWeight: '600',
                            marginTop: '5px'
                          }}>
                          $ {groupsInfo.billing}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <TextField
                    className={classes.textField}
                    inputRef={register}
                    label={intl.formatMessage({id: 'app.metersCards.preInvoice.changeFactLbl'})}
                    name="factor"
                    required
                    variant="outlined"
                  />

                  <Button
                    className={classes.signUpButton}
                    color="secondary"
                    //onClick={this.handleSubmit}
                    type="submit"
                    size="large"
                    variant="contained">
                    {intl.formatMessage({id: 'app.metersCards.preInvoice.recalcBtn'})}
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <div className={classes.fields}>
                    <Typography variant="h3">{intl.formatMessage({id: 'app.metersCards.preInvoice.groups'})}</Typography>
                    <List>
                      {charges
                        .sort((a, b) =>
                          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
                        )
                        .map(charge => (
                          <ListItem>
                            {' '}
                            <Typography>{charge.name} </Typography>{' '}
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </form>
          </PortletContent>
        </Portlet>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
          style={{
            width: '90%',
            margin: '0 auto',
            overflow: 'scroll',
            height: '80%'
          }}>
          <div>
            <Portlet>
              <PortletHeader>
                <Typography
                  className={classes.description}
                  variant="h5"
                  style={{
                    fontWeight: '600',
                    marginTop: '5px'
                  }}>
                  Recalculos a Aplicar sobre las siguientes mediciones
                </Typography>
              </PortletHeader>
              <PortletContent noPadding>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <List>
                      <ListItem>
                        <Typography
                          className={classes.description}
                          variant="h5"
                          style={{
                            fontWeight: '600',
                            marginTop: '5px'
                          }}>
                          Importes sobre Mediciones al Corte
                        </Typography>
                      </ListItem>
                      {lastMonthConsumes.groups_data ? (
                        lastMonthConsumes.groups_data.map(group => (
                          <ListItem>
                            <ListItemText
                              primary={`${group.group_info.name}`}
                            />
                            <ListItemText
                              primary={`$ ${group.consumes.billing.total}`}
                            />
                          </ListItem>
                        ))
                      ) : (
                        <CircularProgress />
                      )}
                    </List>
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <List>
                      <ListItem>
                        <Typography
                          className={classes.description}
                          variant="h5"
                          style={{
                            fontWeight: '600',
                            marginTop: '5px'
                          }}>
                          Importes sobre Mediciones Recalculados
                        </Typography>
                      </ListItem>
                      {previewFactor.groups_data ? (
                        previewFactor.groups_data.map(group => (
                      
                          <ListItem>
                            <ListItemText primary={`${group.group_info.name}`} />
                            <ListItemText primary={`$ ${group.consumes.billing.total}`} />
                          </ListItem>
                        ))
                      ) : (
                        <CircularProgress />
                      )}
                    </List>
                  </Grid>
                </Grid>
              </PortletContent>
              <PortletFooter>
                <Button
                  className={classes.signUpButton}
                  color="secondary"
                  onClick={() => apply()}
                  //type="submit"
                  size="large"
                  variant="contained">
                  Aplicar
                </Button>

                <Button
                  className={classes.signUpButton}
                  color="secondary"
                  onClick={() => handleClose()}
                  //type="submit"
                  size="large"
                  variant="outlined">
                  Ingresar nuevo factor
                </Button>
              </PortletFooter>
            </Portlet>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
}
