import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { showCards, deleteCard, cleanCard } from 'js/actions/cardAction';
import { Link } from 'react-router-dom';
import { history } from 'helpers/history';
import { isAllowed } from 'helpers/auth';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';
const useStyles = makeStyles(styles);

export default function ConcentratorTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cards = useSelector(state => state.cards.cards);
  const user = useSelector(state => state.user.user);
  const concentratorid = props.props;

  const [state, setState] = React.useState({
    columns: [
      { title: 'Identificador', field: 'id' },
      { title: 'Nombre', field: 'name' },
      { title: 'Tipo', field: 'type' },
      { title: 'Direccion MAC', field: 'mac_address' }
    ]
  });

  const deleteCardData = cardId => {
    dispatch(deleteCard(cardId));
  };

  useEffect(() => {
    dispatch(showCards(concentratorid));
  }, []);

  useEffect(() => {
    setState({ ...state, data: cards });
  }, [cards]);

  const permissionsActions = () => {
    const actions = [];

    if (isAllowed(user.permissions, ['card_delete'])) {
      actions.push({
        icon: 'delete',
        tooltip: 'Eliminar Tarjeta',
        onClick: (event, rowData) => deleteCardData(rowData.id)
      });
    }

    if (isAllowed(user.permissions, ['card_update'])) {
      actions.push({
        icon: 'edit',
        tooltip: 'Editar Tarjeta',
        onClick: (event, rowData) => {
          history.push({
            pathname: `/updateCard/${props.meterId}/${rowData.id}`,
            state: { rowData }
          });
        }
      });
    }

    return actions;
  };

  return (
    <MaterialTable
      actions={permissionsActions()}
      columns={state.columns}
      data={state.data}
      options={{
        showTitle: false,
        actionsColumnIndex: -1
      }}
    />
  );
}
