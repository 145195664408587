import React, { Component } from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { history } from 'helpers/history';

export class CorpTable extends Component {
  render() {
    const { corporatives } = this.props;

    const columns = [
      {
        name: 'name',
        label: 'Corporativo',
        style: {
          color: 'red'
        }
      },
      {
        name: 'market_segment',
        label: 'Segmento de Mercado'
      },
      {
        name: 'city',
        label: 'Ciudad'
      },
      {
        name: 'roles',
        label: 'Num. Roles'
      },
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false
        }
      }
    ];

    const data = corporatives.corporatives;

    const options = {
      filterType: 'checkbox',
      onRowClick: rowData => history.push(`/roles/${rowData[4]}`, { rowData })
    };

    console.log(options);

    return (
      <div>
        <MUIDataTable
          title={'Corporativos'}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    corporatives: state.corporatives
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorpTable);
