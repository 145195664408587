import React, { Component, useState } from 'react';
import { Router } from 'react-router-dom';
import { history } from './helpers/history';
import {IntlProvider} from 'react-intl';
import axios from './helpers/history';

// Externals
import { Chart } from 'react-chartjs-2';

// Material helpers
import { ThemeProvider } from '@material-ui/styles';

// ChartJS helpers
import { chartjs } from './helpers';

// Theme
import theme from './theme';

// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Routes
import Routes from './Routes';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { useSelector } from 'react-redux';

import Spanish from './lang/sp.json';
import English from './lang/en.json';

library.add(fas,fab)
dom.watch()


// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});




const localeNav = navigator.language;
//alert( localeNav); 
export default function App() {
  
  //const [gblLocale, setGblLocale] = useState(locale);
  // const [gblMessages, setGblMessages] = useState(lang);
  
  const user = useSelector(state => state.user.user);
  const prefLang = useSelector(state => state.prefs.prefLang);
  const prefLocale = useSelector(state => state.prefs.locale);


  var messages=null;
  if( prefLang=='EN' ){
    messages= English;
  }else{
    messages=Spanish;
  }
 

  return (
    <IntlProvider
      locale={prefLocale}
      messages={messages}
    >
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes user={user} />
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}
