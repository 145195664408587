export default theme => ({
  root: {},
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartWrapper: {
    height: '300px',
    position: 'relative'
  },
  title: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
