import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'helpers/history';
import { showAllUsers, deleteUser } from 'js/actions/userActions';

import { useEffect } from 'react';
import { useState } from 'react';
import MaterialTable from 'material-table';
import { isAllowed } from 'helpers/auth';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { blockUserAdmin } from 'js/actions/userActions';

export default function RoleTable(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { corpid } = props;
  const user = useSelector(state => state.user.user);
  const users = useSelector(state => state.user.users);

  const deleteUserData = user => {
    Swal.fire({
      title: intl.formatMessage({id: 'app.users.usersList.corpusers.msgdelete.title'}),
      text: intl.formatMessage({id: 'app.users.usersList.corpusers.msgdelete.text'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({id: 'app.users.usersList.corpusers.msgdelete.confirm'}) , 
      cancelButtonText: intl.formatMessage({id: 'app.users.usersList.corpusers.msgdelete.cancel'}),
    }).then((result) => {       
      if (result.value==true ) {        
        dispatch(deleteUser(user));
      }
    })
  };

  const updateUser = user => {
    const newActiveState = user.active === 1 ? 0 : 1;
    dispatch(blockUserAdmin(user.id, newActiveState))
    const message = user.active === 1 ? 'locked' : 'unlocked';
    Swal.fire({
      title: intl.formatMessage({id: `app.users.usersList.${message}User`}),
      text: `${user.firstname} ${user.lastname} ${intl.formatMessage({id: `app.users.usersList.${message}UserText`})}`,
      icon: 'success'
    });
  }

  const [state, setState] = useState({
    columns: [
      {
        field: 'firstname',
        title: intl.formatMessage({id: 'app.users.usersList.corpusers.grid.colFirstName'})
      },
      {
        field: 'lastname',
        title: intl.formatMessage({id: 'app.users.usersList.corpusers.grid.colLastName'})
      },
      {
        field: 'role_name',
        title: intl.formatMessage({id: 'app.users.usersList.corpusers.gird.colRolName'})
      },
      {
        field: 'active',
        title: intl.formatMessage({id:'app.users.usersList.statusCorp'}),
        render: rowData => rowData.active === 1 ? intl.formatMessage({id:'app.users.usersList.unlocked'}) : intl.formatMessage({id:'app.users.usersList.locked'})
      },
      {
        field: 'tel',
        title: intl.formatMessage({id: 'app.users.usersList.corpusers.grid.colTel'})
      },
      {
        field: 'email',
        title: intl.formatMessage({id: 'app.users.usersList.corpusers.grid.colEmail'})
      }
    ]
  });

  useEffect(() => {
    dispatch(showAllUsers(corpid));
  }, []);

  useEffect(() => {
    setState({ ...state, data: users });
  }, [users]);

  const permissionsActions = () => {
    const actions = [];

    if (isAllowed(user.permissions, ['user_delete'])) {
      actions.push({
        icon: 'delete',
        tooltip: intl.formatMessage({id: 'app.users.usersList.corpusers.deleteUser'}),
        onClick: (event, rowData) => deleteUserData(rowData)
      });
    }

    if (isAllowed(user.permissions, ['user_update'])) {
      actions.push({
        icon: 'edit',
        tooltip: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser'}),
        onClick: (event, rowData) => {
          history.push({
            pathname: `/updateAdminUser/${rowData.id}`,
            state: { user: rowData , corpId :corpid }
          });
        }
      });
      actions.push({
        icon: 'lock',
        tooltip: intl.formatMessage({id:'app.users.usersList.locked/unlocked'}),
        onClick: (event, rowData) => updateUser(rowData)
      });
    }

    return actions;
  };

  return (
    <div>
      <MaterialTable
        actions={permissionsActions()}
        columns={state.columns}
        data={state.data}
        options={{
          showTitle: false,
          actionsColumnIndex: -1
        }}
        title={'Usuarios'}
      />
    </div>
  );
}
