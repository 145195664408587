import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import { Button, TextField, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function NameConcentratorForm(props) {
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
  };

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextField
          className={classes.textField}
          inputRef={register}
          label="Nombre de Tarjeta"
          name="card_name"
          required
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label="Descripcion"
          name="card_description"
          required
          variant="outlined"
        />

        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          className={classes.button}
        >
          Guardar y Continuar
        </Button>
      </form>
    </Grid>
  );
}

export default NameConcentratorForm;
