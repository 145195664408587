/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import { Chip, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

//import { getGeneralReport } from 'js/actions/chargeDashboardActions';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import moment from 'moment';
const useStyles = makeStyles(styles);

export default function TotalesPorDia(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, heatMap, valueInfo } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );

  const totalPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_per_day
  );

  const totalTotalsPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_totals_per_day
  );

  const totalsDaysOfWeek = useSelector(
    state => state.chargeDashboard.generalReport.totals_days_of_week
  );

  const totalsPerHorary = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary
  );

  const averagePerRange = useSelector(
    state => state.chargeDashboard.generalReport.average_per_range
  );

  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };
  const data = [];
  let obj;

  if (generalReport != undefined || generalReport != null) {
    Object.values(generalReport).forEach(element => {
      element.forEach(element2 => {
        obj = {
          cost_kwh: element2.cost_kwh
        };
        data.push(obj);
      });
    });
  }

  const res = data.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {});

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav]);

  // Calculate daily totals
  const dailyTotals = [];
  const dailyMin = [];
  const dailyMax = [];
  const dailyAvg = [];

  const resetNumber = x => {
    return Number.parseFloat(x).toFixed(2);
  };
  if (generalReport) {
    for (let i = 1; i < Object.keys(generalReport).length + 1; i++) {
      let dailyTotal = 0;
      if (generalReport[i]) {
        generalReport[i].forEach(element => {
          dailyTotal += element.cost_kwh;
        });
      }
      dailyTotals.push(dailyTotal);
    }
  }

  if (generalReport) {
    for (let i = 1; i < Object.keys(generalReport).length + 1; i++) {
      if (generalReport[i]) {
        const costKwhValues = generalReport[i].map(element => element.cost_kwh);
        dailyMin.push(Math.min(...costKwhValues).toFixed(2));
        dailyMax.push(Math.max(...costKwhValues).toFixed(2));
        dailyAvg.push(
          (
            costKwhValues.reduce((sum, value) => sum + value, 0) /
            costKwhValues.length
          ).toFixed(2)
        );
      }
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <div>
      <Paper className={classes.paper}>
        <Chip
          className={classes.title}
          label={
            valueInfo.kwh
              ? intl.formatMessage({
                id: 'app.chargeSolar.board.totPerDay.title'
              })
              : intl.formatMessage({
                id: 'app.chargeSolar.board.totPerDay.titlePrice'
              })
          }
        />
        <PerfectScrollbar>
          <Table
            className={classes.table}
            size="small"
            style={{ width: '100%' }}
          >
            <TableHead>
              {generalReport != undefined || generalReport != null ? (
                <TableRow>
                  <TableCell className={classes.positionLeft}>
                    {intl.formatMessage({
                      id: 'app.charge.board.totPerDay.label'
                    })} {valueInfo.kwh ? 'kWh' : ''}
                  </TableCell>
                  {days.map((day, index) => (
                    <TableCell key={index}>
                      {day.day}
                      <br />
                      {day.weekday.charAt(0).toUpperCase() +
                        day.weekday.slice(1, 3)}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody style={{ padding: '0 !important' }}>
              {generalReport != undefined || generalReport != null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    Total
                  </TableCell>
                  {Object.values(totalPerDay).map((totalRow, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        backgroundColor: valueInfo.kwh
                          ? totalRow.color_hm
                          : totalRow.color_cost
                      }}
                    >
                      {valueInfo.kwh
                        ? totalRow.total
                        : `${formatter.format(totalRow.cost_kwh)}`}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
              {generalReport != undefined || generalReport != null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    {intl.formatMessage({
                      id: 'app.charge.board.totPerDay.min'
                    })}
                  </TableCell>
                  {Object.values(totalPerDay).map((minRow, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      //style={{ root:{ padding:'10px 13px'},backgroundColor: row.color, border:'1px solid #7e7e7e'}}
                    >
                      {valueInfo.kwh ? minRow.min : `$${dailyMin[index]}`}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
              {generalReport != undefined || generalReport != null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    {intl.formatMessage({
                      id: 'app.charge.board.totPerDay.max'
                    })}
                  </TableCell>
                  {Object.values(totalPerDay).map((maxRow, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      //style={{ root:{ padding:'10px 13px'},backgroundColor: row.color, border:'1px solid #7e7e7e'}}
                    >
                      {valueInfo.kwh ? maxRow.max : `$${dailyMax[index]}`}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
              {generalReport != undefined || generalReport != null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    {intl.formatMessage({
                      id: 'app.charge.board.totPerDay.avrg'
                    })}
                  </TableCell>
                  {Object.values(totalPerDay).map((promRow, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      //style={{ root:{ padding:'10px 13px'},backgroundColor: row.color, border:'1px solid #7e7e7e'}}
                    >
                      {valueInfo.kwh ? promRow.prom : `$${dailyAvg[index]}`}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
}
