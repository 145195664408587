//CORPORATIVES
export const GET_CONTROL_RATE_STARTED = 'GET_CONTROL_RATE_STARTED';
export const GET_CONTROL_RATE_SUCCESS = 'GET_CONTROL_RATE_SUCCESS';
export const GET_CONTROL_RATE_FAILURE = 'GET_CONTROL_RATE_FAILURE';

export const GET_LAST_UPDATE_RATE_STARTED = 'GET_LAST_UPDATE_RATE_STARTED';
export const GET_LAST_UPDATE_RATE_SUCCESS = 'GET_LAST_UPDATE_RATE_SUCCESS';
export const GET_LAST_UPDATE_RATE_FAILURE = 'GET_LAST_UPDATE_RATE_FAILURE';

export const POST_CONTROL_RATE_STARTED = 'POST_CONTROL_RATE_STARTED';
export const POST_CONTROL_RATE_SUCCESS = 'POST_CONTROL_RATE_SUCCESS';
export const POST_CONTROL_RATE_FAILURE = 'POST_CONTROL_RATE_FAILURE';

export const DELETE_CONTROL_RATE_SUCCESS = 'DELETE_CONTROL_RATE_SUCCESS';

export const PUT_CONTROL_RATE_SUCCESS = 'PUT_CONTROL_RATE_SUCCESS';


