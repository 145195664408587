import * as InvoiceActionsTypes from '../constants/invoiceAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function invoiceReducer(state = { cfdi: [], payment_types: [], payment_methods:[], loading: false }, action) {
  switch (action.type) {
    case InvoiceActionsTypes.GET_CFDI_STARTED:
      return {
        ...state,
        loading: true
      };
    case InvoiceActionsTypes.GET_CFDI_SUCCESS:
      return {
        ...state, cfdi: action.cfdi
      };
    case InvoiceActionsTypes.GET_PAYMENT_TYPES_STARTED:
      return {
        ...state,
        loading: true
      };
    case InvoiceActionsTypes.GET_PAYMENT_TYPES_SUCCESS:
      return {
        ...state, payment_types: action.payment_types
      };
    case InvoiceActionsTypes.GET_PAYMENT_METHODS_STARTED:
      return {
        ...state,
        loading: true
      };
    case InvoiceActionsTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state, payment_methods: action.payment_methods
      };
    default:
      return state;
  }
}

export default invoiceReducer;
