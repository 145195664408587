import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles, CircularProgress } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { AttachMoney as AttachMoneyIcon } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function Profit(props) {
  const intl = useIntl()
  const { className, ...rest } = props;
  const classes = useStyles();
  const generalConsumes = useSelector(
    state => state.chargeDashboard.generalConsumes
  );
  const { chargeId } = props.ids;
  const rootClassName = classNames(classes.root, className);

  return (
    // <Link to={`/charge/${chargeId}/receipt`}>
    <Paper {...rest} className={rootClassName}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} variant="body2">
          {intl.formatMessage({id: 'app.charge.board.mainHead.estimAmtAsOfToday'})}
          </Typography>

          {generalConsumes.consumes ? (
            <Typography className={classes.value} variant="h3">
              ${' '}
              { parseFloat( generalConsumes.consumes.billing.subtotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className={classes.iconWrapper}>
          <AttachMoneyIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.difference} variant="body2">
          {/* <ArrowUpwardIcon /> */}
        </Typography>
        <Typography className={classes.caption} variant="caption">
        {intl.formatMessage({id: 'app.charge.board.mainHead.estimAmtAsOfToday.vatNotice'})}
        </Typography>
      </div>
    </Paper>
    //</Link>
  );
}
