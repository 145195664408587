// Palette
import palette from 'theme/palette';

// Chart data
export const data = {
  labels: ['Base', 'Intermedia', 'Punta'],
  datasets: [
    {
      label: ' kW',
      yAxisID:'A',
      // backgroundColor: palette.primary.main,
      backgroundColor: "#730065",
      data: [14.868, 3.304, 0.099 ]
    },
    {
      label: ' kWh',
      yAxisID:'B',
      // backgroundColor: palette.common.neutral,
      backgroundColor: '#0080BC',
      data: [71.106, 4.398, 0.375 ]
    },
  
  ]
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: true },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 25,
        maxBarThickness: 25,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        id: 'A',
        position:'left',
        ticks: {
          fontColor: "#730065",
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      },
      {
        id: 'B',
        position:'right',
        ticks: {
          fontColor: "#0080BC",
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
