export const GET_SYSTEM_ALERT_STARTED = 'GET_SYSTEM_ALERT_STARTED';
export const GET_SYSTEM_ALERT_SUCCESS = 'GET_SYSTEM_ALERT_SUCCESS';
export const GET_SYSTEM_ALERT_FAILURE = 'GET_SYSTEM_ALERT_FAILURE';

export const POST_SYSTEM_ALERT_STARTED = 'POST_SYSTEM_ALERT_STARTED';
export const POST_SYSTEM_ALERT_SUCCESS = 'POST_SYSTEM_ALERT_SUCCESS';
export const POST_SYSTEM_ALERT_FAILURE = 'POST_SYSTEM_ALERT_FAILURE';

export const DELETE_SYSTEM_ALERT_STARTED = 'DELETE_SYSTEM_ALERT_STARTED';
export const DELETE_SYSTEM_ALERT_SUCCESS = 'DELETE_SYSTEM_ALERT_SUCCESS';

export const PUT_SYSTEM_ALERT_SUCCESS = 'PUT_SYSTEM_ALERT_SUCCESS';
