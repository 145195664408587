import React from 'react';
import {  useDispatch } from 'react-redux';
import { cleanForm } from 'js/actions/formsAction';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
import { useEffect } from 'react';
const useStyle = makeStyles(styles);

function Hook(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {role} = props.location.state

  useEffect(() => {
    dispatch(cleanForm())
  }, [])

  return (
    <DashboardLayout title="Edicion de Rol">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel />
          </PortletHeader>
          <PortletContent noPadding>
            <FormStepper props={props} role={role}/>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default Hook;
