import * as preInvoiceGroupActionTypes from 'js/constants/preInvoiceGroup';
import axios from 'axios';
import { history } from 'helpers/history';
import Swal from 'sweetalert2';
import getToken from 'helpers/auth';
import { FileSaver, saveAs } from 'file-saver';
import FileDownload from 'js-file-download';

const getConfig = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  };
};


export const getConsumesLastMonth = (groupId,year,month) => {

  
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupId}/previousinvoice/year/${year}/month/${month}`,
        { headers: {
        'Content-Type': 'application/json' 
        }
        
        }
      )
      .then(response => {
        dispatch(getLastConsumes(response.data.data))
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getLastConsumes = lastConsumes => {
  return {
    type: preInvoiceGroupActionTypes.GET_LAST_GROUP_CONSUMES_SUCCESS,
    lastConsumes
  };
};

export const postPreviewFactor = ( reportData , fillCharges ) => {
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .post(
        `https://api.metimur.mx/api/preinvoicing/group/previewfactor`,
          {
            year: reportData.year,
            month: reportData.month,
            group_id: reportData.groupId,
            factor:reportData.factor
          },
        getConfig()
      )
      .then(response => {
        dispatch(postFactor(response.data)) 
        fillCharges();
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};


export const postFactor = preview => {
  return {
    type: preInvoiceGroupActionTypes.POST_PREVIEW_FACTOR_SUCCESS,
    preview
    
  };
};


 