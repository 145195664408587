export default theme => ({
  root: {},
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  title: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  }
})