import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'helpers/history';
import { showMetimurUsers, deleteUser } from 'js/actions/userActions';

import { useEffect } from 'react';
import { useState } from 'react';
import MaterialTable from 'material-table';
import { isAllowed } from 'helpers/auth';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';

export default function RoleTable(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const { corpid } = props;
  const user = useSelector(state => state.user.user);
  const users = useSelector(state => state.user.users);

  const deleteUserData = user => {
    Swal.fire({
      title: `${intl.formatMessage({id:'app.groupsCards.deleteGroupBtn.title'})} `,
      text: `${intl.formatMessage({id:'app.groupsCards.deleteGroupBtn.text'})} ${corpid.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.confirmation'})
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.titleConfirmation'}),
          text: intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.textConfirmation'}),
          icon: 'success'
        });
        dispatch(deleteUser(user))
      }
    });
  };

  const [state, setState] = useState({
    columns: [
      {
        field: 'firstname',
        title: intl.formatMessage({id: 'app.metusers.grid.colFirstName'})
      },
      {
        field: 'lastname',
        title: intl.formatMessage({id: 'app.metusers.grid.colLastName'})
      },
      {
        field: 'role_name',
        title: intl.formatMessage({id: 'app.metusers.gird.colRolName'})
      },
      {
        field: 'tel',
        title: intl.formatMessage({id: 'app.metusers.grid.colTel'})
      },
      {
        field: 'email',
        title: intl.formatMessage({id: 'app.metusers.grid.colEmail'})
      }
    ]
  });

  useEffect(() => {
    dispatch(showMetimurUsers());
  }, []);

  useEffect(() => {
    setState({ ...state, data: users });
  }, [users]);

  const permissionsActions = () => {
    const actions = [];

    if (isAllowed(user.permissions, ['metimur_user_delete'])) {
      actions.push({
        icon: 'delete',
        tooltip: intl.formatMessage({id: 'app.metusers.grid.colAction.delete'}),
        onClick: (event, rowData) => deleteUserData(rowData)
      });
    }

    if (isAllowed(user.permissions, ['metimur_user_update'])) {
      actions.push({
        icon: 'edit',
        tooltip: intl.formatMessage({id: 'app.metusers.grid.colAction.edit'}),
        onClick: (event, rowData) => {
          history.push({
            pathname: `/updateAdminUser/${rowData.id}`,
            state: { user: rowData }
          });
        }
      });
    }

    return actions;
  };

  return (
    <div>
      <MaterialTable
        actions={permissionsActions()}
        columns={[
          {
            field: 'firstname',
            title: intl.formatMessage({ id: 'app.metusers.grid.colFirstName' })
          },
          {
            field: 'lastname',
            title: intl.formatMessage({ id: 'app.metusers.grid.colLastName' })
          },
          {
            field: 'role_name',
            title: intl.formatMessage({ id: 'app.metusers.gird.colRolName' })
          },
          {
            field: 'tel',
            title: intl.formatMessage({ id: 'app.metusers.grid.colTel' })
          },
          {
            field: 'email',
            title: intl.formatMessage({ id: 'app.metusers.grid.colEmail' })
          }
        ]}
        data={state.data}
        options={{
          showTitle: false,
          actionsColumnIndex: -1
        }}
        title={'Usuarios'}
      />
    </div>
  );
}
