import React   from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  List,
  ListItemText,
  ExpansionPanel,
  Grid
} from '@material-ui/core';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import  CorporativeCard  from '../CorporativeCard';
import { getUserAlertsCns } from 'js/actions/userAlertsAction';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {    attendAlert } from 'js/actions/userAlertsAction'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  dangerHeading: {
    backgroundColor: 'red'
  },
  dangerText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '900',
    color: '#fff'
  },

  alertHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: '#EAAB36',
    color: '#fff'
  },

  alertText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '900'
  },

  nameHeading: {
    width: '100%'
  }
}));






export default function SimpleExpansionPanel(props) {
  const classes = useStyles();
  const data = useSelector(state => state.userAlert.userAlerts.messages );
  const dispatch = useDispatch();
  const {chargeId} = props.ids;

  const alert_prior=[ 'BAJO' , 'MEDIO' , 'ALTO' ];

 

const columns = [
  {
    title: 'Fecha',
    field: 'created_at',
    headerStyle: {
      textAlign: 'left',
    },    
    cellStyle: {
      fontWeight: '900',
      fontSize: '1em',
      textAlign: 'left',      
      width: '15%'
    }
  },
  {
    title: 'Descripción',
    field: 'description',

    cellStyle: {     
      textAlign: 'left',
      fontWeight: '500',
      width: '30%',
      fontSize: '.95em'
    }
  },
  {
    title: 'Nivel',
    field: 'lvl',
    cellStyle: {
      textAlign: 'left',
      fontWeight: '500',
      width: '15%',
      fontSize: '.95em'
    } ,
    render: rowData => {
         return  alert_prior[ rowData.lvl-1]+ " "  
    }
  },
  {
    title: 'Ubicación',
    field: 'ubication',
    cellStyle: {
      textAlign: 'left',
      fontWeight: '500',
      width: '40%',
      fontSize: '.95em'
    }
  } 
  
];


const actions=[
  {
    icon: ClearIcon,
    tooltip: 'Marcar alerta como atendida',
  
    onClick: (event, rowData) => {
        console.log(rowData.description);
        markAttended(rowData);
    }     


  } 
];


const markAttended = (alert) =>{
  console.log("se atendera alerta "+ alert.description );
  dispatch(attendAlert(alert.id , chargeId ));
}
 

const showAlertTable= () =>{

   return (

    <div container className={classes.root}>
      <MaterialTable
        columns={columns}
        actions={actions}
        data={data}   
        title="ALERTAS ENCONTRADAS"
        options={{         
          search: true,
          sorting:true,
          toolbar: true,
          paging:true,
          padding:10,        
          headerStyle:{  padding:5}

        }}
      />
    
      </div>

   );
  

}


  

  return (
    <div className={classes.root}>



      <div>
        {
            showAlertTable()            
        }
        
              
      </div> 






    </div>
  );
}
