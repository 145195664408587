import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image
} from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell
} from '@david.kucsai/react-pdf-table';

import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { useSelector } from 'react-redux';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Medium.ttf', fontWeight: 500 },
    { src: '/fonts/Roboto-Thin.ttf', fontWeight: 100 },
    { src: '/fonts/Roboto-Light.ttf', fontWeight: 300 },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/Roboto-Black.ttf', fontWeight: 900 }
  ]
});

const style = StyleSheet.create({
  page: {
    flexDirection: 'column'
  },
  section: {
    margin: 5
  },
  titles: {
    top: 20,
    fontFamily: 'Roboto',
    fontWeight: 900
  },
  section_title: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#840c74',
    fontSize: '12pt',
    paddingTop: '5pt',
    paddingLeft: '5pt',
    border: '3pt solid #840c74'
  },
  section_data: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    fontSize: '10pt',
    textAlign: 'left',
    width: '70%',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  section_values: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    fontSize: '10pt',
    textAlign: 'left',
    width: '30%',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  total_billing: {
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: '16pt',
    marginTop: '10pt'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  details: {
    fontSize: 8,
    marginTop: 10,
    textAlign: 'center',
    color: 'grey'
  },
  logoFooter: {
    position: 'absolute',
    bottom: 35,
    marginVertical: 15,
    marginHorizontal: 245,
    width: '4cm'
  }
});

export default function() {
  const receipt = useSelector(state => state.chargeDashboard.receipt.data);

  console.log(receipt)

  return (
    <div>
      <PDFViewer
        width="612px"
        height="792px"
        style={{ margin: '0 auto', display: 'block' }}>
        <Document>
          <Page size="LETTER" orientation="portrait" style={style.page}>
            <Text
              style={
                (style.titles, { textAlign: 'center', paddingTop: '1cm' })
              }>
              {receipt.charge_info.name}
            </Text>

            <View style={{ paddingTop: '2mm' }}>
              <View style={style.section}>
                <Text style={style.section_title}>ENERGIA ELECTRICA</Text>
                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Medicion: {receipt.charge_info.name}</Text>
                    {/* <Text>
                      Periodo de Consumo :{' '}
                      {receipt.consumes.billing_period.start}{' '}
                    </Text> */}
                  </View>

                  <View style={style.section_values}>
                    <Text>Tarifa: {receipt.consumes.billing.recipe_type}</Text>
                    {/* <Text>
                      {receipt.consumes.billing_period.start} -{' '}
                      {receipt.consumes.billing_period.end}
                    </Text> */}
                  </View>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Consumo</Text>
                    <Text>
                      Demanda Maxima del Mes por uso Red de distribucion{' '}
                    </Text>
                    <Text>Demanda Maxima en Punta (CAPACIDAD) </Text>
                  </View>

                  <View style={style.section_values}>
                    {/* <Text>{receipt.consumes.total_consume_kwh} kWh</Text>
                    <Text>
                      {Math.round(receipt.consumes.distribution * 100) / 100} kW
                    </Text>
                    <Text>
                      {(Math.round(receipt.consumes.capacity) * 100) / 100} kW
                    </Text> */}
                  </View>
                </View>

                {/* CONCEPTOS */}

                <Text style={style.section_title}>FACTURACION</Text>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Cargos por Consumo ** </Text>
                    <Text>
                      Cargos Fijos{' '}
                    </Text>
                    <Text>
                      Cargos por Medicion en Baja Tension y Monitoreo{' '}
                    </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>
                      {' '}
                      ${' '}
                      {receipt.consumes.total_consume_kwh * receipt.cfe.cargo_energia }
                    </Text>
                    <Text>
                      {' '}
                      ${' '}
                      {receipt.consumes.billing.flat_fee }{' '}
                    </Text>
                    <Text>
                      {' '}
                      ${' '}
                      {receipt.consumes.billing.monitoring_fee}{' '}
                    </Text>
                  </View>
                </View>

                <Text style={style.section_title}>TOTALES</Text>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Total Facturado</Text>
                    <Text>IVA</Text>
                    <Text style={style.total_billing}>Total a Pagar </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>
                      {' '}
                      ${' '}
                      {receipt.consumes.billing.subtotal}
                    </Text>
                    <Text> $ {receipt.consumes.billing.iva}</Text>
                    <Text style={style.total_billing}>
                      {' '}
                      $ {receipt.consumes.billing.total}{' '}
                    </Text>
                  </View>
                </View>

                {/* <Text style={style.section_title}>FECHAS</Text> */}

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    {/* <Text>Fecha de Corte</Text>
                    <Text>Fecha de Vencimiento</Text> */}
                  </View>

                  <View style={style.section_values}>
                    {/* <Text>{receipt.consumes.billing_period.cutoff}</Text>
                    <Text>{receipt.consumes.billing_period.expiration}</Text> */}
                  </View>
                </View>
              </View>
            </View>

            { }
          
            <Image
              style={style.logoFooter}
              source="/images/b_w.png"
              cache={false}
            />
            <Text style={style.pageNumber}>
              {`www.metimur.mx     Tecnología de Submedición de Energía    contacto@metimur.mx`}
            </Text>
          </Page>
        </Document>
      </PDFViewer>
      <Link
        to={{
          pathname: `/invoice`,
          state:{
           receipt 
          }
        }}
        // onClick={() => setAdminMeterId(meter.id)}
      >
        <Button variant="contained" color="secondary" style={{ width: '100%' }}>
          FACTURAR
        </Button>
      </Link>
    </div>
  );
}
