import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, Button, Typography } from '@material-ui/core';

// Shared components
import { DisplayMode, SearchInput } from 'components';

// Component styles
import styles from './styles';

class ProductsToolbar extends Component {
  render() {
    const { classes, className, corpData, meterData } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <Grid
          container
          direction="row"
        >
          <Grid
            item
            sm={6}
            md={4}
          >
            <span className={classes.spacer} />
            <Typography
              className={classes.hola}
              variant="h3"
            >
              {corpData.name}
            </Typography>

            <span className={classes.spacer} />
            <Typography
              className={classes.hola}
              variant="body1"
            >
              {corpData.market_segment}
            </Typography>

            <span className={classes.spacer} />
            <Typography
              className={classes.hola}
              variant="body1"
            >
              {corpData.city}
            </Typography>

            <span className={classes.spacer} />
            <Typography
              className={classes.hola}
              variant="body1"
            >
              {corpData.state}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            md={4}
          >
            <img
              alt="corp-logo"
              className={classes.imgLogo}
              src={corpData.logo}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={4}
          >
            <span className={classes.spacer} />
            <Typography className={classes.hola2} variant="h3"> {meterData.name}</Typography>

            <span className={classes.spacer} />
            <Typography className={classes.hola2} variant="body1">{meterData.market_segment}</Typography>

            <span className={classes.spacer} />
            <Typography className={classes.hola2} variant="body1">{meterData.city}</Typography>

            <span className={classes.spacer} />
            <Typography className={classes.hola2} variant="body1">{meterData.state}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ProductsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
