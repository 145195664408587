import * as chargeActionTypes from '../constants/chargeAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function chargeReducer(
  state = { charges: [], charge: [], voltage: [], loading: true, chargeOwner: null },
  action
) {
  switch (action.type) {
    case chargeActionTypes.POST_CHARGE_STARTED:
      return {
        ...state
      };
    case chargeActionTypes.POST_CHARGE_SUCCESS:
      return {
        ...state,
        cards: state.charges.push(action.payload)
      };
    case chargeActionTypes.POST_CHARGE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case chargeActionTypes.GET_CHARGE_STARTED:
      return {
        ...state,
        loading: true
      };
    case chargeActionTypes.GET_CHARGE_SUCCESS:
      return {
        ...state,
        charges: action.charges,
        loading: false
      };

    case chargeActionTypes.GET_CHARGE_FAILURE:
      return {
        ...state, 
        charges:[] ,
        error: action.error,
        loading: false
      };

    case chargeActionTypes.GET_CHARGE_INVOICE_SUCCESS:
      return {
        ...state,
        charge: action.charge
      }

    case chargeActionTypes.GET_CHARGE_TMP_SUCCESS:
      return {
        ...state,
        charge: action.chargeTmp
      };
    case chargeActionTypes.DELETE_CHARGE_SUCCESS:
      return {
        ...state,
        charges: state.charges.filter(({ id }) => id !== action.payload.id)
      };

      //  VOLTAGE

    case chargeActionTypes.POST_VOLTAGE_STARTED:
      return {
        ...state
      };
    case chargeActionTypes.POST_VOLTAGE_SUCCESS:
      return {
        ...state,
        voltage: state.voltage.push(action.payload)
      };
    case chargeActionTypes.POST_VOLTAGE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case chargeActionTypes.GET_VOLTAGE_SUCCESS:
      return {
        ...state,
        voltage: action.voltage
      };
    case chargeActionTypes.GET_CHARGE_OWNER_FAILURE:
      return {
        ...state,
        chargeOwner: null
      };
    case chargeActionTypes.GET_CHARGE_OWNER_SUCCESS:
      return {
        ...state,
        chargeOwner: action.chargeOwner
      };
    case chargeActionTypes.UPDATE_CHARGE_OWNER_SUCCESS:
      return {
        ...state,
        chargeOwner: action.newName
      };
    default:
      return state;
  }
}

export default chargeReducer;
