/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';
import Swal from 'sweetalert2'

import {
  Grid,
  makeStyles,
  Button as ButtonMaterial,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  CircularProgress
} from '@material-ui/core';

import { GroupCard } from '../index';
import { showGroups, deleteGroup } from 'js/actions/groupActions';
import { isAllowed } from 'helpers/auth';

import { getConsumesLastMonth } from 'js/actions/preInvoiceGroupAction';

import styles from './styles';
import { useIntl } from 'react-intl';
import { Close } from '@material-ui/icons';
const useStyles = makeStyles(styles);

export default function GridGroup(props) {
  const intl = useIntl()
  const { meter } = props;
  const dispatch = useDispatch();
  let groups = useSelector(state => state.groups.groups);
  let groupsStatus = useSelector(state => state.groups.loading);
  const user = useSelector(state => state.user.user);
  const corpAdmin = useSelector(state=>state.user.corporative);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id;
  let groupsState = useSelector(state => state.groups);
  let isLoading = groupsStatus;

  const year = new Date().getFullYear();
  const getLastMonth = () => {
    let month = new Date().getMonth() + 1;

    return month - 1;
  };


  useEffect(() => {
    dispatch(showGroups(meter))
  }, []);

  const deleteGroupData = (groupId, meter) => {
    Swal.fire({
      title: `${intl.formatMessage({id:'app.corporate.grid.btnDelete.title'})} `,
      text: `${intl.formatMessage({id:'app.corporate.grid.btnDelete.text'})} ${groupId.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'app.corporate.grid.btnDelete.confirmation'})
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({id: 'app.corporate.grid.btnDelete.titleConfirmation'}),
          text: intl.formatMessage({id: 'app.corporate.grid.btnDelete.textConfirmation'}),
          icon: 'success'
        });
        dispatch(deleteGroup(groupId.id, meter));
      }
    });
  };

  const getConsumesLastMonthData = (groupId, year, month) => {
    dispatch(getConsumesLastMonth(groupId,year,month))
  }

  const [showEditButton, setShowEditButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showInvoiceButton, setShowInvoiceButton] = useState(false);

  const getEditButton = group => {
    if (showEditButton == false) {
      return <div />;
    } else {
      return (
        <Link
          to={{
            pathname: `/updateGroup/${group.id}`,
            state: { group: group }
          }}
        >
          <ButtonMaterial
            color="secondary"
            variant="outlined"
          >
            {intl.formatMessage({id: 'app.groupsCards.editGroupBtn'})}
          </ButtonMaterial>
        </Link>
      );
    }
  };

  const getDeleteButton = (groupId, meter) => {
    if (showDeleteButton == false) {
      return <div />;
    } else {
      return (
        <ButtonMaterial
          onClick={() => deleteGroupData(groupId, meter)}
          style={{border: '1px solid #D64539', color: '#D64539'}}
          variant="outlined"
        >
          {intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn'})}
        </ButtonMaterial>
      );
    }
  };

  const getInvoiceButton = group => {
    if (showInvoiceButton == false) {
      return <div />;
    } else {
      return (
        <Link
          to={{
            pathname: `/preinvoicegroup/${group.id}`,
            state: { group: group }
          }}
        >
          <ButtonMaterial 
            color="secondary" 
            onClick={() => getConsumesLastMonthData(group.id,year,getLastMonth())} 
            variant="outlined"
          >
            {intl.formatMessage({id: 'app.groupsCards.preInvBtn'})}
          </ButtonMaterial>
        </Link>
      );
    }
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {groups.length > 0 ? (
            <Grid
              container
              spacing={2}
            >
              {groups
                .sort((a, b) =>
                  a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
                )
                .map(group => (
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <GroupCard
                      deleteButton={showDeleteButton}
                      editButton={showEditButton}
                      group={group}
                      item
                      key={group.id}
                      meter={meter}
                      setSelectedGroup={setSelectedGroup}
                      setShowDetails={setShowDetails}
                    />

                    <div>
                      {getEditButton(group)}
                      {getDeleteButton(group, group.meter_id)}
                      {getInvoiceButton(group)}
                    </div>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Typography
                  style={{ textAlign: 'center' }}
                  variant="h2"
                >
                  {intl.formatMessage({id: 'app.groupsCards.noGroupsMsg'})}{' '}
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Link >
                  <ButtonMaterial
                    color="secondary"
                    style={{ margin: '0 auto', display: 'block' }}
                    variant="outlined"
                  >
                    {intl.formatMessage({id: 'app.groupsCards.createGroupBtn'})}
                  </ButtonMaterial>
                </Link>
              </Grid>
            </Grid>
          )}
          <Dialog
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            onClose={()=>setShowDetails(false)}
            open={showDetails}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={()=>setShowDetails(false)}
            >
              <Box style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
                <Typography variant="h6">{selectedGroup ? selectedGroup.name : ''}</Typography>
                <IconButton
                  aria-label="close"
                  onClick={()=>setShowDetails(false)}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              dividers
              style={{marginBottom: '3rem'}}
            >
              {selectedGroup ? 
                <Box style={{display:'flex', flexDirection:'column', gap: '1rem', alignItems: 'center'}} >
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  >
                    <Typography style={{fontWeight: 500}}>
                      {intl.formatMessage({id: 'app.groupsCards.editGroup.description'})}
                    </Typography>
                    {selectedGroup.description}
                  </Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  >
                    <Typography style={{fontWeight: 500}}>
                      {intl.formatMessage({id: 'app.groupsCards.editGroup.monitChargeLbl'})}
                    </Typography>
                    {selectedGroup.monitoring_fee}
                  </Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  >
                    <Typography style={{fontWeight: 500}}>
                      {intl.formatMessage({id: 'app.groupsCards.editGroup.fixedChargeLbl'})}
                    </Typography>
                    {selectedGroup.flat_fee}
                  </Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  >
                    <Typography style={{fontWeight: 500}}>
                      {selectedGroup.is_custom_tariff !== 0 ? intl.formatMessage({id: 'app.groupsCards.editGroup.rateTypeLbl'}) : intl.formatMessage({id: 'app.groupsCards.editGroup.customRateLbl'})}
                    </Typography>
                    {selectedGroup.is_custom_tariff !== 0 ? selectedGroup.rate_type : selectedGroup.custom_tariff}
                  </Typography>
                </Box>
                :
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height: '50vh'}}>
                  <CircularProgress />
                </div>
              }
            </DialogContent>
          </Dialog>
        </>
      )}
      <Container
        styles={{ marginRight: '-20px', marginBottom: '-20px', zIndex: 900 }}
      >
        {/* <Button
          icon="fas fa-file-invoice"
          onClick={() => setShowInvoiceButton(!showInvoiceButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.groupsCards.preInvBtn.toolTip'})}
        />
        {isAllowed(user.permissions, ['group_delete']) && (
          <Button
            icon="fa fa-trash"
            onClick={() => setShowDeleteButton(!showDeleteButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn.toolTip'})}
          />
        )}

        {isAllowed(user.permissions, ['group_update']) && (
          <Button
            icon="fa fa-pencil-alt"
            onClick={() => setShowEditButton(!showEditButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.editGroupBtn.toolTip'})}
          />
        )} */}

        {isAllowed(user.permissions, ['group_create']) && (
       
          <LinkFloat
            href={`/newGroup/${corporativeId}/${meter}`}
            icon="fa fa-plus"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.addGroupBtn.toolTip'})}
          />
          
        )}
        <Link
          //key={notification.id}
          to={`/hardwareConfiguration/${meter}`}
        >
          <LinkFloat
            href={`/hardwareConfiguration/${meter}`}
            icon="fab fa-hubspot"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.confHardwarepBtn.toolTip'})}
          />
        </Link>
        <Button
          icon="fa fa-cog"
          rotate
          styles={{
            backgroundColor: '#C69100',
            color: '#fff',
            width: '80px',
            height: '80px',
            fontSize: '1.5em'
          }}
        />
      </Container>
    </div>
  );
}
