import React from 'react';
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function ConsumptionAndDemandChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const {className, consumes, ...rest } = props;

  // const object = [
  //    ['kw',consumes.bas_kw,consumes.int_kw,consumes.punt_kw],
  //    ['kwh',consumes.bas_kwh,consumes.int_kwh,consumes.punt_kwh]
  // ]

  const dataConsumes=[  ]
 
  consumes.consumes.forEach(element=>{
    dataConsumes.push((element).filter( e=> e!=='kW' && e!=='kWh'))
  })  

  

  const data = {
    labels: [intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.base'}), intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.interm'}), intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.tip'})],
    datasets: [
      {
        label: ' kW',
        yAxisID: 'A',
        backgroundColor: ['#8CBA42', '#ebc057', '#ED685E'],
        data: dataConsumes[0]
      },
      {
        label: ' kWh',
        yAxisID: 'B',
        backgroundColor: ['#3A5F0B', '#FFCC00', 'red'],
        data: dataConsumes[1]
      }
    ]
  };

  const options = { responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: { top:20 }  },
    scales: {
      xAxes: [
        { 
          
          barPercentage: 0.9,
          categoryPercentage: 0.8,
          ticks: {
            fontColor: palette.text.secondary,
            fontSize: 16,
            padding: 20
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position:'left',
          ticks: {
            fontColor: '#730065',
            beginAtZero: true,
            min: 0,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        },
        {
          id: 'B',
          position:'right',
          ticks: {
            fontColor: '#0080BC',
            beginAtZero: true,
            min: 0,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'top';
        ctx.font = '16px Lato, Helvetica Neue, Arial, 700';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            if( data ){
              ctx.fillText(data.replace(/\B(?=(\d{3})+(?!\d))/g, ','), bar._model.x, bar._model.y -20 );
            }

          });
        });
      }
    }

  };


  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({id: 'app.chargeSolar.board.demandAndConsumpChart.title'})} />
        <PortletToolbar>
          {/*  */}
        </PortletToolbar>
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Bar
            data={data}
            options={options}
          />
          <div
            className={classes.labelCharts}
            style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
          >
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingRight: '4rem'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
          </div>
            
        </div>
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}
