export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    backgroundColor:'#EAAB36'
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 700,
    fontSize:'1em',
    color: theme.palette.common.white
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4em',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4em'
  },
  icon: {
    color: '#EAAB36',
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing.unit
    }
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.success.dark,
    display: 'inline-flex',
    fontWeight: 700
  },
  caption: {
    color: '#fff'
  }
});
