/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getChargeWithMostConsume} from 'js/actions/meterDashboardActions'


// Externals
import classNames from 'classnames';

// Material components
import { Chip, makeStyles } from '@material-ui/core';


// Shared components
import {
  Portlet,
  PortletContent,
} from 'components';

import {Typography } from '@material-ui/core';



// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)



export default function ChargeWithMostConsumeChart(props) {
  const intl = useIntl()
  // console.log('ChargeWithMostConsumeChart');
  const classes = useStyles()
  const dispatch = useDispatch()
  const chargeMostConsume = useSelector(state=>state.meterDashboard.chargeMostConsume)
  
  const {ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const year = new Date().getFullYear()
  const {corpId, meterId} = ids

  const getCurrentMonth = () => {
    let month =  new Date().getMonth() + 1;
    if ( month<10) {return '0'+month} else {
      return month
    } 
  }
  
  

  useEffect(()=>{     
    dispatch(getChargeWithMostConsume(corpId,meterId))
  },[])

  
  if( chargeMostConsume ){
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
   
        <PortletContent   >
          <Chip label={intl.formatMessage({id: 'app.meter.board.chargeHighestConsumMonth.title'})} className={classes.title}/> 
          <div className={classes.device}>
            <Typography variant="h5">{intl.formatMessage({id: 'app.meter.board.chargeHighestConsumMonth.charge'})}</Typography>
            <Typography
              style={{ fontWeight: 'normal' }}
              variant="h4"
            >
              {chargeMostConsume.charge_name}
            </Typography>
          </div>     
          <div className={classes.device}>
            <Typography variant="h5">{intl.formatMessage({id: 'app.meter.board.chargeHighestConsumMonth.group'})}</Typography>
            <Typography
              style={{ fontWeight: 'normal' }}
              variant="h4"
            >
              {chargeMostConsume.group_name}
            </Typography>
          </div>                             
          <div className={classes.device}>
            <Typography variant="h5">{intl.formatMessage({id: 'app.meter.board.chargeHighestConsumMonth.consumption'})}</Typography>
            <Typography
              style={{ color: '#730065' }}
              variant="h4"
            >
              {chargeMostConsume.total_kwh.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')   } kWh
            </Typography>
          </div>

          <div className={classes.device}>
            <Typography variant="h5">{intl.formatMessage({id: 'app.meter.board.chargeHighestConsumMonth.amount'})}</Typography>
            <Typography
              style={{ color: '#45B880' }}
              variant="h4"
            >
               $ {chargeMostConsume.total_money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')   }
            </Typography>
          </div>                    
                     
                    
        </PortletContent>
                
      </Portlet>
    );

  }else {
    return null;
  }
}








