export default theme => ({
  topbar: {
    position: 'fixed',
    width: '98%',
    top: 0,
    right: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '100 !important',
    [theme.breakpoints.down('sm')]:{
      width: '80%'
    },
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  },
  topbarShift: {
    marginLeft: '200px',
    width: 'calc(-210px + 100vw)'
  },
  sidebar: {
    width: '100%',
    backgroundColor: 'transparent'
  },
  content: {
    marginTop: '64px',
    position:'absolute',
    top:0,
    right:0,
    width: '96%',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  },
  contentShift: {
    marginLeft: '220px',
    width: 'calc(-230px + 100vw)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('md')]:{
      width: '80%'
    },
    [theme.breakpoints.down('sm')]:{
      width: '85%'
    }
  },
  drawerPaper:{
    backgroundColor: 'blue',
  }
});