import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dashboard as DashboardLayout } from 'layouts';
import MaterialTable from 'material-table';

import axios from 'axios';
import { history } from 'helpers/history';
import Swal from 'sweetalert2';
import getToken from 'helpers/auth';
import BlockUi from 'react-block-ui'; 

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  Paper
} from 'components';

// Material helpers
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  CircularProgress,
  Switch
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { updateDataMeter } from 'js/actions/meterActions';
import { makeStyles } from '@material-ui/styles';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';
import {
  postPreviewFactor,
  applyFactor
} from 'js/actions/preInvoiceGroupAction';
import useForm from 'react-hook-form';
import { PortletFooter } from 'components';
import { VerticalAlignBottom } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function MeterForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [calcCharges , setCalcCharges] = React.useState(null);
  const [aplicado , setAplicado] = React.useState(false);
  const [calculado , setCalculado] = React.useState(false);
  const [blocking, setBlocking] = useState();

  const { group } = props.location.state;

  //let charges = useSelector(state => state.charges.charges);
  const [charges , setCharges] = React.useState([]);

  let lastMonthConsumes = useSelector(
    state => state.preInvoiceGroup.lastMonthConsumes
  );
  let previewFactor = useSelector(
    state => state.preInvoiceGroup.previewInvoice
  );

  let {group_totals, charges_data } = lastMonthConsumes

  const year = new Date().getFullYear();
  const getLastMonth = () => {
    const month = new Date();

    const lastMonth = month.getMonth();

    switch (lastMonth - 1) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 4;
      case 4:
        return 5;
      case 5:
        return 6;
      case 6:
        return 7;
      case 7:
        return 8;
      case 8:
        return 9;
      case 9:
        return 10;
      case 10:
        return 11;
      case 11:
        return 12;
      default:
        break;
    }
  };

  const [open, setOpen] = useState(false);
  const [factor, setFactor] = useState(0);
  const [consumes, setConsumes] = useState({});

  const columns = [
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.measurmentCol'}),
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        MinWidth: '25%'
      }
    },
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.baseCol'}),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.intermediateCol'}),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.tipCol'}),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '25%',
        fontSize: '.95em'
      }
    }
  ];


  const columnsRecalc = [
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.measureCol'}),
      field: 'med',      
      cellStyle: {
        fontWeight: 'normal',
        width: '30%',
        fontSize: '1em',
        textAlign: 'left', 
         
      }
    },
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.totAmountCol'}),
      field: 'total',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {                 
        textAlign: 'center',
        fontWeight: 'normal',
        width: '15%',
        fontSize: '1em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.recalcAmountCol'}),
      field: 'total_calc', 
      headerStyle: {
        textAlign: 'center',
      },      
      cellStyle: {         
        
        textAlign: 'center',
        fontWeight: 'normal',
        width: '15%',
        fontSize: '1em'
      }
    } , 
    
    {
      title: intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.bilCol'}), 
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {       
        width: '10%',
        fontSize: '1em',
        textAlign: 'right'         
      } ,

      render: rowData => (

        <Switch
        //checked={this.state.delete_group}
        onChange={handleChangeStatFact}
        disabled={!rowData.is_billiable || rowData.invoiced   || !aplicado }
        //inputRef={register}        
        name="x_{{rowData.id}}"
        value={rowData.id}
        inputProps={{ 'aria-label': 'prefacture_delete' }}
      />


   
      )
    }		


  ];

  

  const handleChangeStatFact = (event, newValue) => {
    console.log(newValue); 
    console.log(event.currentTarget.value);
    let selId=event.currentTarget.value;
    for( var ii=0;ii< dataRecalc.length ; ii++ ) {
      console.log(dataRecalc[ii].id );
     
      if(dataRecalc[ii].id!=selId ){
        continue;
      }
    
      dataRecalc[ii].flagFact=newValue;
           
    }

    console.log(dataRecalc );


  };

  const data = consumes.consumes;
  const dataRecalc=[];

  useEffect(() => {
    dispatch(showChargeInit(group.id));
  }, []);


  useEffect(() => {
    fillCharges();
  }, [charges]);


  const showChargeInit = groupId => {
    return async dispatch => {
        //  dispatch (getChargeStarted())
      axios
        .get(
          `https://api.metimur.mx/api/group/${groupId}/invoicetotals`,
          {
            headers: {
              Authorization: 'Bearer ' + getToken()
            }
          }
        )
        .then(response => {
           //dispatch(getCharges(Object.values(response.data.data)));
           setCharges(response.data.data);
           console.log( response.data );
       //    console.log("Charges :");
         //  console.log( charges );
          // fijarCargas(response.data.data);
        })
        .catch(function(error) {      
          console.log('error:' + error);
          //dispatch(getChargesFail(error));
        });
    };
  };


  const fijarCargas = (cargas) => {
       setCharges(cargas);
       console.log("Charges :");
       console.log(charges)
  }
 

  useEffect(() => {
    setConsumes(
      {...consumes,
      consumes: [{
        med: 'kwh',
        bas: (group_totals == undefined ? 0 : group_totals.consumes.base.consume_kwh),
        int: (group_totals == undefined ? 0 : group_totals.consumes.intermedio.consume_kwh),
        punt: (group_totals == undefined ? 0 : group_totals.consumes.punta.consume_kwh)
      }, {
        med: 'kw',
        bas: (group_totals == undefined ? 0 : group_totals.consumes.base.demand_kw),
        int: (group_totals == undefined ? 0 : group_totals.consumes.intermedio.demand_kw),
        punt: (group_totals == undefined ? 0 : group_totals.consumes.punta.demand_kw)
      }]
    }
    );
  }, [lastMonthConsumes]);

  

  const handleOpen = () => {
   // setOpen(true);
  };

  const handleClose = () => {
   // setOpen(false);
  };

  const onSubmit = values => {
    setFactor(values.factor);
    const preview = {
      year: year,
      month: getLastMonth(),
      groupId: group.id,
      factor: values.factor
    };
    setCalcCharges(null);
    dispatch(postRecalculate(preview ));
    
  };


const postRecalculate = ( reportData  ) => {
    setBlocking(true); 
    return async dispatch => {
      //dispatch(getControlRateStarted())
      axios
        .post(
          `https://api.metimur.mx/api/preinvoicing/group/previewfactor`,
            {
              year: reportData.year,
              month: reportData.month,
              group_id: reportData.groupId,
              factor:reportData.factor
            },{
            headers: {
              Authorization: 'Bearer ' + getToken()
            }
          }
        )
        .then(response => {
          //dispatch(postFactor(response.data)) 
          setBlocking(false)
          setCalcCharges(response.data.data) ;
          setCalculado(true);
          console.log("calcCharges :");
          console.log(calcCharges);
          fillCharges();
        })
        .catch(function(error) {
          setBlocking(false)
          console.log('error:' + error);
        });
    };
  };


  const postFacturar = ( arrFacts  ) => {
    setBlocking(true); 
    return async dispatch => {     
      axios
        .post(
          `https://api.metimur.mx/api/invoice/multiinvoice`,
            {
              charge_ids:arrFacts              
            },{
            headers: {
              Authorization: 'Bearer ' + getToken()
            }
          }
        )
        .then(response => {
          setBlocking(false); 
          Swal.fire({
            title: 'Facturación',
            text:  'Las facturas se crearon correctamente' ,
            icon: 'success'
          });          
        })
        .catch(function(error) {
          setBlocking(false); 
          Swal.fire({
            title: 'Error',
            text: `${error.response.data.message}`,
            icon: 'error'
          });
        });
    };
  };



  const apply = () => {
    const applyValues = {
      year: year,
      month: getLastMonth(),
      groupId: group.id,
      factor: factor
    };


    Swal.fire({
      title: 'Aplicar',
      text: "Está seguro que desea aplicar el factor ? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí,Aplicar' , 
      cancelButtonText: 'Cancelar',
    }).then((result) => {       
       console.log( result  ) ;
      if (result.value==true ) {        
            dispatch(applyFactor(applyValues));
      }
    });






    
  };


  const applyFactor = reportData => {
    setBlocking(true); 
    return async dispatch => {
      //dispatch(getControlRateStarted())
      axios
        .post(
          `https://api.metimur.mx/api/preinvoicing/group/applyfactor`,
            {
              year: reportData.year,
              month: reportData.month,
              group_id: reportData.groupId,
              factor:reportData.factor
            },
            {
              headers: {
                Authorization: 'Bearer ' + getToken()
              }
            }
        )
        .then(response => {
          setBlocking(false); 
          console.log(response);
          setAplicado(true);
          Swal.fire({
            title: 'Facturación',
            text:  'Los cambios se aplicaron correctamente' ,
            icon: 'success'
          });
        })
        .catch(function(error) {         
          setBlocking(false); 
          Swal.fire({
            title: 'Facturación',
            text:  'Error al aplicar los cambios' ,
            icon: 'error'
          });
  
        });
    };
  };
  







  const facturar = () => {
     
    if( dataRecalc==null || dataRecalc.length<=0 ){
       return;
    }

    Swal.fire({
      title: 'Facturar',
      text: "La siguiente acción generará las facturas y CFDI`s correspondientes ante el SAT, desea continuar? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí,Facturar' , 
      cancelButtonText: 'Cancelar',
    }).then((result) => {       
       console.log( result  ) ;
      if (result.value==true ) {        
            dispatch(applyFacturar());
      }
    });
   
  };  


  const applyFacturar = () => {

    let arr_facts=[];
    for( var ii=0;ii< dataRecalc.length ; ii++ ) {
           
      if( dataRecalc[ii].flagFact ){
          arr_facts.push( dataRecalc[ii].id );
      }
            
    }

    console.log( arr_facts );
    if( arr_facts.length<=0 ){

      Swal.fire({
        title: 'Facturación',
        text:  'Debe seleccionar al menos una carga para facturar' ,
        icon: 'warning'
      });

       return;
    }

    console.log("se facturara");
    dispatch( postFacturar(arr_facts));

    
     
  };
  





  const fillCharges = () => {
    console.log("fillCharges ..");
    console.log( charges );
    let mapCharges= null;
    let totCalc=0;
    if(!charges){
      return;
    }

    if(  calcCharges &&  calcCharges.charges_data ) {

   
      console.log( calcCharges.charges_data );
       for( var ii=0;ii< calcCharges.charges_data.length ; ii++ ) {
            if( mapCharges==null ){
               mapCharges= new Object();
            }
            mapCharges[ calcCharges.charges_data[ii].id  ]=calcCharges.charges_data[ii];
       }

    
    }
      

    
    charges
    .sort((a, b) =>
      a.charge_name !== b.charge_name ? (a.charge_name < b.charge_name ? -1 : 1) : 0
    )
    .map(charge => (
        
      dataRecalc.push( {  id: charge.charge_id ,  med : charge.charge_name , total: charge.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") , 
          total_calc : '' ,  flagFact: false , is_billiable: charge.is_billiable , invoiced : charge.invoiced   } )
    ));



    if( mapCharges && dataRecalc && dataRecalc.length>0 ) {
      console.log( mapCharges );
      for( var ii=0;ii< dataRecalc.length ; ii++ ) {
           console.log(dataRecalc[ii].id );
           let chargeTmp=mapCharges[ dataRecalc[ii].id ];
           if(!chargeTmp){
             continue;
           }
           console.log("chargeTmp: ");
           console.log(chargeTmp);
          
           //dataRecalc[ii].total_calc=chargeTmp.total; 
           if( dataRecalc[ii].invoiced  ){
              dataRecalc[ii].total_calc="YA FACTURADA";
           } else{
              dataRecalc[ii].total_calc=chargeTmp.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");          
           }        
           
      }
   }
     


    
  };
  
  fillCharges();


  return (
    <DashboardLayout>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={`${intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.preInvoiceGrp'})} ${group.name}`} />
          </PortletHeader>
          <PortletContent noPadding>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={4}>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <div className={classes.fields}>
                    <div style={{ marginBottom: '20px' }}>
                      <Typography variant="h3">{intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.grpData.title'})}</Typography>
                      <Typography>{intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.grpData.name'})} {group.name} </Typography>
                      <Typography>{intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.grpData.descrip'})} {group.description} </Typography>
                      <Typography>
                      {intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.grpData.rateType'})} {group.rate_type}{' '}
                      </Typography>
                    </div>

                     
                    <div style={{ marginBottom: '10px' }}>
                    <Grid container spacing={4}>
                      <Grid item>
                        <Typography
                          className={classes.description}
                          variant="h3"
                          style={{ marginTop: '5px', color: 'carbon' }}>
                          {intl.formatMessage({id: 'app.groupsCards.preInvoice.grid.recalc.totalAmount'})}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.description}
                          variant="h3"
                          style={{
                            color: '#218A1D',
                            fontWeight: '600',
                            marginTop: '5px'
                          }}>
                          $ {group_totals == undefined ? 0 : group_totals.consumes.billing.total}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  
                    <div style={{ marginBottom: '30px' }}>
                     <MaterialTable
                        className={classes.table}
                        columns={columns}
                        data={data}
                        options={{
                          filtering: false,
                          search: false,
                          sorting: false,
                          toolbar: false,
                          paging: false,
                          padding: 0,
                          headerStyle: {
                            textAlign: 'center',
                            padding: '0',
                            fontSize: '1.1em',
                            fontWeight: '900',
                            minWidth: '25%'
                          }
                        }}
                      /> 
                    </div>
                  </div>

              
                  </Grid>


                  
                <Grid   item lg={12} md={12} xl={12} xs={12}  >

                        <Typography  style={{ marginBottom: '20px' , marginTop: '10px' }}    variant="h3">{intl.formatMessage({id: 'app.groupsCards.preInvoice.billLbl'})}</Typography>

                        <BlockUi   blocking={blocking}>



                        <Grid   spacing={1}   lg={12} md={12} xl={12} xs={12}      container     >


                 
                        <Grid item   lg={3} md={3} xl={3} xs={12} >
                        <TextField  
                          
                          className={classes.textField}
                          
                          inputRef={register}
                          label={intl.formatMessage({id: 'app.groupsCards.preInvoice.changeFactorLbl'})}
                          name="factor"
                          required
                          variant="outlined"
                        />
                     </Grid>

                              
                     <Grid item       >
              <Button  
                 className={classes.signUpButton}
                color="secondary"
                disabled={aplicado}
                //onClick={this.handleSubmit}                   
                type="submit"
                size="large"
                variant="contained">
                {intl.formatMessage({id: 'app.groupsCards.preInvoice.recalcBtn'})}
              </Button>
                 </Grid>
                   
         
               
                 <Grid item      >
                  <Button
                  className={classes.signUpButton}
                  color="secondary"
                  disabled={!calculado || aplicado }
                  onClick={() => apply()}
                  //type="submit"
                  size="large"
                  variant="contained">
                  {intl.formatMessage({id: 'app.groupsCards.preInvoice.applyFactBtn'})}
                </Button>
                </Grid>
               
          
                
            

                                         
                          
                          
                          
                          
                          
                        </Grid>  

                    
 
 
          
 


                   


                        </BlockUi>    

                </Grid>                

                <Grid item lg={12} md={12} xl={12} xs={12}>
                    <BlockUi   blocking={blocking}>                
                    <MaterialTable
                        className={classes.table}
                        columns={columnsRecalc}
                        data={dataRecalc}
                        options={{
                          filtering: false,
                          search: false,
                          sorting: false,
                          toolbar: false,
                          paging: false,
                          padding: 0,
                          headerStyle: {                           
                            padding: '0',
                            fontSize: '1.1em',
                            fontWeight: '900',
                            minWidth: '25%'
                          }
                     
                        }}
                      />
                     </BlockUi>
                </Grid>    
               

               

 
 

              </Grid>
            </form>
          </PortletContent>
        </Portlet>

      
      </div>
    </DashboardLayout>
  );
}
