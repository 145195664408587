export default theme => ({
    root: {
        width: '90%',
        margin: ' 0 auto'
      },
      backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
})
