/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as dashboardChargeActionTypes from 'js/constants/chargeDashboard-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { Receipt } from '@material-ui/icons';
import Swal from 'sweetalert2';

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getGeneralConsume = (chargeId,year,month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeId}/totals`,
        getconfig()
      )
      .then(response => {
        dispatch(generalConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const generalConsume = generalConsume => {
  return {
    type: dashboardChargeActionTypes.GET_GENERAL_CONSUMES_SUCCESS,
    generalConsume
  };
};


export const getMonthWithMostConsume = (ids,year) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/concentrator/${ids.concentratorId}/charge/${ids.chargeId}/consumes/monthmostconsume/${year}`,
        getconfig()
      )
      .then(response => {
        dispatch(monthWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const monthWithMostConsume = chartMonthMostConsume => {
  return {
    type: dashboardChargeActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS,
    chartMonthMostConsume
  };
};



export const getDayWithMostConsume = (ids,year,month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/concentrator/${ids.concentratorId}/charge/${ids.chargeId}/consumes/daymostconsume/${year}/month/${month}`,
        getconfig()
      )
      .then(response => {
        dispatch(dayWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const dayWithMostConsume = chartDayMostConsume => {
  return {
    type: dashboardChargeActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS,
    chartDayMostConsume
  };
};


export const getComparativeYearConsume = (ids,year) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/concentrator/${ids.concentratorId}/charge/${ids.chargeId}/consumes/comparativeyearconsume/${year}`,
        getconfig()
      )
      .then(response => {
        dispatch(comparativeYearConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const comparativeYearConsume = chartComparativeYearConsume => {
  return {
    type: dashboardChargeActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS_CHARGE,
    chartComparativeYearConsume
  };
};


export const getGeneralReport = (ids, year,month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/concentrator/${ids.concentratorId}/charge/${ids.chargeId}/consumes/reportdetail/${year}/month/${month}`,
        getconfig()
      )
      .then(response => {
        dispatch(generalReport(response.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const generalReport = generalReport => {
  return {
    type: dashboardChargeActionTypes.GET_GENERAL_REPORT_SUCCESS,
    generalReport
  };
};

/*
export const getReceipt = (id,year,month) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${id}/receipt/year/${year}/month/${month}`,
        getconfig()
      )
      .then(response => {
        dispatch(receipt(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };

} */



export const getReceipt = (id,year,month) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/charge/receipt',   
        {
          'id' : id ,
          'year' : year ,
          'month' : month  
        },
        getconfig()
      )
      .then(response => {
        dispatch(receipt(response.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };

}

export const getConsumtionAndWeight = (chargeId , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeId}/billing`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};




export const receipt = receipt => {
  return {
    type: dashboardChargeActionTypes.GET_RECEIPT_SUCCESS,
    receipt
  };
};


export const getVoltage = (charge_Id, funcResp) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/charge/voltagevalidation',
        {
          'charge_id':charge_Id,
        },
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data)
      })
      .catch(function(error){
        console.log('error:' + error)
      });
  };
};

export const getHistoricalReport = chargeId => {
  return async dispatch => {
    dispatch(getHistoricalReportStarted())
    axios
      .get(
        `https://api.metimur.mx/api/charge/${chargeId}/reportChargeMonth`,
        getconfig()
      )
      .then(response => {
        dispatch(getHistoricalReportSuccess(response.data))
      })
      .catch(function(error){
        dispatch(getHistoricalReportFailure(error.message));
        console.log('Error en Reporte de Historico:' + error)
      })
  }
}

export const getHistoricalReportStarted = () => ({
  type: dashboardChargeActionTypes.GET_HISTORICAL_REPORT_STARTED
})


export const getHistoricalReportSuccess = historicalReport => {
  return {
    type: dashboardChargeActionTypes.GET_HISTORICAL_REPORT_SUCCESS,
    historicalReport
  }
}

export const getHistoricalReportFailure= (error) => {
  return {
    type: dashboardChargeActionTypes.GET_HISTORICAL_REPORT_FAILURE,
    state: { error }
  }
}

// export const get5MinRecordsVoltage = (startDate, endDate, concentrator_id, chargeId, funcResp ) => {
//   return async dispatch => {
//     axios
//       .post(
//         'https://api.metimur.mx/api/charge/5minutalvoltage',
//         {
//           'concentrator_id' : concentrator_id,
//           'charge_id' : chargeId,
//           'start_date' : startDate,
//           'end_date' : endDate
//         },
//         getconfig()
//       )
//       .then(response => {
//         funcResp(response.data.data, true);
//       })
//       .catch(function(error){
//         console.error('error' + error)
//       });
//   }
// }

// export const get5MinRecords = (startDate , endDate, concentrator_id, charge_id, funcResp ) => {
//   return async dispatch => {
//     axios
//       .post(
//         'https://api.metimur.mx/api/charge/5minutal',   
//         {
//           'concentrator_id' : concentrator_id ,
//           'charge_id' : charge_id ,
//           'start_date' : /*'2023050100'*/ startDate ,
//           'end_date' : /* '2023051023'*/  endDate
//         },
//         getconfig()
//       )
//       .then(response => {
//         funcResp(response.data.data, false);
//       })
//       .catch(function(error) {
//         console.error('error:' + error);
//       });
//   };
// }

export const getPeriodReport = (chargeId, startDate, endDate) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/report/getbillingbyperiod',  // Cambiar a POST
        {
          charge_id: chargeId,  // Enviar los datos en el cuerpo
          start_date: startDate,
          end_date: endDate
        },
        getconfig()  // Configuración adicional
      )
      .then(response => {
        dispatch(getPeriodReportSuccess(response.data.data));
      })
      .catch(function (error) {
        console.log('error:', error);
      });
  }
};

export const getPeriodReportSuccess = data => {
  return {
    type: dashboardChargeActionTypes.GET_PERIOD_REPORT_SUCCESS,
    data
  }
}

export const sendReceipByPeriod = (chargeId, startDate, endDate, phone, email) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/report/sendreceiptbyperiod',
        {
          charge_id: chargeId,
          start_date: startDate,
          end_date: endDate,
          ...(phone && { phone }),
          ...(email && { email })
        },
        getconfig()
      )
      .then(() => {})
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Error al enviar el reporte',
          text: error.message
        });
      });
  }
}

export const getAngle = (fp_id, concentrador_id, charge_id, date) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/getkvar',
        {
          fp_id: fp_id.charge_fp,
          concentrator_id: concentrador_id,
          charge_id: charge_id,
          date: date
        },
        getconfig()
      )
      .then(response => {
        dispatch(getAngleSuccess(response.data.data))
      })
      .catch(error =>{
        dispatch(getAngleFailure(error))
        console.error('Error al obtener el ángulo: '+ error)
      })
  }
}


export const getAngleSuccess = angle => {
  return {
    type: dashboardChargeActionTypes.GET_ANGLE_SUCCESS,
    angle
  }
}

export const getAngleFailure = error => {
  return {
    type: dashboardChargeActionTypes.GET_ANGLE_FAILURE,
    state: { error }
  }
}

export const fpValidation = chargeId => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/charge/fpvalidation',
        {
          charge_id: chargeId
        },
        getconfig()
      )
      .then(response => {
        dispatch(getFpValidationSuccess(response.data.data))
      })
      .catch(error => {
        console.error(error);
      })
  }
}

export const getFpValidationSuccess = fpValidation => {
  return {
    type: dashboardChargeActionTypes.GET_FP_VALIDATION_SUCCESS,
    fpValidation
  }
}