import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getTotalsActualMonth } from "js/actions/meterDashboardActions";

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowDownward as ArrowDownwardIcon,
  TrendingUp as MoneyIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector} from 'react-redux';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles)



export default function Budget(props) {
  const intl = useIntl()
  const classes = useStyles()

  const dispatch = useDispatch()
  const {className, ...rest} = props;
  const rootClassName = classNames(classes.root, className);
  const totalConsumes = useSelector(state=>state.meterDashboard.totalsactualmonth);

  const {corpId,meterId} = props.ids


  useEffect(() => {
    dispatch(getTotalsActualMonth(meterId))
  }, [])

  

  return (
    <Paper
    {...rest}
    className={rootClassName}
  >
    <div className={classes.content}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="body2"
        >
          {intl.formatMessage({id: 'app.meter.board.mainHead.consumtion'})}
        </Typography>
        <Typography
          className={classes.value}
          variant="h3"
        >
         {totalConsumes.total_kwh} kWh 
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <MoneyIcon className={classes.icon} />
      </div>
    </div>
  </Paper>
  )
}


