import * as userActionTypes from '../constants/userAction-types';

const initialState = {
  users: {},
  loading: false,
  error: {}
};

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function usersReducer(state = initialState, action) {
  switch (action.type) {
    

    default:
      return state;
  }
}

export default usersReducer;
