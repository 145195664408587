/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import { Button, TextField, Grid } from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
 
const useStyles = makeStyles(styles);

function RangosMedicionForm(props) {
  const intl = useIntl()
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();  
  const {charge} = props;

  const corporative = useSelector(state => state.user.corporative ); 

  const [demandaMin, setDemandaMin]=React.useState(null);
  const [demandaMax, setDemandaMax]=React.useState(null);
  const [consumoMin, setConsumoMin]=React.useState(null);
  const [consumoMax, setConsumoMax]=React.useState(null);

  
  useEffect(() => {    
    setDemandaMin( charge.demanda_min );
    setDemandaMax( charge.demanda_max );
    setConsumoMin( charge.consumo_min );
    setConsumoMax( charge.consumo_max );    
  }, [charge]);
  

  const handleDemandaMin = val => {
    setDemandaMin(val.target.value);
  };


  const handleDemandaMax = val => {
    setDemandaMax(val.target.value);
  };

  const handleConsumoMin = val => {
    setConsumoMin(val.target.value);
  };

  const handleConsumoMax = val => {
    setConsumoMax(val.target.value);
  };
 

 

  const onSubmit = values => {      
    dispatch(postForm(values));
    props.nextStep();
  };

  

  const classes = useStyles();

  
  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.measurRanges.minDemandLbl'})}
          name="demanda_min"
          onChange={event =>
            handleDemandaMin(event )
          }
          value={demandaMin}  
          variant="outlined"
        />    

        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.measurRanges.maxDemandLbl'})}
          name="demanda_max"
          onChange={event =>
            handleDemandaMax(event )
          }
          value={demandaMax}  
          variant="outlined"
        />           

        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.measurRanges.minConsumpLbl'})}
          name="consumo_min"
          onChange={event =>
            handleConsumoMin(event )
          }
          value={consumoMin}  
          variant="outlined"
        />     

        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.measurRanges.maxConsumpLbl'})}
          name="consumo_max"
          onChange={event =>
            handleConsumoMax(event )
          }
          value={consumoMax}  
          variant="outlined"
        />                     


 
 

        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.descrip.saveAndContinueLbl'})}
        </Button>
      </form>
    </Grid>
  );
}

export default RangosMedicionForm;
