import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { deleteLastPostForm } from 'js/actions/formsAction';
import { updateDataConcentrator } from 'js/actions/concentratorActions';
//import { cleanForm } from 'js/actions/formsAction'

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const classes = useStyles();
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;
  const {concentrator} = props
  //const {meterId} = props.ids

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }
  

  const concentratorEdited = {
    concentrator_name: data[0].data.concentrator_name,
    description:data[0].data.concentrator_description,
    user_ftp: 'concentradores@optimo-automatizacion.com',
    type: data[1].data.concentratorType,
    connection_period: '55',
    baudrate: '9600',
    //group_id: groupId,
    meter_id: concentrator.mater_id
  };
  
  function completeConcentrator ( concentratorEdited){
    dispatch(updateDataConcentrator(concentratorEdited,concentrator));
  };

  return (
    <Grid container className={classes.root}>
      <div className={classes.form}>
        
        <Typography variant="h4">Confirmacion de Concentrador</Typography>

        <Typography variant="body1"> Nombre de Concentrador: {concentratorEdited.concentrator_name} </Typography>

        <Typography variant="body1"> Descripcion: {concentratorEdited.description} </Typography>

        <Typography variant="body1"> Tipo de Concentrador : {concentratorEdited.type}</Typography>
        


        <Button
          color="secondary"
          size="large"
          onClick={()=>completeConcentrator(concentratorEdited)}
          variant="contained"
          className={classes.button}
        >
          Confirmar
        </Button>
        <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          Regresar 
        </Button>
      </div>
    </Grid>
  );
}

export default NameRoleForm;
