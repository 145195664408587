import * as roleActionTypes from 'js/constants/roleAction-types';
import * as formActionTypes from 'js/constants/formsAction-Types'
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};


export const showRoles = ()=> {
  return async dispatch => {
    dispatch(getRolesStarted());
  
    axios
      .get(
        `https://api.metimur.mx/api/role`,
        getconfig()
      )
      .then(response => {
        dispatch(getRoles(response.data.data));      
        
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getRolesStarted = () => ({
  type: roleActionTypes.GET_ROLE_STARTED,
  loading:true 
});


export const getRoles = roles => {
  return {
    type: roleActionTypes.GET_ROLE_SUCCESS,
    roles,
    loading:false
  };
};



export const postDataRole = role => {
  return async dispatch => {
    dispatch(postRoleStarted());

    axios
      .post(
        `https://api.metimur.mx/api/role/permission`,
        {
          role_name: role.role_name,  
          is_public : parseInt( role.is_public ) , 
          permissions_names : role.permissions
        },
        getconfig()
      )
      .then(response => {
        dispatch(postRoleSuccess(role));
      })
      .then((response)=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Rol ${role.role_name} creado exitosamente`,
          icon: 'success'
        }).then(
          ()=>{history.push('/home')}
        );
      })
      .catch(error => {
        dispatch(postRoleFail(error.message));
        
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
        
      });
  };
};

export const postRoleStarted = () => ({
  type: roleActionTypes.POST_ROLE_STARTED,
});

export const postRoleSuccess = role => {
  return {
    type: roleActionTypes.POST_ROLE_SUCCESS,
    payload: {
      name: role.role_name,
      permissions: role.permissions,
    }
  };
};

export const postRoleFail = error => ({
  type: roleActionTypes.POST_ROLE_FAILURE,
  state: { error }
});



export const updateDataRole = role => {
   
  return async dispatch => {
    axios
      .post(
        `https://api.metimur.mx/api/role/permission/edit`,
        {
          role_name: role.role_name,
          is_public: role.is_public ,
          permissions_names : role.permissions ,
          role_id: role.role_id
        },
        getconfig()
      )
      .then(response => {
        dispatch(updateRoleSuccess());
      })
      .then((response)=>{
        Swal.fire({
          title: 'actualizacion Correcta',
          text: `Rol ${role.role_name} actualizado exitosamente`,
          icon: 'success'
        }).then(
          ()=>{history.push('/roles')}
        );
      })
      .catch(error => {
        console.log('ERROR API:' + error);
        
      });
  };
};

export const updateRoleSuccess = () => {
  return {
    type: roleActionTypes.UPDATE_ROLE_SUCCESS,
  };
};


export const deleteRole = role => {
  return async dispatch => {

    dispatch(deleteRoleSuccess(role.id));
    axios.post(
      `https://api.metimur.mx/api/role/delete`,
      { role_name: role.name },
      getconfig()
    )
  };
};

export const deleteRoleSuccess = roleId => {
  return {
    type: roleActionTypes.DELETE_ROLE_SUCCESS,
    payload:{id: roleId} 
  };
};