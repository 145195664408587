import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Grid, TextField, Typography } from '@material-ui/core';

import { postForm , deleteLastPostForm } from 'js/actions/formsAction';
import { showCards, showCardsInputs, showVoltageInputs } from 'js/actions/cardAction';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  concentratorPhase: null,
  cardPhaseA: null,
  inpunt1PhaseA: null,
  relationPhaseA: null
};

function MonoPhasicForm(props) {
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();
  const voltageData = props.voltage;
  let cards = useSelector(state => state.cards.cards);
  let inputs = useSelector(state => state.cards.inputs);
  const comunicationStepForm = useSelector(state => state.forms.data[1]);
  const { unit } = comunicationStepForm.data;

  console.log(voltageData)

  const [voltage, setVoltage] = useState(voltageData.fix_voltage)
  const [cardPhaseA, setCardPhaseA] = useState(voltageData.inputs[0].card_id)
  const [inpunt1PhaseA, setInpuntPhaseA] = useState(voltageData.inputs[0].input)

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
    //console.log('DATA: ', values);
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }

  const handleVoltage = voltage => {
    setVoltage(voltage.target.value);
  };

  const handleCardPhaseA = cardPhaseA => {
    setCardPhaseA(cardPhaseA.target.value);
    dispatch(showVoltageInputs(cardPhaseA.target.value))
  };

  useEffect(() => {
    dispatch(showCards(voltageData.concentrator_id));
  }, []);

  const handleInpunt1PhaseA = inpunt1PhaseA => {
    setInpuntPhaseA(inpunt1PhaseA.target.value);
  };

  const relationPhaseA = relationPhaseA => {
    setValue('relationPhaseA', relationPhaseA.target.value);
  };

  useOnMount(() => {
    register({ name: 'concentratorPhase', type: 'text' });

    register({ name: 'cardPhaseA', type: 'text' });

    register({ name: 'inpunt1PhaseA', type: 'text' });

    register({ name: 'relationPhaseA', type: 'text' });
  });

  const values = getValues();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h4' style={{marginBottom:'10px'}}>Configuracion de Fases</Typography>
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> A </p>
          </Grid>
          
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
              >
                Tarjeta
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                name='cardPhaseA'
                inputRef={register}
                onChange={handleCardPhaseA}
                value={cardPhaseA}
                //labelWidth={labelWidth}
              >
          
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                Entradas
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                inputRef={register}
                onChange={handleInpunt1PhaseA}
                name='inpuntPhaseA'
                value={inpunt1PhaseA}
                //labelWidth={labelWidth}
              >
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
          <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                Relacion
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={relationPhaseA}
                value={values.relationPhaseA}
                //labelWidth={labelWidth}
              > 
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="130:1">130:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <TextField
              className={classes.textField}
              defaultValue={1}
              inputRef={register}
              label="Ajuste"
              name="ajustPhaseA"
              required
              variant="outlined"
            />
            
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          > <TextField
            className={classes.textField}
            defaultValue={0}
            inputRef={register}
            label="Offset"
            name="offsetPhaseA"
            required
            variant="outlined"
          />
            
          </Grid>
          <Typography variant='h4' style={{marginBottom:'25px'}}>Voltaje Predefinido </Typography>
          <FormControl
              className={classes.textField}
              variant="outlined"
              style={{marginLeft:'40px'}}
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                
              >
                Voltaje 
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name='voltage'
                onChange={handleVoltage}
                value={voltage}
                //labelWidth={labelWidth}
              >
                <MenuItem value="110">110</MenuItem>
                <MenuItem value="220">220</MenuItem>
                <MenuItem value="360">360</MenuItem>
                <MenuItem value="480">480</MenuItem>
              </Select>
            </FormControl>
            <Typography variant='h4' style={{marginBottom:'7px',marginTop:'20px'}}>Factor de Potencia Predefinido </Typography>

          <TextField
            className={classes.textField}
            style={{marginLeft:'40px'}}
            inputRef={register}
            defaultValue={voltageData.fix_fp}
            defaultValue={0.95}
            helperText="Favor de Ingresar un valor entre 0.50 y 1"
            name='fp'
            label="Factor de Potencia"
            margin="normal"
            variant="outlined"
            style={{marginBottom:'30px',marginTop:'20px'}}
          />
          
        </Grid>

        <Button
          className={classes.textField}
          color="secondary"
          size="large"
          style={{ marginTop: '10px' }}
          type="submit"
          variant="contained"
        >
          Guardar y Continuar
        </Button>
        <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          Regresar 
        </Button>
      </form>
    </div>
  );
}

export default MonoPhasicForm;
