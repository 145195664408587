export const GET_CHARGE_STARTED = 'GET_CHARGE_STARTED';
export const GET_CHARGE_SUCCESS = 'GET_CHARGE_SUCCESS';
export const GET_CHARGE_FAILURE = 'GET_CHARGE_FAILURE';

export const POST_CHARGE_STARTED = 'POST_CHARGE_STARTED';
export const POST_CHARGE_SUCCESS = 'POST_CHARGE_SUCCESS';
export const POST_CHARGE_FAILURE = 'POST_CHARGE_FAILURE';

export const DELETE_CHARGE_SUCCESS = 'DELETE_CHARGE_SUCCESS';

export const PUT_CHARGE_SUCCESS = 'PUT_CHARGE_SUCCESS';

export const GET_CHARGE_TMP_SUCCESS = 'GET_CHARGE_TMP_SUCCESS';

export const GET_CHARGE_INVOICE_SUCCESS = 'GET_CHARGE_INVOICE_SUCCESS';

export const GET_VOLTAGE_STARTED = 'GET_VOLTAGE_STARTED';
export const GET_VOLTAGE_SUCCESS = 'GET_VOLTAGE_SUCCESS';
export const GET_VOLTAGE_FAILURE = 'GET_VOLTAGE_FAILURE';

export const POST_VOLTAGE_STARTED = 'POST_VOLTAGE_STARTED';
export const POST_VOLTAGE_SUCCESS = 'POST_VOLTAGE_SUCCESS';
export const POST_VOLTAGE_FAILURE = 'POST_VOLTAGE_FAILURE';

export const PUT_VOLTAGE_SUCCESS = 'PUT_VOLTAGE_SUCCESS';

export const CLEAN_VOLTAGE = 'CLEAN_VOLTAGE';

export const GET_CHARGE_OWNER_SUCCESS = 'GET_CHARGE_OWNER_SUCCESS';
export const UPDATE_CHARGE_OWNER_SUCCESS = 'UPDATE_CHARGE_OWNER_SUCCESS';
export const GET_CHARGE_OWNER_FAILURE = 'GET_CHARGE_OWNER_FAILURE';