import React, { useEffect } from 'react';
import useForm from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { showCFDI,showPaymentMethods,showPaymentTypes,postInvoice} from 'js/actions/invoiceActions'
import Swal from 'sweetalert2';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

 

// Material components
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles
} from '@material-ui/core';

import * as action from 'js/actions/userActions';
import { showRoles } from 'js/actions/roleActions';

// Component styles
import styles from './styles';
import { showAllCharge } from 'js/actions/chargeActions';
import { showAllMeters } from 'js/actions/meterActions';
import { showAllGroups } from 'js/actions/groupActions';
import MaterialTable from 'material-table';
import Typography from 'theme/typography';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);




function useOnMount(handler) {
  return useEffect(handler, []);
}

const defaultValues = {
  role: null,
  meter: null,
  group: null,
  charge: null
};

export default function UserForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { setValue, getValues, handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const { user } = props.location.state;
  const { corpId } = props.location.state;
  const user_id = user.id
  const roles = useSelector(state => state.roles.roles);

  const cfdi = useSelector(state => state.invoice.cfdi);
  const payment_types = useSelector(state => state.invoice.payment_types);
  const payment_methods = useSelector(state => state.invoice.payment_methods);


  const [role, setRole] = React.useState(user.role_name);
  const [cfdi_form, setCfdiForm] = React.useState(user.cfdi);
  const [payment_types_form, setPaymentTypeForm] = React.useState(user.tipo_de_pago);
  const [payment_methods_form, setPaymentMethodForm] = React.useState(user.metodo_de_pago);

 
  const meters = useSelector(state => state.meters.meters);
  const groups = useSelector(state => state.groups.groups);
  const charges = useSelector(state => state.charges.charges);

   
  const [meter, setMeter] = React.useState(null);
  const [group, setGroup] = React.useState({group_id:'...',group:'Todas las Mediciones'});
  const [charge, setCharge] = React.useState({charge_id:'...',charge:'Todas las Mediciones'});

  console.log('user is :')
  console.log(user);

  let data1=[];


  const [state,setState]=React.useState({data:[],
    columns: [
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.grid.colMeasure'}), field: 'meter' },
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.grid.colGroup'}), field: 'group' },
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.grid.colCarga'}), field: 'charge' }
    ],

    actions: [
      {
        icon: 'delete',
        tooltip: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.grid.colAction.delete'}),
        onClick: (event, rowData) => deleteItem(rowData  )
      }
       
    ]    
  
  
  
  })

 
   

  const setTable = () => {
    let obj = {
      corporative_id:corpId,
      meter_id: meter.id,
      meter: meter.name,
      group_id: group.id,
      group: group.name,
      charge_id: charge.id,
      charge: charge.name
    };

    setState({
      ...state,
      data:[
        ...state.data,
        obj
      ]
    })

   

    data1=[ ...state.data , obj  ]

    setMeter(null)
    setGroup({group_id:'0',group:'Todas las Mediciones'})
    setCharge({group_id:'0',group:'Todas las Mediciones'});
  };


  useEffect(() => {    
    dispatch(showAllMeters(corpId));
  }, []);


  useEffect(() => {
    dispatch(showRoles());   
  }, []);

  useEffect(() => {
   
    dispatch(showCFDI());
    dispatch(showPaymentTypes());
    dispatch(showPaymentMethods());
    dispatch(action.getUserCharges( user.id , funcRespUserCharges )  )
  }, [])

  const funcRespUserCharges = info => {
    console.log('funcRespUserCharges')    
    let arrData=[];
    if( info.access_list && info.access_list.length>0 ){

      let i=0;
      let meterName=null;
      let groupName=null;
      let chargeName=null;
      for( i=0 ; i<info.access_list.length; i++ ){
        meterName='';
        groupName='';
        chargeName='';
        let row= info.access_list[i];        
        console.log( row);
        if( row.meter_id ){
          if(  info.data  ){
            meterName=getMeterName( row.meter_id , info.data.meters )
          }           
        }

        if( row.group_id ){
          if(  info.data  ){
            groupName=getGroupName( row.group_id , info.data.groups )
          }           
        }        


        if( row.charge_id ){
          if(  info.data  ){
            chargeName=getChargeName( row.charge_id , info.data.charges )
          }           
        }               


        let obj = {
          corporative_id: row.corporative_id ,
          meter_id: row.meter_id ,
          meter: meterName ,
          group_id: row.group_id,
          group: groupName ,
          charge_id: row.charge_id ,
          charge: chargeName
        };  

        arrData.push(obj)                       
      }

    }


    setState({
      ...state,
      data: arrData
    })

   
    data1=arrData;

  };

  const getMeterName = ( meterId , meters ) => {
    console.log('getMeterName')
    console.log('meters :')
    console.log( meters )
    if( !meters ){
      return;
    }

    let i=0;
    for( i=0;i<meters.length; i++ ){
      if( meters[i].id= meterId ){
        return meters[i].name;
      }
    }

    return '';
  
  };


  const getGroupName = ( groupId , groups ) => {
    console.log('getGroupName')
    if( !groups ){
      return;
    }

    let i=0;
    for( i=0;i<groups.length; i++ ){
      if( groups[i].id= groupId ){
        return groups[i].name;
      }
    }

    return '';
  
  };


  const getChargeName = ( chargeId , charges ) => {
    console.log('getChargeName')
    if( !charges ){
      return;
    }

    let i=0;
    for( i=0;i<charges.length; i++ ){
      if( charges[i].id= chargeId ){
        return charges[i].name;
      }
    }

    return '';
  
  };

  const handleChangeMeter = event => {
    setMeter(event.target.value);
    dispatch(showAllGroups(corpId, event.target.value.id));
  };

  const handleChangeGroup = event => {
    setGroup(event.target.value);
    dispatch(showAllCharge(event.target.value.id));
  };

  const handleChangeCharge = event => {
    setCharge(event.target.value);
    //dispatch(showAllCharge(event.target.value))
  };  


  const handleChange = event => {
    setRole(event.target.value);
  };


  const onSubmit = user => {


    if( user.rfc  ){
      let msgError='<pre>';
      let error=false;
      if(!user.razon_social ){
        msgError=intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.socReason.msgError'});
        error=true;
      }

      if( !user.cfdi ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.cfdi.msgError'});
        error=true;              
      }

      if( !user.payment_type ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.payType.msgError'});
        error=true;              
      }

      if( !user.payment_method ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.payMethod.msgError'});
        error=true;              
      }          

      msgError+='</pre>';


      if( error ){
        Swal.fire({
          title: intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.saveError.title'}),
          html:  msgError  ,
          icon: 'error',
        });      
        
        return;            
      }
      
    }

    dispatch(action.updateAccountAdmin(user,user_id , state.data ))
     
  };


  const handleCFDI = cfdi => {
    setCfdiForm(cfdi.target.value);
  };

  const handlePaymentTypes = payment_type => {
    setPaymentTypeForm(payment_type.target.value);
  };

  const handlePaymentMethods = payment_methods => {
    setPaymentMethodForm(payment_methods.target.value);
  };
				


  const deleteItem =  item  => {
    //dispatch(deleteCard(cardId));
    
    if(!item){
      return;
    }

    if( !item.chage_id && !item.group_id && !item.charge_id ){
      return;
    }
 
    let arrData=data1;
    console.log(data1);

    if( arrData && arrData.length>0 ){
       
      let i=0;
      let idx=-1;
      for( i=0 ; i<arrData.length; i++ ){
        let row= arrData[i];

        if( row.charge_id ) {
          if( row.chage_id===item.charge_id ){
            idx=i;
            break;   
          }else {
            if( item.chage_id ){
              continue;
            }               
          }            
        }

        if(row.group_id){
          if( row.group_id===item.group_id  ){
            idx=i;
            break;
          }else{
            if( item.group_id ){
              continue;
            }
          }
        }


        if( row.meter_id ){
          if( row.meter_id==item.meter_id ){
            idx=i;
            break
          }
        }
                              
      }

      console.log('idx vale :'+ idx)
      if( idx>=0 ){
        const data = [...state.data];
        data.splice(idx , 1);              
        setState({ ...state, data });
       

      }

    }      





  };



  return (
    <DashboardLayout title="Edición de Usuario">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={user.firstname}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.firstNameLbl'})}
                      name="firstname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.lastname}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.lastNameLbl'})}
                      name="lastname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.email}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.emailLbl'})}
                      name="email"
                      //onChange={this.handleChange}
                      //value={email}
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.password}
                      helperText={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.passwordRules'})}
                      inputRef={register}

                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.passwordLbl'})}
                      name="password"
                      //onChange={this.handleChange}
                      type="password"
                      variant="filled"
                    />

                    <TextField
                      className={classes.textField}
                      defaultValue={user.tel}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.phoneNumberLbl'})}
                      name="tel"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.cel}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.cellPhoneLbl'})}
                      name="cel"
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.rolLbl'})}</InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="role"
                        onChange={handleChange}
                        value={role}
                      >
                        {roles.map(role => (
                          <MenuItem value={role.name}>{role.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                  </div>
                </Grid>


                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>

                    <TextField
                      className={classes.textField}
                      defaultValue={user.rfc}
                      inputRef={register}
                      //value={values.name}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.rfcLbl'})}
                      name="rfc"
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.razon_social}
                      inputRef={register}
                      //value={values.name}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.socReasonLbl'})}
                      name="razon_social"
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="filled"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.cfdiLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="cfdi"
                        onChange={handleCFDI}
                        value={cfdi_form}
                         
                        //labelWidth={labelWidth}
                      >
                        {
                          cfdi.data ? cfdi.data.map(cfdi => (
                            <MenuItem value={cfdi.id}>{cfdi.name}</MenuItem>

                          )) : console.log('generando cfdis')
                        }
                          


                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="filled"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.payTypeLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="payment_type"
                        onChange={handlePaymentTypes}
                        value={payment_types_form}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_types.data ? payment_types.data.map(payment_types => (
                            <MenuItem value={payment_types.id}>{payment_types.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="filled"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.payMethodLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="payment_method"
                        onChange={handlePaymentMethods}
                        value={payment_methods_form}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_methods.data ? payment_methods.data.map(payment_methods => (
                            <MenuItem value={payment_methods.id}>{payment_methods.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>

 

                 


                    {/*  */}
                  </div>


                  <div className={classes.fields} />


                </Grid>              

    
              </Grid>

              <Grid   >

        
                <form
                  style={{marginBottom: '10px', marginTop: '20px'}}
                >
              


                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <FormControl
                        className={classes.textField}
                        required
                      >
                        <InputLabel id="demo-simple-select-label">
                          {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.configUserAccess.meterLbl'})}
                        </InputLabel>
                        <Select
                          inputRef={register}
                          labelId="demo-simple-select-disabled-label"
                          name="meter"
                          onChange={handleChangeMeter}
                          value={meter}
                        >
                    
                          {meters.map(meter => (
                            <MenuItem value={meter}>{meter.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                    >
                      <FormControl
                        className={classes.textField}
                    
                      >
                        <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.configUserAccess.groupLbl'})}</InputLabel>
                        <Select
                          inputRef={register}
                          labelId="demo-simple-select-disabled-label"
                          name="group"
                          onChange={handleChangeGroup}
                          value={group}
                        >
                      
                          {groups.map(group => (
                            <MenuItem value={group}>{group.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                    >
                      <FormControl
                        className={classes.textField}
                    
                      >
                        <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.configUserAccess.chargeLbl'})}</InputLabel>
                        <Select
                          inputRef={register}
                          labelId="demo-simple-select-disabled-label"
                          name="charge"
                          onChange={handleChangeCharge}
                          value={charge}
                        >
                      
                          {charges.map(charge => (
                            <MenuItem value={charge}>{charge.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                    >
                      <Button
                        className={classes.signUpButton}
                        color="secondary"
                        onClick={setTable}
                        //type="submit"
                        size="large"
                        variant="outlined"
                      >
                        {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.configUserAccess.addToListBtn'})}
                      </Button>
                    </Grid>

                  </Grid>
                </form>
                <MaterialTable
               
                  columns={state.columns}
                  data={state.data}
                  editable={{
               
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          const data = [...state.data];
                          data.splice(data.indexOf(oldData), 1);
                          setState({ ...state, data });
                        }, 600);
                      })
                  }}
                  options={{toolbar:false}}
                />
           

              
           
              </Grid>  






              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.users.usersList.corpusers.editUser.configUserAccess.registerBtn'})}
              </Button>
            </form>

          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
