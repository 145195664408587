import React, { useState, useEffect , useLayoutEffect } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid
} from '@material-ui/core';

import { showMeters, deleteMeter } from 'js/actions/meterActions';
import { showGroups, deleteGroup } from 'js/actions/groupActions';
import { showCharge, deleteCharge } from 'js/actions/chargeActions';

import { useDispatch, useSelector } from 'react-redux';
import { postReportHorarioStarted } from 'js/actions/reportsActions';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  signUpButton: {
    backgroundColor:'#FEC12C',
    width:'250px',
    height:'45px',
    marginTop:'20px',
    [theme.breakpoints.down('md')]:{
      width: '100%'
    }
  }
}));

export default function InputAdornments() {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const meters = useSelector(state => state.meters.meters);
  let groups = useSelector(state => state.groups.groups);
  let charges = useSelector(state => state.charges.charges);
  const user = useSelector(state => state.user.user);
  //let year = new Date().getFullYear()

  const [corporative, setCorporative] = useState();
  const [meter, setMeter] = useState();
  const [group, setGroup] = useState([]);
  const [charge, setCharge] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [type, setType] = useState();
  const [linkRep, setLinkRep] = useState();
  const [blocking, setBlocking] = useState();

  const corpAdmin = useSelector(state => state.user.corporative);
  const corpUser = user.corporative;

  const currYear = new Date().getFullYear();
  
  const years = [ currYear-3 , currYear-2 , currYear-1 , currYear ];

  useEffect(() => {    
    if( user.adser!=1  && corpUser ){     
      getMeters(corpUser.id);
    }
        
  }, []);


  const onSubmit = values => {   
    setBlocking(true)
    dispatch(postReportHorarioStarted( endBlocking ,  values ,corporative , group , charge ));
  };


  function endBlocking( ){
    setBlocking(false);
  }


  function submitReport(e) {
    if( !group || !year || !month || !charge || !type  ){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });

      e.preventDefault();
    }
  }
 


   


  useLayoutEffect(() => {
    /*
    console.log("useLayoutEffect .....charge is:"+ charge );
    let varRep= 'https://api.metimur.mx/api/report/excel/consumedemand/group_id/'+group+'/charges_ids/'+charge+'/year/'+year+'/month/'+month+'/type_report/'+type;
    setLinkRep(varRep)
    console.log( linkRep ) */
       
  }, [ group, year , month , charge, type , linkRep  ]);

  const getSelector = corp => {
    if (corp == 1) {
      return (
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            {intl.formatMessage({id: 'app.reports.hourReports.corporateLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="corporative"
            onChange={handleCorporative}
            value={corporative}
            //labelWidth={labelWidth}
          >
            {corporatives
              ? corporatives.map(el => (
                <MenuItem value={el.id}>{el.name}</MenuItem>
              ))
              : ''}
          </Select>
        </FormControl>
      );
    } else {
      return null
    }
  };

  const getMeters = corporativeId => {
    dispatch(showMeters(corporativeId));
  };

  const getGroup = meterId => {
    dispatch(showGroups(meterId));
  };

  const getCharges = groupId => {
    dispatch(showCharge(groupId));
  };

  const handleCorporative = corporative => {
    setCorporative(corporative.target.value);
    setCharge([]);
    setGroup([]);
    getMeters(corporative.target.value);
  };

  const handleMeter = meter => {
    setMeter(meter.target.value);
    setCharge([]);
    setGroup([]);
    getGroup(meter.target.value);
  };

  const handleGroup = group => {
    setGroup(group.target.value);
    setCharge([]);
    if( group.target.value.length==1  ){         
      getCharges(group.target.value[0] );             
    }else{
      getCharges(0); 
    }


  };

  const handleCharge = chargeVal => {
    setCharge(chargeVal.target.value);
    //getCity(state.target.value);
  };

  const handleYear = year => {
    setYear(year.target.value);
    //getCity(state.target.value);
  };

  const handleMonth = month => {
    setMonth(month.target.value);
    //getCity(state.target.value);
  };

  const handleType = type => {
    setType(type.target.value);
    //getCity(state.target.value);
  };

  return (
    <div>
      <BlockUi
        blocking={blocking}
        tag="div"
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        //onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              md={6}
              style={{display: user.adser ? 'block' : 'none'}}
              xs={6}
            >
              {getSelector(user.adser)}
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.meterLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="meter"
                  onChange={handleMeter}
                  value={meter}
                //labelWidth={labelWidth}
                >
                  {meters
                    ? meters.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : ''}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.groupLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  multiple
                  name="group"
                  onChange={handleGroup}
                  value={group}
                //labelWidth={labelWidth}
                >
                  {groups
                    ? groups.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : ''}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.chargeLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  multiple
                  name="charge"
                  onChange={handleCharge}
                  value={charge}
                //labelWidth={labelWidth}
                >
                  <MenuItem value="TODAS">TODAS</MenuItem>
                  {charges
                    ? charges.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : ''}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.yearLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="year"
                  onChange={handleYear}
                  value={year}
                //labelWidth={labelWidth}
                >
                 
                  {                 
                    years.map(yr => (
                      <MenuItem value={yr}>{yr}</MenuItem>
                    ))                                    
                  }
               
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.monthLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="month"
                  onChange={handleMonth}
                  value={month}
                //labelWidth={labelWidth}
                >
                  <MenuItem value="01">{intl.formatMessage({id: 'app.months.jan'})}</MenuItem>
                  <MenuItem value="02">{intl.formatMessage({id: 'app.months.feb'})}</MenuItem>
                  <MenuItem value="03">{intl.formatMessage({id: 'app.months.mar'})}</MenuItem>
                  <MenuItem value="04">{intl.formatMessage({id: 'app.months.apr'})}</MenuItem>
                  <MenuItem value="05">{intl.formatMessage({id: 'app.months.may'})}</MenuItem>
                  <MenuItem value="06">{intl.formatMessage({id: 'app.months.jun'})}</MenuItem>
                  <MenuItem value="07">{intl.formatMessage({id: 'app.months.jul'})}</MenuItem>
                  <MenuItem value="08">{intl.formatMessage({id: 'app.months.aug'})}</MenuItem>
                  <MenuItem value="09">{intl.formatMessage({id: 'app.months.sep'})}</MenuItem>
                  <MenuItem value="10">{intl.formatMessage({id: 'app.months.oct'})}</MenuItem>
                  <MenuItem value="11">{intl.formatMessage({id: 'app.months.nov'})}</MenuItem>
                  <MenuItem value="12">{intl.formatMessage({id: 'app.months.dec'})}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={3}
              md={user.adser ? 12 : 6}
              xs={user.adser ? 12 : 6}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                //inputRef={useOnMount}
                >
                  {intl.formatMessage({id: 'app.reports.hourReports.reportTypeLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="type"
                  onChange={handleType}
                  value={type}
                //labelWidth={labelWidth}
                >
                  <MenuItem value="CONSUME">{intl.formatMessage({id: 'app.reports.hourReports.reportType.consumpt'})}</MenuItem>
                  <MenuItem value="DEMAND">{intl.formatMessage({id: 'app.reports.hourReports.reportType.demand'})}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

          
            <Button
              className={classes.signUpButton}
              color="secondary"
              //onClick={this.handleSubmit}
              size="large"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({id: 'app.reports.hourReports.uploadBtn'})}
            </Button>
         
          </Grid>
        </form>
      </BlockUi>
    </div>
  );
}
