import React from 'react';
import MaterialTable from 'material-table';
import { makeStyles,Button,FormControl,InputLabel,Select,MenuItem,Input,Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MultiSelect from "@khanacademy/react-multi-select";
import { postForm } from 'js/actions/formsAction';
import styles from './styles';
const useStyles = makeStyles(styles);


export default function MaterialTableDemo(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
  };

  const [state, setState] = React.useState({
    columns: [
      { title: 'Nombre', field: 'name' },
      { title: 'Apellido', field: 'lastname' },
      { title: 'Email', field: 'email' },
      { title: 'Celular', field: 'cel_number'},
    ],
    data: [
      {name:'', lastname:'' ,email:'',cel_number:''},
      {name:'', lastname:'' ,email:'',cel_number:''},
      {name:'', lastname:'' ,email:'',cel_number:''},
    ]
  });

  return (
    <Grid container className={classes.root}>
      <MaterialTable
        columns={state.columns}
        data={state.data}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.push(newData);
                setState({ ...state, data });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data[data.indexOf(oldData)] = newData;
                setState({ ...state, data });
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.splice(data.indexOf(oldData), 1);
                setState({ ...state, data });
              }, 600);
            })
        }}
        options={{
          search: false
        }}
        title="Contactos a los que se les alertaran"
      />
      <Button
        color="secondary"
        onClick={() => onSubmit(state.data)}
        variant="contained"
        style={{marginTop:'10px'}}
      >
        Guardar Contacto
      </Button>
      </Grid>
  );
}
