import React, { useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';

import useForm from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import { connect } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import MaterialTable from 'material-table';

import { showAllMeters } from 'js/actions/meterActions';

import { showAllGroups } from 'js/actions/groupActions';

import { showAllCharge } from 'js/actions/chargeActions';

import { Multiselect } from 'multiselect-react-dropdown';
// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

import * as action from 'js/actions/userActions';
import { showRoles } from 'js/actions/roleActions';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return useEffect(handler, []);
}

const defaultValues = {
  role: null,
  meter: null,
  group: null,
  charge: null
};

export default function UserForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { setValue, getValues, handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const { user } = props.location.state;
  const roles = useSelector(state => state.roles.roles);

  const [role, setRole] = React.useState(user.role);


  console.log(user.role)

  useEffect(() => {    
    dispatch(showRoles());    
  }, []);

  const handleChange = event => {
    setRole(event.target.value);
  };


  const onSubmit = user => {
    dispatch(action. updateAccount(user));
    //console.log(user);
  };


  return (
    <DashboardLayout title={intl.formatMessage({id: 'app.myaccount.editUser.head'})}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.myaccount.editUser.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={6}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={user.personal_info.firstname}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.firstNameLabel'})}
                      name="firstname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.personal_info.lastname}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.lastNameLabel'})}
                      name="lastname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.personal_info.email}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.emailLabel'})}
                      name="email"
                      //onChange={this.handleChange}
                      //value={email}
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      helperText={intl.formatMessage({id: 'app.myaccount.editUser.passwordRules'})}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.newPasswordLabel'})}
                      name="password"
                      type="password"
                      //onChange={this.handleChange}
                      //value={password}
                      variant="filled"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  xl={3}
                  xs={3}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={user.personal_info.tel}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.phoneNumbLabel'})}
                      name="tel"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={user.personal_info.cel}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.myaccount.editUser.cellPhoneLabel'})}
                      name="cel"
                      variant="filled"
                    />
                    {/*  */}
                  </div>
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.myaccount.editUser.updateBtn'})}
              </Button>
            </form>

          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
