/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as cardActionTypes from 'js/constants/cardAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2';

 
const getConfig=()=>{
  return {
    headers: { Authorization: 'Bearer ' + getToken( ) }
  }

}



export const showCards = concentratorId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/concentrator/${concentratorId}/card`,   getConfig()    )
      .then(response => {
        dispatch(getCards(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getCards = cards=> {
  return {
    type: cardActionTypes.GET_CARD_SUCCESS,
    cards
  };
};

export const showAllCards = (corporativeId,meterId) => {    
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/cards`, getConfig() )
      .then(response => {
        console.log('Se obtubo todos los cards ');
        dispatch(getAllCards(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getAllCards = cards=> {
  return {
    type: cardActionTypes.GET_ALL_CARD_SUCCESS,
    cards
  };
};

export const showCardsInputs = cardId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/card/${cardId}/freeinputs`,  getConfig()   )
      .then(response => {
        dispatch(getCardsInputs(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const showVoltageInputs = cardId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/card/${cardId}/freevoltajeinputs`,   getConfig()  )
      .then(response => {
        dispatch(getCardsInputs(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getCardsInputs = cardsinputs => {
  return {
    type: cardActionTypes.GET_CARD_INPUT_SUCCESS,
    cardsinputs
  };
};


export const postDataCard = card => {
  return async dispatch => {
    console.log('TAREJTA ' + JSON.stringify(card));
    dispatch(postCardStarted());

    axios
      .post(
        `https://api.metimur.mx/api/card`,
        {
          name: card.name,
          description: card.description,
          communication_channel: card.communication_channel,
          mac_address: card.mac_address,
          type: card.type,
          concentrator_id: card.concentrator_id,
          serial_number: card.serial_number           
        },
        getConfig()
      )

      .then(response => {
        dispatch(postCardSuccess(card));
        
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Tarjeta ${card.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${card.meter}`)}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al crear',
            html: `Se encontraron los siguientes errores al crear la Tarjeta:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postCardFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const postCardStarted = () => ({
  type: cardActionTypes.POST_CARD_STARTED
});

export const postCardSuccess = data => {
  return {
    type: cardActionTypes.POST_CARD_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      communication_channel: data.communication_channel,
      mac_address: data.mac_address,
      type: data.type,
      concentrator_id: data.concentrator_id,
      modbus: 1,
      serial_number: data.serial_number,
    }
  };
};

export const postCardFail = error => ({
  type: cardActionTypes.POST_CARD_FAILURE,
  state: { error }
});

export const deleteCard = cardId => {
  return async dispatch => {

    dispatch(deleteCardSuccess(cardId));
    axios.post(
      `https://api.metimur.mx/api/card/delete`,
      { id: cardId },
      getConfig()
    )
  };
};

export const deleteCardSuccess = cardId => {
  return {
    type: cardActionTypes.DELETE_CARD_SUCCESS,
    payload:{id: cardId} 
  };
};

export const updateDataCard = (card,cardData) => {
  return async dispatch => {
    console.log('TAREJTA ' + JSON.stringify(card));
    dispatch(updateCardStarted());

    axios
      .put(
        `https://api.metimur.mx/api/card`,
        {
          id:cardData.id,
          name: card.name,
          description: card.description,
          communication_channel: card.communication_channel,
          mac_address: card.mac_address,
          type: card.type,
          concentrator_id: card.concentrator_id,
          serial_number: card.serial_number
        },
        getConfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Actualizacion Correcta',
          text: `Tarjeta ${card.name} actualizada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push('/home')}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al actualizar',
            html: `Se encontraron los siguientes errores al actualizar la Tarjeta:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postCardFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const updateCardStarted = () => ({
  type: cardActionTypes.PUT_CARD_STARTED
});

export const cleanCard = () => ({
  type: cardActionTypes.CLEAN_CARD,
  payload:{
    cards:[]
  }
});