import React, { useState , useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {useDropzone} from 'react-dropzone';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';


// Material
import { Button, Grid, TextField, makeStyles, Typography,FormControl,InputLabel,Select,MenuItem  } from '@material-ui/core';

import * as action from 'js/actions/index';

// Component styles
import styles from './styles';
import { Edit } from '@material-ui/icons';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);



export default function CorporativeForm(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const [files, setFiles] = useState([]);
  const [market_segment, setMarketSegment] = useState('');
  
  const [typePrebilling, setTypePrebilling] = useState();

  const onSubmit = (corporative) => {
    //console.log(corporative)
    dispatch(action.postDataCorporative(corporative,files));
    // dispatch(action.postPrefacturationLevel(corporative))
  };
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div
      className={classes.thumb}
      key={file.name}
    >
      <div className={classes.thumbInner}>
        <img
          className={classes.img}
          src={file.preview}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));

  }, [files]);

  const handleMarketSegment = market_segment => {
    setMarketSegment(market_segment.target.value);
  };

  const handleTypePrebilling = typePrebilling => {
    setTypePrebilling(typePrebilling.target.value);
  };

  return (
    <DashboardLayout title="Creacion de Corporativos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.corporate.newCorporate.headTitle'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.corporate.newCorporate.corpNameLabel'})}
                      //value={values.name}
                      name="name"
                      required
                      variant="outlined"
                    />
               
                                              



                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.corporate.newCorporate.corpDescLabel'})}
                      //onChange={this.handleChange}
                      // value={description}
                      name="description"
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.corporate.newCorporate.cityLabel'})}
                      //onChange={this.handleChange}
                      // value={city}
                      name="city"
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.corporate.newCorporate.stateLabel'})}
                      //onChange={this.handleChange}
                      // value={cstate}
                      name="cstate"
                      required
                      variant="outlined"
                    />
                                  
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.corporate.newCorporate.marketSegLabel'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="market_segment"
                        onChange={handleMarketSegment}
                        value={market_segment}
                        //labelWidth={labelWidth}
                      >
                        <MenuItem value="Plaza Comercial">Plaza Comercial</MenuItem>
                        <MenuItem value="Proyecto de Usos Mixtos">Proyecto de Usos Mixtos</MenuItem>
                        <MenuItem value="Industria de Transformación">Industria de Transformación</MenuItem>
                        <MenuItem value="Parque Industrial">Parque Industrial</MenuItem>
                        <MenuItem value="Plaza Comercial">Plaza Comercial</MenuItem>
                        <MenuItem value="Hotel / Hospedaje">Hotel / Hospedaje</MenuItem>
                        <MenuItem value="Planta de Producción">Planta de Producción</MenuItem>
                        <MenuItem value="Hospital / Clínica / Salud">Hospital / Clínica / Salud</MenuItem>
                        <MenuItem value="Edificio Público Gobierno">Edificio Público Gobierno</MenuItem>
                        <MenuItem value="Agrícola y del Campo">Agrícola y del Campo</MenuItem>
                        <MenuItem value="Terminal Aérea">Terminal Aérea</MenuItem>
                        <MenuItem value="Edificio Corporativo">Edificio Corporativo</MenuItem>
                        <MenuItem value="Oficina Empresarial / Servicios">Oficina Empresarial / Servicios</MenuItem>
                        <MenuItem value="Edificio de Departamentos">Edificio de Departamentos</MenuItem>
                        <MenuItem value="Centros de Espectáculos">Centros de Espectáculos</MenuItem>
                        <MenuItem value="Fraccionamiento Residencial">Fraccionamiento Residencial</MenuItem>
                        <MenuItem value="Club Deportivo / Social">Club Deportivo / Social</MenuItem>
                        <MenuItem value="Cadena Comercial Minorista">Cadena Comercial Minorista</MenuItem>
                        <MenuItem value="Cadena Comercial Mayorista">Cadena Comercial Mayorista</MenuItem>
                        <MenuItem value="Industria Maquiladora">Industria Maquiladora</MenuItem>
                        <MenuItem value="Industria Energética">Industria Energética</MenuItem>
                        <MenuItem value="Servicios Especializados">Servicios Especializados</MenuItem>
                        <MenuItem value="Edificio Educativo">Edificio Educativo</MenuItem>
                        <MenuItem value="Restaurante / Bar / Café">Restaurante / Bar / Café</MenuItem>
                        <MenuItem value="Proyecto Residencial">Proyecto Residencial</MenuItem>
                        <MenuItem value="Suministrador Calificado">Suministrador Calificado</MenuItem>
                        <MenuItem value="Comercializador de Energía">Comercializador de Energía</MenuItem>
                        <MenuItem value="Representante Metimur">Representante Metimur</MenuItem>

                      </Select>
                    </FormControl>
                     
                      

                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <section className={classes.container}>
                      <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <Typography
                          style={{textAlign:'center'}}
                          variant="h4"
                        >{intl.formatMessage({id: 'app.corporate.newCorporate.dragImageLogo'})}</Typography>
                      </div>
                      <aside className={classes.thumbsContainer}>
                        {thumbs}
                      </aside>
                    </section>               
                  </div>
                </Grid>
                <Button
                  className={classes.signUpButton}
                  color="secondary"
                  label={intl.formatMessage({id: 'app.corporate.newCorporate.btnSave'})}
                  //onClick={this.handleSubmit}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {intl.formatMessage({id: 'app.corporate.newCorporate.btnSave'})}{' '}
                </Button>
              </Grid>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
