import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import { useSelector } from "react-redux";
// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowUpward as ArrowUpwardIcon,
  FlashOn as PeopleIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)

export default function Users(props) {
  const intl = useIntl()
  const classes = useStyles()
  const {className, ...rest} = props
  const rootClassName = classNames(classes.root, className);
  const totalConsumes = useSelector(state=>state.meterDashboard.totalsactualmonth);

  return (
    <Paper
    {...rest}
    className={rootClassName}
  >
    <div className={classes.content}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="body2"
        >
          {intl.formatMessage({id: 'app.meter.board.mainHead.maxDemand'})}
        </Typography>
        <Typography
          className={classes.value}
          variant="h3"
        >
          {totalConsumes.demand_max} kW
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <PeopleIcon className={classes.icon} />
      </div>
    </div>
  </Paper>
  )
}

