import * as formsActionTypes from '../constants/formsAction-Types';


function formReducer(state = { data : []}, action) {
  switch (action.type) {
    case formsActionTypes.CLEAN_FORM:
      return (state = action.payload)
      ;
    case formsActionTypes.POST_FORM:
      return {
        ...state,
        formdata: state.data.push(action.payload),
      };
    case formsActionTypes.DELETE_LAST_POST_FORM:
      return {
        ...state,
        formdata: state.data.pop(),
      };

    default:
      return state;
  }
}

export default formReducer;
