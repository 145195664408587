import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getMonthWithMostConsume} from 'js/actions/groupDashboardActions'

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

import {
  LaptopMac as LaptopMacIcon,
  PhoneIphone as PhoneIphoneIcon,
  Refresh as RefreshIcon,
  TabletMac as TabletMacIcon,
  CalendarToday as CalendarIcon,
  TrendingUp as UpIcon
} from '@material-ui/icons';

import { makeStyles, IconButton, Typography } from '@material-ui/core';

import palette from 'theme/palette';

// Chart configuration
import { options } from './chart';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles)

export default function MonthWithMostConsumeChart(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const monthMostConsume = useSelector(state=>state.groupDashboard.monthMostConsume)

  useEffect(()=>{
    dispatch(getMonthWithMostConsume(id,2020))
  },[])


  const data = {
    labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre', 'Octubre','Noviembre','Diciembre',],
    datasets: [
      {
        label: '2020',
        fill: false,
        backgroundColor:'#C69100',
        borderColor:'#C69100',
        lineTension: 0.1,
        // backgroundColor: palette.primary.main,
        data: monthMostConsume.months_consumes
      }
    
    ]

  }

  return (
    <Portlet
    {...rest}
    className={rootClassName}
  >
    <PortletHeader noDivider>
      <PortletLabel title="Mes de Mayor Consumo" />
      <PortletToolbar>
        <Button
          className={classes.dropdownButton}
          size="small"
          variant="text"
        >
          2020
        </Button>
      </PortletToolbar>
    </PortletHeader>
    <PortletContent>
      
      <div className={classes.stats}>
        <div className={classes.device}>
 
          <Typography
            style={{ color: '#730065' , textTransform : 'uppercase'  }}
            variant="h2"
          >
            {monthMostConsume.month_name}
          </Typography>
        </div>
        <div className={classes.device}>
 
          <Typography
            style={{ color: palette.danger.main }}
            variant="h2"
          >
            {Math.round(monthMostConsume.consume*100)/100} kWh
          </Typography>
        </div>
      </div>
      <div className={classes.chartWrapper}>
        <Line
          data={data}
          options={options}
        />
      </div>
    </PortletContent>
  </Portlet>
  )
}




