export default theme => ({
  root: {
    height: 'fit-content !important'
  },
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartWrapper: {
    height: '400px',
    position: 'relative'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  stats: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing.unit
  },
  deviceIcon: {
    color: 'black',

  }
});
