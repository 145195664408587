import React, { Component, useEffect, useRef } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector   } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormControl, InputLabel,Select,MenuItem, Typography } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import {  getDataInvoiceMeter, updateDataInvoiceMeter, updateDataMeter} from 'js/actions/meterActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

export default function FormMeterInvoiceData(props) {
  const intl = useIntl()
  const { meter } = props.location.state;

  console.log(meter)


  const { getValues, register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const { corpId } = props.match.params;
  const [typePrebilling, setTypePrebilling] = useState();  

  const [administrativeName, setAdministrativeName] = useState();  
  const [administrativeRFC, setAdministrativeRFC] = useState();   
  const [administrativeEmail, setAdministrativeEmail] = useState();     
  const [administrativeEmail2, setAdministrativeEmail2] = useState();     
  const [administrativeCP, setAdministrativeCP] = useState();  
  const [administrativeTel, setAdministrativeTel] = useState();   
  
  
  const [ state , setState] = useState();  
  const [ city , setCity] = useState();  

  const [ calle , setCalle] = useState();  
  const [ cp , setCP] = useState();  
  const [ numExt , setNumExt] = useState();  
  const [ colonia , setColonia] = useState();  

  const estados = useSelector(state => state.zepomex.states.estado);
  const cities = useSelector(state => state.zepomex.cities.municipios);


  // const   ctrlAdministrativeName  = useRef();

  /*
  administrative_cp: "67800"
  administrative_email: "ewrew"
  administrative_email2: "ewrew"
  administrative_name: "prueba"
  administrative_rfc: "PRUEBA RFC"
  administrative_tel: "3344544"
*/


  useOnMount(()=>{
    dispatch(showCountrySates());
  })






  const getState = (state) =>{
    if(state === 'NUEVO LEON'){
      return 'NUEVO LEÓN'
    }else{
      return state
    }
  }



    
  const handleChangeState = state => {
    // setValue('state', state.target.value);
    setState(state.target.value);
    getCity(state.target.value);
  };
 
  const getCity = state => {
    dispatch(showCities(state));
  };
 
  const handleChangeCity = city => {
    // setValue ('city', city.target.value)
    setCity( city.target.value )
    //getCP(city.target.value)
  }
 
  const getCP = city => {
    dispatch(showCPS(city));
  };
 
   

  

  console.log(meter.county)


  useEffect(() => {
    console.log('obteniendo datos de facturacion ')
    dispatch( getDataInvoiceMeter( meter.id , responseGetMeterDataInvoice    ))
  }, []);

  

  const responseGetMeterDataInvoice=(  data )=>{
    console.log(data)
      
    setAdministrativeName( data.administrative_name )
    setAdministrativeRFC( data.administrative_rfc )
    setAdministrativeEmail( data.administrative_email )
    setAdministrativeEmail2( data.administrative_email2 )
    setAdministrativeCP( data.administrative_cp )
    setAdministrativeTel( data.administrative_tel )

    setCalle( data.calle )
    setCP( data.codigo_postal )
    setNumExt (data.numero_exterior )
    setColonia( data.colonia )      
    setState( data.estado )
    getCity(data.estado);
    setCity( data.municipio )

  }



  const onSubmit = values => {
    //console.log('DATA: ', values);
    dispatch(updateDataInvoiceMeter(values,meter,corpId));
  };

  
   

  const handleTypePrebilling = typePrebilling => {
    setTypePrebilling(typePrebilling.target.value);
  };

  const handleChangeAdministrativeName =(event)=> {  
    setAdministrativeName(event.target.value);      
  }

  const handleChangeAdministrativeRFC =(event)=> {  
    setAdministrativeRFC(event.target.value);      
  }

  const handleChangeAdministrativeEmail =(event)=> {  
    setAdministrativeEmail(event.target.value);      
  }


  const handleChangeAdministrativeEmail2 =(event)=> {  
    setAdministrativeEmail2(event.target.value);      
  }

  const handleChangeAdministrativeCP =(event)=> {  
    setAdministrativeCP(event.target.value);      
  }

  const handleChangeAdministrativeTel =(event)=> {  
    setAdministrativeTel(event.target.value);      
  }

  //inicio
 

  const handleChangeCalle =(event)=> {  
    setCalle(event.target.value);      
  }  

  const handleChangeCP =(event)=> {  
    setCP(event.target.value);      
  }  

  const handleChangeNumExt =(event)=> {  
    setNumExt(event.target.value);      
  }  

  const handleChangeColonia =(event)=> {  
    setColonia(event.target.value);      
  }  





  return (
    <DashboardLayout title={meter.name}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            
           
            <Typography variant="h4"  >{intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.title'})}</Typography>
            <Typography variant="h5"  >{`${intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.meterLbl'})} ${meter.name} `} </Typography>
           
            
          </PortletHeader>
          <PortletContent noPadding>

         

            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                   
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.manageNameLbl'})}
                      name="administrative_name"
                      onChange={handleChangeAdministrativeName} 
                      required
                      value={administrativeName}
                      variant="outlined"
                    />      

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.rfcLbl'})}
                      name="administrative_rfc"
                      onChange={handleChangeAdministrativeRFC} 
                      required
                      value={administrativeRFC}
                      variant="outlined"
                    />         

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.preInvoiceByLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="preinvoice_for"
                        onChange={handleTypePrebilling}
                        required
                        value={typePrebilling}
                        //labelWidth={labelWidth}
                      >
                        <MenuItem
                          disabled
                          value=""
                        >{intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.preInvoiceBy.msg'})}</MenuItem>
                        <MenuItem value="METER" >Medidores</MenuItem>
                        <MenuItem value="GROUP">Grupos</MenuItem>
                      </Select>
                    </FormControl>       

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.mgmEmailLbl'})}                    
                      name="administrative_email"
                      onChange={handleChangeAdministrativeEmail}
                      required 
                      value={administrativeEmail}
                      variant="outlined"
                    />         

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.mgmEmail2Lbl'})}                                          
                      name="administrative_email2"
                      onChange={handleChangeAdministrativeEmail2}
                      required 
                      value={administrativeEmail2}
                      variant="outlined"
                    />                                                                       

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.mgmPostalCodeLbl'})}
                      //onChange={this.handleChange}
                      // value={cstate}
                      name="administrative_cp"
                      onChange={handleChangeAdministrativeCP}
                      required                       
                      value={administrativeCP}
                      variant="outlined"
                    />  

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.mgmPhoneLbl'})}   
                      name="administrative_tel"
                      onChange={handleChangeAdministrativeTel}                                             
                      required
                      value={administrativeTel}
                      variant="outlined"
                    />                             


                     
                  </div>


                  <div  style={{marginBottom:'20px' , marginTop: '20px' }} >
                    <Typography variant="h4"  >{intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.title'})}</Typography>
                  </div>
                  <div>


                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.stateLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"  
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="state"
                        onChange={handleChangeState}
                        value={state}
                        //labelWidth={labelWidth}
                      >
                      
                        { estados ?  estados.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando estados') 
                        
                        }
                            
                      </Select>
                    </FormControl>


                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                       
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.cityLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label" 
                        name="city"
                        onChange={handleChangeCity}
                        value={city}
                        //labelWidth={labelWidth}
                      >
                       
                        { cities ?  cities.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>                    

                  

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.streetLbl'})}
                      name="calle"
                      onChange={handleChangeCalle}
                      required
                      value={calle}
                      //defaultValue={meter.address}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      inputRef={register}   
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.cpLbl'})}
                      name="cp"
                      
                      onChange={handleChangeCP}
                      value={cp}
                      variant="outlined"
                    />               

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.outDoorNumbLbl'})}
                      //name="address"
                      name="numExt"
                      onChange={handleChangeNumExt}
                      required
                      value={numExt}
                      variant="outlined"
                    />                           

                    <TextField
                      className={classes.textField}
                      id="county"
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.neighborHoodLbl'})} 
                      name="colonia"
                      onChange={handleChangeColonia}
                      value={colonia}
                      variant="outlined"
                    />



                  </div>





                </Grid>
               
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.preInvoice.invoiceData.taxResidence.updateBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
