import * as preInvoiceGroupActionTypes from 'js/constants/preInvoiceGroup';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function cardReducer(state = { lastMonthConsumes: [],previewInvoice:[] }, action) {
  switch (action.type) {
    case preInvoiceGroupActionTypes.GET_LAST_GROUP_CONSUMES_SUCCESS:
      return { ...state, lastMonthConsumes: action.lastConsumes };

    case preInvoiceGroupActionTypes.POST_PREVIEW_FACTOR_SUCCESS:
      return {
        ...state,
        previewInvoice: action.preview
      };



    // case cardActionTypes.GET_ALL_CARD_SUCCESS:
    //   return { ...state, cards: action.cards };

 
    // case cardActionTypes.POST_CARD_FAILURE:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     loading: false
    //   };

    // case cardActionTypes.GET_CARD_INPUT_SUCCESS:
    //   return {...state, inputs: action.cardsinputs};

    // case cardActionTypes.DELETE_CARD_SUCCESS:
    //     return {
    //       ...state,
    //       cards: state.cards.filter(
    //         ({ id }) => id !== action.payload.id
    //       )
    //     };
    //   case cardActionTypes.PUT_CARD_STARTED:
    //       return {
    //         ...state,
    //         loading: true
    //       };
    // case cardActionTypes.CLEAN_CARD:
    //         return (state = action.payload)
    //         ;

    default:
      return state;
  }
}

export default cardReducer;
