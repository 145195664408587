import * as permissionActionTypes from 'js/constants/permissionAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';

const config = {
  headers: { Authorization: 'Bearer ' + getToken() }
};

export const showPermission = meterID => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/PENDIENTE`,
        config
      )
      .then(response => {
        dispatch(getPermission(response.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getPermission = permission => {
  return {
    type: permissionActionTypes.GET_PERMISSION_SUCCESS,
    permission
  };
};

export const postDataPermission = permission => {
  return async dispatch => {
    dispatch(postPermissionStarted());


    console.log(permission)

    axios
      .post(
        `https://api.metimur.mx/api/role`,
        {
          // name: permission.name,
          // description: permission.description,
          // rate_type: permission.rate_type,
          // meter_id: permission.meter_id
        },
        config
      )
      .then(response => {
        // dispatch(postPermissionSuccess(permission));
      })
      .catch(error => {
        dispatch(postpermissionFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};

export const postPermissionStarted = () => ({
  type: permissionActionTypes.POST_PERMISSION_STARTED
});

export const postPermissionSuccess = data => {
  return {
    type: permissionActionTypes.POST_PERMISSION_SUCCESS,
    payload: {
        name: data.name,
        description: data.description,
        rate_type: data.rate_type,
        meter_id: data.meter_id
    }
  };
};

export const postpermissionFail = error => ({
  type: permissionActionTypes.POST_PERMISSION_FAILURE,
  state: { error }
});
