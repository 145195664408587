export const GET_ROLE_STARTED = 'GET_ROLE_STARTED';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const POST_ROLE_STARTED = 'POST_ROLE_STARTED';
export const POST_ROLE_SUCCESS = 'POST_ROLE_SUCCESS';
export const POST_ROLE_FAILURE = 'POST_ROLE_FAILURE';

export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
