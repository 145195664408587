export const GET_CONCENTRATOR_STARTED = 'GET_CONCENTRATOR_STARTED';
export const GET_CONCENTRATOR_SUCCESS = 'GET_CONCENTRATOR_SUCCESS';
export const GET_CONCENTRATOR_FAILURE = 'GET_CONCENTRATOR_FAILURE';

export const POST_CONCENTRATOR_STARTED = 'POST_CONCENTRATOR_STARTED';
export const POST_CONCENTRATOR_SUCCESS = 'POST_CONCENTRATOR_SUCCESS';
export const POST_CONCENTRATOR_FAILURE = 'POST_CONCENTRATOR_FAILURE';
export const DELETE_CONCENTRATOR_SUCCESS = 'DELETE_CONCENTRATOR_SUCCESS';

export const PUT_CONCENTRATOR_STARTED = 'PUT_CONCENTRATOR_STARTED';

export const CLEAN_CONCENTRATOR = 'CLEAN_CONCENTRATOR';
