import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import { Button, TextField, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function NameConcentratorForm(props) {
  const intl = useIntl()
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.name.concenNameLbl'})}
          name="concentrator_name"
          required
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.name.concenDescLbl'})}
          name="concentrator_description"
          required
          variant="outlined"
        />

        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          className={classes.button}
        >
          {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.name.continueBtn'})}
        </Button>
      </form>
    </Grid>
  );
}

export default NameConcentratorForm;
