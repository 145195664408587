import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Grid } from '@material-ui/core';

import { postForm,deleteLastPostForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  concentratorPhase: null,
  concentratorType: null,
  baudrate: null
};

function UserConcentratorForm(props) {
  const user = useSelector(state=>state.user.user)
  const meterAdmin = useSelector(state=>state.user.meter)

  const meterUser = user.meters

  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser[0].id

  let concentrators = useSelector(state => state.concentrators.concentrators);

  const [num_inputs, setNumInputs] = useState(null)
  const [unit, setUnit] = useState('VOLTAJE')

  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();

  const onSubmit = values => {
    //console.log(values)
     dispatch(postForm(values));
     props.nextStep();
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }


  const handleNumInputs = numInputs => {
    setNumInputs(numInputs.target.value);
  };

  const handleUnit = unit => {
    setUnit(unit.target.value);
  };

  const handleCardElementChange = elementType => {
    setValue('elementType', elementType.target.value);
  };

  const handleCardTypeChange = chargeType => {
    setValue('chargeType', chargeType.target.value);
  };

  const handleConcentratorPhase = concentratorPhase => {

    setValue('concentratorPhase', concentratorPhase.target.value)
  }

  useOnMount(() => {
    register({ name: 'elementType', type: 'text' });

    register({ name: 'chargeType', type: 'text' });

    register({ name: 'concentratorPhase', type: 'text'})
  });

  useEffect(()=>{
    dispatch(showConcentrators(meterId));
  },[])

  const values = getValues();

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            Tipo de  Medición
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleCardTypeChange}
            value={values.chargeType}

            //labelWidth={labelWidth}
          >
            <MenuItem value={'MONOFASICA'}>MONOFASICA</MenuItem>
            <MenuItem value={'BIFASICA'}>BIFASICA</MenuItem>
            <MenuItem value={'TRIFASICA'}>TRIFASICA</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            Concentrador
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleConcentratorPhase}
            value={values.concentratorPhase}
            //labelWidth={labelWidth}
          >
            
            {concentrators.map(concentrator => (
              <MenuItem value={concentrator.id}>{concentrator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          className={classes.button}

        >
          Guardar y Continuar
        </Button>
        <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          Regresar 
        </Button>
      </form>
    </Grid>
  );
}

export default UserConcentratorForm;
