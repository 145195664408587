export const GET_MONTH_MOST_CONSUME_SUCCESS = 'GET_MONTH_MOST_CONSUME_SUCCESS';

export const GET_COMPARATIVE_YEARS_CONSUME_SUCCESS = 'GET_COMPARATIVE_YEARS_CONSUME_SUCCESS';

export const GET_DAY_WITH_MOST_CONSUME_SUCCESS = 'GET_DAY_WITH_MOST_CONSUME_SUCCESS';

export const GET_TOP_CHARGE = 'GET_TOP_CHARGE';

export const POST_LOAD_CHARGE = 'POST_LOAD_CHARGE';
export const POST_LAST_LOAD_CHARGE = 'POST_LAST_LOAD_CHARGE';

export const GET_UNIFIQUED_RECEIPT_SUCCESS = 'GET_UNIFIQUED_RECEIPT_SUCCESS';

export const GET_LOAD_HISTORY = 'GET_LOAD_HISTORY';