import * as cardActionTypes from '../constants/cardAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function cardReducer(state = { cards: [], inputs:[] , loading: false }, action) {
  switch (action.type) {
    case cardActionTypes.POST_CARD_STARTED:
      return {
        ...state,
        loading: true
      };
    case cardActionTypes.POST_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.push(action.payload),
        loading: false
      };
    case cardActionTypes.POST_CARD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case cardActionTypes.GET_CARD_SUCCESS:
      return {...state, cards: action.cards};
    
      case cardActionTypes.GET_ALL_CARD_SUCCESS:
      return {...state, cards: action.cards};
    
    case cardActionTypes.GET_CARD_INPUT_SUCCESS:
      return {...state, inputs: action.cardsinputs};
    
    case cardActionTypes.DELETE_CARD_SUCCESS:
        return {
          ...state,
          cards: state.cards.filter(
            ({ id }) => id !== action.payload.id
          )
        };
      case cardActionTypes.PUT_CARD_STARTED:
          return {
            ...state,
            loading: true
          };
    case cardActionTypes.CLEAN_CARD:
            return (state = action.payload)
            ;
    

    default:
      return state;
  }
}

export default cardReducer;
