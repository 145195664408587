import * as groupActionTypes from 'js/constants/groupAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'




const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showGroups = meterID => {
  return async dispatch => {
    dispatch(getGroupsStarted())
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterID}/groups`,
        getconfig()
      )
      .then(response => {
        dispatch(getGroups(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
        dispatch(getGroupsFailure(error))
      });
  };
};

export const showAllGroups = (corpId,meterId) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corpId}/meter/${meterId}/groups`,
        getconfig()
      )
      .then(response => {
        dispatch(getGroups(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};


export const getGroupsList = (corpId,meterId) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corpId}/meter/${meterId}/groups`,
        getconfig()
      )
      .then(response => {
        dispatch(getGroupsListAction(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getGroupsStarted = () => {
  return {
    type: groupActionTypes.GET_GROUP_STARTED,
    loading: true
  }
}

export const getGroups = groups => {
  return {
    type: groupActionTypes.GET_GROUP_SUCCESS,
    groups
  };
};

export const getGroupsFailure = error => {
  return {
    type: groupActionTypes.GET_GROUP_FAILURE,
    state: {error}
  }
}

export const getGroupsListAction = groups => {
  return {
    type: groupActionTypes.GET_GROUP_LIST_SUCCESS,
    groups
  };
};

export const getChargeList = () => {
  return async dispatch => {
    axios
      .get(
        'https://api.metimur.mx/api/group/charges_type/list',
        getconfig()
      )
      .then(response => {
        dispatch(getChargeSuccess(response.data.data));
      })
      .catch(function(error) {
        console.log('Error: ', error);
      })
  }
}

export const getChargeSuccess = charge_types => {
  return {
    type: groupActionTypes.GET_CHARGE_TYPE_LIST,
    charge_types
  }
}


export const postDataGroup = (group,meterId,corpId, iva) => {

  if (group.is_custom_tariff == true) {
    var is_custom_tariff = '1'
    var rate_type = 'EMPTY'
    var custom_tariff = group.custom_tariff
  }else{
    var is_custom_tariff = '0'
    var rate_type = group.rateType
    var custom_tariff = '0'
  }

  const energys = [
    'Electricidad',
    'Solar',
    'Gas',
    'Agua',
    'Eolica'
  ]

  const typeEnergy = energys.findIndex(energy => energy === group.type_energy);

  return async dispatch => {
    dispatch(postGroupStarted());

    axios
      .post(
        `https://api.metimur.mx/api/group`,
        {
          name: group.name,
          description: group.description,
          rate_type: rate_type,
          monitoring_fee: group.monitoring_fee,
          meter_id: meterId,
          corporative_id:corpId,
          is_custom_tariff:is_custom_tariff,
          custom_tariff: custom_tariff,
          flat_fee: group.flat_fee,
          type_energy: typeEnergy,
          bill: group.bill ? 1 : 0,
          iva: iva
        },
        getconfig()
      )
      .then(response => {
        dispatch(postGroupSuccess(group));
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Grupo ${group.name} creado exitosamente`,
          icon: 'success',
        }).then(
          //()=>{history.push(`/meter/${meterId}`)}
          history.push( {
            pathname: `/meter/${meterId}` ,                   
            state: { meter : meterId }
          }  )


        );
      })      
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
        dispatch(postGroupFail(error.message));
      })
  };
};

export const postGroupStarted = () => ({
  type: groupActionTypes.POST_GROUP_STARTED
});

export const postGroupSuccess = data => {
  return {
    type: groupActionTypes.POST_GROUP_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      rate_type: data.rate_type,
      meter_id: data.meter_id,
      is_custom_tariff: data.is_custom_tariff,
      custom_tariff: data.custom_tariff        
    }
  };
};

export const postGroupFail = error => ({
  type: groupActionTypes.POST_GROUP_FAILURE,
  state: { error }
});

export const deleteGroup = (groupId, meterId) => {
  return async dispatch => {

    dispatch(deleteGroupSuccess(groupId));
    axios.post(
      `https://api.metimur.mx/api/group/delete`,
      { group_id: groupId, meter_id: meterId },
      getconfig()
    )
    ;
  };
};

export const deleteGroupSuccess = groupId => {
  return {
    type: groupActionTypes.DELETE_GROUP_SUCCESS,
    payload:{id: groupId} 
  };
};


export const updateDataGroup = (group, groupData, iva) => {
  let is_custom_tariff, rate_type, custom_tariff;

  if (group.is_custom_tariff == true) {
    is_custom_tariff = '1'
    rate_type = 'EMPTY'
    custom_tariff = group.custom_tariff
  }else{
    is_custom_tariff = '0'
    rate_type = group.rateType
    custom_tariff = '0'
  }

  const energys = [
    'Electricidad',
    'Solar',
    'Gas',
    'Agua',
    'Eolica'
  ]

  const typeEnergy = energys.findIndex(energy => energy === group.type_energy);

  return async dispatch => {
    dispatch(updateGroupStarted());

    axios
      .put(
        `https://api.metimur.mx/api/group`,
        {
          group_id:groupData.id,
          name: group.name,
          description: group.description,
          rate_type: rate_type,
          monitoring_fee: group.monitoring_fee,
          meter_id: groupData.meter_id,
          //corporative_id:group.corporative_id,
          is_custom_tariff:is_custom_tariff,
          custom_tariff: custom_tariff,
          flat_fee: group.flat_fee,
          type_energy: typeEnergy,
          bill: group.bill ? 1 : 0,
          iva: iva
        },
        getconfig()
      )
      .then(response => {
        dispatch(postGroupSuccess(group));
      })
      .then(()=>{
        Swal.fire({
          title: 'Actualizacion Correcta',
          text: `Grupo ${group.name} actualizado exitosamente`,
          icon: 'success',
        }).then(
          //  ()=>{history.push(`/meter/${groupData.meter_id}`)}
          ()=>{
            history.push( {
              pathname: `/meter/${groupData.meter_id}` ,                   
              state: { group : groupData }
            }  )
                  
          }


        );
      })      
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
        dispatch(postGroupFail(error));
      });
  };
};

export const updateGroupStarted = () => ({
  type: groupActionTypes.PUT_GROUP_STARTED
});


export const getConsumtionAndWeight = (groupId , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupId}/billing`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};


export const countStatus = (groupId,funcResp) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/group/${groupId}/charges/countsstatus`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};