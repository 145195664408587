/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from 'helpers/history';
import { useEffect } from 'react';
import { useState } from 'react';
import { getUserAlertsCns , deleteAlert } from 'js/actions/userAlertsAction'
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';


export default function CorpTable(props) {
  const alerts = useSelector(state => state.userAlert.userAlertsCns);
  const dispatch = useDispatch();

  const data = [];
 
  const alert_prior=[ 'BAJO' , 'MEDIO' , 'ALTO' ];

  const [state, setState] = useState({
    columns: [
      {
        field: 'name',
        title: 'Nombre'
      },
      {
        field: 'message',
        title: 'Mensaje'
      },
      {
        field: 'lvl',
        title: 'Nivel'  ,
        render: rowData =>{    return  alert_prior[ rowData.lvl-1]+ ' '     },
      } 
    ] ,

    actions: [

      {
        icon: 'delete',
        tooltip: 'Eliminar Alerta',
        onClick: (event, rowData) => deleteAlerta(rowData)


      } ,    
      {
        icon: 'edit',
        tooltip: 'Editar Alerta',
        onClick: (event, rowData) => {
          
          history.push({ 
            pathname: `/editUserAlet/${rowData.id}`,
            state:{alert : rowData}
          })     }
      }
    ]



  });
  

  useEffect(() => {        
    dispatch(getUserAlertsCns(props.chargeId))
  }, [])

 
  const funRef = () => {
    dispatch(  getUserAlertsCns(props.chargeId)); 
       
  }
    
  const deleteAlerta = alert => {
    //  console.log("delete alerta ");

    Swal.fire({
      title: 'Está seguro que desea eliminar la alerta ?',
      text: 'El borrado es irreversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí,eliminar' , 
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value==true ) {        
        dispatch(deleteAlert(alert.id , props.chargeId , funRef ));
      }
    })




        
  };
 

  if( alerts ){
    Object.values(alerts).forEach( alert =>{   
      data.push(alert)      
    }) 
  }



  const columns = [
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'message',
      label: 'Mensaje'
    },
    {
      name: 'lvl',
      label: 'Nivel de Alerta' ,  
      options : {

        customBodyRender: (value, tableMeta, updateValue) => {
          
          if( value==1 ){
            return 'BAJA'
          }else if( value==2 ){
            return 'MEDIA'
          }else if( value==3 ){
            return 'ALTA'
          }else {
            return ''
          }
   
        }
 
      }

 
    },
    {
      name: 'charge_name',
      label: 'Carga'
    }
  ];



  return (

   


    <div>


      <MaterialTable
      
        actions={state.actions}
        columns={state.columns}
        data={data}
        options={{
          showTitle: false,
          actionsColumnIndex: -1
        }}
      />

 
    </div>
  )
}