import * as ZEPOMEXactionsTypes from '../constants/ZEPOMEX-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function ZEPOMEXReducer(state = { states: [], cities : [], cp:[],counties:[], loading: false }, action) {
  switch (action.type) {
    case ZEPOMEXactionsTypes.GET_STATES_STARTED:
      return {
        ...state,
        loading: true
      };
    case ZEPOMEXactionsTypes.GET_STATES_SUCCESS:
      return {
        ...state, states: action.estados
      };
    case ZEPOMEXactionsTypes.GET_CITIES_STARTED:
      return {
        ...state,
        loading: true
      };
    case ZEPOMEXactionsTypes.GET_CITIES_SUCCESS:
      return {
        ...state, cities: action.cities
      };
    case ZEPOMEXactionsTypes.GET_CP_STARTED:
      return {
        ...state,
        loading: true
      };
    case ZEPOMEXactionsTypes.GET_CP_SUCCESS:
      return {
        ...state, cp: action.cp
      };
    case ZEPOMEXactionsTypes.GET_COUNTIES_STARTED:
      return {
        ...state,
        loading: true
      };
    case ZEPOMEXactionsTypes.GET_COUNTIES_SUCCESS:
      return {
        ...state, counties: action.counties
      };
    default:
      return state;
  }
}

export default ZEPOMEXReducer;
