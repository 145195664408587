import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import { showCFDI,showPaymentMethods,showPaymentTypes,postInvoice} from 'js/actions/invoiceActions'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import MaterialTable from 'material-table';
import { showAllMeters } from 'js/actions/meterActions';
import { showAllGroups } from 'js/actions/groupActions';
import { showAllCharge } from 'js/actions/chargeActions';
// Material helpers
import { withStyles,Typography } from '@material-ui/core';

// Material components
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles
} from '@material-ui/core';

import * as action from 'js/actions/userActions';
import { showRoles } from 'js/actions/roleActions';
import Swal from 'sweetalert2';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return useEffect(handler, []);
}

const defaultValues = {
  role: null,
  meter: null,
  group: null,
  charge: null
};

export default function UserForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { setValue, getValues, handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const { corpId } = props.match.params;
  const roles = useSelector(state => state.roles.roles);
  const meters = useSelector(state => state.meters.meters);
  const groups = useSelector(state => state.groups.groups);
  const charges = useSelector(state => state.charges.charges);

  const cfdi = useSelector(state => state.invoice.cfdi);
  const payment_types = useSelector(state => state.invoice.payment_types);
  const payment_methods = useSelector(state => state.invoice.payment_methods);

  const [role, setRole] = React.useState(null);
  const [meter, setMeter] = React.useState(null);
  const [group, setGroup] = React.useState({group_id:'...',group:'Todas las Mediciones'});
  const [charge, setCharge] = React.useState({charge_id:'...',charge:'Todas las Mediciones'});
  const [cfdi_form, setCfdiForm] = React.useState();
  const [payment_types_form, setPaymentTypeForm] = React.useState();
  const [payment_methods_form, setPaymentMethodForm] = React.useState();


  useEffect(() => {
    dispatch(showRoles(corpId));
  }, []);

  useEffect(() => {
    dispatch(showAllMeters(corpId));
  }, []);

  useEffect(() => {
    dispatch(showCFDI());
    dispatch(showPaymentTypes());
    dispatch(showPaymentMethods());
  }, [])


  const handleChange = event => {
    setRole(event.target.value);
  };





  const handleChangeMeter = event => {
    setMeter(event.target.value);
    dispatch(showAllGroups(corpId, event.target.value.id));
  };

  const handleChangeGroup = event => {
    setGroup(event.target.value);
    dispatch(showAllCharge(event.target.value.id));
  };

  const handleChangeCharge = event => {
    setCharge(event.target.value);
    //dispatch(showAllCharge(event.target.value))
  };

  const onSubmit = user => {

     
    if( user.rfc  ){
      let msgError='<pre>';
      let error=false;
      if(!user.razon_social ){
        msgError=intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.socReason.msgError'});
        error=true;
      }

      if( !user.cfdi ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.cfdi.msgError'});
        error=true;              
      }

      if( !user.payment_type ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.payType.msgError'});
        error=true;              
      }

      if( !user.payment_method ){
        msgError+=intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.payMethod.msgError'});
        error=true;              
      }          

      msgError+='</pre>';


      if( error ){
        Swal.fire({
          title: intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.saveError.title'}),
          html:  msgError  ,
          icon: 'error',
        });      
        
        return;            
      }
      
    }


    if( user.rfc=='' ){
      user.rfc=null;
    }

    dispatch(action.signUp(user, corpId, state.data));
    
  };


  const handleCFDI = cfdi => {
    setCfdiForm(cfdi.target.value);
  };

  const handlePaymentTypes = payment_type => {
    setPaymentTypeForm(payment_type.target.value);
  };

  const handlePaymentMethods = payment_methods => {
    setPaymentMethodForm(payment_methods.target.value);
  };
				




  const [state,setState]=React.useState({data:[],
    columns: [
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.grid.colMeasure'}), field: 'meter' },
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.grid.colGroup'}), field: 'group' },
      { title: intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.grid.colCarga'}), field: 'charge' }
    ]
  
  
  
  })

  const setTable = () => {
    let obj = {
      corporative_id:corpId,
      meter_id: meter.id,
      meter: meter.name,
      group_id: group.id,
      group: group.name,
      charge_id: charge.id,
      charge: charge.name
    };

    setState({
      ...state,
      data:[
        ...state.data,
        obj
      ]
    })
    setMeter(null)
    setGroup({group_id:'0',group:'Todas las Mediciones'})
    setCharge({group_id:'0',group:'Todas las Mediciones'});
  };

  return (
    <DashboardLayout title="Creacion de Usuarios">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.firstNameLbl'})}
                      name="firstname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.lastNameLbl'})}
                      name="lastname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.emailLbl'})}
                      name="email"
                      required
                      //onChange={this.handleChange}
                      //value={email}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      helperText={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.passwordRules'})}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.passwordLbl'})}
                      name="password"
                      required
                      type="password"
                      variant="filled"
                    />


                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.phoneNumberLbl'})}
                      name="tel"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.cellPhoneLbl'})}
                      name="cel"
                      required
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.rolLbl'})}</InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="role"
                        onChange={handleChange}
                        value={role}
                      >
                        {roles.map(role => (
                          <MenuItem value={role.id}>{role.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                  </div>
                </Grid>
             
              </Grid>

        
              <form
                style={{marginBottom: '10px', marginTop: '20px'}}
              >
                <Typography
                  //className={classes.title}
                  variant="h4"
                >
                  {intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.head'})}
                </Typography>



                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                  >
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.meterLbl'})}
                      </InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="meter"
                        onChange={handleChangeMeter}
                        value={meter}
                      >
                    
                        {meters.map(meter => (
                          <MenuItem value={meter}>{meter.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                  >
                    <FormControl
                      className={classes.textField}
                    
                    >
                      <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.groupLbl'})}</InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="group"
                        onChange={handleChangeGroup}
                        value={group}
                      >
                      
                        {groups.map(group => (
                          <MenuItem value={group}>{group.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                  >
                    <FormControl
                      className={classes.textField}
                    
                    >
                      <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.chargeLbl'})}</InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="charge"
                        onChange={handleChangeCharge}
                        value={charge}
                      >
                      
                        {charges.map(charge => (
                          <MenuItem value={charge}>{charge.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                  >
                    <Button
                      className={classes.signUpButton}
                      color="secondary"
                      onClick={setTable}
                      //type="submit"
                      size="large"
                      variant="outlined"
                    >
                      {intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.addToListBtn'})}
                    </Button>
                  </Grid>

                </Grid>
              </form>
              <MaterialTable
                columns={state.columns}
                data={state.data}
                options={{toolbar:false}}
              />
              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.users.usersList.corpusers.addUser.configUserAccess.registerBtn'})}
              </Button>
            </form>

          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
