/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { setGroupId } from 'js/actions/userActions.js';

import { makeStyles, Typography, Button, Grid, IconButton, Tooltip, Menu, MenuItem } from '@material-ui/core';

import { Paper } from 'components';
import MaterialTable from 'material-table';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import { Settings } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { deleteGroup } from 'js/actions/groupActions';
import { getConsumesLastMonth } from 'js/actions/preInvoiceGroupAction';
import { isAllowed } from 'helpers';
const useStyles = makeStyles(styles);

export default function GroupCard(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { className, group, meter, setSelectedGroup, setShowDetails } = props;
  const rootClassName = classNames(classes.root, className);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { consumes } = group;
  const consumesTable = [consumes[0], consumes[1]];
  const user = useSelector(state => state.user.user);

  const getConsumesLastMonthData = (groupId, year, month) => {
    dispatch(getConsumesLastMonth(groupId,year,month))
  }

  const setAdminGroupId = groupId => {
    dispatch(setGroupId(groupId));
  };

  const data = [];
  let obj;
  const columns = [
    {
      title: ' ',
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.groupsCards.card.gridCard.baseCol'
      }),
      field: 'bas',
      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.groupsCards.card.gridCard.intermidateCol'
      }),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.groupsCards.card.gridCard.tipCol' }),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  consumesTable.forEach(element => {
    obj = {
      med: element[0],
      bas: element[1],
      int: element[2],
      punt: element[3]
    };

    data.push(obj);
  });

  const deleteGroupData = (groupId, meter) => {
    Swal.fire({
      title: `${intl.formatMessage({id:'app.corporate.grid.btnDelete.title'})} `,
      text: `${intl.formatMessage({id:'app.corporate.grid.btnDelete.text'})} ${groupId.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'app.corporate.grid.btnDelete.confirmation'})
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: intl.formatMessage({id: 'app.corporate.grid.btnDelete.titleConfirmation'}),
          text: intl.formatMessage({id: 'app.corporate.grid.btnDelete.textConfirmation'}),
          icon: 'success'
        });
        dispatch(deleteGroup(groupId.id, meter));
      }
    });
    setAnchorEl(null);
  };

  const handleShowDetails = () => {
    setSelectedGroup(group);
    setShowDetails(true);
    setAnchorEl(null)
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const isAllow = isAllowed(user.permissions, ['group_read']) ? 6 : 12

  return (
    <Paper className={rootClassName}>
      <div className={classes.details}>
        <Grid
          spacing={1}
          style={
            group.rate_type !== 'GDMTH'
              ? {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between'
              }
              : {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }
          }
          xs={12}
        >
          <Grid
            alignItems="flex-start"
            container
            item
            justifyContent="space-between"
            spacing={1}
            style={{
              margin:
                group.type_energy === 2 ? '2px 2px 1.5rem' : '2px 2px 1rem',
              justifyContent: 'space-between'
            }}
            xs={12}
          >
            {group.type_energy === 2 && (
              <Grid
                item
                lg={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 5
                }}
                xs={6}
              >
                <img
                  src="/images/save-energy.png"
                  width="50px"
                />
                <Typography
                  style={{
                    color: '#45B880',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                  variant="h5"
                >
                  {intl.formatMessage({
                    id: 'app.chargeSolar.board.mainHead.title'
                  })}
                </Typography>
              </Grid>
            )}
            <Grid
              className={classes.buttonMore}
              item
              lg={group.type_energy === 2 ? 6 : 12}
              xs={group.type_energy === 2 ? 6 : 12}
            >
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label="settings"
                className={classes.settings}
                onClick={handleShowMenu}
              >
                <Tooltip title={intl.formatMessage({ id: 'app.chargesCards.addCharge.stepper.setting.title' })}>
                  <Settings />
                </Tooltip>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                elevation={1}
                id="simple-menu"
                keepMounted
                onClose={handleCloseMenu}
                open={open}
              >
                <MenuItem onClick={handleShowDetails}>
                  {intl.formatMessage({ id: 'app.details.btn' })}
                </MenuItem>
                {/* <MenuItem
                  onClick={() => getConsumesLastMonthData(group.id, new Date().getFullYear(),new Date().getMonth())}
                >
                  <Link
                    style={
                      {
                        textDecoration: 'none',
                        color: '#000'
                      }
                    }
                    to={
                      {
                        pathname: `/invoiceDataGroup/${group.id}`,
                        state: { group: group }
                      }
                    }
                  >
                    {intl.formatMessage({id: 'app.groupsCards.preInvBtn'})}
                  </Link>
                </MenuItem> */}
                {isAllowed(user.permissions, ['group_delete']) && (
                  <MenuItem onClick={()=> deleteGroupData(group, meter)}>{intl.formatMessage({id: 'app.groupsCards.deleteGroupBtn'})}</MenuItem>
                )}
                {isAllowed(user.permissions, ['group_update']) && (
                  <MenuItem>
                    <Link
                      style={{ textDecoration: 'none', color: '#000' }}
                      to={{
                        pathname: `/updateGroup/${group.id}`,
                        state: { group }
                      }}
                    >
                      {intl.formatMessage({id: 'app.groupsCards.editGroupBtn'})}
                    </Link>
                  </MenuItem>
                )}
              </Menu>
              {/* <Button
                color="secondary"
                onClick={handleShowDetails}
                style={{
                  backgroundColor: group.type_energy === 2 ? '#45B880' : ''
                }}
                variant="contained"
              >
                {intl.formatMessage({ id: 'app.details.btn' })}
              </Button> */}
            </Grid>
            <Grid
              container
              direction="column"
              item
              justifyContent="center"
              lg={6}
              style={{ padding: '2px' }}
              xs={6}
            >
              <Typography variant="h5">
                {group.name}
                <br />
              </Typography>
              <Typography variant="body1">
                {group.description}
                <br />
              </Typography>
            </Grid>
            <Grid
              alignItems="center"
              direction="column"
              item
              justifyContent="center"
              lg={5}
              style={{ textAlign: 'right' }}
              xs={5}
            >
              {group.type_energy === 2 ? (
                <img
                  src="/images/co2-icon.png"
                  style={{ width: '50px' }}
                />
              ) : (
                <img
                  src="/images/icono3_co2.png"
                  style={{ width: '50px', height: '50px' }}
                />
              )}
              <Typography
                style={{
                  color: group.type_energy === 2 ? '#45B880' : '#FF8024',
                  fontWeight: 'bold'
                }}
              >
                {group.consumes.co2} kg *
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {group.rate_type !== 'GDMTH' ? (
              <Grid container>
                <Grid
                  item
                  xs={6}
                >
                  <Typography
                    className={classes.description}
                    style={{ marginTop: '10px', color: 'carbon' }}
                    variant="h5"
                  >
                    {intl.formatMessage({
                      id: 'app.groupsCards.card.maxDemPerDay'
                    })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Typography
                    className={classes.description}
                    style={{
                      color: '#A53693',
                      fontWeight: '600',
                      marginTop: '10px'
                    }}
                    variant="h4"
                  >
                    {consumes.demand_max} kW
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Typography
                    className={classes.description}
                    style={{ marginTop: '10px', color: 'carbon' }}
                    variant="h5"
                  >
                    {intl.formatMessage({
                      id: 'app.groupsCards.card.totalKwPerDay'
                    })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Typography
                    className={classes.description}
                    style={{
                      color: '#A53693',
                      fontWeight: '600',
                      marginTop: '10px'
                    }}
                    variant="h4"
                  >
                    {consumes.total_kwh} kWh
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                columns={columns}
                data={data}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  padding: 0,
                  rowStyle: {
                    fontSize: '15px'
                  },
                  headerStyle: {
                    textAlign: 'center',
                    fontSize: '0.9em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
                style={{
                  boxShadow:
                    group.type_energy === 2
                      ? '0px 1px 5px 5px rgba(69, 184, 128, 0.5), 0px 2px 2px 0px rgba(69, 184, 128, 0.5), 0px 3px 1px -2px rgba(69, 184, 128, 0.5)'
                      : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
                }}
              />
            )}
          </Grid>
          <Grid
            lg={12}
            spacing={2}
            style={{
              display: 'flex',
              gap: '2rem',
              justifyContent: 'flex-end',
              marginRight: '1rem'
            }}
            xs={12}
          >
            <Typography
              className={classes.description}
              style={{ marginTop: '10px', color: '#000' }}
              variant="h5"
            >
              {group.type_energy === 2
                ? intl.formatMessage({
                  id: 'app.chargesSolarCards.card.estAmtPerDay'
                })
                : intl.formatMessage({
                  id: 'app.groupsCards.card.estAmtPerDay'
                })}
            </Typography>
            <Typography
              className={classes.description}
              style={{
                color: '#218A1D',
                fontWeight: '600',
                marginTop: '10px'
              }}
              variant="h5"
            >
              $ {consumes.total}
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            spacing={1}
            xs={12}
          >
            <Grid
              item
              xs={isAllow}
            >
              <Link
                onClick={() => setAdminGroupId(group.id)}
                to={{
                  pathname: `/group/${group.id}`,
                  state: {
                    group: group,
                    meter: meter
                  }
                }}
              >
                <Button
                  color="secondary"
                  style={{
                    width: '100%',
                    backgroundColor: group.type_energy === 2 ? '#45B880' : ''
                  }}
                  variant="contained"
                >
                  {intl.formatMessage({
                    id: 'app.groupsCards.card.chargesBtn'
                  })}
                </Button>
              </Link>
            </Grid>
            {isAllowed(user.permissions, ['group_read']) && (
              <Grid
                item
                spacing={2}
                xs={6}
              >
                <Link
                  to={{
                    pathname: `/groupDashboard/${group.id}`,
                    state: {
                      group
                    }
                  }}
                >
                  <Button
                    color="secondary"
                    style={{
                      width: '100%',
                      backgroundColor: group.type_energy === 2 ? '#45B880' : ''
                    }}
                    variant="contained"
                  >
                    {intl.formatMessage({ id: 'app.groupsCards.card.boardBtn' })}
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            style={{ marginTop: '.5rem' }}
            xs={12}
          >
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center' }}
              variant="body1"
            >
              {intl.formatMessage({
                id: 'app.groupsCards.card.meassCurrMonth'
              })}
            </Typography>
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center' }}
              variant="body1"
            >
              {intl.formatMessage({ id: 'app.groupsCards.card.co2Msg' })}
            </Typography>
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center' }}
              variant="body1"
            >
              {intl.formatMessage({ id: 'app.groupsCards.card.fee' })}{' '}
              {group.rate_type}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
