import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
//import { postForm } from 'js/actions/formsAction';
import {  postDataVoltage } from 'js/actions/chargeActions';
import { deleteLastPostForm } from 'js/actions/formsAction';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const classes = useStyles();
  const formData = useSelector(state => state.forms);
  const dispatch = useDispatch();
  const { data } = formData;
  const {meterId} = props.ids

  console.log(props.ids)

  //console.log(props)

  const completeCharge = charge => {
    dispatch(postDataVoltage(charge));
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }
  

  const getInputs = chargeType => {
    switch (chargeType) {
      case 'MONOFASICA':
        return [
          {
            input: data[2].data.inpunt1PhaseA,
            phase: '1',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          }
        ];

      case 'BIFASICA':
        return [
          {
            input: data[2].data.inputPhaseA,
            phase: '1',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          },
          {
            input: data[2].data.inputPhaseB,
            phase: '2',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseB,
            ajust: data[2].data.ajustPhaseB,
            relation: data[2].data.relationPhaseB,
            offset: data[2].data.offsetPhaseB
          }
        ];

      case 'TRIFASICA':
        return [
          {
            input: data[2].data.inputPhaseA,
            phase: '1',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseA,
            relation: data[2].data.relationPhaseA,
            offset: data[2].data.offsetPhaseA
          },
          {
            input: data[2].data.inputPhaseB,
            phase: '2',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseB,
            relation: data[2].data.relationPhaseB,
            offset: data[2].data.offsetPhaseB
          },
          {
            input: data[2].data.inputPhaseC,
            phase: '3',
            //unit: data[1].data.unit,
            card_id: data[2].data.cardPhaseA,
            ajust: data[2].data.ajustPhaseC,
            relation: data[2].data.relationPhaseC,
            offset: data[2].data.offsetPhaseC
          }
        ];
    }
  };

  const charge = {
    name: data[0].data.charge_name,
    description: data[0].data.charge_description,
    ubication: data[0].data.charge_ubication,
    element_type: data[1].data.elementType,
    charge_type: data[1].data.chargeType,
    num_inputs: data[1].data.num_inputs,
    concentrator_id: data[1].data.concentratorPhase,
    group_id: props.ids.groupId,
    meter_id: meterId,
    fix_fp: data[2].data.fp,
    fix_voltage: data[2].data.voltage,
    inputs: getInputs(data[1].data.chargeType),
    consumes: [
      ['kw', '0.0000', '0.0000', '0.0000'],
      ['kwh', '0.00', '0.00', '0.00']
    ]
  };

  console.log(charge);

  return (
    <Grid
      className={classes.root}
      container
    >
      <div className={classes.form}>
        <Typography variant="h4">Confirmacion de Medicion</Typography>

        <Typography variant="body1">
          {' '}
          Nombre de Medicion: {charge.name}{' '}
        </Typography>
        <Typography variant="body1">
          {' '}
          Descripcion : {charge.description}
        </Typography>
        <Typography variant="body1">
          {' '}
          Tipo de Elemento : {charge.element_type}
        </Typography>

        <Typography variant="body1">
          {' '}
          Tipo de Medicion: {charge.charge_type}
        </Typography>

        <Button
          className={classes.button}
          color="secondary"
          onClick={() => completeCharge(charge)}
          size="large"
          variant="contained"
        >
          Confirmar
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          Regresar 
        </Button>
      </div>
    </Grid>
  );
}

export default NameRoleForm;
