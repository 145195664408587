import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography, makeStyles } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './styles'
import ReactSpeedometer from 'react-d3-speedometer';
import { getLoadFactorHistory, postLastLoadCharge, postLoadCharge } from 'js/actions/groupDashboardActions';
import theme from 'theme';
import { Line } from 'react-chartjs-2';
const useStyles = makeStyles(styles);

const LoadFactor = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chargeId } = props
  const {className,  ...rest } = props;
  const [open, setOpen] = useState(false)
  const rootClassName = classNames(classes.root, className);
  const loadFactorValue = useSelector(state=>state.groupDashboard.loadCharge)
  const lastLoadFactorValue = useSelector(state => state.groupDashboard.lastLoadCharge);
  const loadHistory = useSelector(state => state.groupDashboard.loadHistory)
  const meses= [ 
    intl.formatMessage({id: 'app.months.jan'}) ,
    intl.formatMessage({id: 'app.months.feb'}),
    intl.formatMessage({id: 'app.months.mar'}),
    intl.formatMessage({id: 'app.months.apr'}),
    intl.formatMessage({id: 'app.months.may'}),
    intl.formatMessage({id: 'app.months.jun'}),
    intl.formatMessage({id: 'app.months.jul'}),
    intl.formatMessage({id: 'app.months.aug'}),
    intl.formatMessage({id: 'app.months.sep'}),
    intl.formatMessage({id: 'app.months.oct'}),
    intl.formatMessage({id: 'app.months.nov'}),
    intl.formatMessage({id: 'app.months.dec'})
  ];

  const formattedData = loadHistory && loadHistory.map(item => ({
    month: parseInt(item.month.slice(-2)),
    value: parseFloat(item.load_factor).toFixed(2)
  }));
  

  const mesesOrdenados = formattedData && (formattedData.map(item => meses[item.month - 1])).reverse();
  const datos = formattedData && (formattedData.map(item => parseFloat(item.value))).reverse();

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  const lastMonth = meses[currentMonth - 1];

  const getDateRange = () => {
    let startDate, endDate;

    if(currentDay === 1){
      const previousMonth = new Date(currentYear, currentMonth - 1, 1);
      startDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
      endDate = new Date(currentYear, currentMonth, 0);
    } else if (currentDay === 2) {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(today);
    } else {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(currentYear, currentMonth, currentDay - 1);
    }

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}${month}${day}`;

    }

    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
  }

  const lastRange = () => {
    let startDate, endDate;

    if(currentMonth === 1){
      const previousYear = new Date(currentYear - 1, currentMonth + 11, 1);
      startDate = new Date(previousYear.getFullYear(), previousYear.getMonth(), 1);
      endDate = new Date(currentYear, currentMonth, 0);
    }else {
      const previousMonth = new Date(currentYear, currentMonth - 1, 1);
      startDate = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
      endDate = new Date(currentYear, currentMonth, 0);
    }

    const formateDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}${month}${day}`;
    }

    return {
      startDate: formateDate(startDate),
      endDate: formateDate(endDate)
    }
  }

  useEffect(() => {
    dispatch(postLoadCharge(getDateRange().startDate, getDateRange().endDate, chargeId));
    dispatch(postLastLoadCharge(lastRange().startDate, lastRange().endDate, chargeId))
  }, []);

  const formatPercentage = (value) => {
    if (value == null) {
      return '0.00';
    }
    return (value).toFixed(2); 
  };

  const segmentsStops = [0, 54, 70, 100];

  const segmentFormatter = (value) => {
    return `${value}%`
  }

  const handleHistory = () => {
    dispatch(getLoadFactorHistory(chargeId))
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };


  const data = {
    labels: mesesOrdenados,
    datasets: [
      {
        label: 'Factor de carga',
        fill: false,
        backgroundColor:'#22B14C',
        borderColor:'#22B14C',
        data: datos
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    layout: {
      padding: 20
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let value = tooltipItem.value;
          label += `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`; 
          return label;
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value) {
            return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`;
          }
        }
      }]
    }
  }


  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({ id: 'app.deepview.loadFactor.title' })} />
      </PortletHeader>
      <PortletContent style={{ fontFamily: 'Lato, Helvetica Neue, Arial', display:'flex', alignItems:'center', flexDirection: 'column' , gap: '2rem'}}>
        <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Typography
            style={{marginBottom: theme.spacing.unit}}
            variant="h5"
          >{intl.formatMessage({id: 'app.meter.board.fpVariationInMonth.dataSet.labels.currMonth'})}</Typography>
          <ReactSpeedometer
            currentValueText={`${formatPercentage(loadFactorValue) || 0}%`}
            customSegmentStops={segmentsStops}
            forceRender
            height={125}
            labelFontSize={'12px'}
            maxValue={100}
            minValue={0}
            needleColor={'#000'}
            needleHeightRatio={0.6}
            ringWidth={40}
            segmentColors={['#EB0000', '#FAC800', '#00C454']}
            segments={3}
            segmentValueFormatter={segmentFormatter}
            textColor={'#000'}
            value={formatPercentage(loadFactorValue) || 0}
            valueFormat=".1f"
            valueTextFontSize={'20px'}
            valueTextFontWeight={'600'}
            width={200}
          />
        </Box>
        <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Typography
            style={{marginBottom: theme.spacing.unit}}
            variant="h5"
          >{intl.formatMessage({id: 'app.meter.board.loadFactor.closeLabel'})} {lastMonth}</Typography>
          <Typography
            style={{fontWeight: 500}}
            variant="h4"
          >{lastLoadFactorValue ? `${parseFloat(lastLoadFactorValue).toFixed(2)}%` : '%'}</Typography>
        </Box> 
        <Box>
          <Button
            color="secondary"
            onClick={handleHistory}
            style={{marginBottom: theme.spacing.unit}}
            type="button"
            variant="contained"
          >Ver historial</Button>
        </Box>
      </PortletContent>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h4">Historial de Factor de Carga</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.chartWrapper}>
            {datos ? (
              <Line
                data={data}
                options={options}
              />
            ):(
              <CircularProgress />
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            style={{marginRight: theme.spacing.unit * 2}}
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Portlet>
  );
};

export default LoadFactor;