import React, { Component } from 'react';

import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

// Material helpers
import { withStyles, BottomNavigation } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export default function SalesChart(props) {
  const classes = useStyles();
  const { className, consumes, ...rest } = props;

  const dataConsumes = [];

  consumes.forEach(element=>{
    dataConsumes.push(element.filter( e=> e!=='kw' && e!=='kwh'))

  })  

  const data = {
    labels: ['Base', 'Intermedia', 'Punta'],
    datasets: [
      {
        label: ' kW',
        yAxisID: 'A',
        // backgroundColor: palette.primary.main,
        backgroundColor: ['#8CBA42', '#FCF4A3', '#ED685E'],
        data: dataConsumes[0]
      },
      {
        label: ' kWh',
        yAxisID: 'B',
        // backgroundColor: palette.common.neutral,
        backgroundColor: ['#3A5F0B', '#FFCC00', 'red'],
        data: dataConsumes[1]
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage :0.8,
          ticks: {
            fontColor: palette.text.secondary,
            min: 0
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            fontColor: '#730065',
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        },
        {
          id: 'B',
          position: 'right',
          ticks: {
            fontColor: '#0080BC',
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
    animation: {
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(120,120,120, 2)';
        ctx.textBaseline = 'top';

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    }
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title="Consumo y Demanda (kW / kWh)" />
        <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text"
          >
            Septiembre
          </Button>
        </PortletToolbar>
      </PortletHeader>
      <PortletContent className={classes.chartContent}>
        <div className={classes.chartWrapper}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </PortletContent>
    </Portlet>
  );
}
