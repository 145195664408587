import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeWorkData, deleteCard , deleteHomeworkData } from 'js/actions/onOffAcrtions';
import { Link } from 'react-router-dom';
import {history} from 'helpers/history'

import { makeStyles,Button } from '@material-ui/core';

import styles from './styles';
const useStyles = makeStyles(styles);

export default function ConcentratorTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const homeworks = useSelector(state => state.controlCards.homeworks);
  //const user = useSelector(state=>state.user.user)
  const card = props.props;



  // const corpAdmin = useSelector(state=>state.user.corporative)
  // const corpUser = user.corporative
  // const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin : corpUser.id

  // const meterAdmin = useSelector(state=>state.user.meter)
  // const meterUser = user.meters
  // const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser[0].id

  const deleteCardData = cardId => {
    dispatch(deleteCard(cardId));
  };

  const deleteHomeWorkData = id => {
    dispatch(deleteHomeworkData(id , card.id ));
  };

  useEffect(() => {
    dispatch(getHomeWorkData(card.id));
  }, []);

  useEffect(() => {
    setState({ ...state, data: homeworks });
  }, [homeworks]);


  const [state, setState] = React.useState({
    columns: [
      { title: 'Identificador', field: 'id' },
      { title: 'Nombre', field: 'name' },  
      { title: 'Acción', field: 'action'  , 
      render: rowData =>{ return  rowData.action==1 ?'Encendido' : 'Apagado'  }
    },  
      { title: 'Hora', field: 'hour' },  
      // { title: 'Tipo', field: 'type' },
      // { title: 'Direccion MAC', field: 'mac_address' },
    ],
    actions: [
      {
        icon: 'delete',
        tooltip: 'Eliminar Tarjeta',
        onClick: (event, rowData) => deleteHomeWorkData(rowData.id)
      },
      {
        icon: 'edit',
        tooltip: 'Editar Tarjeta',
        onClick: (event, rowData) => {history.push({ 
          pathname: `/updateHomework/${rowData.id}`,
          state:{hw:rowData}
         })}
      }
    ]
  });

 

  return (
    <MaterialTable
      actions={state.actions}
      columns={state.columns}
      data={state.data}
      options={{
        showTitle:false,
        actionsColumnIndex: -1
      }}
    />
  );
}
