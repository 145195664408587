/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { isAllowed } from 'helpers/auth';
import Swal from 'sweetalert2';
import { blockCorporativeAdmin } from 'js/actions';

export default function CorpTable(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const user = useSelector(state => state.user.user);

  const updateCorporative = (corpId) => {
    const newActiveState = corpId.active === 1 ? 0 : 1; 
    dispatch(blockCorporativeAdmin(corpId.id, newActiveState));
    const message = corpId.active === 0 ? 'locked' : 'unlocked';
    Swal.fire({
      title: intl.formatMessage({id: `app.users.usersList.${message}Crop`}),
      text: `${corpId.name} ${intl.formatMessage({id: `app.users.usersList.${message}CropText`})}`,
      icon: 'success'
    });

  };

  const [state, setState] = useState({
    columns: [
      {
        field: 'name',
        title: intl.formatMessage({
          id: 'app.users.usersList.grid.colName'
        }),
        style: {
          color: 'red'
        }
      },
      {
        field: 'market_segment',
        title: intl.formatMessage({
          id: 'app.users.usersList.grid.colMarkSeg'
        })
      },
      {
        field: 'active',
        title: intl.formatMessage({id:'app.users.usersList.statusCorp'}),
        render: rowData => rowData.active === 0 ? intl.formatMessage({id:'app.users.usersList.unlocked'}) : intl.formatMessage({id:'app.users.usersList.locked'})
      },
      {
        field: 'city',
        title: intl.formatMessage({
          id: 'app.users.usersList.grid.colCity'
        })
      },
      // {
      //   field: 'roles',
      //   title: 'Num. Roles'
      // },
      {
        title: intl.formatMessage({
          id: 'app.users.usersList.grid.colUsersList'
        }),
        render: rowData => (
          <Link
            to={{
              pathname: `/users/${rowData.id}`,
              rowData
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
            >
              {intl.formatMessage({
                id: 'app.users.usersList.grid.colUsersList.btnSeeUsers'
              })}
            </Button>
          </Link>
        )
      }
    ]
  });

  useEffect(() => {
    setState({ ...state, data: corporatives });
  }, [corporatives]);
  
  const permissionsActions = () => {
    const actions = [];

    if(isAllowed(user.permissions, ['corporative_update'])){
      actions.push({
        icon: 'lock',
        tooltip: intl.formatMessage({id:'app.users.usersList.locked/unlocked'}),
        onClick: (event, rowData) => updateCorporative(rowData)
      });
    }
    return actions;
  }

  return (
    <div>
      <MaterialTable
        actions={permissionsActions()}
        columns={state.columns}
        data={state.data} // actions={state.actions}
        options={{
          showTitle: false,
          actionsColumnIndex: -1
        }}
      />
    </div>
  );
}

// export class CorpTable extends Component {
//   render() {
//     const { corporatives } = this.props;

//     const columns = [
//       {
//         name: 'name',
//         label: 'Corporativo',
//         style: {
//           color: 'red'
//         }
//       },
//       {
//         name: 'market_segment',
//         label: 'Segmento de Mercado'
//       },
//       {
//         name: 'city',
//         label: 'Ciudad'
//       },
//       {
//         name: 'roles',
//         label: 'Num. Roles'
//       },
//       {
//         name: 'id',
//         label: 'Id',
//         options: {
//           display: false
//         }
//       }
//     ];

//     const data = corporatives.corporatives;

//     const options = {
//       filterType: 'checkbox',
//       onRowClick: (rowData) =>history.push(`/users/${rowData[4]}`, {rowData})
//     };

//     console.log(options)

//     return (

//     );
//   }
// }

// // const mapStateToProps = state => {
// //   return {
// //     corporatives: state.corporatives
// //   };
// // };

// // const mapDispatchToProps = {};

// // export default connect(
// //   mapStateToProps,
// //   mapDispatchToProps
// // )(CorpTable);
