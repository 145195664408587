import * as InvoiceActionsTypes from 'js/constants/invoiceAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'


const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showCFDI = () => {
  return async dispatch => {
    dispatch(getCFDIStarted());
     axios
      .get(`https://api.metimur.mx/api/catalog/cfditypes`)
      .then(response => {
        dispatch(getCFDISucces(response.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getCFDIStarted = () => ({
  type: InvoiceActionsTypes.GET_CFDI_STARTED,
  loading: true
});

export const getCFDISucces = cfdi => {
  return {
    type: InvoiceActionsTypes.GET_CFDI_SUCCESS,
    cfdi,
  };
};

export const showPaymentTypes = () => {
  return async dispatch => {
    dispatch(getPaymentTypesStarted());
     axios
      .get(`https://api.metimur.mx/api/catalog/paymenttypes`)
      .then(response => {
        dispatch(getPaymentTypesSucces(response.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getPaymentTypesStarted = () => ({
  type: InvoiceActionsTypes.GET_PAYMENT_TYPES_STARTED,
  loading: true
});

export const getPaymentTypesSucces = payment_types => {
  return {
    type: InvoiceActionsTypes.GET_PAYMENT_TYPES_SUCCESS,
    payment_types,
  };
};

export const showPaymentMethods = () => {
  return async dispatch => {
    dispatch(getPaymentMethodsStarted());
     axios
      .get(`https://api.metimur.mx/api/catalog/paymentmethods`)
      .then(response => {
        dispatch(getPaymentMethodsSucces(response.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getPaymentMethodsStarted = () => ({
  type: InvoiceActionsTypes.GET_PAYMENT_METHODS_STARTED,
  loading: true
});

export const getPaymentMethodsSucces = payment_methods => {
  return {
    type: InvoiceActionsTypes.GET_PAYMENT_METHODS_SUCCESS,
    payment_methods,
  };
};

export const postInvoice = (chargeId,corporativeId,invoice) => {

  let arr=[];
  if( invoice.emails_cc ){
    arr= invoice.emails_cc.split(',');
  }

  return async dispatch => {
    axios
      .post(
        `https://api.metimur.mx/api/invoice`,
        {
          corporative_id: corporativeId,
          charge_id: chargeId,
          rfc : invoice.rfc,
          razon_social : invoice.razon_social,
          forma_pago : invoice.payment_type,
          metodo_pago : invoice.payment_method,
          tipo_cfdi : invoice.cfdi, 
          emails_cc : arr 
        },
        getconfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Solicitud de Factura Enviada',
          icon: 'success',
        }).then(
          ()=>{history.push('/')}
        );
      })
      .catch(error => {
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};
