import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';


// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import {
  Book as HomeIcon,
  PermIdentityOutlined as ProfileIcon,
  AssignmentIndOutlined as RolesIcon,
  GroupOutlined as UsersIcon,
  ContactSupportOutlined as HelpIcon,
  Input as LogOutIcon,
  PriorityHigh as AlertIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';
import { injectIntl } from 'react-intl';

class Password extends Component {
  state = {
    values: {
      password: '',
      confirm: ''
    }
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const { values } = this.state;
    const { intl } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel title={intl.formatMessage({id: 'app.help.manualInstLbl'})} />
        </PortletHeader>
        <PortletContent>
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="MANUAL INSTALACION TARJETA V 1 . 0"
              />
            </ListItem>
          </List>
        </PortletContent>
        <PortletFooter className={classes.portletFooter} />
      </Portlet>
    );
  }
}

Password = injectIntl(Password);

Password.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Password);
