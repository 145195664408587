import React, { Component, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Typography, Button } from '@material-ui/core';


import { Paper } from 'components';
// Component styles
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function CorporativeCard(props) {
  const { className, corporative } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();




  const getImage = () => {
    if (corporative.logo != null && corporative.logo != '') {
      return (
        <img
          alt="Product"
          className={classes.image}
          src={corporative.logo}
        />
      );
    } else {
      return (
        <img
          alt="Product"
          className={classes.image}
          src="images/logo-metimur.png"
        />
      );
    }
  };

 

  return (
    <Paper className={rootClassName}>
      <div className={classes.imageWrapper}>{getImage()}</div>

      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="h4"
        >
          {corporative.name}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
        >
          {corporative.market_segment}
        </Typography>
      </div>

    </Paper>
  );
}

CorporativeCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  corporative: PropTypes.object.isRequired
};
