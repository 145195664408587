import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import {
  Button,
  TextField, 
  MenuItem, 
  Select, 
  InputLabel , 
  FormControl ,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function NameRoleForm( props ) {
  const intl = useIntl()
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const {role} = props
  const [isPublica, setPublica] = React.useState(role.is_public);

  const onSubmit = values => {
    values.role_id=role.id
    dispatch(postForm(values));
    props.nextStep()
  };

  const handlePublica  = publica => {
    setPublica(publica.target.value);
  };

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          className={classes.textField}
          defaultValue={role.name}
          inputRef={register}
          label={intl.formatMessage({id: 'app.roles.editRole.stepper.roleName.roleNameLbl'})}
          name="role_name"
          required
          variant="filled"
        />

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            
          >
            {intl.formatMessage({id: 'app.roles.editRole.stepper.roleName.roleTypeLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            inputRef={register}
            labelId="demo-simple-select-outlined-label"
            name="is_public"
            onChange={handlePublica}
            required
            value={isPublica}
            //labelWidth={labelWidth}
          >
            <MenuItem value="1">Marca</MenuItem>
            <MenuItem value="3">Clientes</MenuItem>
          </Select>
        </FormControl>  


          
      
        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.roles.editRole.stepper.roleName.btnSaveContinue'})}
        </Button> 
      </form>

    </Grid>
     
  );
}

export default NameRoleForm;
