export const GET_LAST_GROUP_CONSUMES_STARTED = 'GET_LAST_GROUP_CONSUMES_STARTED';
export const GET_LAST_GROUP_CONSUMES_SUCCESS = 'GET_LAST_GROUP_CONSUMES_SUCCESS';


export const POST_PREVIEW_FACTOR_SUCCESS = 'POST_PREVIEW_FACTOR_SUCCESS';

export const POST_SYSTEM_ALERT_FAILURE = 'POST_SYSTEM_ALERT_FAILURE';

export const DELETE_SYSTEM_ALERT_STARTED = 'DELETE_SYSTEM_ALERT_STARTED';
export const DELETE_SYSTEM_ALERT_SUCCESS = 'DELETE_SYSTEM_ALERT_SUCCESS';

export const PUT_SYSTEM_ALERT_SUCCESS = 'PUT_SYSTEM_ALERT_SUCCESS';
