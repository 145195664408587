import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import { cleanForm } from 'js/actions/formsAction';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';


import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';


import styles from './styles';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
const useStyle = makeStyles(styles);

function FormConcentrator(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {chargeId,groupId, meterId} = props.match.params;
  const rowData = props.location.state.charge;

  useEffect(()=> {
    dispatch(cleanForm())
  }, [])


  return (
    <DashboardLayout title="Actualizacion de Cargas">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel  />
          </PortletHeader>
          <PortletContent noPadding>
            <FormStepper
              charge={rowData}
              chargeId={chargeId}
              groupId={groupId}
              meterId={meterId}
            />
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default FormConcentrator;
