import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import {
  showCards,
  showCardsInputs,
  showVoltageInputs
} from 'js/actions/cardAction';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  cardPhaseA: null,
  inputPhaseA: null,
  cardPhaseB: null,
  inputPhaseB: null,
  cardPhaseB: null,
  inputPhaseB: null,
  cardPhaseB: null,
  inputPhaseB: null,

  relationPhaseA: null,
  relationPhaseB: null,
  relationPhaseC: null
};

function MonoPhasicForm(props) {
  const intl = useIntl();
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();
  const { concentrator } = props;
  let cards = useSelector(state => state.cards.cards);
  let inputs = useSelector(state => state.cards.inputs);
  const comunicationStepForm = useSelector(state => state.forms.data[1]);
  const { unit } = comunicationStepForm.data;

  

  const [voltage, setVoltage] = useState('220');
  const [isDynamic , setIsDynamic ] = useState();
  const [nivel, setNivel] = useState('0');
  const [voltageDynamic , setVoltageDynamic] = useState();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
    //console.log('DATA: ', values);
  };

  const handleNivel = nivel => {
    setNivel(nivel.target.value);
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm());
    props.prevStep();
  };

  const handleVoltage = voltage => {
    setVoltage(voltage.target.value);
  };

  const handleChangeFPDinamico = flag => {
    setIsDynamic(flag.target.checked);    
  };  

  
  const handleChangeVoltageDinamico = flag => {
    setVoltageDynamic(flag.target.checked);    
  };
 
  useEffect(() => {
    dispatch(showCards(concentrator));
  }, []);

  const handleCardPhaseA = cardPhaseA => {
    setValue('cardPhaseA', cardPhaseA.target.value);

    unit != 'VOLTAJE'
      ? dispatch(showCardsInputs(cardPhaseA.target.value))
      : dispatch(showVoltageInputs(cardPhaseA.target.value));
  };
  const inputPhaseA = inputPhaseA => {
    setValue('inputPhaseA', inputPhaseA.target.value);
  };

  const handleCardPhaseB = cardPhaseB => {
    setValue('cardPhaseB', cardPhaseB.target.value);

    unit != 'VOLTAJE'
      ? dispatch(showCardsInputs(cardPhaseB.target.value))
      : dispatch(showVoltageInputs(cardPhaseB.target.value));
  };
  const inputPhaseB = inputPhaseB => {
    setValue('inputPhaseB', inputPhaseB.target.value);
  };

  const handleCardPhaseC = cardPhaseC => {
    setValue('cardPhaseC', cardPhaseC.target.value);

    unit != 'VOLTAJE'
      ? dispatch(showCardsInputs(cardPhaseC.target.value))
      : dispatch(showVoltageInputs(cardPhaseC.target.value));
  };
  const inputPhaseC = inputPhaseC => {
    setValue('inputPhaseC', inputPhaseC.target.value);
  };

  const relationPhaseA = relationPhaseA => {
    setValue('relationPhaseA', relationPhaseA.target.value);
  };

  const relationPhaseB = relationPhaseB => {
    setValue('relationPhaseB', relationPhaseB.target.value);
  };

  const relationPhaseC = relationPhaseC => {
    setValue('relationPhaseC', relationPhaseC.target.value);
  };

  useOnMount(() => {
    register({ name: 'concentratorPhase', type: 'text' });

    register({ name: 'cardPhaseA', type: 'text' });
    register({ name: 'inputPhaseA', type: 'text' });

    register({ name: 'cardPhaseB', type: 'text' });
    register({ name: 'inputPhaseB', type: 'text' });

    register({ name: 'cardPhaseC', type: 'text' });
    register({ name: 'inputPhaseC', type: 'text' });

    register({ name: 'relationPhaseA', type: 'text' });
    register({ name: 'relationPhaseB', type: 'text' });
    register({ name: 'relationPhaseC', type: 'text' });
  });

  const values = getValues();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        style={{ marginBottom: '10px' }}
        variant="h4"
      >
        {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.phaseConftitle'})}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> A </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleCardPhaseA}
                value={values.cardPhaseA}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={inputPhaseA}
                value={values.inputPhaseA}
                //labelWidth={labelWidth}
              >
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={relationPhaseA}
                value={values.relationPhaseA}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="130:1">130:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={1}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseA"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={0}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseA"
              required
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> B </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleCardPhaseB}
                value={values.cardPhaseB}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={inputPhaseB}
                value={values.inputPhaseB}
                //labelWidth={labelWidth}
              >
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={relationPhaseB}
                value={values.relationPhaseB}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="130:1">130:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={1}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseB"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={0}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseB"
              required
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> C </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={handleCardPhaseC}
                value={values.cardPhaseC}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={inputPhaseC}
                value={values.inputPhaseC}
                //labelWidth={labelWidth}
              >
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                onChange={relationPhaseC}
                value={values.relationPhaseC}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="130:1">130:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>                
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={1}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseC"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={0}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseC"
              required
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '25px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.predefVolt'})}
            </Typography>
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.voltageLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="voltage"
                onChange={handleVoltage}
                value={voltage}
                //labelWidth={labelWidth}
              >
                <MenuItem value="110">110</MenuItem>
                <MenuItem value="127">127</MenuItem>
                <MenuItem value="220">220</MenuItem>
                <MenuItem value="230">230</MenuItem>
                <MenuItem value="277">277</MenuItem>
                <MenuItem value="280">280</MenuItem>
                <MenuItem value="360">360</MenuItem>
                <MenuItem value="375">375</MenuItem>
                <MenuItem value="440">440</MenuItem>
                <MenuItem value="480">480</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '25px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.nivelLbl'})}
            </Typography>

            <FormControl
              className={classes.textField}
              variant="outlined"
            >
           
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="nivel"
                onChange={handleNivel}
                value={nivel}
                //labelWidth={labelWidth}
              >

                <MenuItem value="1">Master</MenuItem>
                <MenuItem value="0">Slave</MenuItem>
             
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox                             
                  checked={isDynamic}
                  inputRef={register}
                  name="is_dynamic"
                  onChange={handleChangeFPDinamico}
                />
              }
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.dynamicFP'})}
            />
          </Grid>     

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox                             
                  checked={voltageDynamic}
                  inputRef={register}
                  name="voltage_dynamic"
                  onChange={handleChangeVoltageDinamico}
                />
              }
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.dynamicVoltage'})}
            />
          </Grid>      

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '7px', marginTop: '20px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.predefFP'})}
            </Typography>
            <TextField
              className={classes.textField}
              defaultValue={0.95}
              helperText={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.fpMsg'})}
              inputRef={register}
              margin="normal"
              name="fp"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Button
          color="secondary"
          size="large"
          style={{ marginTop: '10px' }}
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.saveAndContinueBtn'})}
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.returnBtn'})}
        </Button>
      </form>
    </div>
  );
}

export default MonoPhasicForm;
