import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

import { recoveredData } from 'js/actions/userActions';
// Material helpers
import { makeStyles } from '@material-ui/core';


// Material components
import {
  Grid,
  Button,
  IconButton,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import useForm from 'react-hook-form';
const useStyles = makeStyles(styles);




export default function RecoveredEmail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const {token} = props.match.params
  
  const onSubmit = (email) => {
    dispatch(recoveredData(email,token));
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteWrapper}
          item
          lg={5}
        >
          <div className={classes.quote} />
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid
                  item
                  lg={5}
                >
                  <img
                    alt=""
                    src="/images/logo-metimur.png"
                    width="250px"
                  />
                </Grid>
                <div className={classes.fields}>
                  <TextField
                    className={classes.textField}
                    inputRef={register}
                    label="Correo Electronico"
                    name="email"
                    type="email"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    helperText="Reglas de Seguridad : 8 - 16 caracteres, Minimo 1 Mayuscula y 1 Minuscula y un simbolo Especial (@$!%*#?&+-_)"
                    inputRef={register}
                    label="Nueva Contraseña"
                    name="password"
                    type="password"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    inputRef={register}
                    label="Confirmar Nueva Contraseña"
                    name="password_confirmation"
                    type="password"
                    variant="outlined"
                  />
                </div>
                <Button
                  className={classes.signInButton}
                  color="secondary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Recuperar Contraseña
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


