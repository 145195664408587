import * as systemAlerActionTypes from 'js/constants/systemAlertAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import swal from 'sweetalert';


const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getSystemAlerts = () => {
  return async dispatch => {
    dispatch(getSystemAlertsStarted());
    axios
      .get(`https://api.metimur.mx/api/alerts/system/unatended`, getconfig())
      .then(response => {
        dispatch(getSystemAlertsSuccess(response.data.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
};

export const getSystemAlertsStarted = () => ({
  type: systemAlerActionTypes.GET_SYSTEM_ALERT_STARTED,
});

export const getSystemAlertsSuccess = systemAlert => {
  return {
    type: systemAlerActionTypes.GET_SYSTEM_ALERT_SUCCESS,
    systemAlert,
    loading: false
  };
};


// export const postSystemAlert = corporative => {
//   return async dispatch => {
//     dispatch(postCorporativeStarted());

//     axios
//       .post(
//         `https://api.metimur.mx/api/corporative`,
//         {
//           name: corporative.name,
//           city: corporative.city,
//           state: corporative.cstate,
//           description: corporative.description,
//           market_segment: corporative.market_segment
//         },
//         getconfig()
//       )
//       .then(response => {
//         dispatch(postCorporativeSuccess(corporative));
//       })
//       .then(()=>{
//         swal({
//           title: `Concentrador  ${corporative.name}`,
//           text: ` Creado Exitosamente`,
//           type: "success"
//         }).then(
//           ()=>{history.push(`/home`)}
//         );
//       })
//       .catch(error => {
//         dispatch(postCorporativeFail(error.message));
//         console.log('ERROR API:' + error);
//       });
//   };
// };

// export const postCorporativeStarted = () => ({
//   type: systemAlerActionTypes.POST_SYSTEM_ALERT_STARTED
// });

// export const postCorporativeSuccess = data => {
//   return {
//     type: systemAlerActionTypes.POST_SYSTEM_ALERT_SUCCESS,
//     payload: {
//       name: data.name,
//       city: data.city,
//       state: data.cstate,
//       description: data.description,
//       market_segment: data.market_segment
//     }
//   };
// };

// export const postCorporativeFail = error => ({
//   type: systemAlerActionTypes.POST_SYSTEM_ALERT_FAILURE,
//   state: { error }
// });

// export const deleteCorporative = corpId => {
//   return async dispatch => {
//     dispatch(getCorporativeStarted());

//     axios.delete(
//       `https://api.metimur.mx/api/corporative`,
//       { corporative_id: corpId },
//       getconfig()
//     )
//     .then(response => {
//       dispatch(deleteCorporativeSuccess(corpId));
//     })
//     ;
//   };
// };

// export const deleteCorporativeStarted = () => ({
//   type: systemAlerActionTypes.DELETE_SYSTEM_ALERT_STARTED
// });

// export const deleteCorporativeSuccess = corpId => {
//   return {
//     type: systemAlerActionTypes.DELETE_SYSTEM_ALERT_SUCCESS,
//     payload: { corporative_id: corpId }
//   };
// };