export default theme => ({
  root: {
    padding: theme.spacing.unit * 3
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  pagination: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  breadcrumb:{
    fontSize: '1em'
  },  imageWrapper: {
    height: '100px',
    width: '100px',
    marginRight: '15px',
    border: '1px solid #EDF0F2',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
  },
  image: {
    width: '100%'
  },  data: {
    fontSize: '14px',   
    textAlign: 'left' ,  
    paddingBottom :'0px' ,
    paddingTop :'0px' ,
    marginBottom :'0px' ,
    marginTop :'0px'
  },
});
