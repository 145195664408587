import * as dashboardMeterActionTypes from '../constants/meterDashboard-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function concentratorReducer(
  state = {
    totalsactualmonth: [],
    monthMostConsume: [],
    dayMostConsume: [],
    comparativeYearConsume: []
  },
  action
) {
  switch (action.type) {
    case dashboardMeterActionTypes.GET_TOTAL_ACTUAL_MONTH_SUCCESS:
      return { ...state, totalsactualmonth: action.totalsActual };

    case dashboardMeterActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS:
      return { ...state, monthMostConsume: action.chartMonthMostConsume };

    case dashboardMeterActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS:
      return { ...state, dayMostConsume: action.chartDayMostConsume };

    case dashboardMeterActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS:
      return {
        ...state,
        comparativeYearConsume: action.chartComparativeYearConsume
      };

    case dashboardMeterActionTypes.GET_COMPARATIVE_YEARS_TARIFF_SUCCESS:
      return {
        ...state,
        comparativeYearTariff: action.chartComparativeYearTariff
      };

    case dashboardMeterActionTypes.GET_CHARGE_WITH_MOST_CONSUME_SUCCESS:
      return { ...state, chargeMostConsume: action.chargeMostConsume };

    default:
      return state;
  }
}

export default concentratorReducer;
