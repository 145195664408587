import React from 'react';
import { Link } from 'react-router-dom';

import { recoveredEmail } from 'js/actions/userActions';
// Material helpers
import {  InputAdornment, makeStyles } from '@material-ui/core';


// Material components
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Material icons
import { AlternateEmail } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import useForm from 'react-hook-form';
const useStyles = makeStyles(styles);




export default function RecoveredEmail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  
  const onSubmit = (email) => {
    dispatch(recoveredEmail(email));
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.formBox}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    className={classes.divImage}
                  >
                    <img
                      alt=""
                      src="/images/logo-metimur.png"
                      width="140px"
                    />
                  </div>
                  <div className={classes.fields}>
                    <Typography  style={{textAlign:'center', marginBottom: '1rem'}}>Ingresa tu correo electronico de recuperación </Typography>
                    <TextField
                      className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AlternateEmail />
                          </InputAdornment>
                        )
                      }}
                      inputRef={register}
                      name="email"
                      placeholder="Correo Electronico"
                      type="email"
                      variant="outlined"
                    />
                  </div>
                  <Button
                    className={classes.signInButton}
                    color="secondary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Recuperar Contraseña
                  </Button>
                  <Link
                    className={classes.iconTable}
                    to="/login"
                  >
                    <Typography
                      color="secondary"
                      style={{marginTop:'10px' , textAlign: 'center', fontWeight: 500  }}
                    >
                      Regresar
                    </Typography>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


