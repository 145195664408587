/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import classNames from 'classnames';

// Material Helpers
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, makeStyles } from '@material-ui/core';

//Material components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Button } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux';

//Share components
import { Paper } from 'components';

//Components style
import styles from './styles'
import { useIntl } from 'react-intl';

//Chart
import Highcharts, { time } from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import axios from 'axios';
import getToken from 'helpers/auth';
import MaterialTable from 'material-table';
import { ReactComponent as IconSVG } from './i.svg';

const useStyles = makeStyles(styles);

const Current = (props) => {
  const intl = useIntl();
  const { className, ...rest } = props
  const classes = useStyles();
  const dispatch = useDispatch();
  const rootClassName = classNames(classes.root, className);
  const [open, setOpen] = useState(false);
  const [errorMessage , setErrorMessage] = useState();
  const [showStartDatePop , setShowStartDatePop] = useState(false);
  const [showEndDatePop , setShowEndDatePop] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataElectric , setDataElectric ] = useState([]);
  const [stats, setStats] = useState({});
  const [statsImbalance, setStatsImbalance] = useState([]);
  const [timeLabel, setTimeLabel] = useState({min: true, hour: false});
  const concentrator_id = useSelector(state=>state.chargeDashboard.generalConsumes.concentrator_id);
  const charge=  useSelector(state=>state.chargeDashboard.generalConsumes.charge_info);
  const charge_id = charge ? charge.id : 0 ;

  const calculateStats = (arrDataFase) => {
    if(arrDataFase.length === 0) return { min: 0, max: 0, avg: 0};
    const values = arrDataFase.map(item => item[1]);
    const min = Math.min(...values).toFixed(2);
    const max = Math.max(...values).toFixed(2);
    const avg = (values.reduce((sum, value) => sum + value, 0) / values.length).toFixed(2);
    return { min, max, avg};
  }

  const calculateStatsImbalance = (data) => {
    if(data.length === 0) return { avg: 0};
    const values = data.map(item => item[1]);
    const avg = (values.reduce((sum, value) => sum + value, 0) / values.length).toFixed(2);
    return {avg};
  }

  useEffect(()=>{
    filter5MinRecordsElectric(startDate, endDate)
  },[timeLabel])

  const set5MinDates = (data) => {
    let arrDataFase1 = [];
    let arrDataFase2 = [];
    let arrDataFase3 = [];
    let relativeImbalance = [];
    let seriesData = [];
    let statsImbalance = {};
    let stats = {};
    if(timeLabel.hour) {
      const hourlyData = {};

      data.forEach(record => {
        const recordDate = record.date.toString();
        const { data: dataList, relative_imbalance: imbalance } = record;
        const year = parseInt(recordDate.substring(0, 4));
        const month = parseInt(recordDate.substring(4, 6)) - 1;
        const day = parseInt(recordDate.substring(6, 8));
        const hour = parseInt(recordDate.substring(8));

        if (!hourlyData[recordDate]) {
          hourlyData[recordDate] = {
            fase1: [],
            fase2: [],
            fase3: [],
            imbalance: [],
          };
        }
  
        dataList.forEach(({ min, value }, index) => {
          if (value[0] !== undefined) {
            hourlyData[recordDate].fase1.push(parseFloat(value[0]));
          }
          if (value[1] !== undefined) {
            hourlyData[recordDate].fase2.push(parseFloat(value[1]));
          }
          if (value[2] !== undefined) {
            hourlyData[recordDate].fase3.push(parseFloat(value[2]));
          }
          if (imbalance && imbalance[index] !== undefined) {
            hourlyData[recordDate].imbalance.push(parseFloat(imbalance[index]));
          }
        });
      });

      for (const recordDate in hourlyData) {
        const { fase1, fase2, fase3, imbalance } = hourlyData[recordDate];
        const year = parseInt(recordDate.substring(0, 4));
        const month = parseInt(recordDate.substring(4, 6)) - 1;
        const day = parseInt(recordDate.substring(6, 8));
        const hour = parseInt(recordDate.substring(8));
        const date = new Date(year, month, day, hour);
        const milisecs = date.getTime();

        arrDataFase1.push([milisecs, fase1.length > 0 ? Math.max(...fase1) : null]);
        arrDataFase2.push([milisecs, fase2.length > 0 ? Math.max(...fase2) : null]);
        arrDataFase3.push([milisecs, fase3.length > 0 ? Math.max(...fase3) : null]);
        relativeImbalance.push([milisecs, imbalance.length > 0 ? Math.max(...imbalance) : null])

        // Promedios
        // arrDataFase1.push([milisecs, fase1.length > 0 ? fase1.reduce((sum, val) => sum + val, 0) / fase1.length : null]);
        // arrDataFase2.push([milisecs, fase2.length > 0 ? fase2.reduce((sum, val) => sum + val, 0) / fase2.length : null]);
        // arrDataFase3.push([milisecs, fase3.length > 0 ? fase3.reduce((sum, val) => sum + val, 0) / fase3.length : null]);
        // relativeImbalance.push([milisecs, imbalance.length > 0 ? imbalance.reduce((sum, val) => sum + val, 0) / imbalance.length : null]);
      }

      seriesData = [
        { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 1`, data: arrDataFase1, yAxis: 0, color: '#333', marker: { enabled: false }, lineWidth: 3 },
        arrDataFase2.length && { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 2`, data: arrDataFase2, yAxis: 0, color: '#FF4A4A', marker: { enabled: false }, lineWidth: 3},
        arrDataFase3.length && { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 3`, data: arrDataFase3, yAxis: 0, color:'#00AFEF', marker: { enabled: false }, lineWidth: 3 },
        arrDataFase3.length && { name: `${intl.formatMessage({ id: 'app.deepview.unbalance'})}`, data: relativeImbalance, yAxis: 1, color: '#FF964F', marker: { enabled: false }, lineWidth: 1 },
      ].filter(Boolean); 
    
      stats = {
        fase1: calculateStats(arrDataFase1),
      };
  
      if (arrDataFase2.length) {
        stats.fase2 = calculateStats(arrDataFase2);
      }
  
      if (arrDataFase3.length) {
        stats.fase3 = calculateStats(arrDataFase3);
      }
  
      statsImbalance = {
        imbalance: calculateStatsImbalance(relativeImbalance)
      }
    } else {
      data.forEach(record => {
        const recordDate = record.date.toString(); // Asegurar que recordDate es una cadena de texto
        const { data: dataList, relative_imbalance: imbalance } = record;
        
        const year = parseInt(recordDate.substring(0, 4));
        const month = parseInt(recordDate.substring(4, 6)) - 1;
        const day = parseInt(recordDate.substring(6, 8));
        const hour = parseInt(recordDate.substring(8))
  
        dataList.forEach(({ min, value }, index) => {
          const date = new Date(year, month, day, hour, min);
          const milisecs = date.getTime();
    
          if (value[0] !== undefined) {
            arrDataFase1.push([milisecs, parseFloat(value[0])]);
          }
          if (value[1] !== undefined) {
            arrDataFase2.push([milisecs, parseFloat(value[1])]);
          }
          if (value[2] !== undefined) {
            arrDataFase3.push([milisecs, parseFloat(value[2])]);
          }
  
          if (imbalance && imbalance[index] !== undefined) {
            relativeImbalance.push([milisecs, parseFloat(imbalance[index])])
          }
        });
      });
  
      seriesData = [
        { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 1`, data: arrDataFase1, yAxis: 0, color: '#333', marker: { enabled: false }, lineWidth: 3 },
        arrDataFase2.length && { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 2`, data: arrDataFase2, yAxis: 0, color: '#FF4A4A', marker: { enabled: false }, lineWidth: 3},
        arrDataFase3.length && { name: `${intl.formatMessage({ id: 'app.deepview.current' })}(A) 3`, data: arrDataFase3, yAxis: 0, color:'#00AFEF', marker: { enabled: false }, lineWidth: 3 },
        arrDataFase3.length && { name: `${intl.formatMessage({ id: 'app.deepview.unbalance'})}`, data: relativeImbalance, yAxis: 1, color: '#FF964F', marker: { enabled: false }, lineWidth: 1 },
      ].filter(Boolean); 
    
      stats = {
        fase1: calculateStats(arrDataFase1),
      };
  
      if (arrDataFase2.length) {
        stats.fase2 = calculateStats(arrDataFase2);
      }
  
      if (arrDataFase3.length) {
        stats.fase3 = calculateStats(arrDataFase3);
      }
  
      statsImbalance = {
        imbalance: calculateStatsImbalance(relativeImbalance)
      }
    }

    setStats(stats);
    setStatsImbalance(statsImbalance);
    setDataElectric(seriesData);
  };

  const handleTime = event => {
    const { name, checked} = event.target;
    if(name === 'min' && checked) {
      setTimeLabel({
        min: true,
        hour: false
      })
    }else if(name === 'hour' && checked) {
      setTimeLabel({
        min: false,
        hour: true
      })
    }
  }

  const filter5MinRecordsElectric = ( fechaIni ,  fechaFin, concentrador_id, charge_id) => {
    let dayDifs=getDateDiffInDays(fechaIni, fechaFin );
    if( dayDifs>31  ){
      setErrorMessage('['+dayDifs +' ' + intl.formatMessage({id: 'app.deepview.consumption.datesRangeError.rangeTooLong'}) )
      return;           
    }else{
      if( dayDifs<0 ){
        setErrorMessage(intl.formatMessage({id: 'app.deepview.consumption.datesRangeError.wrongIniEnd'}))
        return;           
  
      }
      setErrorMessage(null);
    }
    let txtIniDate= getDateAsString ( fechaIni );
    let txtEndDate= getDateAsString ( fechaFin  );        
    txtIniDate= txtIniDate + '00';
    txtEndDate= txtEndDate + '23';
    dispatch(get5MinRecordsElectric( txtIniDate , txtEndDate, concentrador_id, charge_id, set5MinDates )); 
  }

  const get5MinRecordsElectric = (startDate, endDate) => {
    return async dispatch => {
      axios
        .post(
          'https://api.metimur.mx/api/charge/5minutalcorrienteData',
          {
            'concentrator_id' : concentrator_id ,
            'charge_id' : charge_id ,
            'start_date' : startDate ,
            'end_date' : endDate
          },
          {
            headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
        .then(response => {
          set5MinDates(response.data.data);
        })
        .catch(function(error){
          console.error('Error API: ' + error)
        })
    }
  }

  const getDateDiffInDays = ( startDate , endDate )=>{
    const diffTime =  endDate - startDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays+1; 
  }

  const getDateAsString = ( date ) => {
    let year= date.getFullYear();
    let month= date.getMonth() + 1;
    let day = date.getDate();

    let strMonth=month.toString();
    if( month<10 ){
      strMonth='0' + strMonth;
    }
    let strDay=day.toString();
    if( day<10 ){
      strDay='0' + strDay;
    }

    let strDate= year+ strMonth + strDay ;
    return  strDate;
  }

  const handleClickOpen = () => {
    setOpen(true);
    filter5MinRecordsElectric(startDate, endDate)
  };

  const handleClickClose = () => {
    setOpen(false);
    setTimeLabel({min: true, hour: false})
  };

  const handleSelectEndDate = (finDate) => {
    if(open){
      setEndDate(finDate);    
      setShowEndDatePop(false); 
      filter5MinRecordsElectric( startDate , finDate );

    }else{
      setEndDate(finDate);    
      setShowEndDatePop(false); 
      filter5MinRecordsElectric( startDate , finDate );
    }
  }

  const handleSelectStartDate = (iniDate) => {
    setStartDate(iniDate);
    setShowStartDatePop(false);
    setDataElectric([])
    setErrorMessage(null)
    filter5MinRecordsElectric( iniDate, endDate);          
  }

  const optionsElectric = {
    turboThreshold: 0,
    time: {
      useUTC: false,
      timezone: 'America/Mexico_City'
    },
    credits: {
      enabled: false
    },
    title: {
      text: intl.formatMessage({ id: 'app.deepview.electric.chart.title' })
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%a %e. %b',
        week: '%e. %b',
        month: '%b \'%y',
        year: '%Y'
      }
    },
    yAxis: [{
      title: {
        text: intl.formatMessage({ id: 'app.deepview.electric.chart.amperes' })
      },
      labels: {
        format: '{value}A'
      }
    }, {
      title: {
        text: intl.formatMessage({ id: 'app.deepview.electric.chart.percentage' })
      },
      labels: {
        format: '{value}%'
      },
      opposite: true,
      lineColor: '#FF964F',
      lineWidth: '2'
    }],
    rangeSelector: {
      enabled: true,
      selected: 4
    },
    tooltip: {
      shared: true,
      positioner: function (labelWidth, labelHeight, point) {
        const chart = this.chart;
        let x = point.plotX + chart.plotLeft;
        let y = point.plotY + chart.plotTop - 100;

        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth - 10;
        }
        if (y < 0) {
          y = 50;
        }
        return { x: x, y: y };
      },
      formatter: function () {
        const date = new Date(this.x);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'long'
        };

        const formattedDate = date.toLocaleString(intl.formatMessage({ id: 'app.locale' }), options).replace(',', '');

        let s = `<b>${formattedDate}</b><br/>`;

        this.points.forEach(point => {
          s += `<span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${Highcharts.numberFormat(point.y, 2)}</b> ${point.series.yAxis.opposite ? '%' : 'A'}<br/>`;
        });

        return s;
      }
    },
    // series: [{
    //   name: intl.formatMessage({id: 'app.deepview.electric.chart.demandSerie.label'}), 
    //   turboThreshold: 0 ,
    //   data: dataElectric /* dataConsume.map((point) => ({
    //     x: point[0],
    //     y: point[1],
    //     color: point[1] > 0.5 ? "red" : "green",
    //   }))  */ ,
    //   id: 'dataseries', 
 
    //   tooltip: {
    //     valueDecimals: 4
    //   }
    // }]
    series: dataElectric,
    legend: {
      itemStyle: {
        fontSize: '16px', 
        fontWeight: 'bold'
      }
    }
  }

  return (
    <div>
      <Paper
        {...rest}
        className={rootClassName}
        onClick={handleClickOpen}
      >
        <Box>
          <div className={classes.content}>
            <div className={classes.datails}>
              <Typography
                className={classes.title}
                style={{fontWeight: '700', fontSize: '1rem', color: '#fff'}}
                variant="h6"
              >
                {intl.formatMessage({id: 'app.deepview.electric.btnOpen'})}
              </Typography>
            </div>
            <div className={classes.iconWrapper}>
              <IconSVG className={classes.icon}/>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              {intl.formatMessage({id: 'app.deepview.electric.btnOpen.datesMsg'})}
            </Typography>
          </div>
        </Box>
      </Paper>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClickClose}
        open={open}
      >
        <DialogTitle >
          <Typography
            className={classes.title}
            variant="h3"
          >{intl.formatMessage({id: 'app.deepview.electric.title'})}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              className={classes.title}
              style={{ color: 'red' }}
              variant="h5"
            >{errorMessage}</Typography> 
            <Typography  variant="h5"  > {intl.formatMessage({id: 'app.deepview.electric.datesMsg'})} </Typography>
            <Grid
              style={{paddingTop: '1rem', display: 'flex', justifyContent: 'space-between'}}
            >
              <Grid item>
                <MuiPickersUtilsProvider   utils={DateFnsUtils}   >
                  <KeyboardDatePicker    
                    format="dd/MM/yyyy"
                    InputProps={{
                      onFocus: () => {
                        setShowStartDatePop(true);
                      }
                    }}  
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setShowStartDatePop(true);
                      }
                    }}
                    onChange={date =>  handleSelectStartDate(date)}
                    open={showStartDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowStartDatePop(false);
                      }
                    }}
                    style={{ marginBottom : '10px' , marginRight: '10px' }}
                    value={startDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider   utils={DateFnsUtils}   >
                  <KeyboardDatePicker 
                    format="dd/MM/yyyy"
                    InputProps={{
                      onFocus: () => {
                        setShowEndDatePop(true);
                      }
                    }}  
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setShowEndDatePop(true);
                      }
                    }}
                    onChange={date =>{ setEndDate(date);     handleSelectEndDate(date)}}
                    open={showEndDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowEndDatePop(false);
                      }
                    }}
                    style={{ marginBottom : '10px' , marginRight: '10px' }}
                    value={endDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={timeLabel.min}
                        color="secondary"
                        name="min"
                        onChange={handleTime}
                      />
                    }
                    label={intl.formatMessage({ id: 'app.charge.board.min'})}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={timeLabel.hour}
                        color="secondary"
                        name="hour"
                        onChange={handleTime}
                      />
                    }
                    label={intl.formatMessage({ id: 'app.charge.board.hour' })}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          
          </DialogContentText>
          <Paper    >             
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsElectric}                
            />
          </Paper>
          <Grid
            container
            spacing={3}
            style={{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                style={{marginBottom: '1rem', textAlign: 'center'}}
                variant="h4"
              >{intl.formatMessage({id: 'app.chargesCards.addCharge'})}</Typography>
              <MaterialTable
                columns={[
                  { title: '', field: 'phase', cellStyle: { width: '20%', fontWeight: '900', textAlign: 'center'} },
                  { title: intl.formatMessage({ id: 'app.metersCards.card.voltageMin' }), field: 'min', cellStyle: { backgroundColor: '#8CBA42', color: '#FFF', textAlign: 'center', fontWeight: '500', fontSize: '.95em', width: '25%'}, textAlign:'center', fontSize: '.85rem' },
                  { title: intl.formatMessage({ id: 'app.metersCards.card.voltageMax' }), field: 'max', cellStyle: { backgroundColor: '#D64539', color: '#FFF', textAlign: 'center', fontWeight: '500', fontSize: '.95em', width: '25%'}, textAlign:'center', fontSize: '.85rem' },
                  { title: intl.formatMessage({ id: 'app.metersCards.card.voltageAve' }), field: 'avg', cellStyle: { backgroundColor: '#EAAB36', color: '#FFF', textAlign: 'center', fontWeight: '500', fontSize: '.95em', width: '25%'}, textAlign:'center', fontSize: '.85rem' },
                ]}
                data={[
                  { phase: <span style={{color: '#333', fontSize: '1rem'}}>{intl.formatMessage({ id: 'app.deepview.current' })}(A) 1</span>, ...stats.fase1 },
                  stats.fase2 && { phase: <span style={{color: '#FF4A4A', fontSize: '1rem'}}>{intl.formatMessage({ id: 'app.deepview.current' })}(A) 2</span>, ...stats.fase2 },
                  stats.fase3 && { phase: <span style={{color: '#00AFEF', fontSize: '1rem' }}>{intl.formatMessage({ id: 'app.deepview.current' })}(A) 3</span>, ...stats.fase3 }
                ].filter(Boolean)}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  padding: 0,
                
                  rowStyle: {
                    fontSize: 15 ,
                  },
                  headerStyle: {
                    textAlign: 'center',                 
                    padding: '0',
                    fontSize: '1em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
                title={intl.formatMessage({ id: 'app.chargesCards.addCharge' })}
              />
            </Grid>
            {stats.fase3 && 
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Typography
                  style={{marginBottom: '1rem', textAlign: 'center'}}
                  variant="h4"
                >{intl.formatMessage({id: 'app.deepview.unbalance'})}</Typography>
                <MaterialTable
                  columns={[
                    { title: `${intl.formatMessage({ id: 'app.deepview.unbalance'})} ${intl.formatMessage({ id: 'app.metersCards.card.voltageAve' })} (%)`, field: 'avg', cellStyle: { backgroundColor: '#FF964F', color: '#FFF', textAlign: 'center', fontWeight: '500', fontSize: '.95em', width: '25%'} },
                  ]}
                  data={[
                    { phase: `${intl.formatMessage({ id: 'app.deepview.unbalance'})}`, ...statsImbalance.imbalance },
                  ].filter(Boolean)}
                  options={{
                    filtering: false,
                    search: false,
                    sorting: false,
                    toolbar: false,
                    paging: false,
                    padding: 0,
                  
                    rowStyle: {
                      fontSize: 15 ,
                    },
                    headerStyle: {
                      textAlign: 'center',                 
                      padding: '0',
                      fontSize: '1em',
                      fontWeight: '900',
                      minWidth: '25%'
                    }
                  }}
                  title={intl.formatMessage({ id: 'app.deepview.unbalance'})}
                />
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClickClose}
            size="small"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Current