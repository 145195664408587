import React, { Component } from 'react';

// Externals
import PropTypes, { element } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

// Material helpers
import {
  withStyles,
  makeStyles,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { history } from 'helpers/history';

// Material components
import MUIDataTable from 'mui-datatables';
import MaterialTable from 'material-table';

// Shared components
import { Paper } from 'components';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  Legend
} from 'recharts';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {

  return (
    <MaterialTable
      components={{
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell>Hora</TableCell>
                <TableCell>1</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
                <TableCell>4</TableCell>
                <TableCell>5</TableCell>
                <TableCell>6</TableCell>
                <TableCell>7</TableCell>
                <TableCell>8</TableCell>
                <TableCell>9</TableCell>
                <TableCell>10</TableCell>
                <TableCell>11</TableCell>
                <TableCell>12</TableCell>
                <TableCell>13</TableCell>
                <TableCell>14</TableCell>
                <TableCell>15</TableCell>
                <TableCell>16</TableCell>
                <TableCell>17</TableCell>
                <TableCell>18</TableCell>
                <TableCell>19</TableCell>
                <TableCell>20</TableCell>
                <TableCell>21</TableCell>
                <TableCell>22</TableCell>
                <TableCell>23</TableCell>
                <TableCell>24</TableCell>
                <TableCell>25</TableCell>
                <TableCell>26</TableCell>
                <TableCell>27</TableCell>
                <TableCell>28</TableCell>
                <TableCell>29</TableCell>
                <TableCell>30</TableCell>
                <TableCell>31</TableCell>
              </TableRow>
            </TableHead>
          );
        },
        Row: ({  }) => {
          return (
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>,
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell>
              <TableCell>9</TableCell>
              <TableCell>10</TableCell>
              <TableCell>11</TableCell>
              <TableCell>12</TableCell>
              <TableCell>13</TableCell>
              <TableCell>14</TableCell>
              <TableCell>15</TableCell>
              <TableCell>16</TableCell>
              <TableCell>17</TableCell>
              <TableCell>18</TableCell>
              <TableCell>19</TableCell>
              <TableCell>20</TableCell>
              <TableCell>21</TableCell>
              <TableCell>22</TableCell>
              <TableCell>23</TableCell>
              <TableCell>24</TableCell>
            </TableRow>
          );
        }
      }}
      
      options={{
        search: true,
        toolbar: false,
        sorting: false
        
      }}
    />
  );
}