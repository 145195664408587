import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { AttachMoney as AttachMoneyIcon } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles)


export default function Profit(props) {
  const intl = useIntl()
  const classes = useStyles()
  const {className, ...rest} = props
  const rootClassName = classNames(classes.root, className);
  const totalConsumes = useSelector(state=>state.meterDashboard.totalsactualmonth);



  return (
    <Paper
    {...rest}
    className={rootClassName}
  >
    <div className={classes.content}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="body2"
        >
          {intl.formatMessage({id: 'app.meter.board.mainHead.estimAmtAsOfToday'})}
        </Typography>
        <Typography
          className={classes.value}
          variant="h3"
        >
          $ {totalConsumes.total}
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <AttachMoneyIcon className={classes.icon} />
      </div>
    </div>
     <div className={classes.footer}>
      <Typography
        className={classes.difference}
        variant="body2"
      >
        {/* <ArrowUpwardIcon /> */}
        
      </Typography>
      <Typography
        className={classes.caption}
        variant="caption"
      >
        {intl.formatMessage({id: 'app.meter.board.mainHead.estimAmtAsOfToday.vatNotice'})}
      </Typography>
    </div>
  </Paper>
  )
}

