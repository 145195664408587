import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getComparativeYearConsume} from 'js/actions/meterDashboardActions'

// Externals
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';

// Chart configuration
//import { data, options } from './chart';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
const useStyles = makeStyles(styles)


export default function ComparativeYearConsumeChart(props) {
  const intl = useIntl();
  const classes = useStyles()
  const dispatch = useDispatch()
  const { ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const yearsConsumes = useSelector(state=>state.meterDashboard.comparativeYearConsume)   
  const {corpId,meterId} = ids
  //const {actual_year, previous_year} = yearsConsumes 

  const   actual_year = yearsConsumes? yearsConsumes.actual_year :[];
  const   year_1 =yearsConsumes? yearsConsumes.year_1 :[];
  const   year_2 =yearsConsumes? yearsConsumes.year_2 :[];
  const   year_3 =yearsConsumes? yearsConsumes.year_3 :[];
    

  const year = new Date().getFullYear()

  useEffect(()=>{   
    dispatch(getComparativeYearConsume(corpId,meterId,year))
  },[ids])

  if(actual_year == undefined)
  {
    
    var actual_year_consumes = [];
  }
  else{
    actual_year_consumes = actual_year
  }

  if(year_1 == undefined)
  {
    
    var year_1_consumes = [];
  }
  else{    
    year_1_consumes = year_1
  }


  if(year_2 == undefined)
  {
    
    var year_2_consumes = [];
  }
  else{    
    year_2_consumes = year_2
  }  


  if(year_3 == undefined)
  {
    
    var year_3_consumes = []
  }
  else{    
    year_3_consumes = year_3
  }



  
  const data = {
    labels: [ 
      intl.formatMessage({id: 'app.months.jan'}) ,
      intl.formatMessage({id: 'app.months.feb'}),
      intl.formatMessage({id: 'app.months.mar'}),
      intl.formatMessage({id: 'app.months.apr'}),
      intl.formatMessage({id: 'app.months.may'}),
      intl.formatMessage({id: 'app.months.jun'}),
      intl.formatMessage({id: 'app.months.jul'}),
      intl.formatMessage({id: 'app.months.aug'}),
      intl.formatMessage({id: 'app.months.sep'}),
      intl.formatMessage({id: 'app.months.oct'}),
      intl.formatMessage({id: 'app.months.nov'}),
      intl.formatMessage({id: 'app.months.dec'})


    ],
    datasets: [


      {
        label: year-3,
        fill: false,
        backgroundColor:'#22B14C',
        borderColor:'#22B14C',        
        data: year_3_consumes 
        
      },


      {
        label: year-2,
        fill: false,
        backgroundColor:'#FF7F27',
        borderColor:'#FF7F27',        
        data: year_2_consumes 
        
      },

      {
        label: year-1,
        fill: false,
        backgroundColor:'#0080BC',
        borderColor:'#0080BC',        
        data: year_1_consumes 
        
      },
      {
        label: year,
        fill: false,
        backgroundColor:'#C69100',
        borderColor:'#C69100',        
        data: actual_year_consumes
      }
    
    ]
  };
 

 
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },   
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let value = tooltipItem.value;
          label += `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kWh`;
          return label;
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value) {
            return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          }
        }
      }]
    }
  };

  if( !actual_year || !year_1 || !year_2 || !year_3  ){
    return null;
  }else {

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title={intl.formatMessage({id: 'app.meter.board.consumDiference.title'})} />
 
        </PortletHeader>
        <PortletContent style={{paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit}}>
          <div className={classes.chartWrapper}>
            <Line
              data={data}
              options={options}
              redraw
            />
          </div>
        </PortletContent>
      </Portlet>
    )

  }



}
