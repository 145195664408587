import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { ClientInfobar, ProductCard, ChargeGeneralTable } from './components';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Component styles
import styles from './styles';

class ProductList extends Component {
  renderOptions(group, classes) {
    return (
      <Grid
        container
        spacing={5}
      >
        <Grid
          item
          lg={6}
          md={6}
        >
          <ProductCard
            className={classes.option}
            destination={`/group/${group.id}`}
            fontIcon="fa fa-chart-line"
            productname="Mediciones"
            propGroup={group}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
        >
          <ProductCard
            className={classes.option}
            destination={`/groupDashboard/${group.id}`}
            fontIcon="fa fa-tachometer-alt"
            productname="Consumos"
            propGroup={group}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { meterId } = this.props.match.params;

    const { corp, meter, group } = this.props.location.state;

    return (
      <DashboardLayout  title={`${corp.name} / ${meter.name} / ${group.name}` }>
        <div className={classes.root}>
          {/* <ClientInfobar
            corpData={corp}
            groupData={group}
            meterData={meter}
          /> */}

          <Grid
            className={classes.content}
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <div className={classes.table}>
              {/* <ChargeGeneralTable
                group={this.props.location.state.group}
              /> */}
              </div>
            </Grid>
          </Grid>

          <div className={classes.content}>
            {this.renderOptions(group, classes)}
          </div>

          <Container
          styles={{marginRight: '-20px',
          marginBottom: '-20px'}}
          
          >
            <LinkFloat
              href={`/concentrador/${meterId}/group/${group.id}`}
              icon="fa fa-charging-station"
              styles={{
                backgroundColor: '#00AFEF',
                color: '#fff',
                width: '60px',
                height: '60px',
                fontSize: '1.5rem'
              }}
              tooltip="Concentradores"
            />
            <Button
              icon="fa fa-cog"
              rotate
              styles={{
                backgroundColor: '#C69100',
                color: '#fff',
                width: '80px',
                height: '80px',
                fontSize: '2rem'
              }}
            />
          </Container>
        </div>
      </DashboardLayout>
    );
  }
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);
