import React, { useEffect  } from 'react';


// Material helpers
import {makeStyles } from '@material-ui/core';



// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import {
  cleanConcentrator
} from 'js/actions/concentratorActions';

// Custom components
import { UsersToolbar, UsersTable, ConcentratorTable } from './components';

// Component styles
import styles from './style';

import { Container, Button } from 'react-floating-action-button'
import { Link as LinkFloat } from 'react-floating-action-button'
import { hasRole, isAllowed } from 'helpers/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

const useStyle = makeStyles(styles)

export default function UserList(props) {
  const intl = useIntl()
  const classes = useStyle()
  const dispatch = useDispatch();
  const {meterId} = props.match.params
  const user = useSelector(state=>state.user.user)

  useEffect(() => {
    dispatch(cleanConcentrator());
  }, []);
  



  return (
    <DashboardLayout title="Concentradores">
    <div className={classes.root}>

      <div className={classes.content}>
        <ConcentratorTable meterId={meterId}/>
      </div>
    </div>
    <Container>
      {isAllowed(user.permissions,['concentrator_create'])&&<LinkFloat
        href= {`/newConcentrator/${meterId}`}
        icon= "fa fa-plus"
        tooltip = {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.createConcenToolTip'})}
        styles = {{backgroundColor:"#00AFEF", color: "#fff",width: "60px",height: "60px", fontSize:"1.5em"}}
        />}        
        <Button
          
          icon="fa fa-cog"
          rotate={true}
          styles={{backgroundColor: "#C69100", color: "#fff", width: "80px",height: "80px", fontSize:"1.5em"}}
        >
        </Button>
  
      </Container>
  </DashboardLayout>
  )
}
