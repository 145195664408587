/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import { Chip, Typography } from '@material-ui/core';

//import { getGeneralReport } from 'js/actions/chargeDashboardActions';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function TotalsHourEnergy(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, valueInfo } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );

  const totalPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_per_day
  );

  const totalTotalsPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_totals_per_day
  );

  const totalsDaysOfWeek = useSelector(
    state => state.chargeDashboard.generalReport.totals_days_of_week
  );

  const totalsPerHorary = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary
  );

  const totalsPerHoraryCost = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary_price
  );

  const averagePerRange = useSelector(
    state => state.chargeDashboard.generalReport.average_per_range
  );

  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };
  const data = [];
  let obj;

  if (generalReport != undefined || generalReport != null) {
    Object.values(generalReport).forEach(element => {
      element.forEach(element2 => {
        obj = {
          hora: element2.Hora,
          medicion: element2.Kwh,
          color: element2.color
        };
        data.push(obj);
      });
    });
  }

  const res = data.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {});

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <div>
      <Paper className={classes.paper}>
        <Chip
          className={classes.title}
          label={
            valueInfo.kwh
              ? intl.formatMessage({
                id: 'app.charge.board.totByPowerHours.title'
              })
              : intl.formatMessage({
                id: 'app.charge.board.totByPowerHours.titleCost'
              })
          }
        />
        <Table
          className={classes.table}
          size="medium"
          style={{ width: '100%', minWidth: '100%' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {' '}
                {intl.formatMessage({
                  id: 'app.charge.board.totByPowerHours.base'
                })}{' '}
              </TableCell>
              <TableCell>
                {' '}
                {intl.formatMessage({
                  id: 'app.charge.board.totByPowerHours.intermediate'
                })}{' '}
              </TableCell>
              <TableCell>
                {' '}
                {intl.formatMessage({
                  id: 'app.charge.board.totByPowerHours.tip'
                })}{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: '0 !important' }}>
            {generalReport != undefined || generalReport != null ? (
              <TableRow style={{ height: '0px' }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    backgroundColor: '#bfe5bf',
                    fontSize: '1em',
                    fontWeight: 'bold'
                  }}
                >
                  {valueInfo.money
                    ? formatter.format(Object.values(totalsPerHoraryCost)[0])
                    : Object.values(totalsPerHorary)[0]} kWh  
                </TableCell>

                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    backgroundColor: '#f2e5bf',
                    fontSize: '1em',
                    fontWeight: 'bold'
                  }}
                >
                  {valueInfo.money
                    ? formatter.format(Object.values(totalsPerHoraryCost)[1])
                    : Object.values(totalsPerHorary)[1]} kWh 
                </TableCell>

                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    backgroundColor: '#f2bfbf',
                    fontSize: '1em',
                    fontWeight: 'bold'
                  }}
                >
                  {valueInfo.money
                    ? formatter.format(Object.values(totalsPerHoraryCost)[2])
                    : Object.values(totalsPerHorary)[2]} kWh
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
