import axios from 'axios';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const isTokenExpired = () => {
  const expiresAt = localStorage.getItem('expires_at');
  return new Date() > new Date(expiresAt);
};

axios.interceptors.request.use(
  config => {
    if (!isTokenExpired()) {
      localStorage.removeItem('access_token');
      history.push('/login');
    } else {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('access_token');
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

export default axios;