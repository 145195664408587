import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
const useStyle = makeStyles(styles);

function FormConcentrator(props) {
  const classes = useStyle();
  const {meterId,concentratorId} = props.match.params
  const {rowData} = props.location.state

  console.log(meterId)

  return (
    <DashboardLayout title="Edicion de Tarjeta">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel />
          </PortletHeader>
          <PortletContent>
            <FormStepper meterId={meterId} concentratorId={concentratorId} card={rowData}/>
          </PortletContent> 
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default FormConcentrator;
