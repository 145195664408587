import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import { Link } from 'react-router-dom';
// Material helpers
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl'
import { useIntl, FormattedMessage } from "react-intl";

//Services
import { login } from 'services/auth';

// Material components
import {
  Grid,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

import * as action from 'js/actions/userActions';

const mapDispatchToProps = dispactch => {
  return {
    postUser: user =>
    dispactch(action.login(user))
  }
}



class SignIn extends Component {

  
  // state = {
  //   values: {
  //     email: '',
  //     password: ''
  //   },
  //   touched: {
  //     email: false,
  //     password: false
  //   },
  //   errors: {
  //     email: null,
  //     password: null
  //   },
  //   isValid: false,
  //   isLoading: false,
  //   submitError: null
  // };

  constructor(){
    super();

    this.state = {
      email: '',
      password: '',
      remember_me:'',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

   // this.formatMessage = this.props.intl
  }

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChange(event){
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event){
    event.preventDefault();
    const {email,password,remember_me} = this.state
    this.props.postUser({
      email,
      password,
      remember_me      
    })
    this.setState({
      email: email ,
      password:'',
      remember_me:''

    })
  }

  // validateForm = _.debounce(() => {
  //   const { values } = this.state;

  //   const newState = { ...this.state };
  //   const errors = validate(values, schema);

  //   newState.errors = errors || {};
  //   newState.isValid = errors ? false : true;

  //   this.setState(newState);
  // }, 300);

  // handleFieldChange = (field, value) => {
  //   const newState = { ...this.state };

  //   newState.submitError = null;
  //   newState.touched[field] = true;
  //   newState.values[field] = value;

  //   this.setState(newState, this.validateForm);
  // };

  // handleSignIn = async () => {
  //   try {
  //     const { history } = this.props;
  //     const { values } = this.state;

  //     this.setState({ isLoading: true });

  //     await login(values.email, values.password);
  //     history.push('/home');
  //   } catch (error) {
  //     this.setState({
  //       isLoading: false,
  //       serviceError: error
  //     });
  //   }
  // };

  render() {
    const { classes } = this.props;
    const {
      email, password, remember_me
    } = this.state;

    const { intl } = this.props;

    // const showEmailError = touched.email && errors.email;
    // const showPasswordError = touched.password && errors.password;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={5}>
            <div className={classes.quote} />
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Grid item lg={5}>
                    <img
                      src="/images/logo-metimur.png"
                      alt=""
                      width="250px"
                    />
                  </Grid>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label={intl.formatMessage({ id: "app.login.email" })}
                      id= 'email'
                      onChange={this.handleChange}
                      // onChange={event =>
                      //   this.handleFieldChange('email', event.target.value)
                      // }
                      type="text"
                      value={email}
                      variant="outlined"
                    />
                    {/* {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2">
                        {errors.email[0]}
                      </Typography>
                    )} */}
                    <TextField
                      className={classes.textField}
                      label={intl.formatMessage({ id: "app.login.password" })}
                      id='password'
                      onChange={this.handleChange}
                      // onChange={event =>
                      //   this.handleFieldChange('password', event.target.value)
                      // }
                      type="password"
                      value={password}
                      variant="outlined"
                    />
                     <Link
            className={classes.iconTable}
            to={{
              pathname: `/recoveredEmail`,
              //state: {card:rowData}
            }}
            
          > <Typography color='secondary' style={{marginTop:'10px'}} >{intl.formatMessage({ id: "app.login.recoverPassword" })}</Typography> </Link>
                    {/* {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2">
                        {errors.password[0]}
                      </Typography>
                    )} */}
                  </div>
                  {/* {submitError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitError}
                    </Typography>
                  )} */}
                  {/* {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    button
                  )} */}
                  <Button
                      className={classes.signInButton}
                      color="secondary"
                      // disabled={!isValid}
                      onClick={this.handleSubmit}
                      size="large"
                      variant="contained">
                      {intl.formatMessage({ id: "app.login.btnInitSession" })}
                    </Button>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

SignIn = injectIntl(SignIn);

export default compose(
  withRouter,
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(SignIn);
