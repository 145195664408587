import React from 'react';

import { recoveredData } from 'js/actions/userActions';
// Material helpers
import { InputAdornment, makeStyles } from '@material-ui/core';

// Material components
import {
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Material icons
import { AlternateEmail, LockOutlined } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import useForm from 'react-hook-form';
const useStyles = makeStyles(styles);




export default function RecoveredEmail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const {token} = props.match.params
  
  const onSubmit = (email) => {
    dispatch(recoveredData(email,token));
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.formBox}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    className={classes.divImage}
                  >
                    <img
                      alt="Metimur Logo"
                      src="/images/logo-metimur.png"
                      width="140px"
                    />
                  </div>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmail />
                          </InputAdornment>
                        )
                        
                      }}
                      inputRef={register}
                      name="email"
                      placeholder="Correo Electronico"
                      type="email"
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        )
                      }}
                      inputRef={register}
                      name="password"
                      placeholder="Nueva Contraseña"
                      type="password"
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      helperText="Reglas de Seguridad : 8 - 16 caracteres, Minimo 1 Mayuscula y 1 Minuscula y un simbolo Especial (@$!%*#?&+-_)"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        )
                      }}
                      inputRef={register}
                      name="password_confirmation"
                      placeholder="Confirmar Nueva Contraseña"
                      type="password"
                      variant="outlined"
                    />
                  </div>
                  <Button
                    className={classes.signInButton}
                    color="secondary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Recuperar Contraseña
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


