import React, { useEffect } from 'react';
import MaterialTable from 'material-table';

import {MonoPhasicForm,BiPhasicForm,TriPhasicForm}  from '../../components'
import { Button, Select,MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { postForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';
import { showCards } from 'js/actions/cardAction';

export default function MaterialTableDemo(props) {
  const dispatch = useDispatch();
  const comunicationStepForm = useSelector(state => state.forms.data[1]);
  const { concentratorPhase } = comunicationStepForm.data;
  const {nextStep ,prevStep,charge} = props
  const { num_inputs } = props.charge;
  const inputs = parseInt(num_inputs);
  const onSubmit = values => {
    dispatch(postForm(values));
  };

  const getPhaseData = (inputs, concentratorPhase, charge) => {
    switch (inputs) {
      case 1:
        return <MonoPhasicForm
          charge={charge}
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        />

      case 2:
        return <BiPhasicForm
          charge={charge}
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        /> 

      case 3:
        return <TriPhasicForm
          charge={charge}
          concentrator={concentratorPhase}
          nextStep={nextStep}
          prevStep={prevStep}
        />
    }
  }

  return (
    <div>

      {getPhaseData(inputs , concentratorPhase,charge)}
    </div>
  );
}
