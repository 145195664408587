import * as formsActionTypes from '../constants/formsAction-Types';
import axios from 'axios';
import getToken from 'helpers/auth';


export const postForm = values => {
  return {
    type: formsActionTypes.POST_FORM,
    payload: {
      data: values
    }
  };
};


export const deleteLastPostForm = () => {
  return {
    type: formsActionTypes.DELETE_LAST_POST_FORM
  };
};

export const cleanForm = () => ({
  type: formsActionTypes.CLEAN_FORM,
  payload:{
    data : []
  }
});