import React  from 'react';

import { useSelector } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography/*, Button, LinearProgress */} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent/*, PortletFooter */} from 'components';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

export default function AccountProfile() {
  const classes = useStyles();
  const user = useSelector(state => state.user.user)
  const rootClassName = classNames(classes.root);

  return (
    <Portlet
    className={rootClassName}
  >
    <PortletContent>
      <div className={classes.details}>
        <div className={classes.info}>
          <Typography variant="h2">{user.personal_info.firstname} {user.personal_info.lastname} </Typography>
          <Typography
            className={classes.locationText}
            variant="body1"
          >
            {user.role}
          </Typography>
          <Typography
            className={classes.locationText}
            variant="body1"
          >
            Metimur
          </Typography>
          <Typography
            className={classes.dateText}
            variant="body1"
          >
            Metimur
          </Typography>
        </div>
        {/* <Avatar
          className={classes.avatar}
          src="/images/avatars/avatar_1.png"
        /> */}
      </div>
    </PortletContent>
  </Portlet>
  )
}

