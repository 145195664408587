import * as preInvoiceMeterActionTypes from 'js/constants/preInvoiceMeter';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function cardReducer(state = { lastMonthConsumes: [],previewInvoice:[] }, action) {
  switch (action.type) {
    case preInvoiceMeterActionTypes.GET_LAST_METER_CONSUMES_SUCCESS:
      return { ...state, lastMonthConsumes: action.lastConsumes };

    case preInvoiceMeterActionTypes.POST_PREVIEW_FACTOR_SUCCESS:
      return {
        ...state,
        previewInvoice: action.preview
      };
      
    default:
      return state;
  }
}

export default cardReducer;
