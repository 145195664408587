import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'react-hook-form';
import { postControlRate } from 'js/actions/controlRateActions ';

import { makeStyles, Grid, Typography, Button } from '@material-ui/core';

import { useDropzone } from 'react-dropzone';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function InputAdornments() {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const onSubmit = () => {
    dispatch(postControlRate(files));
  };

  const thumbs = files.map(file => (
    <div
      key={file.name}
      style={{ textAlign: 'center', margin: '0 auto' }}
    >
      <Typography
        styles={{ textAlign: 'center' }}
        variant="h3"
      >
        {file.name}
      </Typography>

      <Typography variant='caption' style={{marinTop:'10px'}}> Espera unos segundos en lo que se carga tu archivo tarifario </Typography>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className={classes.root}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          <div className={classes.fields}>
            <section className={classes.container}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Typography
                  style={{ textAlign: 'center', marginTop: '25px' }}
                  variant="h4"
                >
                  Arrastra y Suelta o da Click para cargar el Excel Tarifario
                </Typography>
              </div>
              <aside className={classes.thumbsContainer}>{thumbs}</aside>
            </section>
          </div>
        </Grid>
        <Button
          className={classes.textField}
          color="secondary"
          label="Guardar"
          style={{backgroundColor:'#FEC12C', width:'250px'}}
          size="large"
          type="submit"
          variant="contained"
        >
          Enviar{' '}
        </Button>
       
      </form>
    </div>
  );
}
