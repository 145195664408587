import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import {
  showCards,
  showCardsInputs,
  showVoltageInputs
} from 'js/actions/cardAction';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  cardPhaseA: null,
  inputPhaseA: null,
  cardPhaseB: null,
  inputPhaseB: null,
  cardPhaseB: null,
  inputPhaseB: null,
  cardPhaseB: null,
  inputPhaseB: null,

  relationPhaseA: null,
  relationPhaseB: null,
  relationPhaseC: null
};

function MonoPhasicForm(props) {
  const intl = useIntl();
  const { charge } = props;

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();

  let cards = useSelector(state => state.cards.cards);
  let inputs = useSelector(state => state.cards.inputs);

  const [voltage, setVoltage] = useState(charge.voltage.constant_value);
  const [cardPhaseA, setCardPhaseA] = useState(charge.inputs[0].card_id);
  const [modbusPhaseA, setModbusPhaseA] = useState(null);
  const [inputPhaseA, setInputPhaseA] = useState(charge.inputs[0].input);
  const [relationPhaseA, setRelationPhaseA] = useState(
    charge.inputs[0].relation
  );

  const [cardPhaseB, setCardPhaseB] = useState(
    charge.inputs[1] != undefined ? charge.inputs[1].card_id : null
  );
  const [modbusPhaseB, setModbusPhaseB] = useState(null);
  const [inputPhaseB, setInputPhaseB] = useState(
    charge.inputs[1] != undefined ? charge.inputs[1].input : null
  );
  const [relationPhaseB, setRelationPhaseB] = useState(
    charge.inputs[1] != undefined ? charge.inputs[1].relation : null
  );

  const [cardPhaseC, setCardPhaseC] = useState(
    charge.inputs[2] != undefined ? charge.inputs[2].card_id : null
  );
  const [modbusPhaseC, setModbusPhaseC] = useState(null);
  const [inputPhaseC, setInputPhaseC] = useState(
    charge.inputs[2] != undefined ? charge.inputs[2].input : null
  );
  const [relationPhaseC, setRelationPhaseC] = useState(
    charge.inputs[2] != undefined ? charge.inputs[2].relation : null
  );

  const [isDynamic, setIsDynamic] = useState();
  const [nivel, setNivel] = useState(charge.is_father);
  const [voltageDynamic , setVoltageDynamic] = useState();

  const handleNivel = nivel => {
    setNivel( nivel.target.value );
  };

  useEffect(() => {
    dispatch(showCards(charge.concentrator_id));
  }, []);

  useEffect(() => {
    const newModBus = cards.find(card => card.id === cardPhaseA);
    if (newModBus === undefined) {
      console.log('modbusSeteado');
    } else {
      setModbusPhaseA(newModBus.modbus);
    }
  }, [cardPhaseA]);

  useEffect(() => {
    const newModBus = cards.find(card => card.id === cardPhaseB);
    if (newModBus === undefined) {
      console.log('modbusSeteado');
    } else {
      setModbusPhaseB(newModBus.modbus);
    }
  }, [cardPhaseB]);

  useEffect(() => {
    const newModBus = cards.find(card => card.id === cardPhaseC);
    if (newModBus === undefined) {
      console.log('modbusSeteado');
    } else {
      setModbusPhaseC(newModBus.modbus);
    }
  }, [cardPhaseC]);


  const handleChangeFPDinamico = flag => {
    setIsDynamic(flag.target.checked);    
  };

  const handleChangeVoltageDinamico = flag => {
    setVoltageDynamic(flag.target.checked);    
  };

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
    //console.log('DATA: ', values);
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm());
    props.prevStep();
  };

  const handleVoltage = voltage => {
    setVoltage(voltage.target.value);
    console.log(voltage.target.value);
  };

  const handleCardPhaseA = cardPhaseA => {
    setCardPhaseA(cardPhaseA.target.value);
    dispatch(showCardsInputs(cardPhaseA.target.value));
  };

  const handleCardPhaseB = cardPhaseB => {
    setCardPhaseB(cardPhaseB.target.value);
    dispatch(showCardsInputs(cardPhaseB.target.value));
  };

  const handleCardPhaseC = cardPhaseC => {
    setCardPhaseC(cardPhaseC.target.value);
    dispatch(showCardsInputs(cardPhaseC.target.value));
  };

  const handleInputPhaseA = inputPhaseA => {
    setInputPhaseA(inputPhaseA.target.value);
  };

  const handleInputPhaseB = inputPhaseB => {
    setInputPhaseB(inputPhaseB.target.value);
  };
  const handleInputPhaseC = inputPhaseC => {
    setInputPhaseC(inputPhaseC.target.value);
  };

  const handleRelationPhaseA = relationPhaseA => {
    setRelationPhaseA(relationPhaseA.target.value);
  };

  const handleRelationPhaseB = relationPhaseB => {
    setRelationPhaseB(relationPhaseB.target.value);
  };

  const handleRelationPhaseC = relationPhaseC => {
    setRelationPhaseC(relationPhaseC.target.value);
  };

  return (
    <div className={classes.root}>
      <Typography
        style={{ marginBottom: '10px' }}
        variant="h4"
      >
        {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.phaseConftitle'})}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> A </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="cardPhaseA"
                onChange={handleCardPhaseA}
                value={cardPhaseA}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={charge.inputs[0].card_modbus}
              disabled
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.modBusLbl'})}
              name="modbusPhaseA"
              required
              value={modbusPhaseA}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="inputPhaseA"
                onChange={handleInputPhaseA}
                value={inputPhaseA}
                //labelWidth={labelWidth}
              >
                <MenuItem value={charge.inputs[0].input}>
                  {charge.inputs[0].input}
                </MenuItem>
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="relationPhaseA"
                onChange={handleRelationPhaseA}
                value={relationPhaseA}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>                 
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={charge.inputs[0].ajust}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseA"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={charge.inputs[0].offset}
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseA"
              required
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> B </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="cardPhaseB"
                onChange={handleCardPhaseB}
                value={cardPhaseB}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={charge.inputs[1] != undefined
                ? charge.inputs[1].card_modbus
                : null}
              disabled
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.modBusLbl'})}
              name="modbusPhaseB"
              required
              value={modbusPhaseB}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="inputPhaseB"
                onChange={handleInputPhaseB}
                value={inputPhaseB}
                //labelWidth={labelWidth}
              >
                {charge.inputs[1] != undefined ? (
                  <MenuItem value={charge.inputs[1].input}>
                    {charge.inputs[1].input}
                  </MenuItem>
                ) : null}

                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="relationPhaseB"
                onChange={handleRelationPhaseB}
                value={relationPhaseB}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>                 
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={
                charge.inputs[1] != undefined ? charge.inputs[1].ajust : 1
              }
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseB"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={
                charge.inputs[1] != undefined ? charge.inputs[1].offset : 0
              }
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseB"
              required
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <p style={{ textAlign: 'center', marginTop: '25px' }}> C </p>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.cardLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="cardPhaseC"
                onChange={handleCardPhaseC}
                value={cardPhaseC}
                //labelWidth={labelWidth}
              >
                {cards.map(card => (
                  <MenuItem value={card.id}>{card.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={charge.inputs[2] != undefined
                ? charge.inputs[2].card_modbus
                : null}
              disabled
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.modBusLbl'})}
              name="modbusPhaseC"
              required
              value={modbusPhaseC}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.inputsLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="inputPhaseC"
                onChange={handleInputPhaseC}
                value={inputPhaseC}
                //labelWidth={labelWidth}
              >
                {charge.inputs[2] != undefined ? (
                  <MenuItem value={charge.inputs[2].input}>
                    {charge.inputs[2].input}
                  </MenuItem>
                ) : null}
                {inputs.map(input => (
                  <MenuItem value={input}>{input}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                inputRef={useOnMount}
              >
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.relationLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="relationPhaseC"
                onChange={handleRelationPhaseC}
                value={relationPhaseC}
                //labelWidth={labelWidth}
              >
                <MenuItem value="50:1">50:1</MenuItem>
                <MenuItem value="100:1">100:1</MenuItem>
                <MenuItem value="200:1">200:1</MenuItem>
                <MenuItem value="500:1">500:1</MenuItem>
                <MenuItem value="1000:1">1000:1</MenuItem>
                <MenuItem value="25:5">25:5</MenuItem>
                <MenuItem value="50:5">50:5</MenuItem>
                <MenuItem value="75:5">75:5</MenuItem>
                <MenuItem value="100:5">100:5</MenuItem>
                <MenuItem value="150:1">150:1</MenuItem>
                <MenuItem value="150:5">150:5</MenuItem>
                <MenuItem value="200:5">200:5</MenuItem>
                <MenuItem value="250:5">250:5</MenuItem>
                <MenuItem value="300:1">300:1</MenuItem>
                <MenuItem value="300:5">300:5</MenuItem>
                <MenuItem value="400:5">400:5</MenuItem>
                <MenuItem value="500:5">500:5</MenuItem>
                <MenuItem value="600:5">600:5</MenuItem>
                <MenuItem value="800:5">800:5</MenuItem>
                <MenuItem value="1000:5">1000:5</MenuItem>
                <MenuItem value="1500:5">1500:5</MenuItem>
                <MenuItem value="2000:1">2000:1</MenuItem>
                <MenuItem value="2000:5">2000:5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={
                charge.inputs[2] != undefined ? charge.inputs[2].ajust : 1
              }
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.adjustLbl'})}
              name="ajustPhaseC"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <TextField
              className={classes.textField}
              defaultValue={
                charge.inputs[2] != undefined ? charge.inputs[2].offset : 0
              }
              inputRef={register}
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.offsetLbl'})}
              name="offsetPhaseC"
              required
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '25px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.predefVolt'})}
            </Typography>
            <FormControl
              className={classes.textField}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.voltageLbl'})}
              </InputLabel>
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="voltage"
                onChange={handleVoltage}
                value={voltage}
                //labelWidth={labelWidth}
              >
                <MenuItem value="110">110</MenuItem>
                <MenuItem value="127">127</MenuItem>
                <MenuItem value="220">220</MenuItem>
                <MenuItem value="230">230</MenuItem>
                <MenuItem value="277">277</MenuItem>
                <MenuItem value="280">280</MenuItem>
                <MenuItem value="360">360</MenuItem>
                <MenuItem value="375">375</MenuItem>
                <MenuItem value="440">440</MenuItem>              
                <MenuItem value="480">480</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '25px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.nivelLbl'})}
            </Typography>

            <FormControl
              className={classes.textField}
              variant="outlined"
            >
           
              <Select
                id="demo-simple-select-outlined"
                inputRef={register}
                labelId="demo-simple-select-outlined-label"
                name="nivel"
                onChange={handleNivel}
                value={nivel}
                //labelWidth={labelWidth}
              >

                <MenuItem value="1">Master</MenuItem>
                <MenuItem value="0">Slave</MenuItem>
             
              </Select>
            </FormControl>
          </Grid>            
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox
                  checked={isDynamic}
                  defaultChecked={!charge.fp.is_constant}
                  inputRef={register}
                  name="is_dynamic"
                  onChange={handleChangeFPDinamico}
                />
              }
              label={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.dynamicFP'})}
            />
          </Grid>            
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox         
                  checked={voltageDynamic}                  
                  defaultChecked={!charge.voltage.is_constant}
                  inputRef={register}
                  name="voltage_dynamic"
                  onChange={handleChangeVoltageDinamico}
                />
              }
              label={intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.configuration.dynamicVoltage'})}
            />
          </Grid>    
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={12}
          >
            <Typography
              style={{ marginBottom: '7px', marginTop: '20px' }}
              variant="h4"
            >
              {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.predefFP'})}
            </Typography>
            <TextField
              className={classes.textField}
              defaultValue={charge.fp.constant_value}
              helperText={intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.fpMsg'})}
              inputRef={register}
              margin="normal"
              name="fp"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Button
          color="secondary"
          size="large"
          style={{ marginTop: '10px' }}
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.saveAndContinueBtn'})}
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.editCharge.stepper.configuration.returnBtn'})}
        </Button>
      </form>
    </div>
  );
}

export default MonoPhasicForm;
