import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';
import { history } from 'helpers/history';

// Material components
import { Typography/*, Divider*/} from '@material-ui/core';

// Material icons
// import {
//   AccessTime as AccessTimeIcon,
//   GetApp as GetAppIcon
// } from '@material-ui/icons';

// // Shared components
// import { Paper } from 'components';
// import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from 'recharts';
import MUIDataTable from 'mui-datatables';

// Component styles
import styles from './styles';

class ProductCard extends Component {
  render() {
    const { classes, className, consumes } = this.props;

    const columns = ["Carga", "Base", "Intermedia", "Punta","Total"];

    const data = consumes;
    
    const options = {
      filter: true,
      selectableRows: "none",
      responsive: "scroll",
      pagination: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter:false,
      sort: false
      
    };
    const rootClassName = classNames(classes.root, className);

    return (
      
        
        <MUIDataTable 
          data={data} 
          columns={columns} 
          options={options} 
        />
        
        
    );
  }
}

ProductCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);
