/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Bar } from 'react-chartjs-2';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function SalesChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { className, ...rest } = props;
  const totalConsumes = useSelector(state=>state.meterDashboard.totalsactualmonth);
 


  const data = {
    labels: [intl.formatMessage({id: 'app.meter.board.demandAndConsump.dataSet.base'}), intl.formatMessage({id: 'app.meter.board.demandAndConsump.dataSet.interm'}), intl.formatMessage({id: 'app.meter.board.demandAndConsump.dataSet.tip'})],
    datasets: [
      {
        label: ' kW',
        yAxisID: 'A',
        backgroundColor: ['#8CBA42', '#ebc057', '#ED685E'],
        data:totalConsumes[0]
      },
      {
        label: ' kWh',
        yAxisID: 'B',
        backgroundColor: ['#3A5F0B', '#FFCC00', 'red'],
        data:totalConsumes[1]  
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
    },
    layout: { padding: { top:20 } },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.8,            
          ticks: {
            fontColor: palette.text.secondary,
            fontSize: 14,
            padding: 20
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            fontColor: '#730065',
            beginAtZero: true,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        },
        {
          id: 'B',
          position: 'right',
          opposite: true,
          ticks: {
            fontColor: '#0080BC',
            beginAtZero: true,
            callback: function(value) {
              return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    },
    animation: {
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'top';
        ctx.font = '20px Lato, Helvetica Neue, Arial, 700';
           

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), bar._model.x, bar._model.y -20);
          });
        });
      }
    }
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({id: 'app.meter.board.demandAndConsump.title'})} />
        <PortletToolbar />
      </PortletHeader>
      <PortletContent>
        <div   className={classes.chartWrapper}>
          <Bar   
            data={data}
            options={options}
            redraw
          />
          <div
            className={classes.labelCharts}
            style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-around' }}
          >
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '2rem'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
            <div style={{ width: '33.3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingRight: '4rem'}}>
              <Typography>kW</Typography>
              <Typography>kWh</Typography>
            </div>
          </div>
        </div>
      </PortletContent>
    </Portlet>
  );
}
