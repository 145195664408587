// Palette
import palette from 'theme/palette';

// Chart data
export const data = {
  labels: ['00:00', '01:00', '02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00',
  '13:00', '14:00', '15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],
  datasets: [
    {
      label: '2019',
      fill: false,
      backgroundColor:'#00AFEF',
      borderColor:'#00AFEF',
      lineTension: 0.1,
      // backgroundColor: palette.primary.main,
      data: [14, 15, 14, 16, 14, 15, 14, 13, 16, 13, 15, 16, 14,16, 15, 14, 16, 14, 15, 14, 13, 16, 13, 15, 14]
    },

  
  ]
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
};
