import React, { useState , useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {useDropzone} from 'react-dropzone';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';


// Material
import { Button, Grid, TextField, makeStyles, Typography,FormControl,InputLabel,Select,MenuItem  } from '@material-ui/core';

import { showCFDI,showPaymentMethods,showPaymentTypes,postInvoice} from 'js/actions/invoiceActions'


// Component styles
import styles from './styles';
import { Edit } from '@material-ui/icons';
const useStyles = makeStyles(styles);



export default function CorporativeForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const cfdi = useSelector(state => state.invoice.cfdi);
  const payment_types = useSelector(state => state.invoice.payment_types);
  const payment_methods = useSelector(state => state.invoice.payment_methods);
  const {receipt} = props.location.state
  const [cfdi_form, setCfdiForm] = useState()
  const [payment_types_form, setPaymentTypeForm] = useState()
  const [payment_methods_form, setPaymentMethodForm] = useState()

  const handleCFDI = cfdi => {
    setCfdiForm(cfdi.target.value);
  };

  const handlePaymentTypes = payment_type => {
    setPaymentTypeForm(payment_type.target.value);
  };

  const handlePaymentMethods = payment_methods => {
    setPaymentMethodForm(payment_methods.target.value);
  };


  const onSubmit = (corporative) => {
    dispatch(postInvoice(receipt.charge_info.id,receipt.corporative_info.id, corporative))
    // dispatch(action.postDataCorporative(corporative,files));
    // dispatch(action.postPrefacturationLevel(corporative))
  };


  useEffect(() => {
    dispatch(showCFDI());
    dispatch(showPaymentTypes());
    dispatch(showPaymentMethods());
  }, [])
  

  return (
    <DashboardLayout title="Creacion de Corporativos">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Datos de Facturacion" />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="RFC"
                      //value={values.name}
                      name="rfc"
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Razón social"
                      //value={values.name}
                      name="razon_social"
                      variant="outlined"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          CFDI
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="cfdi"
                        onChange={handleCFDI}
                        value={cfdi_form}
                        //labelWidth={labelWidth}
                      >
                        {
                          cfdi.data ? cfdi.data.map(cfdi => (
                            <MenuItem value={cfdi.id}>{cfdi.name}</MenuItem>

                          )) : console.log('generando cfdis')
                        }
                          


                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          Tipo de Pago:
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="payment_type"
                        onChange={handlePaymentTypes}
                        value={payment_types_form}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_types.data ? payment_types.data.map(payment_types => (
                            <MenuItem value={payment_types.id}>{payment_types.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                          Metodo de Pago:
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="payment_method"
                        onChange={handlePaymentMethods}
                        value={payment_methods_form}
                        //labelWidth={labelWidth}
                      >
                        {
                          payment_methods.data ? payment_methods.data.map(payment_methods => (
                            <MenuItem value={payment_methods.id}>{payment_methods.name}</MenuItem>

                          )) : console.log('generando tipo de pagos')
                        }
                      </Select>
                    </FormControl>

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Correo electrónico (pueden ser varios separados por comas ) "
                      //value={values.name}
                      name="emails_cc"
                      variant="outlined"
                    />

                  </div>
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Facturar
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
