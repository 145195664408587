import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getUsers } from 'services/user';

// Custom components
import { UsersToolbar, UsersTable, CorpTable} from './components';

// Component styles
import styles from './style';

class UserList extends Component {
  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Roles">
        <div className={classes.root}>

        <Typography variant="h3">Seleccione un corporativo</Typography>
          {/* <UsersToolbar selectedUsers={selectedUsers} /> */}
          <div className={classes.content}>
            <CorpTable></CorpTable>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
