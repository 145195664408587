import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
const useStyle = makeStyles(styles);

function FormConcentrator(props) {
  const classes = useStyle();
  const {groupId, meterId} = props.match.params

  

  return (
    <DashboardLayout title="Configuración de Voltaje">
      <div className={classes.root}>
        <Portlet>
          <PortletContent noPadding>
            <FormStepper meterId={meterId} groupId={groupId}/>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default FormConcentrator;
