import React, { useSelector,useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { getUserAlerts } from 'js/actions/userAlertsAction'

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  Budget,
  Users,
  Profit,
  ConsumptionAndDemandChart,
  AlertCard,
  Progress,
  MonthWithMostConsumeChart,
  ComparativeYearConsumeChart,
  DayWithMostConsumeChart,
  MonthChart,
  ProductList,
  PerformanceChart,
  OrdersTable
} from './components';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  }
});

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {chargeId,groupId} = props.match.params
  
  useEffect(() => {
    dispatch(getUserAlerts(chargeId))
  }, [])

  return (
    <DashboardLayout title="Control de Alertas ">
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget className={classes.item}   /> 
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Users className={classes.item} /> 
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Profit className={classes.item}  /> 
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <AlertCard className={classes.item} ids={{groupId:groupId,chargeId:chargeId}}/>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <ConsumptionAndDemandChart className={classes.item} ids={{chargeId:chargeId}} /> 
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
}
