import React from 'react';

import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import { makeStyles } from '@material-ui/core/styles';

import { FormStepper } from './components';

import styles from './styles';
const useStyle = makeStyles(styles);

function FormConcentrator(props) {
  const classes = useStyle();
  const {groupId, chargeId} = props.match.params

  
  let alert = null 
  if(  props.location.state &&  props.location.state.alert  ){
     alert=props.location.state.alert
  }

 
  return (
    <DashboardLayout title="Creacion de Alerta de Usuario">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Nueva Alerta de Usuario" />
          </PortletHeader>
          <PortletContent noPadding> 
                <FormStepper  alert={alert}   ids={{groupId:groupId,chargeId:chargeId}}/>            
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}

export default FormConcentrator;
