export const GET_GROUP_STARTED = 'GET_GROUP_STARTED';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';

export const POST_GROUP_STARTED = 'POST_GROUP_STARTED';
export const POST_GROUP_SUCCESS = 'POST_GROUP_SUCCESS';
export const POST_GROUP_FAILURE = 'POST_GROUP_FAILURE';

export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const PUT_GROUP_STARTED = 'PUT_GROUP_STARTED';

export const GET_GROUP_LIST_STARTED = 'GET_GROUP_LIST_STARTED';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAILURE = 'GET_GROUP_LIST_FAILURE';

export const GET_CHARGE_TYPE_LIST = 'GET_CHARGE_TYPE_LIST';
