/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/styles';

// Material components
import { Badge, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Material icons
import { Notifications } from '@material-ui/icons';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAlerts } from 'js/actions/userAlertsAction';
const useStyles = makeStyles(styles);

export default function Users(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { className, ...rest } = props;
  const { groupId, chargeId } = props.ids;
  const rootClassName = classNames(classes.root, className);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  let alerts = useSelector(state => state.userAlert.userAlerts.messages);
  let alertCounts = alerts === undefined ? 0 : alerts.length;


  useEffect(() => {
    dispatch(getUserAlerts(chargeId))
  }, [])

  return (
    <Badge
      badgeContent={alerts === undefined ? 0 : alertCounts}
      className={rootClassName}
      color="primary"
      {...rest}
      showZero
    >
      <Link
        to={`/group/${groupId}/charge/${chargeId}/alert`}
      >
        <Button
          color="secondary"
          variant="contained"
        >
          <Notifications />{' '}
          {!isSmallScreen && ` ${intl.formatMessage({ id: 'app.charge.board.mainHead.alerts' })}`}
        </Button>
      </Link>
    </Badge>
    // <Paper
    //   {...rest}
    //   className={rootClassName}
    // >
    //   <Link
    //     to={`/group/${groupId}/charge/${chargeId}/alert`}
    //   >
    //     <div className={classes.content}>
    //       <div className={classes.details}>
    //         <Typography
    //           className={classes.title}
    //           variant="body2"
    //         >
    //           {intl.formatMessage({id: 'app.charge.board.mainHead.alerts'})}
    //         </Typography>
    //       </div>
    //       <div className={classes.iconWrapper}>
    //         <AlertIcon className={classes.icon} />
    //       </div>
    //     </div>
    //     <div className={classes.footer}>
    //       <Typography
    //         className={classes.caption}
    //         variant="caption"
    //       >{intl.formatMessage({id: "app.roles.addRole.stepper.permissions.alerts.btn"})}</Typography>
    //     </div>
    //   </Link>
    // </Paper>
  );
}
