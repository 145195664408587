import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {DescriptionCardForm, ComunicationCardForm, ConfigurationCardForm,ConfirmationForm } from '../index'


const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Descripciones', 'Comunicaciones', 'Configuracion de Voltaje', 'Confirmacion'];
}

function getStepContent(stepIndex, handleNext, handleBack, props) {
  switch (stepIndex) {
    case 0:
      return (<DescriptionCardForm nextStep={handleNext} prevStep={handleBack}  voltage={props.voltage}  ></DescriptionCardForm>)
    case 1:
      return (<ComunicationCardForm  nextStep={handleNext} prevStep={handleBack} voltage={props.voltage}  ids={props}></ComunicationCardForm>)
    case 2:
      return (<ConfigurationCardForm ids={props} nextStep={handleNext}  voltage={props.voltage} prevStep={handleBack}></ConfigurationCardForm>)
    case 3:
        return (<ConfirmationForm ids={props} prevStep={handleBack} voltage={props.voltage} ></ConfirmationForm>);
    default:
      return 'Unknown stepIndex';
  }
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root} style={{margin:'0 auto'}}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep, handleNext, handleBack, props)}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}
