import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Divider, Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  company: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 0.5
  }
});

class Footer extends Component {
  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div />
      // <div className={rootClassName}>
      //   <Divider />
      //   <Typography
      //     className={classes.company}
      //     variant="body1"
      //   >
      //     &copy; Metimur Technologies & Services, S.A. de C.V 2020
      //   </Typography>
      //   <Typography variant="caption">
      //      Version: 2.0.0 dev
      //   </Typography>
      // </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
