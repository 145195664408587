export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    margin:'0 auto'
    // backgroundColor: theme.palette.background.default,
    //height: '100vh'
  },
  form: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '125px',
    margin:'0 auto',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    },
  },
   button: {
     marginTop: theme.spacing(1),
     marginRight: theme.spacing(1)
   }
});
  