import * as permissionActionTypes from '../constants/permissionAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function permissionReducer(state = { permissions: [], loading: false }, action) {
  switch (action.type) {
    case permissionActionTypes.POST_PERMISSION_STARTED:
      return {
        ...state,
        loading: true
      };
    case permissionActionTypes.POST_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: state.permission.push(action.payload),
        loading: false
      };
    case permissionActionTypes.POST_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case permissionActionTypes.GET_PERMISSION_SUCCESS:
      return {...state, permissions: action.permissions};

    default:
      return state;
  }
}

export default permissionReducer;
