import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getUsers } from 'services/user';

// Custom components
import { UsersToolbar, UsersTable, ConcentratorTable } from './components';

// Component styles
import styles from './style';

import { Container, Button } from 'react-floating-action-button'
import { Link as LinkFloat } from 'react-floating-action-button'


class UserList extends Component {
  render() {
    const { classes } = this.props;
    //const { meterId } = this.props.match.params
    const {card} = this.props.location.state


    return (
      
      <DashboardLayout title="Tarjetas de Control">
        <div className={classes.root}>
          <div className={classes.content}>
            <ConcentratorTable props={card} /> 
          </div>
        </div>
        <Container>
            <LinkFloat
            href= {`/newControlCard/${card.concentrator_id}/${card.id}`}
            icon= "fa fa-plus"
            tooltip = "Crear Tarjeta de Control"
            styles = {{backgroundColor:"#00AFEF", color: "#fff",width: "60px",height: "60px", fontSize:"1.5em"}}
            />
            <Button
              
              icon="fa fa-cog"
              rotate={true}
              styles={{backgroundColor: "#C69100", color: "#fff", width: "80px",height: "80px", fontSize:"1.5em"}}
            >
            </Button>
      
          </Container>
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
