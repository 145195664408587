import React from 'react';

// Externals
import classNames from 'classnames';
// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Typography, CircularProgress } from '@material-ui/core';

// Material icons
import {
  TrendingUp as MoneyIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function Budget(props) {
  const intl = useIntl();
  const { className, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(classes.root, className);

  const generalConsumes = useSelector(
    state => state.chargeDashboard.generalConsumes
  );

  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="body2"
          >
            {intl.formatMessage({ id: 'app.chargeSolar.board.mainHead.consumtion' })}
          </Typography>

          {generalConsumes.consumes ? (
            <Typography
              className={classes.value}
              variant="h3"
            >
              {generalConsumes.consumes.total_consume_kwh
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
              kWh
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className={classes.iconWrapper}>
          <MoneyIcon className={classes.icon} />
        </div>
      </div>
    </Paper>
  );
}
