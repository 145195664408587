import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import MaterialTable from 'material-table';
import { showAllMeters } from 'js/actions/meterActions';
import { showAllGroups } from 'js/actions/groupActions';
import { showAllCharge } from 'js/actions/chargeActions';
import Swal from 'sweetalert2';

// Material components
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Typography
} from '@material-ui/core';

import * as action from 'js/actions/userActions';
import { showRoles } from 'js/actions/roleActions';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return useEffect(handler, []);
}

const defaultValues = {
  role: null,
  meter: null,
  group: null,
  charge: null
};

export default function UserForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { setValue, getValues, handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const { corpId } = props.match.params;
  const roles = useSelector(state => state.roles.roles);
  const meters = useSelector(state => state.meters.meters);
  const corporatives = useSelector(state => state.corporatives.corporatives);
  const groups = useSelector(state => state.groups.groups);
  const charges = useSelector(state => state.charges.charges);

  const [role, setRole] = React.useState(null);
  const [corporative, setCorporative] = React.useState(null);
  const [meter, setMeter] = React.useState({meter_id:'0',meter:'Todas las Mediciones'});
  const [group, setGroup] = React.useState({group_id:'0',group:'Todas las Mediciones'});
  const [charge, setCharge] = React.useState({charge_id:'0',charge:'Todas las Mediciones'});

  console.log(corporative)

  useEffect(() => {
    dispatch(showRoles(corpId));
  }, []);

  useEffect(() => {
    dispatch(showAllMeters(corpId));
  }, []);

  const handleChange = event => {
    setRole(event.target.value);
  };

  const handleChangeCorporative = event => {
    setCorporative(event.target.value);
    dispatch(showAllMeters(event.target.value.id));
  };

  const handleChangeMeter = event => {
    setMeter(event.target.value);
    dispatch(showAllGroups(corporative.id, event.target.value.id));
  };

  const handleChangeGroup = event => {
    setGroup(event.target.value);
    dispatch(showAllCharge(event.target.value.id));
  };

  const handleChangeCharge = event => {
    setCharge(event.target.value);
    //dispatch(showAllCharge(event.target.value))
  };

  const metimur_user = true;

  const onSubmit = user => {

    dispatch(action.signUp(user, corpId, state.data, metimur_user));
    //console.log(user);
  };

  const [state, setState] = React.useState({
    data: [],
    columns: [
      { title: intl.formatMessage({id: 'app.metusers.addUser.gridCharges.corpCol'}), field: 'corporative' },
      { title: intl.formatMessage({id: 'app.metusers.addUser.gridCharges.meterCol'}), field: 'meter' },
      { title: intl.formatMessage({id: 'app.metusers.addUser.gridCharges.groupCol'}), field: 'group' },
      { title: intl.formatMessage({id: 'app.metusers.addUser.gridCharges.chargeCol'}), field: 'charge' }
    ]
  });

  const setTable = () => {
    let obj = {
      corporative_id: corporative.id,
      corporative: corporative.name,
      meter_id: meter.id,
      meter: meter.name,
      group_id: group.id,
      group: group.name,
      charge_id: charge.id,
      charge: charge.name
    };

    setState({
      ...state,
      data: [...state.data, obj]
    });
    setCorporative(null)
    setMeter(null);
    setGroup({ group_id: '0', group: 'Todas las Mediciones' });
    setCharge({ charge_id: '0', charge: 'Todas las Mediciones' });
  };

  return (
    <DashboardLayout title="Creacion de Usuarios">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Nuevo Usuario" />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={6}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.firstNameLbl'})}
                      name="firstname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.lastNameLbl'})}
                      name="lastname"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.emailLbl'})}
                      name="email"
                      required
                      //onChange={this.handleChange}
                      //value={email}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      helperText={intl.formatMessage({id: 'app.metusers.addUser.passwordRules'})}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.passwordLbl'})}
                      name="password"
                      required
                      type="password"
                      //onChange={this.handleChange}
                      //value={password}
                      variant="filled"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  xl={3}
                  xs={3}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.phoneNumbLbl'})}
                      name="tel"
                      required
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metusers.addUser.cellPhoneNumb'})}
                      name="cel"
                      required
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">{intl.formatMessage({id: 'app.metusers.addUser.roleSel'})}</InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="role"
                        onChange={handleChange}
                        value={role}
                      >
                        <MenuItem value="">
                          <em>{intl.formatMessage({id: 'app.metusers.addUser.roleSelMsg'})}</em>
                        </MenuItem>
                        {roles.map(role => (
                          <MenuItem value={role.id}>{role.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/*  */}
                  </div>
                </Grid>
              </Grid>

              <form
                //className={classes.form}
                //onSubmit={setTable}
                style={{ marginBottom: '10px', marginTop: '20px' }}
              >
                <Typography
                  //className={classes.title}
                  variant="h4"
                >
                  {intl.formatMessage({id: 'app.metusers.addUser.addToList'})}
                </Typography>

                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={3}
                  >
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {intl.formatMessage({id: 'app.metusers.addUser.corpSel'})}
                      </InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="corporative"
                        onChange={handleChangeCorporative}
                        value={corporative}
                      >
                        <MenuItem value="">
                          <em>{intl.formatMessage({id: 'app.metusers.addUser.corpSelMsg'})}</em>
                        </MenuItem>
                        {corporatives.map(corporative => (
                          <MenuItem value={corporative}>{corporative.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                  >
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {intl.formatMessage({id: 'app.metusers.addUser.meterSel'})}
                      </InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="meter"
                        onChange={handleChangeMeter}
                        value={meter}
                      >
                        <MenuItem value="">
                          <em>{intl.formatMessage({id: 'app.metusers.addUser.meterSelMsg'})}</em>
                        </MenuItem>
                        {meters.map(meter => (
                          <MenuItem value={meter}>{meter.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                  >
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {intl.formatMessage({id: 'app.metusers.addUser.groupSel'})}
                      </InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="meter"
                        onChange={handleChangeGroup}
                        value={group}
                      >
                        <MenuItem value="">
                          <em>{intl.formatMessage({id: 'app.metusers.addUser.groupSelMsg'})}</em>
                        </MenuItem>
                        {groups.map(group => (
                          <MenuItem value={group}>{group.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                  >
                    <FormControl
                      className={classes.textField}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        {intl.formatMessage({id: 'app.metusers.addUser.chargeSel'})}
                      </InputLabel>
                      <Select
                        inputRef={register}
                        labelId="demo-simple-select-disabled-label"
                        name="meter"
                        onChange={handleChangeCharge}
                        value={charge}
                      >
                        <MenuItem value="">
                          <em>{intl.formatMessage({id: 'app.metusers.addUser.chargeSelMsg'})}</em>
                        </MenuItem>
                        {charges.map(charge => (
                          <MenuItem value={charge}>{charge.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                  >
                    <Button
                      className={classes.signUpButton}
                      color="secondary"
                      onClick={setTable}
                      //type="submit"
                      size="large"
                      variant="outlined"
                    >
                      {intl.formatMessage({id: 'app.metusers.addUser.addToListBtn'})}
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <MaterialTable
                columns={state.columns}
                data={state.data}
                options={{ toolbar: false }}
              />
              <Button
                className={classes.signUpButton}
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metusers.addUser.registerBtn'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
