import React, { useEffect  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Button, Typography, List, ListItem } from "@material-ui/core";
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import {showRoles,deleteRole} from 'js/actions/roleActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {history} from 'helpers/history'
import {translatePermission} from 'helpers/translate'
import { isAllowed } from 'helpers/auth';
import { useIntl } from 'react-intl';



export default function RoleTable(props){
  //const corpid = props.corpid
  const intl = useIntl()
  const user = useSelector(state=>state.user.user)
  const dispatch = useDispatch()
  const roles = useSelector(state => state.roles.roles)
  const data = roles;
  const MySwal = withReactContent(Swal)

  useEffect(() => {
     dispatch (showRoles())
  }, [])

  const deleteRoleData = role => {
    
    dispatch(deleteRole(role));
  };

  const columns = [
    {
      field: 'name',
      title: intl.formatMessage({id: 'app.roles.grid.nameCol'}),
    },
    {
      field: 'created_at',
      title: intl.formatMessage({id: 'app.roles.grid.creatDateCol'}),
    },
    {
      field:'',
      title:intl.formatMessage({id: 'app.roles.grid.roleDetailCol'}),
      render: rowData => <Button color="secondary" onClick={()=> displayRoleData(rowData) }>{intl.formatMessage({id: 'app.roles.grid.roleDetailCol.msg'})}</Button>
    }
  ];


  const permissionsActions = () => {

    const actions = []

    if(isAllowed(user.permissions,['role_delete'])){
      actions.push( {
        icon: 'delete',
        tooltip: intl.formatMessage({id: 'app.roles.grid.acctions.delete'}),
        onClick: (event, rowData) => deleteRoleData(rowData)
      })
    }
    if(isAllowed(user.permissions,['role_update'])){
      actions.push(
        {
          icon: 'edit',
          tooltip: intl.formatMessage({id: 'app.roles.grid.acctions.edit'}),
          onClick: (event, rowData) => {history.push({ 
            pathname: `/updateRole/${rowData.id}`,
            state:{role: rowData}
           })}
        }

      )
    }

    return actions
    
  }

  

 const  displayRoleData = (roleData) =>{

  const tpermission = translatePermission(roleData.permissions)

  MySwal.fire({
  title: <Typography>{intl.formatMessage({id: 'app.roles.rolesPopUpMessage.title'})}: {roleData.name}</Typography>,
  html: <List>{roleData.permissions.map(el=>(<ListItem>{el.description}</ListItem>))}</List>,
  })

 } 



 if( roles  && Array.isArray( roles ) && roles.length>0  ){

   
    
  return (
    <div>
      <MaterialTable
           options={{
            showTitle:false,
            actionsColumnIndex: -1
          }}
          data={data}
          columns={columns}
          actions={permissionsActions()}
        />
    </div>
  )

 }else {
    return null;
 }


}