import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  ArrowDownward as ArrowDownwardIcon,
  TrendingUp as MoneyIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { injectIntl } from 'react-intl';

class Budget extends Component {
  render() {
    const { classes, className, consumes, typeEnergy, ...rest } = this.props;
    const { intl } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              {typeEnergy === 2 ?  intl.formatMessage({id: 'app.groupSolar.board.mainHead.dayConsumption'}): intl.formatMessage({id: 'app.group.board.mainHead.dayConsumption'})}
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {consumes.total_kwh} kWh
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <MoneyIcon className={classes.icon} />
          </div>
        </div>
      </Paper>
    );
  }
}

Budget.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

Budget = injectIntl(Budget);

export default withStyles(styles)(Budget);
