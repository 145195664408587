import * as userAlertsTypes from 'js/constants/userAlertsAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import swal from 'sweetalert';
import Swal from 'sweetalert2';


const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};


export const getUserAlerts = chargeId => {
  console.log('getUserAlerts');
  return async dispatch => {
    dispatch(getUserAlertsStarted());
    axios
      .get(`https://api.metimur.mx/api/charge/${chargeId}/alerts`, getconfig())
      .then(response => {
        dispatch(getUserAlertsSuccess(response.data.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
}; 



export const getUserAlertsCns = chargeId => {
  console.log('getUserAlertsCns');
  return async dispatch => {
    dispatch(getUserAlertsCnsStarted());
    axios
      .get(`https://api.metimur.mx/api/charge/${chargeId}/alerts/config`, getconfig())
      .then(response => {
        dispatch(getUserAlertsCnsSuccess(response.data.data));
      })
      .catch(function(error) {
        console.log('ERROR' + error);
      });
  };
}; 


export const getUserAlertsStarted = () => ({
  type: userAlertsTypes.GET_USER_ALERT_STARTED,
  loading: true
});




export const getUserAlertsSuccess = userAlerts => {
  return {
    type: userAlertsTypes.GET_USER_ALERT_SUCCESS,
    userAlerts,
    loading: false
  };
};

export const getUserAlertsCnsSuccess = userAlerts => {
  return {
    type: userAlertsTypes.GET_USER_ALERTS_CNS_SUCCESS,
    userAlertsCns:userAlerts ,
    loading: false
  };
};



export const getUserAlertsCnsStarted = () => ({
  type: userAlertsTypes.GET_USER_ALERTS_CNS_STARTED,
  loading: true
});


export const postUserAlerts = alert => {
  return async dispatch => {
    console.log(alert);
    dispatch(postUserAlertStarted());
  
    if( alert.id  ){
    
      axios
      .put(
        `https://api.metimur.mx/api/alerts/user` ,
        {
          id: alert.id ,
          group_id: alert.group_id,
          charge_id: alert.charge_id,
          name: alert.name_alert,
          lvl:alert.lvl_alert,
          message:alert.message_alert,
          rules:alert.rules,
          contacts:alert.contacts,
          message_id: "1",
          active:1
        },
        getconfig()
      )
      .then(response => {
        //dispatch(postUserAlertSuccess(alert));
          Swal.fire({
            title: 'La Alerta se actualizo correctamente '  ,
            text: `${alert.name_alert}`,
            icon: 'success',
          }).then(
            // ()=>{history.push('/home')}
            ()=>{history.push(`/alerts/${alert.group_id}/charge/${alert.charge_id}` )}
          );
        


        })
        .then(()=>{
 


        }
            
        )
        .catch(error => {
          console.log('ERROR API AL MODIFICAR:' + error);
          dispatch(postUserAlertFail(error.message));

          const errorMessage = error.response.data.message
          
          Swal.fire({
            title: 'Error',
            text: `<br/>${errorMessage.join('<br/>')}`,
            icon: 'error',
          });
        
        });


    }else{
    
      axios
      .post(
        `https://api.metimur.mx/api/alerts/user/create` ,
        {          
          group_id: alert.group_id,
          charge_id: alert.charge_id,
          name: alert.name_alert,
          lvl:alert.lvl_alert,
          message:alert.message_alert,
          rules:alert.rules,
          contacts:alert.contacts,
          message_id: "1",
          active:1
        },
        getconfig()
      )
      .then(response => {
        dispatch(postUserAlertSuccess(alert));
      })
      .then(()=>{

          Swal.fire({
            title: 'Alerta Creada Exitosamente'  ,
            text: '',
            icon: 'success',
          }).then(
            ()=>{history.push(`/alerts/${alert.group_id}/charge/${alert.charge_id}`)}
          );
        
        }
            
        )
        .catch(error => {
          console.log('ERROR API:' + error);
          dispatch(postUserAlertFail(error.message));

          const errorMessage = error.response.data.message
          
          Swal.fire({
            title: 'Error',
            text: `<br/>${errorMessage.join('<br/>')}`,
            icon: 'error',
          });
       
        });      



    }


  };
};

export const postUserAlertStarted = () => ({
  type: userAlertsTypes.POST_USER_ALERT_STARTED
});

export const postUserAlertSuccess = alert => {
  return {
    type: userAlertsTypes.POST_USER_ALERT_SUCCESS,
  };
};

export const postUserAlertFail = error => ({
  type: userAlertsTypes.POST_USER_ALERT_FAILURE,
  state: { error }
});


export const deleteAlert = ( alertId , chargeId , funcRef  ) => {
  return async dispatch => {
    dispatch(deleteUserAlertStarted());
    //dispatch(deleteAlertSuccess(alertId));
    axios.post(
      `https://api.metimur.mx/api/alerts/user/delete`,      
      {
        id: alertId   
      },
      getconfig()
    ) .then(response => {
      //dispatch(deleteUserAlertSuccess(alertId));
      // getUserAlertsCns(chargeId); 
    })
      .then(()=>{

        Swal.fire({
          title: 'Alerta Eliminada Exitosamente'  ,
          text: '',
          icon: 'success',
        }).then(
          // ()=>{history.push('/home')}
          // ()=>{ getUserAlertsCns(chargeId);  }
          ()=>{ funcRef(); }
        );
      
      }
          
      )
      .catch(error => {
      //dispatch(postUserAlertFail(error.message));
        //console.log('ERROR API:' + error);
      });      




  };
};

export const deleteUserAlertStarted = () => ({
  type: userAlertsTypes.DELETE_USER_ALERT_STARTED
});

export const deleteUserAlertSuccess = alertId => {
  return {
    type: userAlertsTypes.DELETE_USER_ALERT_SUCCESS,
    payload:{id: alertId} 
  };
};



export const attendAlert = (alertId , chargeId ) => {
  return async dispatch => {
    //dispatch(deleteUserAlertStarted());
    //dispatch(deleteAlertSuccess(alertId));
    axios.post(
      `https://api.metimur.mx/api/alerts/notification/attend`,      
      {
        alert_id: alertId   
      },
      getconfig()
    ) .then(response => {
      // dispatch(deleteUserAlertSuccess(alertId));
      dispatch( getUserAlerts(chargeId));
      console.log('attend request was ok');
        
    })
    
      .catch(error => {
      //dispatch(postUserAlertFail(error.message));
        //console.log('ERROR API:' + error);
      });      




  };
};



  
export const DELETE_USER_ALERT_STARTED = 'DELETE_USER_ALERT_STARTED';
export const DELETE_USER_ALERT_SUCCESS = 'DELETE_USER_ALERT_SUCCESS';






// export const deleteCorporative = corpId => {
//   return async dispatch => {
//     dispatch(getCorporativeStarted());

//     axios.delete(
//       `https://api.metimur.mx/api/corporative`,
//       { corporative_id: corpId },
//       getconfig()
//     )
//     .then(response => {
//       dispatch(deleteCorporativeSuccess(corpId));
//     })
//     ;
//   };
// };

// export const deleteCorporativeStarted = () => ({
//   type: actionTypes.DELETE_CORPORATIVE_STARTED
// });

// export const deleteCorporativeSuccess = corpId => {
//   return {
//     type: actionTypes.DELETE_CORPORATIVE_SUCCESS,
//     payload: { corporative_id: corpId }
//   };
// };