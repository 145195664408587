export default theme => ({
  root: {},
  buttonContainer: {
    marginRight: '-120px',
    marginBottom: '-20px',
    zIndex: '900',
    [theme.breakpoints.down('lg')]: {
      marginRight: '-90px'
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '-20px'
    },
  },
  fabIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '60px',
    height: '60px',
    transition: 'all .3s ease-in-out',
    borderRadius: '50px',
    '&:hover':{
      width: 'auto'
    }
  },
  fabText: {
    color: '#FFF',
    transition: 'all .3s ease-in-out',
    transform: 'translateX(10px)',
    display: 'none',
  },
  fabTextVisible: {
    display: 'block',
    transform: 'translateX(0px)',
    transition: 'all .3s ease-in-out',
  },
});