import React from 'react';
import { useSelector } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { makeStyles } from '@material-ui/core/styles';

// Material components
import { Button, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function Account() {
  const classes = useStyles();
  const user = useSelector(state => state.user.user)
  const rootClassName = classNames(classes.root);

  
  return (
    <Portlet
    className={rootClassName}
  >
    <PortletHeader>
      <PortletLabel
        subtitle="Datos personales"
        title="Perfil"
      />
    </PortletHeader>
    <PortletContent noPadding>
      <form
        
        noValidate
      >
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            helperText="Please specify the first name"
            label="First name"
            margin="dense"
            required
            value={user.personal_info.firstname}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label="Last name"
            margin="dense"
            required
            value={user.personal_info.lastname}
            variant="outlined"
          />
        </div>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Email Address"
            margin="dense"
            required            
            value={user.personal_info.email}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label="Phone Number"
            margin="dense"
            type="number"
            value={user.personal_info.tel}
            variant="outlined"
          />
        </div>
      </form>
    </PortletContent>
    {/* <PortletFooter className={classes.portletFooter}>
      <Button
        color="secondary"
        variant="contained"
      >
        Save details
      </Button>
    </PortletFooter> */}
  </Portlet>
  )
}

