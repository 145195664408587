import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image
} from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell
} from '@david.kucsai/react-pdf-table';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Medium.ttf', fontWeight: 500 },
    { src: '/fonts/Roboto-Thin.ttf', fontWeight: 100 },
    { src: '/fonts/Roboto-Light.ttf', fontWeight: 300 },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/Roboto-Black.ttf', fontWeight: 900 }
  ]
});

const style = StyleSheet.create({
  page: {
    flexDirection: 'column'
  },
  section: {
    margin: 5
  },
  titles: {
    top: 20,
    fontFamily: 'Roboto',
    fontWeight: 900
  },
  section_title: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#840c74',
    fontSize: '12pt',
    paddingTop: '5pt',
    paddingLeft: '5pt',
    border: '3pt solid #840c74'
  },
  section_data: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    fontSize: '10pt',
    textAlign: 'left',
    width: '70%',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  section_values: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    fontSize: '10pt',
    textAlign: 'left',
    width: '30%',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  total_billing: {
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: '16pt',
    marginTop: '10pt'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  details: {
    fontSize: 8,
    marginTop: 10,
    textAlign: 'center',
    color: 'grey'
  },
  logoFooter: {
    position: 'absolute',
    bottom: 35,
    marginVertical: 15,
    marginHorizontal: 245,
    width: '4cm'
  }
});

export default function() {
  const receipt = useSelector(state => state.chargeDashboard.receipt);

  return (
    <div>
      <PDFViewer
        width="612px"
        height="792px"
        style={{ margin: '0 auto', display: 'block' }}>
        <Document>
          <Page size="LETTER" orientation="portrait" style={style.page}>
            {/* <Image
              style={{ width: '3.5cm', margin: '6mm 0 0 6mm' }}
              source={corpAdminLogo.logo}
              cache={false}
            /> */}

            <Text
              style={
                (style.titles, { textAlign: 'center', paddingTop: '1cm' })
              }>
              {receipt.charge_info.name}
            </Text>

            <View style={{ paddingTop: '2mm' }}>
              <View style={style.section}>
                <Text style={style.section_title}>ENERGIA ELECTRICA</Text>
                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Medicion: {receipt.charge_info.name}</Text>
                    <Text>
                      Periodo de Consumo :{' '}
                      {receipt.consumes.billing_period.start}{' '}
                    </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>Tarifa: {receipt.consumes.billing.recipe_type}</Text>
                    <Text>
                      {receipt.consumes.billing_period.start} -{' '}
                      {receipt.consumes.billing_period.end}
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Consumo</Text>
                    <Text>
                      Demanda Maxima del Mes por uso Red de distribucion{' '}
                    </Text>
                    <Text>Demanda Maxima en Punta (CAPACIDAD) </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>{receipt.consumes.total_consume_kwh} kWh</Text>
                    <Text>
                      {Math.round(receipt.consumes.distribution * 100) / 100} kW
                    </Text>
                    <Text>
                      {(Math.round(receipt.consumes.capacity) * 100) / 100} kW
                    </Text>
                  </View>
                </View>

                {/* CONCEPTOS */}

                <Text style={style.section_title}>FACTURACION</Text>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Cargos por Consumo ** </Text>
                    <Text>Cargos por Demanda ** </Text>
                    <Text>
                      Cargos por Medicion en Baja Tension y Monitoreo{' '}
                    </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>
                      {' '}
                      ${' '}
                      {Math.round(
                        (receipt.consumes.billing.total_base +
                          receipt.consumes.billing.total_intermedio +
                          receipt.consumes.billing.total_punta) *
                          100
                      ) / 100}
                    </Text>
                    <Text> $ {receipt.cargo_distribucion}</Text>
                    <Text>
                      {' '}
                      ${' '}
                      {Math.round(
                        (receipt.consumes.billing.flat_fee +
                          receipt.consumes.billing.loss_burden) *
                          100
                      ) / 100}{' '}
                    </Text>
                  </View>
                </View>

                <Text style={style.section_title}>TOTALES</Text>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Total Facturado</Text>
                    <Text>IVA</Text>
                    <Text style={style.total_billing}>Total a Pagar </Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>
                      {' '}
                      ${' '}
                      {Math.round(receipt.consumes.billing.subtotal * 100) /
                        100}
                    </Text>
                    <Text> $ {receipt.consumes.billing.iva}</Text>
                    <Text style={style.total_billing}>
                      {' '}
                      $ {receipt.consumes.billing.total}{' '}
                    </Text>
                  </View>
                </View>

                <Text style={style.section_title}>FECHAS</Text>

                <View style={{ flexDirection: 'row' }}>
                  <View style={style.section_data}>
                    <Text>Fecha de Corte</Text>
                    <Text>Fecha de Vencimiento</Text>
                  </View>

                  <View style={style.section_values}>
                    <Text>{receipt.consumes.billing_period.cutoff}</Text>
                    <Text>{receipt.consumes.billing_period.expiration}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={style.section}>
              <Text style={style.section_title}>
                DESGLOCE DE CONSUMO Y DEMANDA
              </Text>

              <Table
                data={[
                  {
                    concepto: 'Transmision',
                    base:
                      Math.round(
                        receipt.cfe.transmision.base *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    intermedia:
                      Math.round(
                        receipt.cfe.transmision.intermedio *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    punta:
                      Math.round(
                        receipt.cfe.transmision.punta *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100
                  },
                  {
                    concepto: 'CENACE',
                    base:
                      Math.round(
                        receipt.cfe.cenace.base *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    intermedia:
                      Math.round(
                        receipt.cfe.cenace.intermedio *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    punta:
                      Math.round(
                        receipt.cfe.cenace.punta *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100
                  },
                  {
                    concepto: 'SCNMEM',
                    base:
                      Math.round(
                        receipt.cfe.scnmem.base *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    intermedia:
                      Math.round(
                        receipt.cfe.scnmem.intermedio *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100,
                    punta:
                      Math.round(
                        receipt.cfe.scnmem.punta *
                          receipt.consumes.total_consume_kwh *
                          100
                      ) / 100
                  }
                ]}>
                <TableHeader>
                  <TableCell>Concepto</TableCell>
                  <TableCell>Base</TableCell>
                  <TableCell>Intermedia</TableCell>
                  <TableCell>Punta</TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell getContent={r => r.concepto} />
                  <DataTableCell getContent={r => r.base} />
                  <DataTableCell getContent={r => r.intermedia} />
                  <DataTableCell getContent={r => r.punta} />
                </TableBody>
              </Table>
            </View>

            <Text style={style.details}>
              {' '}
              {
                '** Desgloce de conceptos para CONSUMO (Base , Intermedia , Punta) y DEMANDA (Cargos por dermanda Distribucion / Generacion)'
              }{' '}
            </Text>
            <View style={style.section}>
              <Text style={style.section_title}>
                CUOTAS DEL KWH POR CONCEPTO **
              </Text>

              <Table
                data={[
                  {
                    concepto: 'Punta',
                    costo: receipt.cfe.price_punta,
                    kwh: receipt.consumes.punta.consume_kwh,
                    subtotal:
                      Math.round(
                        receipt.cfe.price_punta *
                          receipt.consumes.punta.consume_kwh *
                          100
                      ) / 100
                  },
                  {
                    concepto: 'Intermedia',
                    costo: receipt.cfe.price_intermedio,
                    kwh: receipt.consumes.intermedio.consume_kwh,
                    subtotal:
                      Math.round(
                        receipt.cfe.price_intermedio *
                          receipt.consumes.intermedio.consume_kwh *
                          100
                      ) / 100
                  },
                  {
                    concepto: 'Base',
                    costo: receipt.cfe.price_base,
                    kwh: receipt.consumes.base.consume_kwh,
                    subtotal:
                      Math.round(
                        receipt.cfe.price_base *
                          receipt.consumes.base.consume_kwh *
                          100
                      ) / 100
                  },
                  {
                    concepto:
                      'Cargos por demanda por uso de la red de Distribución',
                    costo:
                      Math.round(receipt.cfe.capacidad.distribucion * 100) /
                      100,
                    kwh: Math.round(receipt.consumes.distribution * 100) / 100,
                    subtotal:
                      Math.round(
                        receipt.cfe.capacidad.distribucion *
                          receipt.consumes.distribution *
                          100
                      ) / 100
                  },
                  {
                    concepto: 'Cargos por demanda por capacidad (Generación)',
                    costo:
                      Math.round(receipt.cfe.capacidad.capacidad * 100) / 100,
                    kwh: Math.round(receipt.consumes.capacity * 100) / 100,
                    subtotal:
                      Math.round(
                        receipt.cfe.capacidad.capacidad *
                          receipt.consumes.capacity *
                          100
                      ) / 100
                  }
                ]}>
                <TableHeader>
                  <TableCell>Concepto</TableCell>
                  <TableCell>Costo</TableCell>
                  <TableCell>KWH</TableCell>
                  <TableCell>Subtotal</TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell getContent={r => r.concepto} />
                  <DataTableCell getContent={r => r.costo} />
                  <DataTableCell getContent={r => r.kwh} />
                  <DataTableCell getContent={r => r.subtotal} />
                </TableBody>
              </Table>
            </View>

            <Image
              style={style.logoFooter}
              source="/images/b_w.png"
              cache={false}
            />
            <Text style={style.pageNumber}>
              {`www.metimur.mx     Tecnología de Submedición de Energía    contacto@metimur.mx`}
            </Text>
          </Page>
        </Document>
      </PDFViewer>
      <Link
        to={{
          pathname: `/invoice`,
          state:{
           receipt 
          }
        }}
        // onClick={() => setAdminMeterId(meter.id)}
      >
        <Button variant="contained" color="secondary" style={{ width: '100%' }}>
          FACTURAR
        </Button>
      </Link>
    </div>
  );
}
