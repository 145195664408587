/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { Clear as DoneIcon } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);


export default function Profit(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const alerts = useSelector(state => state.systemAlert.systemAlert );

  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="body2"
          >
            {intl.formatMessage({id: 'app.alerts.header.highLevelIssues'})}
          </Typography>
          <Typography
            className={classes.value}
            variant="h3"
          >
            0
            {/* {alerts 
              ? alerts.alert_lvl_2_count
              : 0} */}
          </Typography>
        </div>
        <div className={classes.iconWrapper}>
          <DoneIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.footer}>
        <Typography
          className={classes.difference}
          variant="body2"
        >
          {/* <ArrowUpwardIcon /> */}
        
        </Typography>

      </div>
    </Paper>
  )
}



