import React, { Component } from 'react';
import palette from 'theme/palette';
import { useSelector } from 'react-redux';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Typography } from '@material-ui/core';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
  CallMergeRounded
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Chart configuration
import { options } from './chart.js';

// Component styles
import styles from './styles.jsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(styles);

export default function ConsumptionAndDemandChart(props) {
  const classes = useStyles();
  const { className, consumes, ...rest } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport
  );

  const data = {
    labels: [],
    datasets:[
      {
        label:'Demanda',
        fill: false,
      lineTension: 0.3,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
       //backgroundColor:'#bfe5bf',
        data:[]
      }
    ]
  };

  const options = { responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    animation: {
      onComplete: function () {
          var chartInstance = this.chart,
              ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);

              });
          });
      }
  }
    //cornerRadius: 20,
    // tooltips: {
    //   enabled: true,
    //   mode: 'index',
    //   intersect: false,
    //   borderWidth: 1,
    //   borderColor: palette.border,
    //   backgroundColor: palette.common.white,
    //   titleFontColor: palette.text.primary,
    //   bodyFontColor: palette.text.secondary,
    //   footerFontColor: palette.text.secondary
    // },
    // layout: { padding: 0 },
    // scales: {
    //   xAxes: [{
    //     stacked: true,
    //     gridLines: {
    //       display: false,
    //     }
    //   }],
    //   yAxes: [{
    //     stacked: true,
    //     ticks: {
    //       beginAtZero: true,
    //     },
    //     type: 'linear',
    //   }]
    // }
  
  };

  

  Object.values(generalReport).forEach((element, index) => {
    data.labels.push(`${index + 1}`);
    
    //data.datasets[0].data.push( Math.max.apply(Math, element.map(function(o) { return o.Kw; })).toFixed(2))

   //console.log( Math.max.apply(Math, element.map(function(o) { return o.Kw; })))

  })

  

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletHeader noDivider>
        <PortletLabel title="Demanda Maxima por Día" />
        {/* <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text">
            Septiembre
          </Button>
        </PortletToolbar> */}
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          /> 
        </div>
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}
