import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getComparativeYearConsume} from 'js/actions/groupDashboardActions'


// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

// Chart configuration
import { data, options } from './chart';

// Component styles
import styles from './styles';
import { getStoredState } from 'redux-persist';
const useStyles = makeStyles(styles)


export default function ComparativeYearConsumeChart(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const yearsConsumes = useSelector(state=>state.groupDashboard.comparativeYearConsume);
  const {actual_year, previous_year} = yearsConsumes

  useEffect(()=>{
    dispatch(getComparativeYearConsume(id,2020))
  },[])

  if(actual_year == undefined)
  {
    console.log('procesando datos')
  }
  else{
    var actual_year_consumes = actual_year.months_consumes
  }

  if(previous_year == undefined)
  {
    console.log('procesando datos')
  }
  else{
    var previous_year_consumes = previous_year.months_consumes
  }


  const data = {
    labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto', 'Septiembre', 'Octubre','Noviembre','Diciembre'],
    datasets: [
      {
        label: '2019',
        fill: false,
        backgroundColor:'#C69100',
        borderColor:'#C69100',
        data: previous_year_consumes
        
      },
      {
        label: ' 2020',
        fill: false,
        
        backgroundColor:'#0080BC',
        borderColor:'#0080BC',
        data: actual_year_consumes
      }
    
    ]
  };


  return (
    <Portlet
    {...rest}
    className={rootClassName}
  >
    <PortletHeader noDivider>
      <PortletLabel title="Diferencia de Consumo (Año Actual vs Año Anterior)" />
      <PortletToolbar>
        <Button
          className={classes.dropdownButton}
          size="small"
          variant="text"
        >
          2020
        </Button>
      </PortletToolbar>
    </PortletHeader>
    <PortletContent>
      <div className={classes.chartWrapper}>
           <Line
          data={data}
          options={options}
          />
      </div>
    </PortletContent>
  </Portlet>
  )
}
