import React from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import {
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function NameRoleForm(props) {
  const intl = useIntl()
  const classes = useStyles();
  const form = useSelector(state => state.form);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xl={4} xs={6}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.corporate.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_corporative}
                      name="corporative_create"
                      inputRef={register}
                      inputProps={{ 'aria-label': 'corporative_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.corporate.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_corporative}
                      inputRef={register}
                      name="corporative_read"
                      inputProps={{ 'aria-label': 'corporative_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.corporate.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_corporative}
                      inputRef={register}
                      name="corporative_update"
                      inputProps={{ 'aria-label': 'corporative_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.corporate.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_corporative}
                      inputRef={register}
                      name="corporative_delete"
                      inputProps={{ 'aria-label': 'corporative_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.corporate.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.fieldsDown}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.charge.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_charge}
                      inputRef={register}
                      name="charge_create"
                      inputProps={{ 'aria-label': 'charge_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.charge.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_charge}
                      inputRef={register}
                      name="charge_read"
                      inputProps={{ 'aria-label': 'charge_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.charge.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_charge}
                      inputRef={register}
                      name="charge_update"
                      inputProps={{ 'aria-label': 'charge_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.charge.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_charge}
                      inputRef={register}
                      name="charge_delete"
                      inputProps={{ 'aria-label': 'charge_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.charge.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={6}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.meter.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_meter}
                      inputRef={register}
                      name="meter_create"
                      inputProps={{ 'aria-label': 'meter_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.meter.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_meter}
                      inputRef={register}
                      name="meter_read"
                      inputProps={{ 'aria-label': 'meter_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.meter.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_meter}
                      inputRef={register}
                      name="meter_update"
                      inputProps={{ 'aria-label': 'meter_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.meter.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_meter}
                      inputRef={register}
                      name="meter_delete"
                      inputProps={{ 'aria-label': 'meter_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.meter.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.fieldsDown}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.roles.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_role}
                      inputRef={register}
                      name="role_create"
                      inputProps={{ 'aria-label': 'role_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.roles.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_role}
                      inputRef={register}
                      name="role_read"
                      inputProps={{ 'aria-label': 'role_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.roles.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_role}
                      inputRef={register}
                      name="role_update"
                      inputProps={{ 'aria-label': 'role_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.roles.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_role}
                      inputRef={register}
                      name="role_delete"
                      inputProps={{ 'aria-label': 'role_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.roles.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.group.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="group_create"
                      inputProps={{ 'aria-label': 'group_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.group.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="group_read"
                      inputProps={{ 'aria-label': 'group_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.group.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="group_update"
                      inputProps={{ 'aria-label': 'group_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.group.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="group_delete"
                      inputProps={{ 'aria-label': 'group_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.group.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.fieldsDown}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.users.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_user}
                      inputRef={register}
                      name="user_create"
                      inputProps={{ 'aria-label': 'user_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.users.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_user}
                      inputRef={register}
                      name="user_read"
                      inputProps={{ 'aria-label': 'user_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.users.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_user}
                      inputRef={register}
                      name="user_update"
                      inputProps={{ 'aria-label': 'user_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.users.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_user}
                      inputRef={register}
                      name="user_delete"
                      inputProps={{ 'aria-label': 'user_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.users.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.metUsers.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="metimur_user_create"
                      inputProps={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.metUsers.create'})}
                    />
                  }
                  label="Crear"
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="metimur_user_read"
                      inputProps={{ 'aria-label': 'metimur_user_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.metUsers.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="metimur_user_update"
                      inputProps={{ 'aria-label': 'metimur_user_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.metUsers.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="metimur_user_delete"
                      inputProps={{ 'aria-label': 'metimur_user_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.metUsers.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>

          <div className={classes.fieldsDown}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.cards.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_user}
                      inputRef={register}
                      name="card_create"
                      inputProps={{ 'aria-label': 'card_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.cards.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_user}
                      inputRef={register}
                      name="card_read"
                      inputProps={{ 'aria-label': 'card_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.cards.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_user}
                      inputRef={register}
                      name="card_update"
                      inputProps={{ 'aria-label': 'card_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.cards.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_user}
                      inputRef={register}
                      name="card_delete"
                      inputProps={{ 'aria-label': 'card_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.cards.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.alerts.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="alert_create"
                      inputProps={{ 'aria-label': 'alert_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.alerts.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="alert_read"
                      inputProps={{ 'aria-label': 'alert_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.alerts.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="alert_update"
                      inputProps={{ 'aria-label': 'alert_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.alerts.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="alert_delete"
                      inputProps={{ 'aria-label': 'alert_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.alerts.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tariff.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="tariff_create"
                      inputProps={{ 'aria-label': 'tariff_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tariff.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="tariff_read"
                      inputProps={{ 'aria-label': 'tariff_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tariff.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="tariff_update"
                      inputProps={{ 'aria-label': 'tariff_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tariff.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="tariff_delete"
                      inputProps={{ 'aria-label': 'tariff_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tariff.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.sysAlerts.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      disabled
                      inputRef={register}
                      name="metimur_alert_create"
                      inputProps={{ 'aria-label': 'metimur_alert_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.sysAlerts.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="metimur_alert_read"
                      inputProps={{ 'aria-label': 'metimur_alert_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.sysAlerts.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="metimur_alert_update"
                      inputProps={{ 'aria-label': 'metimur_alert_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.sysAlerts.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="metimur_alert_delete"
                      disabled
                      inputProps={{ 'aria-label': 'metimur_alert_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.sysAlerts.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.concentrator.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="concentrator_create"
                      inputProps={{ 'aria-label': 'concentrator_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.concentrator.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="concentrator_read"
                      inputProps={{ 'aria-label': 'concentrator_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.concentrator.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="concentrator_update"
                      inputProps={{ 'aria-label': 'concentrator_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.concentrator.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="concentrator_delete"
                      inputProps={{ 'aria-label': 'concentrator_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.concentrator.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.controlCards.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="onoff_card_create"
                      inputProps={{ 'aria-label': 'onoff_card_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.controlCards.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="onoff_card_read"
                      inputProps={{ 'aria-label': 'onoff_card_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.controlCards.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="onoff_card_update"
                      inputProps={{ 'aria-label': 'onoff_card_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.controlCards.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="onoff_card_delete"
                      inputProps={{ 'aria-label': 'onoff_card_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.controlCards.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tasks.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="onoff_task_create"
                      inputProps={{ 'aria-label': 'onoff_task_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tasks.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="onoff_task_read"
                      inputProps={{ 'aria-label': 'onoff_task_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tasks.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="onoff_task_update"
                      inputProps={{ 'aria-label': 'onoff_task_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tasks.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="onoff_task_delete"
                      inputProps={{ 'aria-label': 'onoff_task_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.tasks.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>

        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.fields}>
            <FormControl component="fieldset">
              <Typography variant="h4">{intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.preInvoice.title'})}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.create_group}
                      inputRef={register}
                      name="prefacture_create"
                      inputProps={{ 'aria-label': 'prefacture_create' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.preInvoice.create'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.read_group}
                      inputRef={register}
                      name="prefacture_read"
                      inputProps={{ 'aria-label': 'prefacture_read' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.preInvoice.read'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.update_group}
                      inputRef={register}
                      name="prefacture_update"
                      inputProps={{ 'aria-label': 'prefacture_update' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.preInvoice.update'})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      //checked={this.state.delete_group}
                      inputRef={register}
                      name="prefacture_delete"
                      inputProps={{ 'aria-label': 'prefacture_delete' }}
                    />
                  }
                  label={intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.preInvoice.delete'})}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Button
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
        className={classes.button}>
        {intl.formatMessage({id: 'app.roles.addRole.stepper.permissions.saveContinueBtn'})}
      </Button>
    </form>
  );
}

export default NameRoleForm;
