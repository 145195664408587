import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components';

// Views
//CORPORTATIVE
import Corporation from './views/Corporation';
import FormCorporation from 'views/FormCorporative';
import FormCorporationEditable from 'views/FormCorporativeEditable';

//METERS
import Meters from './views/Meters';
import FormMeter from './views/FormMeter';
import FormMeterEditable from './views/FormMeterEditable';
import HardwareConfiguration from './views/HardwareHome';
import MeterDashboard from './views/Meter Dashboard';
import PreInvoiceMeter from './views/PreInvoiceMeter';

//GROUPS
import Groups from './views/Groups';
import FormGroup from './views/FormGroup';
import FormGroupEditable from './views/FormGroupEditable';
import GroupHome from './views/GroupHome';
import GroupDashboard from './views/Group Dashboard';
import PreInvoiceGroup from './views/PreInvoiceGroup';


//CONCENTRATORS
import CardConcentrator from './views/CardConcentrator';
import Concentrator from './views/Concentrator';
import FormConcentrator from './views/FormConcentrator';
import FormConcentratorEditable from './views/FormConcentratorEditable'

//CARDS
import Card from './views/Card';
import AllCards from './views/AllCards';
import FormCard from './views/FormCard';
import FormCardEditable from './views/FormCardEditable';

//CONTROL CARDS
import ControlCard from './views/ControlCards';
import FormControlCard from './views/FormControlCard';
import FormControlCardEditable from './views/FormControlCardEditable';
import ControlCardList from './views/AllCardsHomeworks';
import HomeWorks from './views/Homeworks';
import FormHomeWorks from './views/FormHomeWorks';
import FormHomeWorksEditable from './views/FormHomeWorksEditable';
// import FormControlCardEditable from './views/FormControlCardEditable';
import PowerFactors from './views/PowerFactor';

import FormPowerFactor from './views/FormPowerFactor';

//CHARGES
import Charge from './views/Charge';
import FormCharge from './views/FormCharge';
import FormChargeEditable from './views/FormChargeEditable';


//DASHBOARD
import Dashboard from './views/Charge Dashboard'
import DashboardSolar from 'views/Charge Solar Dashboard';
import Receipt from './views/Receipt';

//CHARGE ALERTS
import UserAlertDashboard from './views/UserAlertDashboard';
import UserChargeNotofication from './views/UserChargeNotification';
import FormUserAlert from './views/FormUserAlert';

//ACCONT
import Account from './views/Account';

//USERS
import CorpUsersList from './views/CorpUsersList';
import UserList from './views/UsersList';
import UsersListMetimur from './views/UsersListMetimur';
import FormUser from './views/FormUser';
import FormUserEditable from './views/FormUserEditable';
import FormAdminUserEditable from './views/FormUserEditableAdmin';
import FormUserMetimur from './views/FormUserMetimur';

//ROLES
import CorpRoleList from './views/CorpRoleList';
import RoleList from './views/RoleList';
import FormRole from './views/FormRole';
import FormRoleEditable from './views/FormRoleEditable';

//CONTROL RATE
import ControlRate from './views/ControlRate';
import FormControlRate from './views/FormControlRate';

import Reports from './views/Reports';
import Invoices from './views/Invoice';

//ALERTS
import NotificationDashboard from './views/NotificationDashboard';


//VOLTAGE
import VoltageConcentrator from './views/ConcentratorForVoltage';
import FormVoltage from './views/FormVoltage';
import FormVoltageEditable from './views/FormVoltageEditable';


//SETTINGS
import Settings from './views/Settings';

//LOGIN
import LoginPrueba from './views/Login Prueba';
import NewLogin from './views/newLogin';
import RecoveredEmailPassword from './views/Recover Password Email';
import RecoveredEmailPasswordNew from './views/Recover Password Email New';
import RecoveredFormPasswordOld from './views/Recover Password Form';
import RecoveredFormPassword from './views/Recover Password Form New';

//INVOICE FORM
import FormInvoice from 'views/FormInvoice';


import GeneralReport from './views/GeneralReport';
import ProductList from './views/ProductList';
import Typography from './views/Typography';
import Icons from './views/Icons';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';

import { hasRole, isAllowed } from './helpers/auth';
import { useSelector } from 'react-redux';
import AllCardsHomeworks from 'views/AllCardsHomeworks';
import FormMeterInvoiceData from 'views/FormMeterInvoiceData';
import FormChargeInvoiceData from 'views/FormChargeInvoiceData';


export default function Routes(props) {
  const user = useSelector(state=>state.user.user)

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={`${user.home_url}`}
      />
      {/* CORPORATIVE */}
      <PrivateRoute
        component={Corporation}
        exact
        path="/home"
      /> 
      <PrivateRoute
        component={FormCorporation}
        exact
        path="/newCorporation"
      />
      <PrivateRoute
        component={FormCorporationEditable}
        exact
        path="/updateCorporative/:corpId"
      />

      {/* METER */}

      <PrivateRoute
        component={Meters}
        exact
        path="/corporative/:corpId"
      />
      
      <PrivateRoute
        component={FormMeter}
        exact
        path="/newMeter/:corpId"
      />
      <PrivateRoute
        component={FormMeterEditable}
        exact
        path="/updateMeter/:meterId"
      />

      <PrivateRoute
        component={FormMeterInvoiceData}
        exact
        path="/invoiceDataMeter/:meterId"
      />      

      <PrivateRoute
        component={HardwareConfiguration}
        exact
        path="/hardwareConfiguration/:meterId"
      />

      <PrivateRoute
        component={MeterDashboard}
        exact
        path="/meterDashboard/:corpId/:meterId"
      />

      {/* GROUP */}

      <PrivateRoute
        component={Groups}
        exact
        path="/meter/:meterId"
      />
      <PrivateRoute
        component={FormGroup}
        exact
        path="/newGroup/:corpId/:meterId"
      />
      <PrivateRoute
        component={FormGroupEditable}
        exact
        path="/updateGroup/:groupId"
      />
      <PrivateRoute
        component={GroupHome}
        exact
        path="/meter/:meterId/group/:groupId"
      />
      <PrivateRoute
        component={GroupDashboard}
        exact
        path="/groupDashboard/:groupId"
      />

      {/* CONCENTRATOR */}
      <PrivateRoute
        component={Concentrator}
        exact
        path="/concentradors/:meterId"
      />
      <PrivateRoute
        component={FormConcentrator}
        exact
        path="/newConcentrator/:meterId"
      />
      <PrivateRoute
        component={FormConcentratorEditable}
        exact
        path="/updateConcentrator/:concentratorId"
      /> 

      {/* CARD */}
      <PrivateRoute
        component={Card}
        exact
        path="/card/:meterId"
      />
      <PrivateRoute
        component={AllCards}
        exact
        path="/allcards/:meterId"
      />
      <PrivateRoute
        component={FormCard}
        exact
        path="/newCard/:meterId/:concentratorId"
      />
      <PrivateRoute
        component={FormCardEditable}
        exact
        path="/updateCard/:meterId/:cardId"
      />
      <PrivateRoute
        component={GeneralReport}
        exact
        path="/generalReport"
      />

      {/* CONTROL CARD */}
      <PrivateRoute
        component={ControlCard}
        exact
        path="/controlCards/:cardId"
      />
      <PrivateRoute
        component={AllCardsHomeworks}
        exact
        path="/allcardsHomework/:meterId"
      />
    
      <PrivateRoute
        component={HomeWorks}
        exact
        path="/homeworks/:cardId"
      />

      <PrivateRoute
        component={PowerFactors}
        exact
        path="/powerfactor/:meterId"
      />
      
      <PrivateRoute
        component={FormControlCard}
        exact
        path="/newControlCard/:concentratorId/:cardId"
      />

      <PrivateRoute
        component={FormControlCardEditable}
        exact
        path="/updateControlCard/:cardControlId"
      />

      <PrivateRoute
        component={FormHomeWorks}
        exact
        path="/newHomework/:concentratorId/:cardId"
      />

      <PrivateRoute
        component={FormPowerFactor}
        exact
        path="/newPowerFactor/:meterId"
      />

      <PrivateRoute
        component={FormPowerFactor}
        exact
        path="/editPowerFactor/:meterId/:fpId"
      />      

      <PrivateRoute
        component={FormHomeWorksEditable}
        exact
        path="/updateHomework/:homeworkId"
      />

      {/* VOLTAGE */}
      <PrivateRoute
        component={VoltageConcentrator}
        exact
        path="/voltage/:meterId"
      />

      <PrivateRoute
        component={FormVoltage}
        exact
        path="/newVoltage/:meterId"
      />
      <PrivateRoute
        component={FormVoltageEditable}
        exact
        path="/updateVoltage/:voltageId"
      />
      {/*
      <PrivateRoute
        component={AllCards}
        exact
        path="/allcards/:meterId"
      />
      
      <PrivateRoute
        component={FormCardEditable}
        exact
        path="/updateCard/:meterId/:cardId"
      /> */}


      {/* CHARGE */}
      <PrivateRoute
        component={Charge}
        exact
        path="/group/:groupId"
      />
      <PrivateRoute
        component={FormCharge}
        exact
        path="/newCharge/:groupId"
      />
      <PrivateRoute
        component={FormChargeEditable}
        exact
        path="/updateCharge/:chargeId"
      />

      <PrivateRoute
        component={FormChargeInvoiceData}
        exact
        path="/invoiceDataCharge/:chargeId"
      />


      {/* DASHBOARD */}
      <PrivateRoute
        component={Dashboard}
        exact
        path="/dashboardCharge/group/:groupId/charge/:chargeId"
      />
      <PrivateRoute
        component={DashboardSolar}
        exact
        path="/dashboardSolarCharge/group/:groupId/charge/:chargeId"
      />
      <PrivateRoute
        component={Receipt}
        exact
        path="/charge/:chargeId/receipt"
      />

      {/*  CHARGE ALERTS */}
      <PrivateRoute
        component={UserAlertDashboard}
        exact
        path="/group/:groupId/charge/:chargeId/alert"
      />
      <PrivateRoute
        component={UserChargeNotofication}
        exact
        path="/alerts/:groupId/charge/:chargeId"
      />
      <PrivateRoute
        component={FormUserAlert}
        exact
        path="/newUserAlet/:groupId/charge/:chargeId"
      />
      <PrivateRoute
        component={FormUserAlert}
        exact
        path="/editUserAlet/:alertId"
      />      

      <PrivateRoute
        component={FormInvoice}
        exact
        path="/invoice"
      />

      {/* ACCOUNT */}
      <PrivateRoute
        component={Account}
        exact
        path="/account"
      />

      {/* USERS */}
      <Redirect
        exact
        from="/users"
        to={`${user.users_url}`}
      />
      <PrivateRoute
        component={UsersListMetimur}
        exact
        path="/usersMetimur"
      />
      <PrivateRoute
        component={CorpUsersList}
        exact
        path="/usersByCorps"
      />
      <PrivateRoute
        component={UserList}
        exact
        path="/users/:corpId"
      />
      <PrivateRoute
        component={FormUserMetimur}
        exact
        path="/newMetimurUser"
      />
      <PrivateRoute
        component={FormUser}
        exact
        path="/newUser/:corpId"
      />
      <PrivateRoute
        component={FormUserEditable}
        exact
        path="/updateUser/:userId"
      />
      <PrivateRoute
        component={FormAdminUserEditable}
        exact
        path="/updateAdminUser/:userId"
      />

      {/* ROLES */}
      <PrivateRoute
        component={CorpRoleList}
        exact
        path="/rolesByCorps"
      />
      <PrivateRoute
        component={RoleList}
        exact
        path="/roles"
      />
      <PrivateRoute
        component={FormRole}
        exact
        path="/newRole"
      />
      <PrivateRoute
        component={FormRoleEditable}
        exact
        path="/updateRole/:roleId"
      />

      {/* RATES */}
      <PrivateRoute
        component={ControlRate}
        exact
        path="/controlRate"
      />
      <PrivateRoute
        component={FormControlRate}
        exact
        path="/newControlRate"
      />

      {/* REOPORTS */}
      <PrivateRoute
        component={Reports}
        exact
        path="/reports"
      />

      {/* REOPORTS */}
      <PrivateRoute
        component={Invoices}
        exact
        path="/invoices"
      />      
      
      {/* PRE-INVOICE */}
      <PrivateRoute
        component={PreInvoiceMeter}
        exact
        path="/preinvoice/:meterId"
      />

      <PrivateRoute
        component={PreInvoiceGroup}
        exact
        path="/preinvoicegroup/:groupId"
      />
      
      {/*NOTIFICATION DASHBOARD */}
      <PrivateRoute
        component={NotificationDashboard}
        exact
        path="/notificationDashboard"
      />

      {/* SETTINGS */}
      <PrivateRoute
        component={Settings}
        exact
        path="/settings"
      />

      {/*  LOGIN */}

      <Route
        component={NewLogin}
        exact
        path="/login"
      />      

      <Route
        component={LoginPrueba}
        exact
        path="/oldLogin"
      />
      <Route
        component={RecoveredEmailPasswordNew}
        exact
        path="/recoveredEmail"
      />
      <Route
        component={RecoveredEmailPassword}
        exact
        path="/recoveredEmailOld"
      />
      <Route
        component={RecoveredFormPassword}
        exact
        path="/recoveredForm/:token"
      />
      <Route
        component={RecoveredFormPassword}
        exact
        path="/recoveredFormOld/:token"
      />

      <PrivateRoute
        component={ProductList}
        exact
        path="/products"
      />
      <PrivateRoute
        component={Typography}
        exact
        path="/typography"
      />
      <PrivateRoute
        component={Icons}
        exact
        path="/icons"
      />
      <PrivateRoute
        component={UnderDevelopment}
        exact
        path="/under-development"
      />
      <PrivateRoute
        component={NotFound}
        exact
        path="/not-found"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
}
