import React, { Component } from 'react';

import ReactPlayer from 'react-player'

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Checkbox, Typography, Button } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

class Notifications extends Component {
  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="Webinars / Tutoriales "
            title="Cursos"
          />
        </PortletHeader>
        <PortletContent noPadding>

          <ReactPlayer className={classes.video} url='https://www.youtube.com/watch?v=ysz5S6PUM-U'/>
          
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
       
        </PortletFooter>
      </Portlet>
    );
  }
}

Notifications.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Notifications);
