/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import {
  Grid,
  makeStyles,
  Button as ButtonMaterial,
  Typography,
  Dialog,
  Stepper,
  Step,
  StepButton,
  CircularProgress,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Fab
} from '@material-ui/core';

import { MeterCard } from '../../components';

import { showMeters, deleteMeter, getMeterFailure } from 'js/actions/meterActions';

import { getConsumesLastMonth } from 'js/actions/preInvoiceMeterAction';
import clsx from 'clsx';

import Swal from 'sweetalert2'

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';
import { setMeterId } from 'js/actions/userActions.js';
import { isAllowed } from 'helpers/auth';
import AddIcon from '@material-ui/icons/Add';

import styles from './styles';
import { useIntl } from 'react-intl';
import { Close } from '@material-ui/icons';
const useStyles = makeStyles(styles);

export default function GridMeter(props) {
  const intl = useIntl()
  const { coporative_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const meters = useSelector(state => state.meters.meters);
  const user = useSelector(state => state.user.user);
  const [hoverFab, setHoverFab] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showInvoiceButton, setShowInvoiceButton] = useState(false);
  const [showInvoiceDataButton, setShowInvoiceDataButton] = useState(false);  
  const [showDetails, setShowDetails] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [selectedCorp, setSelectedCorp] = useState(null);
  let meterStatus = useSelector(state => state.meters.loading);
  let isLoading = meterStatus;

  const year = new Date().getFullYear();
  const getLastMonth = () => {
    let month = new Date().getMonth() + 1;

    return month - 1;
  };

  useEffect(() => {
    dispatch(getMeterFailure());
    dispatch(showMeters(coporative_id));
  }, []);

  const setAdminMeterId = (meterId) => {
    dispatch(setMeterId(meterId))
  }

  // const deleteMeterData = (meterId, corpId) => {
  //   Swal.fire({
  //     title: `${intl.formatMessage({
  //       id: 'app.metersCards.deleteMetBtn.title'
  //     })} `,
  //     text: `${intl.formatMessage({
  //       id: 'app.metersCards.deleteMetBtn.text'
  //     })} ${meterId.name}`,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: intl.formatMessage({
  //       id: 'app.metersCards.deleteMetBtn.confirmation'
  //     })
  //   }).then(result => {
  //     if (result.value) {
  //       Swal.fire({
  //         title: intl.formatMessage({
  //           id: 'app.metersCards.deleteMetBtn.titleConfirmation'
  //         }),
  //         text: intl.formatMessage({
  //           id: 'app.metersCards.deleteMetBtn.textConfirmation'
  //         }),
  //         icon: 'success'
  //       });
  //       dispatch(deleteMeter(meterId.id, corpId));
  //     }
  //   });
  // };

  // const getConsumesLastMonthData = (corpId, meterId, year, month) => {
  //   dispatch(getConsumesLastMonth(corpId, meterId, year, month));
  // };

  // const getEditButton = meter => {
  //   if (showEditButton == false) {
  //     return <div />;
  //   } else {
  //     return (
  //       <Link
  //         to={{
  //           pathname: `/updateMeter/${meter.id}`,
  //           state: { meter: meter }
  //         }}
  //       >
  //         <ButtonMaterial
  //           color="secondary"
  //           variant="outlined"
  //         >
  //           {intl.formatMessage({ id: 'app.metersCards.editMeterBtn' })}
  //         </ButtonMaterial>
  //       </Link>
  //     );
  //   }
  // };

  // const getDeleteButton = (meterId, corpId) => {
  //   if (showDeleteButton == false) {
  //     return <div />;
  //   } else {
  //     return (
  //       <ButtonMaterial
  //         onClick={() => deleteMeterData(meterId, corpId)}
  //         style={{ border: '1px solid #D64539', color: '#D64539' }}
  //         variant="outlined"
  //       >
  //         {intl.formatMessage({ id: 'app.metersCards.deleteMetBtn' })}
  //       </ButtonMaterial>
  //     );
  //   }
  // };

  // const getInvoiceButton = meter => {
  //   if (showInvoiceButton == false) {
  //     return <div />;
  //   } else {
  //     return (
  //       <Link
  //         to={{
  //           pathname: `/preinvoice/${meter.id}`,
  //           state: { meter: meter }
  //         }}
  //       >
  //         <ButtonMaterial
  //           color="secondary"
  //           onClick={() =>
  //             getConsumesLastMonthData(
  //               meter.corporative_id,
  //               meter.id,
  //               year,
  //               getLastMonth()
  //             )
  //           }
  //           variant="outlined"
  //         >
  //           {intl.formatMessage({ id: 'app.metersCards.preInvBtn' })}
  //         </ButtonMaterial>
  //       </Link>
  //     );
  //   }
  // };

  // const getInvoiceDataButton = meter => {
  //   if (showInvoiceDataButton == false) {
  //     return <div />;
  //   } else {
  //     return (
  //       <Link
  //         to={{
  //           pathname: `/invoiceDataMeter/${meter.id}`,
  //           state: { meter: meter }
  //         }}
  //       >
  //         <ButtonMaterial
  //           color="secondary"
  //           onClick={() =>
  //             getConsumesLastMonthData(
  //               meter.corporative_id,
  //               meter.id,
  //               year,
  //               getLastMonth()
  //             )
  //           }
  //           variant="outlined"
  //         >
  //           {intl.formatMessage({ id: 'app.metersCards.invDataBtn' })}
  //         </ButtonMaterial>
  //       </Link>
  //     );
  //   }
  // };

  return (
    <>
      {isLoading ? 
        (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><CircularProgress /></div>)
        : (
        <>
          {
            meters.length > 0 ? (
              <Grid
                container
                spacing={4}
              >
                {meters.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0).map(meter=>(
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >

                    <MeterCard
                      corpId={coporative_id}
                      item
                      key={meter.id}
                      meter = {meter}
                      setSelectedCorp={setSelectedCorp}
                      setShowDetails={setShowDetails}
                    />

                    {/* <div>
                      {getEditButton(meter)}
                      {getDeleteButton(meter,coporative_id)}
                      {getInvoiceButton(meter)}
                      {getInvoiceDataButton(meter)}
                    </div> */}
                  </Grid>
                ))}

              </Grid>

            ) : ( 
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Typography
                    style={{textAlign: 'center'}}
                    variant="h2"
                  >{intl.formatMessage({id: 'app.metersCards.noMetersMsg'})}</Typography>
                </Grid> 
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Link
                    to={{
                      pathname:`/newMeter/${coporative_id}`
                    }}
                  >
                    <ButtonMaterial
                      color="secondary"
                      style={{margin:'0 auto', display:'block'}}
                      variant="outlined"
                    >{intl.formatMessage({id: 'app.metersCards.createMeterBtn'})}</ButtonMaterial>
                  </Link>
                  
                </Grid> 
              
              
              </Grid>
            )
          }
          
          <Dialog
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            onClose={() => {setShowDetails(false)}}
            open={showDetails}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => {setShowDetails(false)}}
            >
              <Box style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
                <Box>
                  <Typography variant="h6">{selectedCorp ? selectedCorp.name : ''}</Typography>
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={() => {setShowDetails(false)}}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent
              dividers
              style={{marginBottom: '3rem'}}
            >
              { selectedCorp ? (
                <Box style={{display:'flex', flexDirection:'column', gap: '1rem', alignItems: 'center'}}>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.descriptLbl'})}</Typography>{selectedCorp.description}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.countryLbl'})}</Typography>{selectedCorp.country}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.stateLbl'})}</Typography>{selectedCorp.state}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.cityLbl'})}</Typography>{selectedCorp.city}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.postalCodeLbl'})}</Typography>{selectedCorp.cp}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.neighborhoodLbl'})}</Typography>{selectedCorp.county}</Typography>
                  <Typography
                    style={{fontWeight:400, textAlign: 'center'}}
                    variant="h6"
                  ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.addressLbl'})}</Typography>{selectedCorp.address}</Typography>
                  {selectedCorp.contracted_demand && 
                    <Typography
                      style={{fontWeight:400, textAlign: 'center'}}
                      variant="h6"
                    ><Typography style={{fontWeight: 500}}>{intl.formatMessage({id: 'app.metersCards.editMeter.contractedDemandLbl'})}</Typography>{selectedCorp.contracted_demand} kW</Typography>
                  }
                </Box>
              ):(
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height: '50vh'}}>
                  <CircularProgress />
                </div>
              )}
            </DialogContent>
          </Dialog>
          {/* <UploadDocuments
            corporative={coporative_id}
            open={openDocuments}
            selectedCorp={selectedCorp}
            setOpen={setOpenDocuments}
          /> */}
        </>)}
      <Container className={classes.buttonContainer}>
        {/* <Button
          icon="fas fa-file-invoice-dollar"
          onClick={() => setShowInvoiceDataButton(!showInvoiceDataButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({
            id: 'app.metersCards.invDataBtn.toolTip'
          })}
        />
        <Button
          icon="fas fa-file-invoice"
          onClick={() => setShowInvoiceButton(!showInvoiceButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({
            id: 'app.metersCards.preInvBtn.toolTip'
          })}
        />
        {isAllowed(user.permissions, ['meter_delete']) && (
          <Button
            icon="fa fa-trash"
            onClick={() => setShowDeleteButton(!showDeleteButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({id: 'app.metersCards.deleteMetBtn.toolTip'})}
          />}
          {isAllowed(user.permissions,['meter_update'])&&<Button
            icon="fa fa-pencil-alt"
            onClick={() => setShowEditButton(!showEditButton)}
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip={intl.formatMessage({
              id: 'app.metersCards.editMeterBtn.toolTip'
            })}
          />
        )} */}

        {isAllowed(user.permissions, ['meter_create']) && (
          <Fab
            aria-label="add"
            className={classes.fabIcon}
            color="secondary"
            href={`/newMeter/${coporative_id}`}
            onMouseEnter={()=>setHoverFab(true)}
            onMouseLeave={()=>setHoverFab(false)}
            variant="extended"
          >
            <AddIcon />
            <Typography
              className={clsx(classes.fabText, {
                [classes.fabTextVisible]: hoverFab
              })}
            >
              Nuevo Nodo
            </Typography>
          </Fab>
          // <LinkFloat
          //   href={`/newMeter/${coporative_id}`}
          //   icon="fa fa-plus"
          //   rotate
          //   styles={{
          //     backgroundColor: '#00AFEF',
          //     color: '#fff',
          //     width: '60px',
          //     height: '60px',
          //     fontSize: '1.5em'
          //   }}
          //   tooltip={intl.formatMessage({
          //     id: 'app.metersCards.addMetBtn.toolTip'
          //   })}
          // />
        )}

        {/* <Button
          icon="fa fa-cog"
          rotate
          styles={{
            backgroundColor: '#C69100',
            color: '#fff',
            width: '80px',
            height: '80px',
            fontSize: '1.5em'
          }}
        /> */}
      </Container>
    </>
  ) 
}
