import React, { Component, useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { FormControl, InputLabel,Select,MenuItem } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { showCountrySates, showCities, showCPS, showCounties} from 'js/actions/zepomexActions'
import {  postDataMeter} from 'js/actions/meterActions'
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  state: null,
  city: null,
  cp: null,
  county: null,
};


export default function MeterForm(props) {
  const intl = useIntl()
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const estados = useSelector(state => state.zepomex.states.estado);
  const cities = useSelector(state => state.zepomex.cities.municipios);
  const cps = useSelector(state => state.zepomex.cp.cp);
  const counties = useSelector(state => state.zepomex.counties.colonia);
  const { corpId } = props.match.params;


  const onSubmit = values => {
    //console.log('DATA: ', values);
    dispatch(postDataMeter(values,corpId));
  };

  const handleState = state => {
    setValue('state', state.target.value);
    getCity(state.target.value);
  };

  const getCity = state => {
    dispatch(showCities(state));
  };

  const handleCity = city => {
    setValue ('city', city.target.value)
    getCP(city.target.value)
  }

  const getCP = city => {
    dispatch(showCPS(city));
  };

  
  const handleCP = cp => {
    setValue ('cp', cp.target.value)
    getCounties(cp.target.value)
  }
  

  const getCounties = cp =>  {
    dispatch(showCounties(cp))
  }

  const handleCounties = countie => {
    setValue ('county', countie.target.value)
  }

  useOnMount(()=>{

    register({name: 'state', type:'text '});
    register({name:'city', type:'text'});
    register({name:'cp', type:'text'});
    register({name:'county', type:'text'});


  })

  const values = getValues();
  
  
  useEffect(() => {
    dispatch(showCountrySates());
  },[]);

  return (
    <DashboardLayout title="Creacion de Medidores">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title={intl.formatMessage({id: 'app.metersCards.addMeter.title'})} />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.mterNameLbl'})}
                      name="name"
                      required
                      //onChange={this.handleChange}
                      // value={name}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.descriptLbl'})}
                      name="description"
                      required
                      //onChange={this.handleChange}
                      //value={description}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.countryLbl'})}
                      name="country"
                      required
                      // onChange={this.handleChange}
                      // value={country}
                      variant="outlined"
                    />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.stateLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={handleState}
                        value={values.state}
                        //labelWidth={labelWidth}
                      >
                      
                        { estados ?  estados.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando estados') 
                        
                        }
                            
                      </Select>
                    </FormControl>

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.cityLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={handleCity}
                        value={values.city}
                        //labelWidth={labelWidth}
                      >
                       
                        { cities ?  cities.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>

                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <div className={classes.fields}>

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.postalCodeLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={handleCP}
                        value={values.cp}
                        //labelWidth={labelWidth}
                      >
                       
                        { cps ?  cps.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        inputRef={useOnMount}
                        required
                      >
                        {intl.formatMessage({id: 'app.metersCards.addMeter.neighborhoodLbl'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        onChange={handleCounties}
                        value={values.county}
                        //labelWidth={labelWidth}
                      >
                      
                        { counties ?  counties.map(el => (
                          <MenuItem value={el}>{el}</MenuItem>
                        ))  : console.log('cargando municipios') 
                        }
                            
                      </Select>
                    </FormControl>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.addressLbl'})}
                      name="address"
                      required
                      //onChange={this.handleChange}
                      //value={address}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.addMeter.payDeadLineLbl'})}    
                      name="payment_deadline"
                      required
                      //onChange={this.handleChange}
                      //value={address}
                      variant="outlined"
                    />      

                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.metersCards.editMeter.contractedDemandLbl'})}    
                      name="contracted_demand"
                      required
                      //onChange={this.handleChange}
                      //value={address}
                      variant="outlined"
                    />                                    
                      
                  </div>
                </Grid>
              </Grid>
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.addMeter.submit'})}
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
