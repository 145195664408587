import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Material icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';

import {

  LocalAtm as AlarmIcon,
  AttachMoney as CalendarIcon,
  TrendingUp as UpIcon
} from '@material-ui/icons';

import { IconButton, Typography } from '@material-ui/core';

import palette from 'theme/palette';

// Chart configuration
import { data, options } from './chart';

// Component styles
import styles from './styles';

class SalesChart2 extends Component {
  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title="Datos Estimados del Periodo" />
          <PortletToolbar>
            <Button
              className={classes.dropdownButton}
              size="small"
              variant="text"
            >
              2020
            </Button>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.device}>
              <AlarmIcon className={classes.deviceIcon} />
              <Typography variant="h2">Costo</Typography>
              <Typography
                style={{ color: '#730065' }}
                variant="h1"
              >
                $13.50
              </Typography>
            </div>
          
     
            <div className={classes.device}>
              <UpIcon className={classes.deviceIcon} />
              <Typography variant="h2">Consumo</Typography>
              <Typography
                style={{ color: '#EAAB36' }}
                variant="h1"
              >
                245 kWh
              </Typography>
            </div>
            <div className={classes.device}>
              <CalendarIcon className={classes.deviceIcon} />
              <Typography variant="h2">Importe Estimado</Typography>
              <Typography
                style={{ color: '#00AFEF' }}
                variant="h1"
              >
                $ 1, 000.00
              </Typography>
            </div>
            {/* <div className={classes.device}>
              <UpIcon className={classes.deviceIcon} />
              <Typography variant="h2">Consumo</Typography>
              <Typography
                style={{ color: '#FEC12C' }}
                variant="h1"
              >
                980 kwH
              </Typography>
            </div> */}
          
          {/* <div className={classes.chartWrapper}>
            <Line
              data={data}
              options={options}
            />
          </div> */}
        </PortletContent>
        {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
      </Portlet>
    );
  }
}

SalesChart2.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SalesChart2);
