import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Button, TextField, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  concentratorType: null,

};

function UserConcentratorForm(props) {
  const intl = useIntl()
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep();
    //console.log('DATA: ', values);
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }

  const handleConcentratorTypeChange = concentratorType => {
    setValue('concentratorType', concentratorType.target.value);
  };



  useOnMount(() => {
    register({ name: 'concentratorType', type: 'text' });


  });

  const values = getValues();

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
            {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.config.typeLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleConcentratorTypeChange}
            value={values.concentratorType}
            //labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value={'ETHERNET'}>ETHERNET</MenuItem>
            <MenuItem value={'VIRTUAL'}>VIRTUAL</MenuItem>
          </Select>
        </FormControl>
        <Button
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
          className={classes.button}
        >
           {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.config.continueBtn'})}
        </Button>
        <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          {intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrator.addConcentrator.stepper.config.returnBtn'})}
        </Button>
      </form>
    </Grid>
  );
}

export default UserConcentratorForm;
