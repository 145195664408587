import * as controlRateActionTypes from '../constants/controlRateAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function controlRateReducer(state = { control_rate: [], last_update:[], loading: false }, action) {
  switch (action.type) {
    case controlRateActionTypes.POST_CONTROL_RATE_STARTED:
      return {
        ...state,
        loading: true
      };
    case controlRateActionTypes.POST_CONTROL_RATE_SUCCESS:
      return {
        ...state,
        rates: state.rates.push(action.payload),
        loading: false
      };
    case controlRateActionTypes.POST_CONTROL_RATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case controlRateActionTypes.GET_CONTROL_RATE_SUCCESS:
      return {...state, control_rate: action.control_rate};

    case controlRateActionTypes.GET_LAST_UPDATE_RATE_SUCCESS:
      return {...state, last_update: action.last_update};

    default:
      return state;
  }
}

export default controlRateReducer;
