import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Grid } from '@material-ui/core';
import { GDMHTForm } from '../index';
import { PDBTForm } from '../index';
import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import { useSelector, useDispatch } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <Typography
      aria-labelledby={`nav-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function NavTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const nextStep = values => {
    props.nextStep();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBack = () => {
    dispatch(deleteLastPostForm());
    props.prevStep();
  };

  return (
    <Grid className={classes.root}>
      <AppBar position="static">
        <Tabs
          aria-label="nav tabs example"
          onChange={handleChange}
          value={value}
          variant="fullWidth"
        >
          <LinkTab
            href="/ADE1"
            label="Puerto 1"
            {...a11yProps(0)}
          />
          <LinkTab
            href="/ADE2"
            label="Puerto 2"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel
        index={0}
        value={value}
      >
        <GDMHTForm />
      </TabPanel>
      <TabPanel
        index={1}
        value={value}
      >
        <PDBTForm />
      </TabPanel>

      <Button
        color="secondary"
        onClick={() => nextStep()}
        //type="submit"
        size="large"
        variant="contained"
      >
        Guardar y Continuar
      </Button>
      <Button
          onClick={handleBack}
          className={classes.backButton}
        >
          Regresar 
        </Button>
    </Grid>
  );
}
