import React from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Typography, CircularProgress } from '@material-ui/core';

// Material icons
import {
  FlashOn as PeopleIcon
} from '@material-ui/icons';

import { useSelector } from 'react-redux';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function Users(props) {
  const intl = useIntl();
  const { className, ...rest } = props;
  const classes = useStyles();
  const generalConsumes = useSelector(
    state => state.chargeDashboard.generalConsumes
  );

  const rootClassName = classNames(classes.root, className);

  return (
    <Paper
      {...rest}
      className={rootClassName}
    >
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="body2"
          >
            {intl.formatMessage({ id: 'app.chargeSolar.board.mainHead.maxDemand' })}
          </Typography>

          {generalConsumes.consumes ? (
            <Typography
              className={classes.value}
              variant="h3"
            >
              {generalConsumes.consumes.demand_max
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
              kW
            </Typography>
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className={classes.iconWrapper}>
          <PeopleIcon className={classes.icon} />
        </div>
      </div>
      {/* <div className={classes.footer}>
      <Typography
        className={classes.difference}
        variant="body2"
      >
        <ArrowUpwardIcon />
        16%
      </Typography>
      <Typography
        className={classes.caption}
        variant="caption"
      >
        Since last month
      </Typography>
    </div> */}
    </Paper>
  );
}
