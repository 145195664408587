/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Externals
import classNames from 'classnames';

//Material components
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Receipt as ReceiptIcon } from '@material-ui/icons';

//Redux
import { getHistoricalReport } from 'js/actions/chargeDashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import styles from './styles';

const useStyles = makeStyles(styles);

const Historical = (props) => {
  const { chargeId, className, ...rest } = props;
  const intl = useIntl()
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className)
  const dispatch = useDispatch();
  let historical = useSelector(state => state.chargeDashboard.historicalReport)
  let loading = useSelector(state => state.chargeDashboard.loading)
  
  useEffect(()=>{
    dispatch(getHistoricalReport(chargeId))
  },[chargeId]);

  return (
    <Paper
      {...rest}
      className={rootClassName}
      style={{backgroundColor: !loading ? '#EAAB36' : 'grey'}}
    >
      {historical && historical.startsWith('https://') ? (
        <Link
          className={classes.iconTable}
          download
          target="_blank"
          to={{
            pathname: `${historical}`
          }}
        >
          <div className={classes.content}>
            <div className={classes.content}>
              <div className={classes.details}>
                <Typography
                  className={classes.title}
                  variant="body2"
                >
                  {intl.formatMessage({id: 'app.charge.board.mainHead.historicalReport'})}
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <ReceiptIcon className={classes.icon} />
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              {intl.formatMessage({id: 'app.charge.board.mainHead.historicalFooter'})}
            </Typography>
          </div>
        </Link>
      ):(
        <Box
          className={classes.iconTable}
        >
          <div className={classes.content}>
            <div className={classes.content}>
              <div className={classes.details}>
                <Typography
                  className={classes.title}
                  variant="body2"
                >
                  {intl.formatMessage({id: 'app.charge.board.mainHead.historicalReport'})}
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <ReceiptIcon
                  className={classes.icon}
                  style={{color: 'grey'}}
                />
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              No Disponible Aún
            </Typography>
          </div>
        </Box>
      )}
    </Paper>
  )
}

export default Historical;
