export const USERS_SIGNUP_STARTED = 'USERS_SIGNUP_STARTED';  
export const USERS_SIGNUP_SUCCESS = 'USERS_SIGNUP_SUCCESS';
export const USERS_SIGNUP_FAILURE = 'USERS_SIGNUP_FAILURE';  

export const USER_LOGIN_STARTED = 'USERS_LOGIN_STARTED';  
export const USER_LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';  
export const USER_CHANGE_LANG = 'USERS_CHANGE_LANG';  

export const USER_LOGOUT_STARTED = 'USERS_LOGOUT_STARTED';
export const USER_LOGOUT_SUCCESS = 'USERS_LOGOUT_SUCCESS';

export const GET_ALL_USERS_STARTED = 'GET_ALL_USERS_STARTED';  
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';

export const SET_CORP_ID= 'SET_CORP_ID'
export const SET_METER_ID= 'SET_METER_ID'
export const SET_GROUP_ID= 'SET_GROUP_ID'

export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';  

export const CLEAN_USER = 'CLEAN_USER';  