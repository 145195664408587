import * as roleActionTypes from '../constants/roleAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function roleReducer(state = { roles: [], loading: false }, action) {
  switch (action.type) {
    case roleActionTypes.GET_ROLE_STARTED:
      return {
        ...state,
        loading: true
      };
    case roleActionTypes.GET_ROLE_SUCCESS:
      return { ...state, 
        roles: action.roles, 
        loading: false 
      };

    case roleActionTypes.POST_ROLE_STARTED:
      return {
        ...state,
        loading: true
      };
    case roleActionTypes.POST_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.push(action.payload),
        loading: false
      };

    case roleActionTypes.POST_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case roleActionTypes.DELETE_ROLE_SUCCESS:
        return {
          ...state,
          roles: state.roles.filter(
            ({ id }) => id !== action.payload.id
          )
        };

    
    default:
      return state;
  }
}

export default roleReducer;
