import React, { useState , useEffect } from 'react';
import useForm from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {useDropzone} from 'react-dropzone';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';


// Material
import { Button, Grid, TextField, makeStyles, Typography,FormControl,InputLabel,Select,MenuItem   } from '@material-ui/core';

import * as action from 'js/actions/index';

// Component styles
import styles from './styles';
import { Edit } from '@material-ui/icons';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);



export default function CorporativeForm(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const [files, setFiles] = useState([]);
  const {corporative} = props.location.state

  console.log(props.location.state )

  const [market_segment, setMarketSegment] = useState(corporative.market_segment);

  
  const [typePrebilling, setTypePrebilling] = useState();

  const onSubmit = (corporativeData) => {
    // console.log(corporativeData, corporative.id)
    dispatch(action.updateDataCorporative(corporativeData,corporative.id,files));
  };
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div
      className={classes.thumb}
      key={file.name}
    >
      <div className={classes.thumbInner}>
        <img
          className={classes.img}
          src={file.preview}
        />
      </div>
    </div>
  ));


 
  const displayCurrentLogo = () => {

    if( corporative.logo ){

      return (

        <div style={{marginTop:'20px' }}  >

          <img 
            alt="new"
            border={'1px'}
            src={corporative.logo}
            width={'200px'}
          />

          <Typography
            className={classes.description}
            style={{  marginTop: '20px' ,  color: 'carbon' }}
            variant="h4"
          >
            {intl.formatMessage({id: 'app.corporate.editCorporate.currentLogo'})}
          </Typography>          


        </div>
  
  
      );      


    }else {
      return null;
    }


  }




  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleMarketSegment = market_segment => {
    console.log( market_segment.target.value )
    setMarketSegment(market_segment.target.value);
  };

  const handleTypePrebilling = typePrebilling => {
    setTypePrebilling(typePrebilling.target.value);
  };

  return (
    <DashboardLayout title={intl.formatMessage({id: 'app.corporate.editCorporate.headTitle'})}>
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel/>
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      defaultValue={corporative.name}
                      inputRef={register}
                      label={intl.formatMessage({id: 'app.corporate.editCorporate.corpNameLabel'})}
                      name="name"
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={corporative.description}
                      inputRef={register}
                      //onChange={this.handleChange}
                      label={intl.formatMessage({id: 'app.corporate.editCorporate.corpDescLabel'})}
                      name="description"
                      required
                      variant="outlined"
                    />
                    
                
               
                                                            

                    <TextField
                      className={classes.textField}
                      defaultValue={corporative.city}
                      inputRef={register}
                      //onChange={this.handleChange}
                      label={intl.formatMessage({id: 'app.corporate.editCorporate.cityLabel'})}
                      name="city"
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      defaultValue={corporative.state}
                      inputRef={register}
                      //onChange={this.handleChange}
                      label={intl.formatMessage({id: 'app.corporate.editCorporate.stateLabel'})}
                      name="cstate"
                      required
                      variant="outlined"
                    />
                                        
                    <FormControl
                      className={classes.textField}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                          
                      >
                        {intl.formatMessage({id: 'app.corporate.editCorporate.marketSegLabel'})}
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="market_segment"
                        onChange={handleMarketSegment}
                        value={market_segment}                 
                        //labelWidth={labelWidth}
                      >
                        <MenuItem value="PLAZA COMERCIAL">Plaza Comercial</MenuItem>
                        <MenuItem value="PROYECTO DE USOS MIXTOS">Proyecto de Usos Mixtos</MenuItem>
                        <MenuItem value="INDUSTRIA DE TRANSFORMACION">Industria de Transformación</MenuItem>
                        <MenuItem value="PARQUE INDUSTRIAL">Parque Industrial</MenuItem>
                        <MenuItem value="PLAZA COMERCIAL">Plaza Comercial</MenuItem>
                        <MenuItem value="HOTEL / HOSPEDAJE">Hotel / Hospedaje</MenuItem>
                        <MenuItem value="PLANTA DE PRODUCCION">Planta de Producción</MenuItem>
                        <MenuItem value="HOSPITAL / CLINICA / SALUD">Hospital / Clínica / Salud</MenuItem>
                        <MenuItem value="EDIFICIO PUBLICO GOBIERNO">Edificio Público Gobierno</MenuItem>
                        <MenuItem value="AGRICOLA Y DEL CAMPO">Agrícola y del Campo</MenuItem>
                        <MenuItem value="TERMINAL AEREA">Terminal Aérea</MenuItem>
                        <MenuItem value="EDIFICIO CORPORATIVO">Edificio Corporativo</MenuItem>
                        <MenuItem value="OFICINA EMPRESARIAL / SERVICIOS">Oficina Empresarial / Servicios</MenuItem>
                        <MenuItem value="EDIFICIO DE DEPARTAMENTOS">Edificio de Departamentos</MenuItem>
                        <MenuItem value="CENTROS DE ESPECTACULOS">Centros de Espectáculos</MenuItem>
                        <MenuItem value="FRACCIONAMIENTO RESIDENCIAL">Fraccionamiento Residencial</MenuItem>
                        <MenuItem value="CLUB DEPORTIVO / SOCIAL">Club Deportivo / Social</MenuItem>
                        <MenuItem value="CADENA COMERIAL MINORISTA">Cadena Comercial Minorista</MenuItem>
                        <MenuItem value="CADENA COMERIAL MAYORISTA">Cadena Comercial Mayorista</MenuItem>
                        <MenuItem value="INDUSTRIA MAQUILADORA">Industria Maquiladora</MenuItem>
                        <MenuItem value="INDUSTRIA ENERGÉTICA">Industria Energética</MenuItem>
                        <MenuItem value="SERVICIOS ESPECIALIZADOS">Servicios Especializados</MenuItem>
                        <MenuItem value="EDIFICIO EDUCATIVO">Edificio Educativo</MenuItem>
                        <MenuItem value="RESTAURANTE / BAR / CAFE">Restaurante / Bar / Café</MenuItem>
                        <MenuItem value="PROYECTO RESIDENCIAL">Proyecto Residencial</MenuItem>
                        <MenuItem value="SUMINISTRADOR CALIFICADO">Suministrador Calificado</MenuItem>
                        <MenuItem value="COMERCIALIZADOR DE ENERGIA">Comercializador de Energía</MenuItem>
                        <MenuItem value="REPRESENTANTE METIMUR">Representante Metimur</MenuItem>

                      </Select>
                    </FormControl>
                   
                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <section className={classes.container}>
                      <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <Typography
                          style={{textAlign:'center'}}
                          variant="h4"
                        >{intl.formatMessage({id: 'app.corporate.editCorporate.dragImageLogo'})}</Typography>
                      </div>
                      <aside className={classes.thumbsContainer}>
                        {thumbs}
                      </aside>
                    </section>

                  
                  </div>
                  <div>{
                    displayCurrentLogo()                    
                  }


                  </div>



                </Grid>
                <Button
                  className={classes.signUpButton}
                  color="secondary"
                  label={intl.formatMessage({id: 'app.corporate.editCorporate.btnSave'})}
                  //onClick={this.handleSubmit}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {intl.formatMessage({id: 'app.corporate.editCorporate.btnSave'})}{' '}
                </Button>
              </Grid>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
