import * as prefsActionTypes from '../constants/prefsAction-types';

const initialState = {
   // prefLang : 'EN' ,
   // locale : 'en-US'

    prefLang : 'SP' , 
    locale : 'es-MX'
  
};

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function prefsReducer(state = initialState, action) {

  console.log( action )
  switch (action.type) {

    case prefsActionTypes.PREF_LANG_CHANGED:
         
    return {
      ...state,                
      prefLang : action.lang.prefLang ,
      locale : action.lang.locale
      
      //error: {}
    };
    

    default:
      return state;
  }
}

export default prefsReducer;