import {createStore, applyMiddleware, compose} from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import  storage  from "redux-persist/lib/storage";
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';
import logger from 'redux-logger'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist:['user','corporatives','preInvoiceGroup','preInvoiceMeter'],
  blacklist: ['chargeDashboard']
}

const persistedReducer = persistReducer(persistConfig,rootReducer);
const store = createStore(persistedReducer,storeEnhancers(applyMiddleware(thunk,logger)));

const persistor = persistStore(store)

export {store, persistor}