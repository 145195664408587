import * as concentratorActionTypes from 'js/constants/concentratorAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2'

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showConcentrators = meterId => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterId}/concentrators`,
        getconfig()
      )
      .then(response => {
        dispatch(getConcentrators(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getConcentrators = concentrators => {
  return {
    type: concentratorActionTypes.GET_CONCENTRATOR_SUCCESS,
    concentrators
  };
};

export const postDataConcentrator = concentrator => {
  return async dispatch => {
    dispatch(postConcentratorStarted());

    axios
      .post(
        `https://api.metimur.mx/api/concentrator`,
        {
          name: concentrator.concentrator_name,
          description: concentrator.description,
          type: concentrator.type,
          meter_id: concentrator.meter_id
        },
        getconfig()
      )
      .then(response => {
        dispatch(postConcentratorSuccess(concentrator));
        
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Concentrador ${concentrator.concentrator_name} creado exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${concentrator.meter_id}`)}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al actualizar',
            html: `Se encontraron los siguientes errores al actualizar el concentrador:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postConcentratorFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const postConcentratorStarted = () => ({
  type: concentratorActionTypes.POST_CONCENTRATOR_STARTED
});

export const postConcentratorSuccess = concentrator => {
  return {
    type: concentratorActionTypes.POST_CONCENTRATOR_SUCCESS,
    payload: {
      name: concentrator.concentrator_name,
      description:concentrator.description,
      type: concentrator.concentratorType,
      ftp_user: concentrator.user_ftp,
      connection_period: concentrator.connection_period,
      baudrate: concentrator.baudrate,
      group_id: concentrator.group_id,
      meter_id: concentrator.meter_id
    }
  };
};

export const postConcentratorFail = error => ({
  type: concentratorActionTypes.POST_CONCENTRATOR_FAILURE,
  state: { error }
});

export const deleteConcentrator = concentratorId => {
  return async dispatch => {

    dispatch(deleteConcentratorSuccess(concentratorId));
    axios.post(
      `https://api.metimur.mx/api/concentrator/delete`,
      { concentrator_id: concentratorId },
      getconfig()
    )    
  };
};

export const deleteConcentratorSuccess = concentratorId => {
  return {
    type: concentratorActionTypes.DELETE_CONCENTRATOR_SUCCESS,
    payload:{id: concentratorId} 
  };
};



export const updateDataConcentrator = (concentrator,concentratorData) => {
  return async dispatch => {
    dispatch(updateConcentratorStarted());

    axios
      .put(
        `https://api.metimur.mx/api/concentrator`,
        {
          id:concentratorData.id,
          name: concentrator.concentrator_name,
          description: concentrator.description,
          type: concentrator.type,
          meter_id: concentratorData.meter_id
        },
        getconfig()
      )
      .then(()=>{
        Swal.fire({
          title: 'Actualiazion Correcta',
          text: `Concentrador ${concentrator.concentrator_name} actualizado exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${concentrator.meter_id}`)}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al actualizar',
            html: `Se encontraron los siguientes errores al actualizar el concentrador:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postConcentratorFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const updateConcentratorStarted = () => ({
  type: concentratorActionTypes.PUT_CONCENTRATOR_STARTED
});

export const cleanConcentrator = () => ({
  type: concentratorActionTypes.CLEAN_CONCENTRATOR,
  payload:{
    data:[]
  }
});