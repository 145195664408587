/* eslint-disable no-class-assign */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import _ from 'underscore';
import { Link } from 'react-router-dom';
// Material helpers
import { InputAdornment, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl'


// Material components
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import styles from './styles';
import * as action from 'js/actions/userActions';
import { AlternateEmail, LockOutlined } from '@material-ui/icons';

const mapDispatchToProps = dispactch => {
  return {
    postUser: user =>
      dispactch(action.login(user))
  }
}

class NewLogin extends Component {
  constructor(){
    super();
    this.state = {
      email: '',
      password: '',
      remember_me:'',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  handleChange(event){
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event){
    event.preventDefault();
    const {email,password,remember_me} = this.state
    this.props.postUser({
      email,
      password,
      remember_me      
    })
    this.setState({
      email: email ,
      password:'',
      remember_me:''

    })
  }


  render() {
    const { classes } = this.props;
    const {
      email, password, remember_me
    } = this.state;

    const { intl } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >           
          <Grid
            className={classes.content}
            item
            lg={12}
            xs={12}
          >
            <div   className={classes.contentWrapper}  >
              <div   className={classes.formBox}       >
                <div className={classes.contentBody}>
                  <form
                    className={classes.form}
                    onSubmit={this.handleSubmit}
                  >
                    <div  className={classes.divImage} >
                      <img
                        alt="Metimur Logo"
                        src="/images/logo-metimur.png"
                        width="140px"
                      />
                    </div>
                    <div className={classes.fields}>
                      <TextField
                        className={classes.textField}
                        id= "email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AlternateEmail />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.handleChange}
                        placeholder={intl.formatMessage({ id: 'app.login.email' })}
                        type="text"
                        value={email}
                        variant="outlined"
                      />
                      {/* {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2">
                        {errors.email[0]}
                      </Typography>
                    )} */}
                      <TextField
                        className={classes.textField}
                        id="password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlined />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.handleChange}
                        placeholder={intl.formatMessage({ id: 'app.login.password' })}
                        type="password"
                        value={password}
                        variant="outlined"
                      />
                      {/* {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2">
                        {errors.password[0]}
                      </Typography>
                    )} */}
                    </div>
                    {/* {submitError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitError}
                    </Typography>
                  )} */}
                    {/* {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    button
                  )} */}
                    <Button
                      className={classes.signInButton}
                      color="secondary"
                      // disabled={!isValid}
                      onClick={this.handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      {intl.formatMessage({ id: 'app.login.btnInitSession' })}
                    </Button>
                    <Link
                      className={classes.iconTable}
                      to={{
                        pathname: '/recoveredEmail',
                        //state: {card:rowData}
                      }}
                    >
                      <Typography
                        color="secondary"
                        style={{marginTop:'10px' , textAlign: 'center', fontWeight: 500 }}
                      >{intl.formatMessage({ id: 'app.login.recoverPassword' })}</Typography> 
                    </Link>
                  </form>
                </div>
              </div>
            </div>



          </Grid>
        </Grid>
      </div>
    );
  }
}

NewLogin.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

NewLogin = injectIntl(NewLogin);

export default compose(
  withRouter,
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(NewLogin);
