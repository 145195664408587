import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, TextField,Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { postForm, deleteLastPostForm } from 'js/actions/formsAction';
import { showConcentrators } from 'js/actions/concentratorActions';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Swal from 'sweetalert2';
const useStyles = makeStyles(styles);

function useOnMount(handler) {
  return React.useEffect(handler, []);
}

const defaultSelectValues = {
  concentratorType: null,
  baudrate: null
};

function UserConcentratorForm(props) {
  const { setValue, getValues, register, handleSubmit } = useForm({
    defaultSelectValues
  });
  const intl = useIntl();
  const dispatch = useDispatch();
  const concentrators = useSelector(state => state.concentrators.concentrators);
  const { meterId } = props.ids;

  useEffect(() => {
    dispatch(showConcentrators(meterId));
  }, []);

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }

  const onSubmit = values => {
    if(values.comunicationType !== undefined && values.concentrator !== undefined && values.cardType !== undefined) {
      dispatch(postForm(values));
      props.nextStep();
    } else {
      Swal.fire({
        title: intl.formatMessage({id:'app.chargesCards.addCharge.stepper.comunications.dataWrongTitle'}),
        text: intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.comunications.dataWrongText'}),
        icon: 'error'
      })
    }
  };

  const handleCardTypeChange = cardType => {
    setValue('cardType', cardType.target.value);
  };

  const handleConcentratorChange = concentrator => {
    setValue('concentrator', concentrator.target.value);
  };

  const handleComunicationTypeChange = comunictionType => {
    setValue('comunicationType', comunictionType.target.value);
  };

  useOnMount(() => {
    register({ name: 'cardType', type: 'text' });

    register({ name: 'concentrator', type: 'text' });

    register({ name: 'comunicationType', type: 'text' });
  });

  const values = getValues();

  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
          Tipo de Tarjeta
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleCardTypeChange}
            value={values.cardType}

          //labelWidth={labelWidth}
          >
            <MenuItem value={'OMA-M14E'}>OMA-M14E</MenuItem>
          </Select>
        </FormControl>

        <TextField
          className={classes.textField}
          id="outlined-number"
          InputLabelProps={{
            shrink: true
          }}
          inputRef={register}
          label="Numero de Serie"
          margin="normal"
          name="serial_number"
          required
          type="number"
          variant="outlined"
        />

        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
          Concentrador
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleConcentratorChange}
            value={values.concentrator}
          //labelWidth={labelWidth}
          >

            {concentrators.map(concentrator => (
              <MenuItem value={concentrator.id}>{concentrator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            inputRef={useOnMount}
          >
          Via de Comunicacion
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            onChange={handleComunicationTypeChange}
            value={values.comunicationType}
          //labelWidth={labelWidth}
          >

            <MenuItem value={'RS485'}>RS485</MenuItem>
            <MenuItem value={'ETHERNET'}>ETHERNET</MenuItem>
          </Select>
        </FormControl>

        <TextField
          className={classes.textField}
          id="outlined-number"
          InputLabelProps={{
            shrink: true
          }}
          inputRef={register}
          label="Dirección MAC"
          margin="normal"
          name="mac_address"
          variant="outlined"
        />

        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
        Guardar y Continuar
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          Regresar 
        </Button>
      </form>

    </Grid>



  );
}

export default UserConcentratorForm;
