import React, { useEffect } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';

import { getGeneralReport } from 'js/actions/chargeDashboardActions';

import { Bar } from 'react-chartjs-2';

import _ from 'lodash'

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody
} from '@material-ui/core';

// Component styles
import styles from './styles';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport
  );
  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month =  new Date().getMonth() + 1;
    if ( month<10) {return '0'+month} else {
      return month
    } 
  }
  const data = [];
  let obj;

  const array = Object.values(generalReport)

  

  // useEffect(() => {
  //   dispatch(getGeneralReport(ids, year,getCurrentMonth()));
  // }, []);

  Object.values(generalReport).forEach(element => {
    element.forEach(element2 => {
      obj = {
        hora: element2.Hora,
        medicion: element2.KWh,
        color: element2.color
      };
      data.push(obj);
    });
  });

  const res = data.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {});

  return (
    <div className={classes.root}>
      <Bar
        data={data}
        //options={options}
      />
    </div>
  );
}
