// Palette
import palette from 'theme/palette';

// Chart data
export const data = {
  labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre', 'Octubre','Noviembre','Diciembre',],
  datasets: [
    {
      label: '2019',
      fill: false,
      backgroundColor:'#C69100',
      borderColor:'#C69100',
      lineTension: 0.1,
      // backgroundColor: palette.primary.main,
      data: [1060, 1155, 1250, 1300, 1200, 1250, 1255, 1200, 1450, 1185, 1300, 1175, 1250]
    }
  
  ]
};

// Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
};
