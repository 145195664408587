/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logout } from 'js/actions/userActions';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';

// Material components
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Tooltip
} from '@material-ui/core';

// Material icons
import {
  HomeOutlined as HomeIcon,
  PermIdentityOutlined as ProfileIcon,
  AssignmentIndOutlined as RolesIcon,
  GroupOutlined as UsersIcon,
  ContactSupportOutlined as HelpIcon,
  Input as LogOutIcon,
  PriorityHigh as AlertIcon,
  Description as DescriptionIcon ,
  LocalAtm as LocalAtmIcon
} from '@material-ui/icons';

import { Fee } from 'icons';

import { isAllowed } from 'helpers/auth';
import { history } from 'helpers/history';

const drawerWidth = 215;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    border: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: '999 !important',
    backgroundColor: '#6A1A5F',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderBottomRightRadius: '15px',
    borderTopRightRadius: '15px',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '65px'
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '63px',
    flexShrink: 0
  },
  logoLink: {
    fontSize: 0
  },
  logoImage: {
    cursor: 'pointer'
  },
  logoDivider: {
    marginBottom: theme.spacing.unit * 2
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: '100px',
    height: '100px'
  },
  nameText: {
    marginTop: theme.spacing.unit * 2
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.dark}`,
      borderRadius: '4px',
      '& $listItemText': {
        color: theme.palette.text.primary
      },
      '& $listItemIcon': {
        color: theme.palette.common.main,
        fill: theme.palette.common.main,
        marginLeft: '-4px'
      }
    },
    '& + &': {
      marginTop: theme.spacing.unit
    }
  },
  activeListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.text.primary,
      fill: theme.palette.text.primary,
    },
    '& $listItemIcon': {
      color: theme.palette.common.main,
      fill: theme.palette.common.main,
      marginLeft: '-4px'
    }
  },
  listItemIcon: {
    marginRight: 0,
    color:theme.palette.common.white,
    fill: theme.palette.common.white
  },
  
  listItemText: {
    display: 'block',
    fontWeight: 500,
    color: theme.palette.text.white
  },
  listItemTextHide: {
    display: 'none'
  },

  listDivider: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  },

  logoImg:{
    marginTop:theme.spacing.unit,
    filter: 'drop-shadow(0px 5px 5px #000)'
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end'
  }
}));

export default function Sidebar(props) {

  const intl = useIntl()
  const classes = useStyles();
  const { className, open, mobile, close } = props;
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();
  const user = useSelector(state=>state.user.user);
  const [active, setActive] = useState(false);

  const finishSession = () =>{
    dispatch(logout()) 
  }

  const feeFunc = () => {
    setActive(!active)
  }

  return (
    <Drawer
      anchor="left"
      className={rootClassName}
      open={open}
      variant={mobile ? 'temporary' : 'permanent'}
    >
      <nav
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open || mobile,
          [classes.drawerClose]: !open
        })}
      >
        <img
          alt=""
          className={classes.logoImg}
          onClick={()=>history.push('/')}
          src={open ? '/images/logo_horizontal.png': '/images/favicon_metimur.png'}
          width={open ? '180px':'50px'}
        />
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to={`${user.home_url}`}
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.home'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.home'})}
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/account"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.myAccount'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ProfileIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.myAccount'})}
            />
          </ListItem>
          {isAllowed(user.permissions,['user_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/usersByCorps"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.users'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <UsersIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.users'})}
            />
          </ListItem>}

          {isAllowed(user.permissions,['metimur_user_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/usersMetimur"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.metUsers'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <UsersIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.metUsers'})}
            />
          </ListItem>
          }
          
          
          {isAllowed(user.permissions,['role_read'])&&
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/roles"
          >  
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.roles'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <RolesIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.roles'})}
            />
          </ListItem>
          }
          {isAllowed(user.permissions,['tariff_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/controlRate"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.feeCtrl'})}
            >
              <ListItemIcon
                className={classes.listItemIcon}
              >
                <Fee/>
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.feeCtrl'})}
            />
          </ListItem>}
          {isAllowed(user.permissions,['metimur_alert_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/notificationDashboard"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.alerts'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AlertIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.alerts'})}
            />
          </ListItem>}

          {isAllowed(user.permissions,['invoice_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/reports"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.reports'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DescriptionIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.reports'})}
            />
          </ListItem>}
          
          {isAllowed(user.permissions,['invoice_read'])&&<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/invoices"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.invoices'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <LocalAtmIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.invoices'})}
            />
          </ListItem>}
          
          
        </List>
        <Divider className={classes.listDivider} />
        <List
          className={classes.footerMenu}
          component="div"
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={close}
            to="/settings"
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.help'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <HelpIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.help'})}
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            component="a"
            onClick ={()=>finishSession()}
          >
            <Tooltip
              placement="right"
              title={intl.formatMessage({id: 'app.barmenu.logout'})}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <LogOutIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              className={clsx(classes.listItemText, {
                [classes.listItemText]: open,
                [classes.listItemTextHide]: !open,
              })}
              classes={{primary: classes.listItemText }}
              primary={intl.formatMessage({id: 'app.barmenu.logout'})}
            />
          </ListItem>
        </List>
      </nav>
    </Drawer>
  );
}
