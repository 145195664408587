import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { ClientInfobar, ProductCard, Notifications,Password } from './components';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);


export default function HardwareHome(props) {
  const intl = useIntl()
  const classes = useStyles()
  const {meterId} = props.match.params


  const renderOptions = (classes, meterId) => {
    return (
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          lg={2}
          md={2}
        >
          <ProductCard
            //className={classes.option}
            destination={`/concentradors/${meterId}`}
            fontIcon="fas fa-charging-station"
            productname={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrators'})}
            meterId={meterId}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
        >
          <ProductCard
            //className={classes.option}
            destination={`/allcards/${meterId}`}
            fontIcon="fas fa-microchip"
            productname={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.cards'})}
            //propGroup={group}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
        >
          <ProductCard
            //className={classes.option}
            destination={`/allcardsHomework/${meterId}`}
            fontIcon="fas fa-plug"
            productname={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.tasks'})}
            //propGroup={group}
          />
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
        >
   <ProductCard
            //className={classes.option}
            destination={`/powerfactor/${meterId}`}
            
            fontIcon="fa fa-square"
            productname={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.powerFact'})}
            //propGroup={group}
          />
        </Grid>


        <Grid
          item
          lg={2}
          md={2}
        >


 

          <ProductCard
            //className={classes.option}
            destination={`/voltage/${meterId}`}
            fontIcon="fas fa-broadcast-tower"
            productname={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.voltage'})}
            //propGroup={group}
          />
        </Grid>
      </Grid> 
    )
  } 
  

  return (
    <DashboardLayout title='Configuracion de Hardware'>
      <div className={classes.root}>

        <Grid
          className={classes.content}
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <div className={classes.table}>
            {/* <ChargeGeneralTable
              group={this.props.location.state.group}
            /> */}
            </div>
          </Grid>
        </Grid>

        <div className={classes.content}>
          {renderOptions(classes,meterId)}
        </div>

        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Password />
            </Grid>
          </Grid>
        </div>

        

        <Container
        styles={{marginRight: '-20px',
        marginBottom: '-20px'}}
        
        >
          <LinkFloat
            //href={`/concentrador/${meterId}/group/${group.id}`}
            icon="fa fa-charging-station"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5rem'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.toolTip.concentrator'})}
          />
          <Button
            icon="fa fa-cog"
            rotate
            styles={{
              backgroundColor: '#C69100',
              color: '#fff',
              width: '80px',
              height: '80px',
              fontSize: '2rem'
            }}
          />
        </Container>
      </div>
    </DashboardLayout>
  );
}



