import * as preInvoiceMeterActionTypes from 'js/constants/preInvoiceMeter';
import axios from 'axios';
import { history } from 'helpers/history';
import Swal from 'sweetalert2';
import getToken from 'helpers/auth';
import { FileSaver, saveAs } from 'file-saver';
import FileDownload from 'js-file-download';

const getConfig = () => {
  return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getToken()
    }
  };
};


export const getConsumesLastMonth = (corporativeId,meterId,year,month) => {

  
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/previousinvoice/year/${year}/month/${month}`,
        { 
          headers: {
          'Content-Type': 'application/json' 
          }
          
          }
      )
      .then(response => {
        dispatch(getLastConsumes(response.data.data))
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getLastConsumes = lastConsumes => {
  return {
    type: preInvoiceMeterActionTypes.GET_LAST_METER_CONSUMES_SUCCESS,
    lastConsumes
  };
};

export const postPreviewFactor = reportData => {
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .post(
        `https://api.metimur.mx/api/preinvoicing/meter/previewfactor`,
          {
            year: reportData.year,
            month: reportData.month,
            corporative_id: reportData.corporativeId,
            meter_id: reportData.meterId,
            factor:reportData.factor
          },
          { 
            headers: {
            'Content-Type': 'application/json' 
            }
            
            }
      )
      .then(response => {
        dispatch(postFactor(response.data.data))
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};


export const postFactor = preview => {
  return {
    type: preInvoiceMeterActionTypes.POST_PREVIEW_FACTOR_SUCCESS,
    preview
    
  };
};


export const applyFactor = reportData => {
  return async dispatch => {
    //dispatch(getControlRateStarted())
    axios
      .post(
        `https://api.metimur.mx/api/preinvoicing/meter/applyfactor`,
          {
            year: reportData.year,
            month: reportData.month,
            corporative_id: reportData.corporativeId,
            meter_id: reportData.meterId,
            factor:reportData.factor
          },
          { 
            headers: {
            'Content-Type': 'application/json' 
            }
            
            }
      )
      .then(response => {
        console.log(response)
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};
