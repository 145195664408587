import * as concentratorActionTypes from '../constants/concentratorAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function concentratorReducer(
  state = { concentrators: [], loading: false },
  action
) {
  switch (action.type) {
    case concentratorActionTypes.POST_CONCENTRATOR_STARTED:
      return {
        ...state,
        loading: true
      };
    case concentratorActionTypes.POST_CONCENTRATOR_SUCCESS:
      return {
        ...state,
        concentrators: state.concentrators.push(action.payload),
        loading: false
      };
    case concentratorActionTypes.POST_CONCENTRATOR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case concentratorActionTypes.GET_CONCENTRATOR_SUCCESS:
      return { ...state, concentrators: action.concentrators };

    case concentratorActionTypes.DELETE_CONCENTRATOR_SUCCESS:
      return {
        ...state,
        concentrators: state.concentrators.filter(
          ({ id }) => id !== action.payload.id
        )
      };
    case concentratorActionTypes.PUT_CONCENTRATOR_STARTED:
      return {
        ...state,
        loading: true
      };
    case concentratorActionTypes.CLEAN_CONCENTRATOR:
        return (state = action.payload)
        ;

    default:
      return state;
  }
}

export default concentratorReducer;
