import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';

import {
  Button,
  TextField, 
  InputLabel , 
  MenuItem, 
  Select, 
  FormControl ,
  Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);


function NameRoleForm( props ) {
  const intl = useIntl()
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [isPublica, setPublica] = React.useState('');

  const onSubmit = values => {
    dispatch(postForm(values));
    props.nextStep()
  };

  const classes = useStyles();

  const handlePublica  = publica => {
    setPublica(publica.target.value);
  };

  return (
    <Grid container className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <TextField
        className={classes.textField}
        inputRef={register}
        label={intl.formatMessage({id: 'app.roles.addRole.stepper.roleName.roleNameLbl'})}
        name="role_name"
        required
        variant="filled"
      />

<FormControl
          className={classes.textField}
          variant="outlined"
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            
          >
            {intl.formatMessage({id: 'app.roles.addRole.stepper.roleName.roleTypeLbl'})}
          </InputLabel>
          <Select
            id="demo-simple-select-outlined"
            labelId="demo-simple-select-outlined-label"
            name="is_public"
            inputRef={register}
            required
            value={isPublica}
            onChange={handlePublica}
            //labelWidth={labelWidth}
          >
              <MenuItem value="1">Marca</MenuItem>
              <MenuItem value="3">Clientes</MenuItem>
          </Select>
        </FormControl>  
      
     <Button
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
        className={classes.button}
      >
        {intl.formatMessage({id: 'app.roles.addRole.stepper.roleName.btnSaveContinue'})}
      </Button> 
    </form>

    </Grid>
     
  );
}

export default NameRoleForm;
