export default theme => ({
  root: {
    maxWidth: '100%',
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3
  },
  imageWrapper: {
    height: '64px',
    width: '64px',
    margin: '0 auto',
    border: '1px solid #EDF0F2',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  stepButton: {
    '& .MuiStepLabel-label': {
      fontSize: '1rem',
    },
  },
  details: {},
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2
  },
  description: {
    lineHeight: '16px',
    height: theme.spacing.unit * 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit
  },
  measuring:{
    textAlign:'center',
    padding:0,
    maxWidth:'20%',
    minWidth:'20%',
    fontWeight: 600,
    fontSize: '1.2em'
  },
  medition:{
    textAlign:'center',
    padding:0,
    maxWidth:'20%',
    minWidth:'20%',
    fontSize: '1.2em',
    fontWeight: 600
  },
  tableCell:{
    padding: 0,
    fontSize:'1em',
    fontWeight:400,
    maxWidth:'20%',
    minWidth:'20%',
    color: '#fff',
    textAlign:'center'
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  buttonStrepper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit*3}px`,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderRadius: '.25rem',
    color: 'white',
    fontWeight: 600,
    fontSize: '1rem',
    '&:last-child':{
      backgroundColor: '#A53693',
    }
  }

  
});
