/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState , useEffect } from 'react';
import useForm from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { postForm } from 'js/actions/formsAction';
import { deleteLastPostForm } from 'js/actions/formsAction';

import { Button, TextField, Grid , Select, MenuItem  } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { getChargeVoltage } from 'js/actions/chargeActions';
import InputLabel from '@material-ui/core/InputLabel';
 
import FormControl from '@material-ui/core/FormControl'; 

const useStyles = makeStyles(styles);

function RangosMedicionForm(props) {
  const intl = useIntl();
  const form = useSelector(state => state.form);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(deleteLastPostForm())
    props.prevStep();
  }
  /*
  useEffect(() => {    
    //dispatch(getChargeVoltage(corporative.id , funcRespChargeVoltage ));
  }, []);
  */
 
  const onSubmit = values => {       
    dispatch(postForm(values));
    props.nextStep();
  };

  const isValid = txt => {     
    let bValid=true ;
    let restricted = [ '/' ,  '\\'  , ':' , '?' ,  '*' ];
        
    for( var idx=0; idx< restricted.length; idx++  ){         
      if (txt.indexOf(restricted[idx]) >-1) {
        return false;
      }
    }  
    return bValid;
  }

 
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          className={classes.textField}
          inputRef={register}
          label="Demanda Mínima"
          name="demanda_min"
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label="Demanda Máxima"
          name="demanda_max"
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label="Consumo Mínimo"
          name="consumo_min"
          variant="outlined"
        />

        <TextField
          className={classes.textField}
          inputRef={register}
          label="Consumo Máximo"
          name="consumo_max"
          variant="outlined"
        />
 

        <Button
          className={classes.button}
          color="secondary"
          size="large"
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.descrip.saveAndContinueLbl'})}
        </Button>
        <Button
          className={classes.backButton}
          onClick={handleBack}
        >
          {intl.formatMessage({id: 'app.chargesCards.addCharge.stepper.confirmation.backBtn'})}
        </Button>
      </form>
    </Grid>
  );
}

export default RangosMedicionForm;
