export default theme => ({
  root: {},
  dropdownButton: {
    marginRight: -theme.spacing.unit * 2
  },
  chartWrapper: {
    height: '400px',
    position: 'relative'
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  stats: {
    marginTop: '0',
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem',
    textAlign: 'center',
    padding: theme.spacing.unit,
    paddingTop: '0'
  },
  deviceIcon: {
    color: theme.palette.common.neutral
  }
});
