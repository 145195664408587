import React, { Component, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Typography, Button } from '@material-ui/core';


import { Paper } from 'components';
// Component styles
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function CorporativeCard(props) {
  const { className, alert} = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();

  return (
    <Paper className={rootClassName}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="h4"
        >
          {alert.charge_name}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
        >
          {alert.description}
        </Typography>
        <Typography
          className={classes.description}
          variant='caption'
        >
          {alert.created_at}
        </Typography>
      </div>

    </Paper>
  );
}

CorporativeCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  corporative: PropTypes.object.isRequired
};
