/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/core';

// Material components
import { Typography, Box } from '@material-ui/core';

// Material icons
import { Receipt as ReceiptIcon } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { getUnifiquedReceipt } from 'js/actions/groupDashboardActions';
import { useSelector ,  useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
const useStyles = makeStyles(styles);

export default function Receipt(props) {
  const intl = useIntl()
  const classes = useStyles();
  
  const dispatch = useDispatch()
  const { className,...rest } = props;
  const { groupId } = props.ids;
  const rootClassName = classNames(classes.root, className);
  const receiptURL = useSelector(state => state.groupDashboard.receipt);

  //  const year = new Date().getFullYear();

  const getLastMonth = () => {
    const month = new Date()

    const lastMonth = month.getMonth()

    switch (lastMonth-1) {
      case 0:
        return 1
      case 1:
        return 2
      case 2:
        return 3
      case 3:
        return 4
      case 4:
        return 5
      case 5:
        return 6
      case 6:
        return 7
      case 7:
        return 8
      case 8:
        return 9
      case 9:
        return 10
      case 10:
        return 11
      case 11:
        return 12
      default:
        break;
    }
  };


  const getReceiptData = groupId => {

    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    month++;
    if( month==1 ){
      month=12 ;
      year--;
    }else {
      month=month-1;
    }

    dispatch(getUnifiquedReceipt(groupId,year, month ))

  }

  useEffect(()=> {
    getReceiptData(groupId);
  }, [groupId])

  const handleReceipt = () => {
    if(receiptURL.data === undefined) {
      Swal.fire({
        titleText: intl.formatMessage({id: 'app.chagrouprge.board.mainHead.receiptConsult.alert'}),
        icon: 'warning'
      })
    }
  }

  return (
    <Paper
      {...rest}
      className={rootClassName}
      onClick={handleReceipt}
    >
      <Link
        className={classes.iconTable}
        download={receiptURL.data !== undefined ? true : false}
        style={receiptURL.data === undefined ? {pointerEvents: 'none'} : {}}
        target={receiptURL.data !== undefined ? '_blank' : ''}
        to={{
          pathname: `${receiptURL.data !== undefined ? receiptURL.data : '/#'}`
        }}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              {intl.formatMessage({id: 'app.group.board.mainHead.receiptConsult'})}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <ReceiptIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>

          <Typography
            className={classes.caption}
            variant="caption"
          >
            {intl.formatMessage({id: 'app.group.board.mainHead.receiptConsult.linkMsg'})}
          </Typography>
        </div>
      </Link>
    </Paper>
  );
}