import * as prefsActionsTypes from 'js/constants/prefsAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history';
import swal from 'sweetalert';
import Swal from 'sweetalert2';



export const changeUserLanguage = prefLng => { 
  return async dispatch => {
    return dispatch(changeLangEvent( prefLng )); 
  }
}

export const changeLangEvent = lang => ({
    type:  prefsActionsTypes.PREF_LANG_CHANGED ,
     lang
  });