export const GET_STATES_STARTED = 'GET_STATES_STARTED';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const GET_CITIES_STARTED = 'GET_CITIES_STARTED';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';


export const GET_COUNTIES_STARTED = 'GET_COUNTIES_STARTED';
export const GET_COUNTIES_SUCCESS = 'GET_COUNTIES_SUCCESS';
export const GET_COUNTIES_FAILURE = 'GET_COUNTIES_FAILURE';


export const GET_CP_STARTED = 'GET_CP_STARTED';
export const GET_CP_SUCCESS = 'GET_CP_SUCCESS';
export const GET_CP_FAILURE = 'GET_CP_FAILURE';

