export default theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: '#E1E1E1',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    paddingLeft: theme.spacing.unit * 5,
    [theme.breakpoints.down('sm')]:{
      justifyContent: 'space-between',
      paddingLeft: theme.spacing.unit * 2
    }
  },
  title: {
    marginLeft: theme.spacing.unit
    //marginLeft: '170px'
  },
  chargeName: {
    fontWeight: 600,
    color: '#A53693',
    transition: 'all .3s ease-in-out',
    opacity: 0, 
    transform: 'translateY(-10px)',
    fontSize: '20px'
  },
  chargeNameVisible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  menuButton: {
    marginLeft: '10px'
  },
  menuButtonOpen: {
    marginLeft: '50px'
  },
  menuButtonMobile: {
    marginLeft: '0px'
  },
  notificationsButton: {
    marginLeft: 'auto',
    marginRight:'15px',
    [theme.breakpoints.down('sm')]:{
      marginRight: '0',
      marginLeft: '0'
    }
  },
  signOutButton: {
    right: theme.spacing.unit
  },
  backButton:{
    backgroundColor: theme.palette.common.secondary,
    color:'#fff'
  },
  translate: {
    fontWeight: 600,
    display: 'flex',
    lineHeight: '0',
    [theme.breakpoints.down('sm')]:{
      display: 'none',
      padding: 0
    }
  },
  hamburguerIcon: {
    marginLeft: '7px',
    marginRight: '7px',
    fontWeight: '600',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]:{
      marginLeft: '0',
      marginRight: '0',
      minWidth: '16px',
    }
  }
});
