import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles,makeStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  PriorityHigh as NotificationIcon,

} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(styles);


export default function Budget(props) {
  const classes = useStyles()
  const {  className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const alerts = useSelector(state => state.userAlert.userAlerts);
  //const alerts = useSelector(state => state.systemAlert.systemAlert);
 
  
  return (
    <Paper
    {...rest}
    className={rootClassName}
  >
    <div className={classes.content}>
      <div className={classes.details}>
        <Typography
          className={classes.title}
          variant="body2"
        >
          Problemas de Nivel Bajo
        </Typography>
        <Typography
          className={classes.value}
          variant="h3"
        >
         {alerts.alert_lvl_1_count
                ? alerts.alert_lvl_1_count
                : 0}
        </Typography>
      </div>
      <div className={classes.iconWrapper}>
        <NotificationIcon className={classes.icon} />
      </div>
    </div>
  </Paper>
  )
}
