import React, { useState } from 'react';

// Material helpers
import {  makeStyles } from '@material-ui/core';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Shared services
import { getUsers } from 'services/user';

// Custom components
import { UsersToolbar, UsersTable, CorpTable} from './components';

// Component styles
import styles from './style';
const useStyles = makeStyles(styles);

export default function UserList(props) {
  const classes = useStyles();
  const {chargeId,groupId} = props.match.params;

  const [showEditButton, setShowEditButton] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  
  return (
    <DashboardLayout title="Alertas de Carga">
        <div className={classes.root}>
          <div className={classes.content}>
            <CorpTable chargeId={chargeId}/>
          </div>
        </div>
        <Container styles={{ marginRight: '-20px', marginBottom: '-20px',zIndex:900 }}>
          <LinkFloat
            href={`/newUserAlet/${groupId}/charge/${chargeId}`}
            icon="fa fa-plus"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5em'
            }}
            tooltip="Alta de Alerta"
          />
          <Button
            icon="fa fa-cog"
            rotate
            styles={{
              backgroundColor: '#C69100',
              color: '#fff',
              width: '80px',
              height: '80px',
              fontSize: '1.5em'
            }}
          />
        </Container>
      </DashboardLayout>
  )
}
