/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-class-assign */
import React, { useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { Box, withStyles } from '@material-ui/core';

// Material components
import {
  Breadcrumbs,
  Link as LinkMaterial,
  Grid,
  Typography
} from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  Budget,
  Users,
  Profit,
  ConsumptionAndDemandChart,
  MonthWithMostConsumeChart,
  ComparativeYearConsumeChart,
  DayWithMostConsumeChart,
  Measurements,
  TopChargesChart,
  Receipt
} from './components';
import { injectIntl } from 'react-intl';
import theme from 'theme';
import { isAllowed } from 'helpers';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { primary } from 'common/colors';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  breadcrumb:{
    fontSize: '1em',
    marginRight: theme.spacing.unit
  },
  item: {
    height: '100%'
  }
});

const Dashboard = (props) => {
  const { classes } = props;
  const { group } = props.location.state;
  const { groupId } = props.match.params;
  const { intl } = props;
  const user = useSelector(state => state.user.user);
  const typeEnergy = group.type_energy;

  const corpAdmin = useSelector(state=>state.user.corporative)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id

  const meterAdmin = useSelector(state=>state.user.meter);
  const meterUser = user.meters;
  const meterId = meterUser === undefined || meterUser === null ? meterAdmin : meterUser.id;

  const storedCorpName = localStorage.getItem(`corpName_${corporativeId}`);
  const storedMeterName = localStorage.getItem(`meterName_${meterId}`);

  useEffect(() => {
    if (corpAdmin && corpAdmin.name) {
      localStorage.setItem(`corpName_${corporativeId}`, corpAdmin.name);
    }
  }, [corpAdmin, corporativeId]);

  useEffect(() => {
    if (meterUser && meterUser.name) {
      localStorage.setItem(`meterName_${meterId}`, meterUser.name);
    }
  }, [meterUser, meterId]);

  const meterName = meterUser && meterUser.name ? meterUser.name : storedMeterName;
  const corpName = corpAdmin && corpAdmin.name ? corpAdmin.name : storedCorpName;

  const budgets = group.bill && group.bill === 1 ? 2 : 3;

  return (
    <DashboardLayout
      chargeName={group.name}
      title="Tablero"
    >
      <div className={classes.root}>
        <Typography
          style={{color:'#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2"
        >
          {intl.formatMessage({id: 'app.group.board.title'})}
        </Typography>
        <Box style={{display: 'flex', flexDirection:'row', flexWrap: 'wrap'}}>
          <Breadcrumbs
            arial-label="breadcrumb"
            className={classes.breadcrumb}
            separator=":"
          >
            {/* {isAllowed(user.permissions,['corporative_read']) && (
              <LinkMaterial
                color="inherit"
                component={NavLink}
                to="/home"
              >
                {intl.formatMessage({id: 'app.groups.nav.corp'})}
              </LinkMaterial>
            )} */}
            {isAllowed(user.permissions,['meter_read'])&&<LinkMaterial
              color="inherit"
              component={NavLink}
              style={{color:'#A53693'}}
              to={{pathname: `/corporative/${corporativeId}`}}
            >
              {intl.formatMessage({id: 'app.groups.nav.meters'})}
            </LinkMaterial>}
            {isAllowed(user.permissions,['group_read'])&&<LinkMaterial
              color="inherit"
              component={NavLink}
              to={{pathname: `/meter/${meterId}`, state: {meterId}}}
            >
              { meterName ? meterName : intl.formatMessage({id: 'app.groups.nav.groupLab2'})}
            </LinkMaterial> }
          </Breadcrumbs>
          <Typography style={{fontWeight: 600, color: '#00AFEF'}} > | </Typography>
          <Breadcrumbs
            arial-label="breadcrumb"
            className={classes.breadcrumb}
            separator=":"
            style={{marginLeft: theme.spacing.unit}}
          >
            {isAllowed(user.permissions,['group_read'])&&<LinkMaterial
              color="inherit"
              component={NavLink}
              style={{color:'#A53693'}}
              to={{pathname: `/meter/${meterId}`, state: {meterId}}}
            >
              {intl.formatMessage({id: 'app.charges.nav.groupLab2'})}
            </LinkMaterial> }
            <Typography variant="h5">{group ? group.name : ''}</Typography>
          </Breadcrumbs>
        </Box>
        <Grid
          container
          spacing={2}
          style={{marginTop: theme.spacing.unit * 2}}
        >
          <Grid
            item
            lg={budgets}
            sm={6}
            xl={budgets}
            xs={12}
          >
            <Budget
              className={classes.item}
              consumes={group.consumes}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            lg={budgets}
            sm={6}
            xl={budgets}
            xs={12}
          >
            <Users
              className={classes.item}
              consumes={group.consumes}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            lg={budgets}
            sm={6}
            xl={budgets}
            xs={12}
          >
            <Profit
              className={classes.item}
              consumes={group.consumes}
              typeEnergy={typeEnergy}
            />
          </Grid>
          {group.bill === 1 &&
            <Grid
              item
              lg={budgets}
              sm={6}
              xl={budgets}
              xs={12}
            >
              <Receipt
                className={classes.item}
                ids={{ groupId: groupId }}
              />
            </Grid>
          }
          <Grid
            item
            lg={budgets}
            sm={6}
            xl={budgets}
            xs={12}
          >
            <Measurements
              className={classes.item}
              group={group}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <ConsumptionAndDemandChart
              className={classes.item}
              consumes={group.consumes}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}
          >
            <MonthWithMostConsumeChart
              className={classes.item}
              id={groupId}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <ComparativeYearConsumeChart
              className={classes.item}
              id={group.id}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}
          >
            <DayWithMostConsumeChart
              className={classes.item}
              id={group.id}
              typeEnergy={typeEnergy}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TopChargesChart
              className={classes.item}
              groupId={groupId}
              typeEnergy={typeEnergy}
            />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(styles)(Dashboard));
