import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './styles';
import ReactSpeedometer from 'react-d3-speedometer';
import {
  getLoadFactorHistory,
  postLastLoadCharge,
  postLoadCharge
} from 'js/actions/groupDashboardActions';
import theme from 'theme';
import { Line } from 'react-chartjs-2';
const useStyles = makeStyles(styles);

const LoadFactor = props => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chargeId } = props;
  const { className, ...rest } = props;
  const [open, setOpen] = useState(false);
  const rootClassName = classNames(classes.root, className);
  const loadFactorValue = useSelector(state => state.groupDashboard.loadCharge);
  const lastLoadFactorValue = useSelector(
    state => state.groupDashboard.lastLoadCharge
  );
  const loadHistory = useSelector(state => state.groupDashboard.loadHistory);
  const meses = [
    intl.formatMessage({ id: 'app.months.jan' }),
    intl.formatMessage({ id: 'app.months.feb' }),
    intl.formatMessage({ id: 'app.months.mar' }),
    intl.formatMessage({ id: 'app.months.apr' }),
    intl.formatMessage({ id: 'app.months.may' }),
    intl.formatMessage({ id: 'app.months.jun' }),
    intl.formatMessage({ id: 'app.months.jul' }),
    intl.formatMessage({ id: 'app.months.aug' }),
    intl.formatMessage({ id: 'app.months.sep' }),
    intl.formatMessage({ id: 'app.months.oct' }),
    intl.formatMessage({ id: 'app.months.nov' }),
    intl.formatMessage({ id: 'app.months.dec' })
  ];

  const formattedData = loadHistory && loadHistory.map(item => ({
    month: parseInt(item.month.slice(-2)),
    value: parseFloat(item.load_factor).toFixed(2),
    prom: parseFloat(item.promkwh).toFixed(2)
  }));
  

  const mesesOrdenados =
  formattedData && formattedData.map(item => meses[item.month - 1]).reverse();
  const datos =
  formattedData &&
  formattedData.map(item => parseFloat(item.value)).reverse();
  const dataPrice =
  formattedData && formattedData.map(item => parseFloat(item.prom)).reverse();

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  const lastMonth = meses[currentMonth - 1];

  const getDateRange = () => {
    let startDate, endDate;

    if (currentDay === 1) {
      const previousMonth = new Date(currentYear, currentMonth - 1, 1);
      startDate = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth(),
        1
      );
      endDate = new Date(currentYear, currentMonth, 0);
    } else if (currentDay === 2) {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(today);
    } else {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(currentYear, currentMonth, currentDay - 1);
    }

    const formatDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      return `${year}${month}${day}`;
    };

    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
  };

  const lastRange = () => {
    let startDate, endDate;

    if (currentMonth === 1) {
      const previousYear = new Date(currentYear - 1, currentMonth + 11, 1);
      startDate = new Date(
        previousYear.getFullYear(),
        previousYear.getMonth(),
        1
      );
      endDate = new Date(currentYear, currentMonth, 0);
    } else {
      const previousMonth = new Date(currentYear, currentMonth - 1, 1);
      startDate = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth(),
        1
      );
      endDate = new Date(currentYear, currentMonth, 0);
    }

    const formateDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      return `${year}${month}${day}`;
    };

    return {
      startDate: formateDate(startDate),
      endDate: formateDate(endDate)
    };
  };

  useEffect(() => {
    dispatch(
      postLoadCharge(getDateRange().startDate, getDateRange().endDate, chargeId)
    );
    dispatch(
      postLastLoadCharge(lastRange().startDate, lastRange().endDate, chargeId)
    );
  }, []);

  const formatPercentage = value => {
    if (value == null) {
      return '0.00';
    }
    return value.toFixed(2);
  };

  const segmentsStops = [0, 54, 70, 100];

  const segmentFormatter = value => {
    return `${value}%`;
  };

  const handleHistory = () => {
    dispatch(getLoadFactorHistory(chargeId));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const limitFp = datos ? Math.ceil((Math.max(...datos) + 10) / 10) * 10 : 100;
  const limitPrice = dataPrice ? Math.max(...dataPrice) + 2 : 10;

  const data = {
    labels: mesesOrdenados,
    datasets: [
      {
        label: 'Factor de Carga %',
        fill: false,
        backgroundColor: '#22B14C',
        borderColor: '#22B14C',
        data: datos,
        yAxisID: 'y-axis-1'
      },
      {
        label: 'Precio Medio $',
        fill: false,
        backgroundColor: '#ED685E',
        borderColor: '#ED685E',
        data: dataPrice,
        yAxisID: 'y-axis-2'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function(dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            const value = dataset.data[index];
            const unit = i === 0 ? '%' : '$'; 
            const posY = i === 0 ? bar._model.y - 5 : bar._model.y - 17;
            ctx.fillText(`${i === 0 ? value + ' ' + unit : unit + ' ' + value}`, bar._model.x, posY);
          });
        });
      }
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      callbacks: {
        label: function(tooltipItem, data) {
          const factorCarga = data.datasets[0].data[tooltipItem.index];
          const precioPromedio = data.datasets[1].data[tooltipItem.index];
  
          // Construimos el texto del tooltip mostrando ambos valores
          const label = `Factor de Carga: ${factorCarga.toFixed(2)}% | Precio Medio: $${precioPromedio.toFixed(2)}`;
  
          return label;
        }
      }
    },
    scales: {
      xAxes: [
        {
          offset: true,
        }
      ],
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            callback: function(value) {
              return `${value.toFixed(2)}%`;
            },
            max: limitFp
          },
          labels: {
            format: '{value} %'
          }
        },
        {
          id: 'y-axis-2',
          type: 'linear',
          display: true,
          border: '1px solid #22B14C',
          position: 'right',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            callback: function(value) {
              return `$${value.toFixed(2)}`;
            },
            max: limitPrice
          }
        }
      ]
    },
  };


  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel
          title={intl.formatMessage({ id: 'app.deepview.loadFactor.title' })}
        />
      </PortletHeader>
      <PortletContent
        style={{
          fontFamily: 'Lato, Helvetica Neue, Arial',
          gap: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '1rem'
          }}
        >
          <Typography
            style={{ marginBottom: theme.spacing.unit }}
            variant="h5"
          >
            {intl.formatMessage({
              id: 'app.meter.board.fpVariationInMonth.dataSet.labels.currMonth'
            })}
          </Typography>
          <ReactSpeedometer
            currentValueText={`${formatPercentage(loadFactorValue) || 0}%`}
            customSegmentStops={segmentsStops}
            forceRender
            height={125}
            labelFontSize={'12px'}
            maxValue={100}
            minValue={0}
            needleColor={'#000'}
            needleHeightRatio={0.6}
            ringWidth={40}
            segmentColors={['#EB0000', '#FAC800', '#00C454']}
            segments={3}
            segmentValueFormatter={segmentFormatter}
            textColor={'#000'}
            value={formatPercentage(loadFactorValue) || 0}
            valueFormat=".1f"
            valueTextFontSize={'20px'}
            valueTextFontWeight={'600'}
            width={200}
          />
        </Box>
        <Chip
          className={classes.title}
          label={`${intl.formatMessage({
            id: 'app.meter.board.loadFactor.closeLabel'
          })} ${lastMonth}`}
        />
        <Typography
          style={{ fontWeight: 600, textAlign: 'center' }}
          variant="h4"
        >
          {lastLoadFactorValue
            ? `${parseFloat(lastLoadFactorValue).toFixed(2)}%`
            : '%'}
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            color="secondary"
            onClick={handleHistory}
            style={{ marginBottom: theme.spacing.unit }}
            type="button"
            variant="contained"
          >
            Ver historial
          </Button>
        </Box>
      </PortletContent>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitle>
          <Chip
            className={classes.title}
            label="Historial de Factor de Carga"
          />
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.chartWrapper}>
            {datos ? (
              <Line
                data={data}
                options={options}
              />
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            style={{ marginRight: theme.spacing.unit * 2 }}
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Portlet>
  );
};

export default LoadFactor;
