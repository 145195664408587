import * as dashboardMeterActionTypes from 'js/constants/meterDashboard-types';
import axios from 'axios';
import getToken from 'helpers/auth';

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getTotalsActualMonth = meterId => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterId}/totalsactualmonth`,
        getconfig()
      )
      .then(response => {
        dispatch(getTotalsActual(response.data.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const getTotalsActual = totalsActual => {
  return {
    type: dashboardMeterActionTypes.GET_TOTAL_ACTUAL_MONTH_SUCCESS,
    totalsActual
  };
};

export const getMonthWithMostConsume = (corporativeId, meterId, year) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/consumes/monthmostconsume/${year}`,
        getconfig()
      )
      .then(response => {
        dispatch(monthWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const monthWithMostConsume = chartMonthMostConsume => {
  return {
    type: dashboardMeterActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS,
    chartMonthMostConsume
  };
};



export const getDayWithMostConsume = (corporativeId,meterId , year,month ) => {
    return async dispatch => {
      axios
        .get(
          `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/consumes/groupmostconsume/${year}/month/${month}`,
          getconfig()
        )
        .then(response => {
          dispatch(dayWithMostConsume(response.data.data));
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
};

export const dayWithMostConsume = chartDayMostConsume => {
  return {
    type: dashboardMeterActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS,
    chartDayMostConsume
  };
};

export const getChargeWithMostConsume = (corporativeId, meterId) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/consumes/chargewithmostconsume`,
        getconfig()
      )
      .then(response => {
        dispatch(chargeWithMostConsume(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const chargeWithMostConsume = chargeMostConsume => {
  return {
    type: dashboardMeterActionTypes.GET_CHARGE_WITH_MOST_CONSUME_SUCCESS,
    chargeMostConsume
  };
};


export const getComparativeYearConsume = (corporativeId,meterId , year) => {
    return async dispatch => {
      axios
        .get(
          `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/consumes/comparativeyearconsume/${year}`,
          getconfig()
        )
        .then(response => {
          dispatch(comparativeYearConsume(response.data.data));
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
};

export const comparativeYearConsume = chartComparativeYearConsume => {
  return {
    type: dashboardMeterActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS,
    chartComparativeYearConsume
  };
};



  export const getComparativeYearTariff = (corporativeId,meterId , years) => {
    return async dispatch => {
      axios
        .post(
          `https://api.metimur.mx/api/cfe/tariffs/comparativeinyears`,
          {
            corporative_id:corporativeId,
            meter_id:meterId,
            years: years
          },
          getconfig()
        )
        .then(response => {
          dispatch(comparativeYearTariff(response.data.data));
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
};

export const comparativeYearTariff = chartComparativeYearTariff => {
  return {
    type: dashboardMeterActionTypes.GET_COMPARATIVE_YEARS_TARIFF_SUCCESS,
    chartComparativeYearTariff
  };
};



  export const getFPByMeter = (meterId , funcResponse ) => {
    return async dispatch => {
      axios
        .get(
          `https://api.metimur.mx/api/meter/${meterId}/fp`,
          getconfig()
        )
        .then(response => {
         //dispatch(getTotalsActual(response.data.data));
         funcResponse(response.data.data )
        })
        .catch(function(error) {
          console.log( error);
        });
    };
  };


  
  export const fpConsume = (fpId, startDate , endDate , startHour , endHour , funcResponse ) => {
    return async dispatch => {
      axios
        .post(
          `https://api.metimur.mx/api/fp/consumes`,
          {
            fp_id:fpId,
            start_date : startDate ,
            end_date : endDate ,
            start_hour : startHour ,
            end_hour : endHour 
            
          },
          getconfig()
        )
        .then(response => {
          //dispatch(comparativeYearTariff(response.data.data));
          funcResponse(response.data.data )
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
};

export const getFpAvgPerMonth = (fpId, startDate, funcResponse) => {
  return async dispatch => {
    axios
      .post(
        `https://api.metimur.mx/api/fp/getFpAvgPerMonth`,
        {
          fp_id: fpId,
          date: startDate
        },
        getconfig()
      )
      .then(response => {
        //dispatch(comparativeYearTariff(response.data.data));
        funcResponse(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getDataByGroup = (corpId, meterId, funcResponse) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corpId}/meter/${meterId}/meterstotal`,
        getconfig()
      )
      .then(response => {
        //dispatch(getTotalsActual(response.data.data));
        funcResponse(response.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const getContractedDemand = (corporativeId, meterId, funcResponse) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeId}/meter/${meterId}/contracted_demand`,
        getconfig()
      )
      .then(response => {
        dispatch(getTotalsActual(response.data.data));
        funcResponse(response.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};
