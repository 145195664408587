import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography/*, Divider*/} from '@material-ui/core';

// Material icons
// import {
//   AccessTime as AccessTimeIcon,
//   GetApp as GetAppIcon
// } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class ProductCard extends Component {
  render() {
    const { classes, className, propGroup } = this.props;

    console.log(propGroup)

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper className={rootClassName}>
        <Link to={{
          pathname:this.props.destination,
          state:{ propGroup: propGroup}
          }}>
        <div className={classes.descritionButton}>
          <div className={classes.imageWrapper}>
            <i className={this.props.fontIcon}></i>
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h1"
            >
            {this.props.productname}
            </Typography>
          </div>
        </div>
        </Link>
      </Paper>
    );
  }
}

ProductCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);
