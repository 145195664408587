import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services

// Custom components
import {ControlRateForm, DayWithMostConsumeChart} from './components';

// Component styles
import styles from './style';

class UserList extends Component {
  constructor(props){
    super(props)
  }
  
  signal = true;

  state = {
    isLoading: false,
    limit: 10,
    users: [],
    selectedUsers: [],
    error: null
  };

  render() {
    const { classes } = this.props;


    return (
      <DashboardLayout title="Buscador Tarifario">
        <div className={classes.root}>
          <div className={classes.content}>
            <DayWithMostConsumeChart style={{ marginBottom: '20px' }}/>
            <ControlRateForm/>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
