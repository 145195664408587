import React, { useEffect } from 'react';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// import validate from 'validate.js';
// import _ from 'underscore';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Material helpers
import { makeStyles  , Typography } from '@material-ui/core';

// Material components
import {
  Button,
  //Checkbox,
  // CircularProgress,
  Grid,
  //IconButton,
  TextField
  // Typography
} from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

// Component styles
import styles from './styles';
import { postDataOnOffCard,showHomeworkInputs,getOnOffCards, postHomeworkData } from 'js/actions/onOffAcrtions';

const useStyles = makeStyles(styles);


export default function GroupForm(props) {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { concentratorId, cardId } = props.match.params;

  const cards = useSelector(state => state.controlCards.cards);
  const inputs = useSelector(state => state.controlCards.inputs);

  const [controlCard, setControlCard] = React.useState(null);
  const [input, setInput] = React.useState(null);
  const [action, setAction] = React.useState(null);

  const [days0, setDays0] = React.useState([]);
  const [repeat0, setRepeat0] = React.useState(null);

  const [days1, setDays1] = React.useState([]);
  const [repeat1, setRepeat1] = React.useState(null);


  useEffect(() => {
    dispatch(getOnOffCards(cardId));
  }, []);

  const onSubmit = values => {
    //console.log('DATA: ', values);
    dispatch(postHomeworkData(values, concentratorId, cardId));
  };


  const handleControlCard = card => {
    setControlCard(card.target.value);
    dispatch(showHomeworkInputs(card.target.value))
  };

  const handleInput = input => {
    setInput(input.target.value);
    
  };



  const handleAction = action => {
    setAction(action.target.value);
  };



  const handleChangeDays0 = event => {
    setDays0(event.target.value);
  };

  const handleRepeat0= repeat => {
    setRepeat0(repeat.target.value);
  };


  const handleChangeDays1 = event => {
    setDays1(event.target.value);
  };

  const handleRepeat1= repeat => {
    setRepeat1(repeat.target.value);
  };



  return (
    <DashboardLayout title="Creacion de Tarea">
      <div className={classes.root}>
        <Portlet>
          <PortletHeader>
            <PortletLabel title="Tarea de Control" />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Nombre de Tarea"
                      name="name"
                      required
                      //value={name}
                      variant="filled"
                    />
                    <TextField
                      className={classes.textField}
                      inputRef={register}
                      label="Descripcion"
                      name="description"
                      required
                      //value={description}
                      variant="filled"
                    />
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                          Tarjeta de Control
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="card_onoff_id"
                        onChange={handleControlCard}
                        value={controlCard}
                        //labelWidth={labelWidth}
                      >
                        {cards ? (cards.map(card => (
                          <MenuItem value={card.id}>
                            {card.name}
                          </MenuItem>
                        ))): (console.log('cargando tarjetas'))}
                
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                          Entrada
                      </InputLabel>
                      <Select
                        id="demo-simple-select-outlined"
                        inputRef={register}
                        labelId="demo-simple-select-outlined-label"
                        name="card_onoff_input"
                        onChange={handleInput}
                        value={input}
                        //labelWidth={labelWidth}
                      >
                        {inputs.map(input=> (
                          <MenuItem value={input}>
                            {input}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      className={classes.textField}
                      defaultValue="2017-05-24"
                      inputRef={register}
                      label="Dia de Inicio"
                      name="init_date"
                      type="date"
                    />
                    
                    <TextField
                      className={classes.textField}
                      defaultValue="2017-05-24"
                      inputRef={register}
                      label="Dia de Fín"
                      name="end_date"
                      type="date"
                    />





                    <div>                        
                      <div  style={{ marginTop:'10px' }}  >
                        <Typography
                          style={{ color: '#730065' , textTransform : 'uppercase'  }}
                          variant="h3"
                        >Encendido  </Typography>
                      </div>
                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        label="Hora"
                        name="hour1"
                        required
                        type="time"
                        //value={description}
                        variant="filled"
                      />
                      <FormControl
                        className={classes.textField}
                        required
                      >
                        <InputLabel id="demo-simple-select-label">
                                  Dias
                        </InputLabel>
                        <Select
                          inputRef={register}
                          labelId="demo-simple-select-disabled-label"
                          multiple
                          name="days1"
                          onChange={handleChangeDays1}
                          value={Object.values(days1)}
                        >
                          <MenuItem value={'01'}>Lunes</MenuItem>
                          <MenuItem value={'02'}>Martes</MenuItem>
                          <MenuItem value={'03'}>Miercoles</MenuItem>
                          <MenuItem value={'04'}>Jueves</MenuItem>
                          <MenuItem value={'05'}>Viernes</MenuItem>
                          <MenuItem value={'06'}>Sabado</MenuItem>
                          <MenuItem value={'00'}>Domingo</MenuItem>
                        </Select>
                      </FormControl>                  
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          //inputRef={useOnMount}
                        >
                                  Retraso(s)
                        </InputLabel>
                        <Select
                          id="demo-simple-select-outlined"
                          inputRef={register}
                          labelId="demo-simple-select-outlined-label"
                          name="delay1"
                          onChange={handleRepeat1}
                          value={repeat1}
                          //labelWidth={labelWidth}
                        >
                          <MenuItem value={'00'}>0</MenuItem>
                          <MenuItem value={'10'}>10</MenuItem>
                          <MenuItem value={'20'}>20</MenuItem>
                          <MenuItem value={'30'}>30</MenuItem>
                          <MenuItem value={'40'}>40</MenuItem>
                          <MenuItem value={'50'}>50</MenuItem>
                        </Select>
                      </FormControl>
                    </div>                     





                          
                    <div  style={{ marginBottom:'15px' , marginTop:'40px' }}  > 

                      <div  style={{ marginBottom:'10px' }}  >
                        <Typography
                          style={{ color: '#730065' , textTransform : 'uppercase'  }}
                          variant="h3"
                        >Apagado  </Typography>
                      </div>

                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        label="Hora"
                        name="hour0"
                        required
                        type="time"
                        //value={description}
                        variant="filled"
                      />
                      <FormControl
                        className={classes.textField}
                        required
                      >
                        <InputLabel id="demo-simple-select-label">
                                Dias
                        </InputLabel>
                        <Select
                          inputRef={register}
                          labelId="demo-simple-select-disabled-label"
                          multiple
                          name="days0"
                          onChange={handleChangeDays0}
                          value={Object.values(days0)}
                        >
                          <MenuItem value={'01'}>Lunes</MenuItem>
                          <MenuItem value={'02'}>Martes</MenuItem>
                          <MenuItem value={'03'}>Miercoles</MenuItem>
                          <MenuItem value={'04'}>Jueves</MenuItem>
                          <MenuItem value={'05'}>Viernes</MenuItem>
                          <MenuItem value={'06'}>Sabado</MenuItem>
                          <MenuItem value={'00'}>Domingo</MenuItem>
                        </Select>
                      </FormControl>                  
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          //inputRef={useOnMount}
                        >
                                Retraso(s)
                        </InputLabel>
                        <Select
                          id="demo-simple-select-outlined"
                          inputRef={register}
                          labelId="demo-simple-select-outlined-label"
                          name="delay0"
                          onChange={handleRepeat0}
                          value={repeat0}
                          //labelWidth={labelWidth}
                        >
                          <MenuItem value={'00'}>0</MenuItem>
                          <MenuItem value={'10'}>10</MenuItem>
                          <MenuItem value={'20'}>20</MenuItem>
                          <MenuItem value={'30'}>30</MenuItem>
                          <MenuItem value={'40'}>40</MenuItem>
                          <MenuItem value={'50'}>50</MenuItem>
                        </Select>
                      </FormControl>
                    </div>





                  </div>
                </Grid>
              </Grid>

              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </form>
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
}
