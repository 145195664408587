import * as onOffActionTypes from 'js/constants/onOffAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history'
import Swal from 'sweetalert2';

const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getOnOffCards = cardId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/onoffcard/card/${cardId}`,  getconfig()   )
      .then(response => {
        dispatch(getCards(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getCards = cardsOnOff => {
  return {
    type: onOffActionTypes.GET_ONOFF_CARD_SUCCESS,
    cardsOnOff
  };
};


export const getHomeWorkData = cardId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/onoff/card/${cardId}`,  getconfig() )
      .then(response => {
        dispatch(getHomework(response.data.data));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};

export const getHomework = homeworks => {
  return {
    type: onOffActionTypes.GET_HOMEWORKS_SUCCESS,
    homeworks
  };
};

export const postDataOnOffCard = (cardOnOff,concentrator_id,card_id,meter) => {
    return async dispatch => {
      axios
        .post(
          `https://api.metimur.mx/api/onoffcard`,
          {
            name: cardOnOff.name,
            description: cardOnOff.description,
            ubication: cardOnOff.ubication,
            concentrator_id: concentrator_id,
            card_id: card_id,
            direction_id: cardOnOff.direction_id
          },
          getconfig() 
        )
  
      .then(response => {
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Tarjeta de Control ${cardOnOff.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${meter}`)}
        );
      })
      .catch(error => {
        //dispatch(postCardFail(error.message));
        const errorMessage = error.response.data.message;

        Swal.fire({
          title: 'Error al actualizar',
          html: `Se encontraron los siguientes errores:<br/>${errorMessage.join('<br/>')}`,
          icon: 'error'
        });
      });
  };
};
  
export const postCardStarted = () => ({
  type: onOffActionTypes.POST_ONOFF_CARD_STARTED
});
  
export const postCardSuccess = data => {
  return {
    payload: {
      name: data.name,
      description: data.description,
      ubication: data.ubication,
      concentrator_id: data.concentrator_id,
      card_id: data.card_id,
      direction_id: data.direction_id
    }
  };
};
  
export const postCardFail = error => ({
  type: onOffActionTypes.POST_ONOFF_CARD_FAILURE,
  state: { error }
});
  
export const showHomeworkInputs = controlCardId => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/onoffcard/${controlCardId}/inputs`,  getconfig() )
      .then(response => {
        dispatch(getCardsInputs(Object.values(response.data.data)));
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};
export const getCardsInputs = homeworkInputs => {
  return {
    type: onOffActionTypes.GET_ONOFF_CARD_INPUT_SUCCESS,
    homeworkInputs
  };
};


export const postHomeworkData = (homework,concentrator_id,card_id,meter) => {
  return async dispatch => {
    //console.log('TAREJTA ' + JSON.stringify(card));
    //dispatch(postCardStarted());

    axios
      .post(
        `https://api.metimur.mx/api/onoff`,
        {
          concentrator_id: concentrator_id,
          card_id: card_id,
          card_onoff_id: homework.card_onoff_id,
          card_onoff_input: homework.card_onoff_input,
          name: homework.name,
          description: homework.description,
          action:homework.action,
          init_date:homework.init_date,
          end_date:homework.end_date,
          tasks: [
            {
              type: 1,
              hours: homework.hour1  ,
              delay: homework.delay1 ,
              days: Object.values(homework.days1.split(',')) 
            },
            {
              type: 0,
              hours: homework.hour0  ,
              delay: homework.delay0 ,
              days: Object.values(homework.days0.split(',')) 
            }
          ]



         
        },
        getconfig() 
      )
      .then(response => {
      })
      .then(()=>{
        Swal.fire({
          title: 'Alta Correcta',
          text: `Tarea de Control ${homework.name} creada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${meter}`)}
        );
      })
      .catch(error => {
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};



export const postHomeworkSuccess = homework => {
  return {
    payload: {
      concentrator_id: homework.concentrator_id,
      card_id: homework.card_id,
      card_onoff_id: homework.card_onoff_id,
      card_onoff_input: homework.card_onoff_input,
      name: homework.name,
      description: homework.description,
      action:homework.action,
      hour:homework.hour,
      days:homework.days,
      init_date:homework.init_date,
      end_date:homework.end_date,
      repeat:homework.repeat
    }
  };
};

export const deleteCard = cardId => {
  return async dispatch => {

    dispatch(deleteCardSuccess(cardId));
    // axios.delete(
    //   `https://api.metimur.mx/api/corporative`,
    //   { corporative_id: corpId },
    //   getconfig()
    // )
    // .then(response => {
      
    // })
    // ;
  };
};

export const deleteHomeworkData = (id , cardId )  => {
  return async dispatch => {

     axios.post(
       `https://api.metimur.mx/api/onoff/delete`,
       { id: id },
       getconfig() 
     )
     .then(response => {
    })
    .then(()=>{
      Swal.fire({
        title: 'Actualización Correcta',
        text: `Tarea de Control eliminada exitosamente`,
        icon: 'success',
      }).then(
          ()=> dispatch(getHomeWorkData(cardId))
        // ()=>{history.push(`/homeworks/${cardId}`)}
        );
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: `${error.response.data.message}`,
          icon: 'error',
        })
      });



  };
};

 

export const updateHomeworkData = ( id ,  homework,concentrator_id,card_id,meter  ) => {
  return async dispatch => {
    //console.log('TAREJTA ' + JSON.stringify(card));
    //dispatch(postCardStarted());

    axios
      .put(
        `https://api.metimur.mx/api/onoff`,
        {
          id:id ,
          concentrator_id: concentrator_id,
          card_id: card_id,
          card_onoff_id: homework.card_onoff_id,
          card_onoff_input: homework.card_onoff_input,
          name: homework.name,
          description: homework.description,     
          init_date:homework.init_date,
          end_date:homework.end_date,   
          tasks: [
            {
              type: 1,
              hours: homework.hour1  ,
              delay: homework.delay1 ,
              days: Object.values(homework.days1.split(',')) 
            },
            {
              type: 0,
              hours: homework.hour0  ,
              delay: homework.delay0 ,
              days: Object.values(homework.days0.split(',')) 
            }
          ]


         
        },
        getconfig() 
      )
      .then(response => {
      })
      .then(()=>{
        Swal.fire({
          title: 'Actualización Correcta',
          text: `Tarea de Control ${homework.name} actualizada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push(`/hardwareConfiguration/${meter}`)}
        );
      })
      .catch(error => {
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};






export const deleteCardSuccess = cardId => {
  return {
    type: onOffActionTypes.DELETE_ONOFF_CARD_SUCCESS,
    payload:{id: cardId} 
  };
};

export const updateDataCardControl = (cardOnOff,cardValue) => {
  console.log(cardOnOff,cardValue)
  return async dispatch => {
    //console.log('TAREJTA ' + JSON.stringify(card));
    // dispatch(updateCardStarted());

    axios
      .put(
        `https://api.metimur.mx/api/onoffcard`,
        {
          id:cardValue.id,
          name: cardOnOff.name,
          description: cardOnOff.description,
          ubication: cardOnOff.ubication,
          concentrator_id: cardValue.concentrator_id,
          card_id: cardValue.card_id,
          direction_id: cardOnOff.direction_id
        },
        getconfig() 
      )
      .then(()=>{
        Swal.fire({
          title: 'Actualizacion Correcta',
          text: `Tarjeta ${cardOnOff.name} actualizada exitosamente`,
          icon: 'success',
        }).then(
          ()=>{history.push('/home')}
        );
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          Swal.fire({
            title: 'Error al actualizar',
            html: `Se encontraron los siguientes errores al actualizar:<br/>${errorMessage.join('<br/>')}`,
            icon: 'error'
          });
        } else {
          dispatch(postCardFail(error.message));
          console.log('ERROR API:' + error);
        }
      });
  };
};

export const updateCardStarted = () => ({
  type: onOffActionTypes.PUT_ONOFF_CARD_STARTED
});