/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import * as userActionsTypes from 'js/constants/userAction-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { showCorporatives } from '.';

const getconfig = () => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
};

//LOGIN
export const login = user => {   
  return async dispatch => {
    dispatch(loginStarted(user));
    axios
      .post('https://api.metimur.mx/api/auth/login', {
        email: user.email,
        password: user.password,
        remember_me: false
      })
      .then(function(response) {
        dispatch(loginSuccess(response.data));              
        localStorage.setItem('access_token', response.data.access_token);   
        dispatch(showCorporatives());     
        history.push('/');
      }).catch(error => {
        Swal.fire({
          title: 'Error',
          text: `${error.response.data.message}`,
          icon: 'error'
        });
      });
  };
};



export const loginStarted = user => ({
  type: userActionsTypes.USER_LOGIN_STARTED,
  user
});

export const loginSuccess = user => ({
  type: userActionsTypes.USER_LOGIN_SUCCESS,
  user
});

export const loginFailure = error => ({
  type: userActionsTypes.USER_LOGIN_FAILURE,
  error
});

//SIGNUP
export const signUp = (user, corpId, access_list, metimur_user = false) => {
  return async dispatch => {
    dispatch(signUpStarted(user));
    axios
      .post(
        'https://api.metimur.mx/api/auth/signup',
        {
          firstname: user.firstname,
          lastname: user.lastname,
          corporative_id: corpId,
          role_id: JSON.parse(user.role),
          tel: user.tel,
          cel: user.cel,
          email: user.email,
          password: user.password,
          access_list: access_list,
          metimur_user: metimur_user , 
          rfc: user.rfc ,
          razon_social:user.razon_social , 
          cfdi:user.cfdi ,
          tipo_de_pago:user.payment_type ,
          metodo_de_pago:user.payment_method,
          active: 1
        },
        getconfig()
      )
      .then(function(response) {
        dispatch(signUpSuccess(user));
      })
      .then(response => {
        swal({
          title: 'Alta de Usuario Correcto',
          text: `${user.firstname} ${user.lastname}`,
          icon: 'success'
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: `${error.response.data.message}`,
          icon: 'error'
        });
      });
  };
};

export const signUpStarted = () => ({
  type: userActionsTypes.USERS_SIGNUP_STARTED
});

export const signUpSuccess = users => ({
  type: userActionsTypes.USERS_SIGNUP_SUCCESS,
  users
});

export const signUpFailure = error => ({
  type: userActionsTypes.USERS_SIGNUP_FAILURE,
  error
});

//LOGOUT
export const logout = () => {
  return dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/auth/logout',
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(response => {

        let selLangTmp= localStorage.getItem('prefLang');
        let localeTmp= localStorage.getItem('locale');

        localStorage.clear();
        if( selLangTmp && localeTmp ){
          localStorage.setItem('prefLang', selLangTmp );
          localStorage.setItem('locale', localeTmp );
        }

        history.push('/login');
        dispatch(logoutSuccess());
      });
  };
};

export const logoutSuccess = () => ({
  type: userActionsTypes.USER_LOGOUT_SUCCESS
});

export const recoveredEmail = email => {
  return async dispatch => {
    //dispatch(loginStarted(user));
    axios
      .post('https://api.metimur.mx/api/password/create', email)
      .then(function(response) {
        Swal.fire({
          title: 'Correo Enviado Exitosamente',
          icon: 'success'
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: `${error.response.data.message}`,
          icon: 'error'
        });
      });
  };
};

export const recoveredEmailSuccess = () => ({
  type: userActionsTypes.USER_LOGIN_STARTED
});

export const recoveredData = (email, token) => {
  return async dispatch => {
    //dispatch(loginStarted(user));
    axios
      .post('https://api.metimur.mx/api/password/reset', {
        email: email.email,
        password: email.password,
        password_confirmation: email.password_confirmation,
        token: token
      })
      .then(function(response) {
        Swal.fire({
          title: 'Contraseña actualizada correctamente',
          text: '',
          icon: 'success'
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: `${error.response.data.message}`,
          icon: 'error'
        });
      });
  };
};

//GET USERS

export const showAllUsers = corporativeID => {
  return async dispatch => {
    dispatch(getAllUsersStarted());
    axios
      .get(
        `https://api.metimur.mx/api/user/corporative/${corporativeID}`,
        getconfig()
      )
      .then(response => {
        dispatch(getAllUsers(response.data.data));
      })
      .catch(function(error) {
        console.log('error: ' + error);
      });
  };
};

export const getAllUsers = users => {
  return {
    type: userActionsTypes.GET_ALL_USERS_SUCCESS,
    users
  };
};

export const getAllUsersStarted = () => ({
  type: userActionsTypes.GET_ALL_USERS_STARTED
});

export const setCorpId = corpId => {
  return {
    type: userActionsTypes.SET_CORP_ID,
    corpId
  };
};

export const setMeterId = meterId => {
  return {
    type: userActionsTypes.SET_METER_ID,
    meterId
  };
};

export const setGroupId = groupId => {
  return {
    type: userActionsTypes.SET_GROUP_ID,
    groupId
  };
};

export const showMetimurUsers = () => {
  return async dispatch => {
    axios
      .get('https://api.metimur.mx/api/users/metimur', getconfig())
      .then(response => {
        dispatch(getMetimurUsers(response.data.data));
      })
      .catch(function(error) {
        console.log('error: ' + error);
      });
  };
};

export const getMetimurUsers = users => {
  return {
    type: userActionsTypes.GET_ALL_USERS_SUCCESS,
    users
  };
};

export const updateAccount = user => {
  return async dispatch => {
    axios
      .put(
        'https://api.metimur.mx/api/auth/user/update',
        {
          firstname: user.firstname,
          lastname: user.lastname,
          tel: user.tel,
          email: user.email,
          password: user.password
        },
        getconfig()
      )
      .then(function(response) {
        dispatch(accountUpdateSuccess(user));
      })
      .then(response => {
        Swal.fire({
          title: 'Cuenta Actualizada Exitosamente',
          text: 'A continuación se cerrara tu sesion para actualizar tus credenciales',
          icon: 'success'
        }).then(() => {
          localStorage.clear();
          history.push('/login');
        });
      })
      .catch(function(e) {
        const errorMessage = e.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `Favor de Validar todos los campos y sus reglas: <br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};

export const accountUpdateSuccess = () => ({
  type: userActionsTypes.USERS_UPDATE_SUCCESS
});

export const updateAccountAdmin = (user, id , access_list ) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/admin/user/update',
        {
          user_id: id,
          firstname: user.firstname,
          lastname: user.lastname,
          tel: user.tel,
          role: user.role,
          email: user.email, 
          access_list: access_list,
          password: user.password
        },
        getconfig()
      )
      .then(function(response) {
        dispatch(accountUpdateAdminSuccess(user));
      })
      .then(response => {
        Swal.fire({
          title: 'Cuenta Actualizada Exitosamente',
          text: 'A continuación se cerrara tu sesion para actualizar tus credenciales',
          icon: 'success'
        }).then(() => {
          localStorage.clear();
          history.push('/login');
        });
      })
      .catch(function(e) {
        Swal.fire({
          title: 'Error',
          text: 'Favor de Validar todos los campos y sus reglas',
          icon: 'error'
        });
      });
  };
};

export const accountUpdateAdminSuccess = () => ({
  type: userActionsTypes.USERS_UPDATE_SUCCESS
});

export const cleanUsers = () => ({
  type: userActionsTypes.CLEAN_USER,
  payload: []
});

export const deleteUser = user => {
  return async dispatch => {
    dispatch(deleteUserSuccess(user.id));
    axios.post(
      'https://api.metimur.mx/api/admin/user/delete',
      { user_id: user.id },
      getconfig()
    );
  };
};

export const deleteUserSuccess = userId => {
  return {
    type: userActionsTypes.DELETE_USER_SUCCESS,
    payload: { id: userId }
  };
};


export const getUserCharges = (userId , funcResp  ) => {
  return async dispatch => {
    dispatch(getAllUsersStarted());
    axios
      .get(
        `https://api.metimur.mx/api/user/${userId}`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data )
        //dispatch(getAllUsers(response.data.data));
      })
      .catch(function(error) {
        console.log('error: ' + error);
      });
  };
};


export const blockUserAdmin = (userId, active) => {
  return async dispatch => {
    axios
      .post(
        'https://api.metimur.mx/api/admin/user/activation_user',
        {
          user_id: userId,
          active: active
        },
        getconfig()
      )
      .then(function(response){
        dispatch(blockUserSuccess(userId, active));
      })
      .catch(function(error){
        console.log('error: '+ error)
      });
  }
}

export const blockUserSuccess = (userId, active) => {
  return {
    type: userActionsTypes.BLOCK_USER_SUCCESS,
    payload: {userId, active}
  }
}